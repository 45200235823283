import colors from "../../../../../theme/colors";

const styles = theme => ({
  tableHeadCell: {
    fontSize: "0.875rem",
    color: theme.palette.text.disabled,
    borderBottom: `1px solid ${colors.backgroundDarkGray}`,
    padding: theme.spacing(1),
  },
  tableDataCell: {
    fontSize: "0.875rem",
    verticalAlign: "top",
    padding: theme.spacing(1),
  },
  cellText: {
    fontSize: "0.875rem",
    lineHeight: 1.25,
    marginBottom: theme.spacing(1),
  },
  cellSecondaryText: {
    fontSize: "0.75rem",
    lineHeight: 1.25,
    color: theme.palette.text.disabled,
    marginTop: theme.spacing(0.5),
  },
  pdfHeader: {
    textAlign: "center",
  },
  netAmount: {
    fontWeight: "bold",
  },
  totalAmount: {
    color: theme.palette.text.disabled,
  },
  invoiceDate: {
    width: "8%",
  },
  customerCell: {
    width: "25%",
    verticalAlign: "middle",
  },
  invoiceNumber: {
    width: "10%",
  },
  productCell: {
    width: "20%",
  },
  documentType: {
    width: "8%",
  },
  amount: {
    width: "8%",
  },
  pdf: {
    width: "8%",
    verticalAlign: "middle",
  },
  allowedDownload: {
    cursor: "pointer",
  },
  notAllowedDownload: {
    cursor: "not-allowed",
  },
  mark: {
    width: "4%",
  },
});

export default styles;

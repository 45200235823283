import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CheckboxItem from "../checkboxItem";
import "./stockNotificationSelection.css";
import {
  isAnotherUserNotification,
  prepareNotificationCheckboxOptions,
  formatDateToMMDDYYYY,
} from "../../../utils/stockNotificationUtil";

function StockNotificationSelection({
  onChange,
  emails,
  checkboxItemsStatus,
  notifications = [],
  user = {},
}) {
  const { t } = useTranslation();
  const checkboxOptions = prepareNotificationCheckboxOptions(
    notifications,
    emails,
    user
  );

  return (
    <div className="stock-notification__container">
      {checkboxOptions.map(n => (
        <CheckboxItem
          key={n.email}
          onChange={onChange}
          checked={checkboxItemsStatus[n.email]}
          disabled={isAnotherUserNotification(n, user)}
          value={n.email}
          label={n.email === user.email ? t("notifyMe") : t("notifyOthers")}
          extraText={`${t("subscribed")} ${n.name} ${formatDateToMMDDYYYY(
            new Date(n.creationDate)
          )}`}
          showExtraText={isAnotherUserNotification(n, user)}
        />
      ))}
    </div>
  );
}

StockNotificationSelection.propTypes = {
  onChange: PropTypes.func,
  emails: PropTypes.arrayOf(PropTypes.shape({})),
  checkboxItemsStatus: PropTypes.shape({}),
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({}),
};

StockNotificationSelection.defaultProps = {
  onChange: () => {}, // eslint-disable-line
  emails: [],
  checkboxItemsStatus: {},
  notifications: [],
  user: {},
};

export default StockNotificationSelection;

const Decimal = require("decimal.js-light");
const moment = require("moment");

// 'ZROO' - whole sale price
// 'ZDS1' - annual discount (this is already in final price)
// 'ZDS2' - campaign discount
// 'ZR01' - SAP Condition type ZR01 (fixed)
// 'ZR02' - Tender price (fixed)
// 'ZR03' - best price (fixed)
// 'ZRDW' - discounted WS price
// 'MWST' - output tax
// 'VPRS' - internal price
// 'ZCDF' - changing delivery Fee (not needed)
// 'ZDIS' - distribution fee (not needed)
// 'ZDHF' - damage handling fee (not needed)
const PRECISION = 2;
export const PRICE_TYPE_NORMAL = "NO_CODE";
export const PRICE_TYPE_DISCOUNT = "ZDS1";
export const PRICE_TYPE_CAMPAIGN = "ZDS2";

export const PRICE_TYPE_ZR01 = "ZR01";
export const PRICE_TYPE_ZR02 = "ZR02";
export const PRICE_TYPE_ZR03 = "ZR03";

// helper
const isValidCondType = condType =>
  condType === PRICE_TYPE_DISCOUNT || condType === PRICE_TYPE_CAMPAIGN;

const filterScalesByType = (entries, condType, singleProductBestPrice) =>
  entries
    .filter(
      x =>
        x.type === condType &&
        x.price.comparedTo(singleProductBestPrice) < 0 &&
        !x.quantity.equals(1)
    )
    .sort((a, b) => a.quantity - b.quantity);

export const convertScaleToGenericEntries = priceData => {
  const price = new Decimal(priceData.price);
  // filter valid entries
  const scaleEntries = priceData.scales.filter(scale =>
    isValidCondType(scale.condType)
  );
  return scaleEntries.map(entry => {
    // amount is negative
    const discountPercent = new Decimal(1000)
      .plus(entry.amount)
      .div(1000)
      .toFixed(PRECISION);
    const quantity = new Decimal(entry.quantity);
    return {
      type: entry.condType,
      quantity,
      price: price.mul(discountPercent),
    };
  });
};

export const getBestQuantityBasedDiscounts = discounts => {
  const sortedDiscounts = discounts.sort((a, b) => a.quantity - b.quantity);
  const result = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const discount of sortedDiscounts) {
    if (result.length === 0) {
      result.push(discount);
      continue; // eslint-disable-line no-continue
    }
    const lastDiscount = result[result.length - 1];
    if (
      lastDiscount.quantity === discount.quantity &&
      lastDiscount.price.comparedTo(discount.price) > 0
    ) {
      result[result.length - 1] = discount;
      continue; // eslint-disable-line no-continue
    }
    if (lastDiscount.price.comparedTo(discount.price) > 0) {
      result.push(discount);
    }
  }
  return result;
};

export const getPrices = (priceData, productCount) => {
  if (priceData == null || priceData.price == null) {
    return {
      price: "0",
      discountPrice: "0",
      campaignPrice: "0",
      bestPrice: "0",
      vatRate: "0",
    };
  }

  // convert all to generic form
  const entries = convertScaleToGenericEntries(priceData);

  // sort campaigns first (ZDS1 vs. ZDS2)
  entries.sort((a, b) => a.type.localeCompare(b.type)).reverse();

  const isNextCheaper = (current, next) => {
    if (current == null) {
      return next;
    }
    return next.lessThan(current);
  };

  const getBest = (currentBest, nextPrice, nextType) => {
    if (currentBest.price == null || nextPrice.lessThan(currentBest.price)) {
      return {
        price: nextPrice,
        type: nextType,
      };
    }
    return currentBest;
  };

  // find out best discount and campaign
  const prices = entries.reduce(
    (curr, nextEntry) => {
      // enough quantity?
      if (nextEntry.quantity.lessThanOrEqualTo(productCount)) {
        // next
        const next = curr;

        // is it campaign or annual
        if (nextEntry.type === PRICE_TYPE_CAMPAIGN) {
          if (isNextCheaper(curr.campaign, nextEntry.price)) {
            // update with cheaper price
            next.campaign = nextEntry.price;

            // update best
            next.best = getBest(
              curr.best,
              nextEntry.price,
              PRICE_TYPE_CAMPAIGN
            );
          }
        } else if (isNextCheaper(curr.discount, nextEntry.price)) {
          // update with cheaper price
          next.discount = nextEntry.price;

          // update best
          next.best = getBest(curr.best, nextEntry.price, PRICE_TYPE_DISCOUNT);
        }
        return next;
      }
      // skip since not enough quantity (count)
      return curr;
    },
    {
      discount: priceData.discountPrice
        ? new Decimal(priceData.discountPrice)
        : null,
      campaign: priceData.campaignPrice
        ? new Decimal(priceData.campaignPrice)
        : null,
      best: {
        price: new Decimal(priceData.bestPrice),
        type: priceData.bestPriceType,
      },
    }
  );

  // get the actual price
  const discountPrice = prices.discount;
  const campaignPrice = prices.campaign;
  const bestPrice = prices.best.price;
  const bestPriceType = prices.best.type;
  const singleProductBestPrice = new Decimal(priceData.bestPrice);
  const singleProductBestPriceType = priceData.bestPriceType;

  const quantityBasedCampaigns = filterScalesByType(
    entries,
    PRICE_TYPE_CAMPAIGN,
    singleProductBestPrice
  );

  const quantityBasedYearlyDiscount = filterScalesByType(
    entries,
    PRICE_TYPE_DISCOUNT,
    singleProductBestPrice
  );

  const bestQuantityBasedDiscount = getBestQuantityBasedDiscounts([
    ...quantityBasedCampaigns,
    ...quantityBasedYearlyDiscount,
  ]);

  return {
    ...priceData,
    discountPrice:
      discountPrice != null ? discountPrice.toFixed(PRECISION) : null,
    campaignPrice:
      campaignPrice != null ? campaignPrice.toFixed(PRECISION) : null,
    bestPrice: bestPrice.toFixed(PRECISION),
    bestPriceType,
    singleProductBestPrice: singleProductBestPrice.toFixed(PRECISION),
    singleProductBestPriceType,
    // is there valid campaign ongoing regardless of the price
    campaign: campaignPrice != null,
    // is there better discount price (not including the same value)
    discount: discountPrice != null,
    quantityBasedCampaigns,
    quantityBasedYearlyDiscount,
    bestQuantityBasedDiscount,
  };
};

export const calculateVatPrice = (price, vatPercent) => {
  const vatDecimal = new Decimal(vatPercent || 0).div(100);
  return new Decimal(price)
    .mul(vatDecimal.plus(1).toNumber())
    .toFixed(PRECISION);
};

export const calculateCartProductPrice = (cartProduct, pricesMap) => {
  const { product } = cartProduct;
  const { count } = cartProduct;
  const priceData = pricesMap.get(product.materialId);

  if (priceData != null) {
    const prices = getPrices(priceData, count);
    return {
      price: new Decimal(prices.price).mul(count).toFixed(PRECISION),
      discountPrice:
        prices.discountPrice != null
          ? new Decimal(prices.discountPrice).mul(count).toFixed(PRECISION)
          : null,
      campaignPrice:
        prices.campaignPrice != null
          ? new Decimal(prices.campaignPrice).mul(count).toFixed(PRECISION)
          : null,
      bestPrice: new Decimal(prices.bestPrice).mul(count).toFixed(PRECISION),
      bestPriceType: prices.bestPriceType,
      campaign: prices.campaign,
      discount: prices.discount,
      vatRate: new Decimal(priceData.vatRate || 0).toFixed(PRECISION),
    };
  }
  return null;
};

export const calculateCartProductOverallPrice = (cartProducts, pricesMap) => {
  if (Array.isArray(cartProducts) && pricesMap) {
    const result = cartProducts.reduce(
      (acc, next) => {
        const prices = calculateCartProductPrice(next, pricesMap);
        if (prices == null) {
          return acc;
        }

        return {
          price: acc.price.plus(prices.bestPrice),
          priceVat: acc.priceVat.plus(
            calculateVatPrice(prices.bestPrice, prices.vatRate)
          ),
        };
      },
      {
        price: new Decimal(0),
        priceVat: new Decimal(0),
      }
    );
    return {
      price: result.price.toFixed(PRECISION),
      priceVat: result.priceVat.toFixed(PRECISION),
    };
  }
  return null;
};

/*
 * 1 if the value of a is greater than the value of b
 * -1 if the value of a is less than the value of b
 * 0 if a and b have the same value
 * NaN if the value of either this Decimal or x is NaN
 */
export const comparePrices = (a, b) => new Decimal(a).comparedTo(b);

/*
 * Returns the soonest campaign expiration date from priceData.
 * Takes an account both scale dates and campaignDates from the
 * priceData root.
 */
export const getSoonestCampaignExpirationDate = priceData => {
  const scales = (priceData || {}).scales || [];
  const campaignDates = (priceData || {}).campaignDates || null;

  const dates = scales.map(scale => scale.dateTo);
  if (campaignDates && campaignDates.to) {
    dates.push(campaignDates.to);
  }

  if (dates.length === 0) {
    return null;
  }

  dates.sort((a, b) => moment(a).diff(moment(b)));
  return moment(dates[0]).format("DD.MM.YYYY");
};

import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history"; // eslint-disable-line
import { sentry, AnalyticsUtil } from "@oriola-origo/origo-common-client-lib";
import loggerMiddleware from "../middlewares/logger";
import createRootReducer from "../reducers/reducers";
import analyticsMiddleware from "../middlewares/analytics";

const sentryReduxEnhancer = sentry.createReduxEnhancer({});

export const history = createBrowserHistory();

const configureStore = preloadedState => {
  const middlewareArray = [
    routerMiddleware(history),
    loggerMiddleware,
    thunkMiddleware,
  ];

  // if analytics enabled
  if (AnalyticsUtil.isAnalyticsEnabled() === true) {
    middlewareArray.push(analyticsMiddleware);
  }

  const middlewareEnhancer = applyMiddleware(...middlewareArray);

  const enhancers = [middlewareEnhancer, sentryReduxEnhancer];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const composedEnhancers = composeEnhancers(...enhancers);

  const rootReducer = createRootReducer(history);
  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  // if not prod, reload only changed reducers
  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("../reducers", () => store.replaceReducer(rootReducer));
  }

  return store;
};
export default configureStore;

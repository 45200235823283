import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useSelector } from "react-redux";
import Badge from "@material-ui/core/Badge";
import { findLastModifiedCart } from "../../../utils/cartUtil/cartUtil";

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textTransform: "none",
    backgroundColor: theme.palette.grey[200],
  },
  buttonAndText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
}));

function CartButton({ text, onClick }) {
  const classes = useStyles();
  const { carts } = useSelector(state => state.cart);
  let cartItemCount = 0;
  const cart = findLastModifiedCart(carts);
  if (cart) {
    cartItemCount = (cart.products || []).length;
  }

  return (
    <Button className={classes.button} onClick={onClick}>
      <div className={classes.buttonAndText}>
        <Typography
          className={classes.text}
          variant="caption"
          color="textPrimary"
        >
          {text}
        </Typography>
        <Badge badgeContent={cartItemCount} color="primary">
          <ShoppingCartIcon />
        </Badge>
      </div>
    </Button>
  );
}

CartButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

CartButton.defaultProps = {
  text: "",
  onClick: () => {}, // eslint-disable-line
};

export default CartButton;

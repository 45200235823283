import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { getFormattedDateTime } from "../../../utils/date/date";
import Colors from "../../../theme/colors";

const useStyles = makeStyles(theme => ({
  announcementItem: {},
  announcementContent: {
    marginBottom: "0.07rem",
    "&:hover": {
      background: Colors.hoverGray,
    },
    display: "flex",
    alignItems: "center",
  },
  flex: {
    display: "flex",
  },
  logo: {
    width: "3rem",
    maxHeight: "3rem",
    marginRight: "1rem",
    objectFit: "contain",
    flexShrink: 0,
  },
  category: {
    color: theme.palette.text.disabled,
  },
  dot: {
    margin: "auto .3rem",
    height: ".3rem",
    width: ".3rem",
    backgroundColor: theme.palette.text.disabled,
    borderRadius: "50%",
  },
  divLink: {
    display: "flex",
    textDecoration: "none",
    paddingBottom: "1rem",
    paddingTop: "1rem",
  },
  divider: {},
}));

function AnnouncementItem({ announcement }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const publishedAt = getFormattedDateTime(announcement.publishedAt);
  const [imgError, setImgError] = useState(false);

  const handleError = () => {
    setImgError(true);
  };

  return (
    <div className={classes.announcementItem}>
      <div className={classes.announcementContent}>
        <a
          className={classes.divLink}
          href={announcement.url}
          rel="noopener noreferrer"
          target="_blank"
        >
          {imgError ? (
            <div
              className={classes.logo}
              style={{
                backgroundColor: "transparent",
              }}
              role="img"
            />
          ) : (
            <img
              alt={t("announcementLogo")}
              src={announcement.publisherLogoUrl}
              className={classes.logo}
              onError={handleError}
            />
          )}
          <div>
            <div className={classes.flex}>
              <Typography className={classes.category} variant="body2" noWrap>
                {announcement.category}
              </Typography>
              <div className={classes.dot} />
              <Typography className={classes.category} variant="body2" noWrap>
                {publishedAt}
              </Typography>
            </div>
            <Typography variant="body2" color="textPrimary">
              {announcement.title}
            </Typography>
          </div>
        </a>
      </div>
      <Divider className={classes.divider} variant="fullWidth" />
    </div>
  );
}

AnnouncementItem.propTypes = {
  announcement: PropTypes.shape({
    category: PropTypes.string.isRequired,
    publishedAt: PropTypes.string.isRequired,
    publisherLogoUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

AnnouncementItem.defaultProps = {};

export default AnnouncementItem;

import moment from "moment-timezone";

const TIMEZONE = "Europe/Helsinki";

const getTimezoneAdjustedTimeOnHour = (timezone, hour) =>
  moment().tz(timezone).set({ hour, minute: 0, second: 0, millisecond: 0 });

export const getOrderingNotAvailableBetweenHours = () => ({
  from: getTimezoneAdjustedTimeOnHour(TIMEZONE, 0),
  to: getTimezoneAdjustedTimeOnHour(TIMEZONE, 5),
});

export const isOrderingAvailableInCurrentTime = () => {
  const currentTime = moment();
  const { from, to } = getOrderingNotAvailableBetweenHours();
  return !currentTime.isBetween(from, to);
};

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const OrigoTextButton = withStyles(
  theme => ({
    root: {
      marginRight: theme.spacing(4),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  }),
  { withTheme: true }
)(props => (
  <Button variant="contained" className={props.classes.root} {...props}>
    {props.children}
  </Button>
));

export default OrigoTextButton;

import { ProductUtil } from "@oriola-origo/origo-common-client-lib";

const getProductCategoryLocalizedName = (language, category) => {
  const languages = ProductUtil.getLanguagesInOrderOfPrecedence(language);
  return languages.reduce((acc, lang) => {
    // found
    if (acc && acc.length > 0) {
      return acc;
    }

    const variableName = `structureName${lang.key.toUpperCase()}`;
    return category[variableName];
  }, "");
};
export default getProductCategoryLocalizedName;

// eslint-disable-next-line
import { FilterType } from "../../../redux/reducers";

export const createFilter = (type, value = null) => ({ type, value });

export const createCategoriesFilter = categories => {
  let newFilter = { type: FilterType.NoFilter, value: null };
  // create filters and update
  if (categories.length > 0) {
    const value = categories.reduce((acc, category) => {
      if (acc.length) {
        return `${acc} OR category:"${category.id}"`;
      }
      return `category:"${category.id}"`;
    }, "");
    // category filter
    newFilter = { type: FilterType.Category, value };
  }
  return newFilter;
};

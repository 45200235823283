import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MoreOrLess from "./moreOrLess";
// eslint-disable-next-line
import InvoiceTableRow from "./invoiceTableRow";
import { HeaderCell } from "./cells";
import styles from "./styles";

const useStyles = makeStyles(styles);

function InvoiceTable({ invoices, favoriteInvoiceIds, onMarkClicked }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState();
  const COLLAPSED_TABLE_ITEM_COUNT = 10;

  if (invoices.length < 1) {
    return null;
  }
  const invoiceRow = invoice => (
    <InvoiceTableRow
      key={`invoices-table-row-${invoice.invoiceNumber}`}
      invoice={invoice}
      marked={favoriteInvoiceIds.includes(invoice.invoiceNumber)}
      onMarkClicked={onMarkClicked}
      classes={classes}
    />
  );

  const renderTable = () => (
    <Table>
      <TableHead className={classes.tableHead}>
        <TableRow>
          <HeaderCell>{t("invoiceTable.invoiceDate")}</HeaderCell>
          <HeaderCell>{t("invoiceTable.customer")}</HeaderCell>
          <HeaderCell>{t("invoiceTable.documentType")}</HeaderCell>
          <HeaderCell>{t("invoiceTable.invoiceNumber")}</HeaderCell>
          <HeaderCell>{t("invoiceTable.amount")}</HeaderCell>
          <HeaderCell className={classes.pdfHeader}>
            {t("invoiceTable.pdf")}
          </HeaderCell>
          <HeaderCell>{t("invoiceTable.mark")}</HeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(expanded
          ? invoices
          : invoices.slice(0, COLLAPSED_TABLE_ITEM_COUNT)
        ).map(invoice => invoiceRow(invoice))}
      </TableBody>
    </Table>
  );

  return (
    <div>
      <MoreOrLess
        enabled={invoices.length > COLLAPSED_TABLE_ITEM_COUNT}
        text={t("invoices")}
        count={invoices.length}
        moreLabel={t("showMore")}
        lessLabel={t("showLess")}
        value={expanded}
        // eslint-disable-next-line
        onToggleExpand={expanded => setExpanded(expanded)}
      >
        {renderTable()}
      </MoreOrLess>
    </div>
  );
}

InvoiceTable.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape({})),
  favoriteInvoiceIds: PropTypes.arrayOf(PropTypes.shape({})),
  onMarkClicked: PropTypes.func,
};

InvoiceTable.defaultProps = {
  invoices: [],
  favoriteInvoiceIds: [],
  onMarkClicked: () => {}, // eslint-disable-line
};

export default InvoiceTable;

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import { useSelector } from "react-redux";
// eslint-disable-next-line
import { OrderProductName } from "../orderTableCells/orderTableCells";
// eslint-disable-next-line
import { SinglePrice } from "../../../generic";
import { Thumbnail } from "../../../productList/productTable/tableCells";
import { getBackorderQuantity } from "../../../../utils/orderUtil/orderUtil";
import { canViewProducts } from "../../../auth/authorization/customerRules";

const { getThumbnailUrl } = ProductUtil;

function DeliveredTableRow({
  classes,
  products,
  order,
  highlight,
  orderlineType,
}) {
  const { t } = useTranslation();
  const { selectedCustomerId, customers } = useSelector(
    state => state.customer
  );
  const customer = customers.find(c => c.customerId === selectedCustomerId);
  const productViewAllowed = canViewProducts(customer);

  let product = products.find(x => x.materialId === order.productId);
  if (!product) product = {};

  const orderlineStatus =
    order.lineStatuses.find(x => x.status === orderlineType) || {};
  const backOrderCount = getBackorderQuantity(order);
  const rootClass = highlight ? classes.highlightRow : undefined;
  const thumbnailUrl = getThumbnailUrl(product);

  return (
    <div className={rootClass}>
      <div className={`${classes.column} ${classes.productRow}`}>
        <div className={classes.columnImage}>
          <Thumbnail url={thumbnailUrl} />
        </div>
        <div className={`${classes.column} ${classes.columnName}`}>
          <OrderProductName
            product={product}
            stockStatus={{}}
            order={order}
            productViewAllowed={productViewAllowed}
          />
        </div>
        <div className={`${classes.column} ${classes.columnUnitPrice}`}>
          <SinglePrice
            price={order.netPrice}
            priceVat={order.price}
            currency={order.currency}
            vatRate={order.vatPercent}
          />
        </div>
        <div className={`${classes.column} ${classes.columnOrdered}`}>
          <Typography align="center" className={classes.amountText}>
            {order.orderQuantity || 0}
          </Typography>
        </div>
        <div className={`${classes.column} ${classes.columnDelivered}`}>
          <Typography align="center" className={classes.amountText}>
            {orderlineStatus.quantity || 0}
          </Typography>
          {backOrderCount > 0 && (
            <Typography
              align="center"
              className={classes.tableHeaderText}
            >{`${backOrderCount} ${t("inBackOrder")}`}</Typography>
          )}
        </div>
      </div>
    </div>
  );
}
DeliveredTableRow.propTypes = {
  classes: PropTypes.shape({
    highlightRow: PropTypes.string,
    column: PropTypes.string,
    productRow: PropTypes.string,
    columnImage: PropTypes.string,
    columnName: PropTypes.string,
    columnUnitPrice: PropTypes.string,
    columnOrdered: PropTypes.string,
    columnDelivered: PropTypes.string,
    amountText: PropTypes.string,
    tableHeaderText: PropTypes.string,
  }).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      materialId: PropTypes.string,
    })
  ).isRequired,
  order: PropTypes.shape({
    productId: PropTypes.string,
    netPrice: PropTypes.number,
    price: PropTypes.number,
    currency: PropTypes.string,
    vatPercent: PropTypes.number,
    orderQuantity: PropTypes.number,
    lineStatuses: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        quantity: PropTypes.number,
      })
    ),
  }).isRequired,
  highlight: PropTypes.bool.isRequired,
  orderlineType: PropTypes.string.isRequired,
};

DeliveredTableRow.defaultProps = {};
export default DeliveredTableRow;

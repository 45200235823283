const Decimal = require("decimal.js-light");

const currencySymbols = {
  EUR: "€",
};
const DEFAULT_LANG = "fi-FI";

export const getCurrencySymbol = currency => currencySymbols[currency];

export const getDefaultCurrencySymbol = () => getCurrencySymbol("EUR");

export const formatCurrency = (value, currency, lang = DEFAULT_LANG) => {
  if (value === null || !Number.isFinite(+value)) {
    return "";
  }

  const strAmount = Number(new Decimal(+value)).toLocaleString(lang, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const currencySymbol = currency
    ? getCurrencySymbol(currency)
    : getDefaultCurrencySymbol();
  return `${strAmount} ${currencySymbol}`;
};

export const formatVatRate = (vatRate, lang = DEFAULT_LANG) => {
  if (!vatRate || !Number.isFinite(+vatRate)) {
    return "";
  }

  const vatStr = Number(new Decimal(+vatRate)).toLocaleString(lang, {
    maximumFractionDigits: 1,
  });

  return `${vatStr} %`;
};

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Tab, Tabs } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import isDevEnv from "../../../utils/env/env";

export const ProductTab = Object.freeze({
  Products: "products",
  MostOrdered: "mostOrdered",
  Favorites: "favorites",
  Campaigns: "campaign",
});

const useStyles = makeStyles(theme => ({
  tab: {
    ...theme.typography.h6,
    color: theme.palette.text.hint,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "1.07rem",
    "&:disabled": {
      color: theme.palette.text.hint,
    },
  },
}));

function ProductTabs({
  currentTab,
  onTabChanged,
  allDisabled,
  mostOrderedDisabled,
  favoritesDisabled,
  campaignDisabled,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Tabs
      value={currentTab}
      onChange={onTabChanged}
      indicatorColor="primary"
      textColor="secondary"
    >
      <Tab
        id="all-products-tab"
        className={classes.tab}
        label={t("allProducts")}
        value={ProductTab.Products}
        disabled={allDisabled}
      />
      {isDevEnv() && (
        <Tab
          id="most-ordered-tab"
          className={classes.tab}
          label={t("mostOrderedProducts")}
          value={ProductTab.MostOrdered}
          disabled={mostOrderedDisabled}
        />
      )}
      <Tab
        id="favorite-tab"
        className={classes.tab}
        label={t("ownFavorites")}
        value={ProductTab.Favorites}
        disabled={favoritesDisabled}
      />
      <Tab
        id="campaign-tab"
        className={classes.tab}
        label={t("campaignProducts")}
        value={ProductTab.Campaigns}
        disabled={campaignDisabled}
      />
    </Tabs>
  );
}

ProductTabs.propTypes = {
  currentTab: PropTypes.string.isRequired,
  onTabChanged: PropTypes.func.isRequired,
  allDisabled: PropTypes.bool,
  mostOrderedDisabled: PropTypes.bool,
  favoritesDisabled: PropTypes.bool,
  campaignDisabled: PropTypes.bool,
};

ProductTabs.defaultProps = {
  allDisabled: false,
  mostOrderedDisabled: false,
  favoritesDisabled: false,
  campaignDisabled: false,
};

export default ProductTabs;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useDispatch, useSelector } from "react-redux";
import { findCustomerById } from "../../../utils/customer/customer";
import {
  getOrderStatuses,
  getBackOrdersForOderSortedByName,
  getDeliveredForOrder,
  getProcessingOrderLines,
  getRejectedOrderLines,
} from "../../../utils/orderUtil/orderUtil";
// eslint-disable-next-line
import DeliveredTable from "./deliveredTable/deliveredTable";
import BackOrdersTable from "./backOrdersTable/backOrdersTable";
import {
  DynamicSearchField,
  IconTextButton,
  Progress,
  NotificationPopup,
} from "../../generic";
// eslint-disable-next-line import/no-cycle
import OrderViewTable from "../orderViewTable/orderViewTable";
import { fetchOrder, notificationHide } from "../../../redux/reducers";
import ORDER_TYPES from "../../../constants/orderTypes";
import OrderFilterMenu from "../ordersInvoices/orderFilterMenu/orderFilterMenu";
import EditBackorderPopup from "./editBackorderPopup/editBackorderPopup";
import DeleteBackorderPopup from "./deleteBackorderPopup/deleteBackorderPopup";
import { ShoppingCartRound, TrashcanRound } from "../../../images";
import { canViewProducts } from "../../auth/authorization/customerRules";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    marginLeft: "7.3%",
    marginRight: "7.3%",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
    },
    padding: theme.spacing(3),
  },
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  headerDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  headerTitle: {
    ...theme.typography.h5,
    marginTop: "0.3rem",
    marginLeft: theme.spacing(4),
  },
  headerBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tab: {
    ...theme.typography.h6,
    color: theme.palette.text.hint,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "1.07rem",
  },
  searchContainer: {
    marginBottom: theme.spacing(3),
  },
  fixedFooter: {
    display: "flex",
    flexDirection: "row",
    paddingTop: theme.spacing(1),
    maxWidth: 1600,
  },
  footerProductColumn: {
    display: "flex",
    width: "54%",
  },
  footerPriceColumn: {
    width: "12%",
  },
  footerPriceTitle: {
    flexGrow: "1",
    textAlign: "right",
    paddingRight: theme.spacing(1),
  },
  footerAmountTitle: {
    width: "8%",
    textAlign: "right",
  },
  footerAmount: {
    width: "5%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  textSmallMargin: {
    marginRight: theme.spacing(1),
  },
  textHorizontalMargin: {
    marginRight: theme.spacing(2),
  },
  filters: {
    minWidth: "12rem",
    marginBottom: theme.spacing(2),
  },
  boldText: {
    fontWeight: "bold",
  },
  loadingIndicator: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
  },
}));

function OrderCard({ history, match }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [searchFilter, setSearchFilter] = useState(null);
  const { orderId } = match.params;
  const { order, fetchingOrders, sendingBackorder } = useSelector(
    state => state.order
  );
  const { customers, selectedCustomerId } = useSelector(
    state => state.customer
  );
  const customer = findCustomerById(order.customerId, customers) || {};
  const orderStatusesStr = getOrderStatuses(order, t);
  const backOrders = getBackOrdersForOderSortedByName(order, searchFilter);
  const delivered = getDeliveredForOrder(order, searchFilter);
  const processing = getProcessingOrderLines(order, searchFilter);
  const rejected = getRejectedOrderLines(order, searchFilter);
  const [tabValue, setTabValue] = useState(0);
  const dispatch = useDispatch();

  const selectedCustomer = findCustomerById(selectedCustomerId, customers);
  const productViewAllowed = canViewProducts(selectedCustomer);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      dispatch(fetchOrder(orderId));
    }
    return () => {
      ignore = true;
    };
  }, [dispatch, orderId]);

  const SHOW_ALL = "showAll";
  const [filters, setFilters] = useState({
    showAll: {
      name: t("showAll"),
      value: SHOW_ALL,
      checked: true,
      title: t("productsAndOrders"),
    },
    backorder: {
      name: t("orderFilter.backorders"),
      value: ORDER_TYPES.backorder,
      checked: true,
    },
    processing: {
      name: t("orderFilter.processing"),
      value: ORDER_TYPES.processing,
      checked: true,
    },
    delivered: {
      name: t("orderFilter.delivered"),
      value: ORDER_TYPES.delivered,
      checked: true,
    },
    rejected: {
      name: t("orderFilter.rejected"),
      value: ORDER_TYPES.rejected,
      checked: true,
    },
  });

  const { title } = useSelector(state => state.notificationPopup);
  const NotificationIcon =
    title === t("backorderDeleteSuccesful") ? (
      <TrashcanRound />
    ) : (
      <ShoppingCartRound />
    );

  // navigation helper
  const goBack = () => {
    history.goBack();
  };
  const showProgress = fetchingOrders || sendingBackorder;
  if (showProgress) {
    return (
      <div className={classes.root}>
        <div className={classes.loadingIndicator}>
          <Progress show={showProgress} size={50} />
        </div>
      </div>
    );
  }

  const renderHeader = () => (
    <div className={classes.header}>
      <div className={classes.headerBlock}>
        <IconTextButton
          text={t("goBack")}
          icon={<ArrowLeftIcon />}
          onClick={() => goBack()}
        />
        <Typography className={classes.headerTitle} color="secondary">{`${t(
          "orderNo"
        )} ${orderId}`}</Typography>
      </div>
      <Typography className={classes.headerDescription} color="secondary">
        {orderStatusesStr}
      </Typography>
    </div>
  );

  const renderSearch = () => (
    <div className={classes.searchContainer}>
      <DynamicSearchField
        onSearch={text => setSearchFilter(text)}
        placeholder={t("ordersViewSearchPlaceholder")}
        selectedCustomerId={selectedCustomerId}
      />
    </div>
  );

  const renderDelivered = () =>
    delivered && delivered.length > 0 && filters.delivered.checked ? (
      <DeliveredTable
        orders={delivered}
        backOrders={backOrders}
        title={t("delivered")}
        orderlineType={ORDER_TYPES.delivered}
        productViewAllowed={productViewAllowed}
      />
    ) : null;

  const renderProcessing = () =>
    processing && processing.length > 0 && filters.processing.checked ? (
      <DeliveredTable
        orders={processing}
        backOrders={[]}
        title={t("processing")}
        orderlineType={ORDER_TYPES.processing}
        productViewAllowed={productViewAllowed}
      />
    ) : null;

  const renderBackOrders = () =>
    backOrders && backOrders.length > 0 && filters.backorder.checked ? (
      <BackOrdersTable
        orders={backOrders}
        order={order}
        orderlineType={ORDER_TYPES.backorder}
        productViewAllowed={productViewAllowed}
      />
    ) : null;

  const renderRejected = () =>
    rejected && rejected.length > 0 && filters.rejected.checked ? (
      <DeliveredTable
        orders={rejected}
        backOrders={[]}
        title={t("cancelled")}
        orderlineType={ORDER_TYPES.rejected}
        productViewAllowed={productViewAllowed}
      />
    ) : null;

  // tab handler
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const renderTab = () => {
    switch (tabValue) {
      case 0:
        return (
          <div>
            <OrderViewTable order={order} customer={customer} />
            <OrderFilterMenu filters={filters} setFilters={setFilters} />
            {renderBackOrders()}
            {renderProcessing()}
            {renderDelivered()}
            {renderRejected()}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      {renderHeader()}
      <Divider className={classes.headerDivider} variant="fullWidth" />
      {renderSearch()}
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="secondary"
      >
        <Tab className={classes.tab} label={t("orderInformation")} value={0} />
        <Tab className={classes.tab} label={t("invoice")} value={1} />
      </Tabs>
      {renderTab()}
      <EditBackorderPopup />
      <DeleteBackorderPopup />
      <NotificationPopup
        id="notification-popup"
        icon={NotificationIcon}
        onClose={() => dispatch(notificationHide())}
      />
    </div>
  );
}

OrderCard.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderId: PropTypes.string,
    }),
  }),
};

OrderCard.defaultProps = {
  history: {},
  match: {},
};

export default withRouter(OrderCard);

/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// eslint-disable-next-line
import BackordersTableRow from "./backordersTableRow";
import { HeaderCell } from "../../openOrders/openOrdersBackOrdersCells";
import styles from "../tableStyles";
import { Can, Permission, ANY_CUSTOMER } from "../../../../auth";
import { findCustomerById } from "../../../../../utils/customer/customer";

const useStyles = makeStyles(styles);

function BackordersTable({ backorderLines }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { productStockStatusesMap, stockStatusFetchIdMap } = useSelector(
    state => state.stock
  );
  const { productAvailabilityStatusesMap, availabilityStatusFetchIdMap } =
    useSelector(state => state.availability);
  const { selectedCustomerId, customers } = useSelector(
    state => state.customer
  );
  const userData = useSelector(state => state.user.userData);
  const selectedCustomer = findCustomerById(selectedCustomerId, customers);

  const backorderRow = (orderLine, index) => {
    const { productId } = orderLine;
    return (
      <BackordersTableRow
        key={`backorders-table-row-${orderLine.order.orderId}-${orderLine.orderLineId}-${index}`}
        orderLine={orderLine}
        classes={classes}
        productStockStatus={productStockStatusesMap.get(productId)}
        fetchingStockStatus={stockStatusFetchIdMap.get(productId) === true}
        fetchingProductStockStatus={
          stockStatusFetchIdMap.get(productId) === true
        }
        productAvailability={productAvailabilityStatusesMap.get(productId)}
        fetchingAvailability={
          availabilityStatusFetchIdMap.get(productId) === true
        }
      />
    );
  };

  const renderTable = () => (
    <Table>
      <TableHead className={classes.tableHead}>
        <TableRow>
          <HeaderCell>{t("backordersTable.product")}</HeaderCell>
          <HeaderCell>{t("backordersTable.orderDate")}</HeaderCell>
          <HeaderCell>{t("backordersTable.deliveryAddress")}</HeaderCell>
          <HeaderCell>{t("backordersTable.orderNumber")}</HeaderCell>
          <HeaderCell>{t("backordersTable.poNumber")}</HeaderCell>
          <HeaderCell>{t("backordersTable.estimatedAvailability")}</HeaderCell>
          <HeaderCell>{t("backordersTable.price")}</HeaderCell>
          <Can
            user={userData}
            customerContext={ANY_CUSTOMER}
            perform={Permission.ORDER_MODIFY_BACKORDER}
            data={{ customers, selectedCustomer }}
            ok={() => (
              <HeaderCell align="center">
                {t("backordersTable.amountEdit")}
              </HeaderCell>
            )}
            nok={() => <HeaderCell>{t("amount")}</HeaderCell>}
          />
          <Can
            user={userData}
            customerContext={ANY_CUSTOMER}
            perform={Permission.ORDER_MODIFY_BACKORDER}
            data={{ customers, selectedCustomer }}
          >
            <HeaderCell>{t("backordersTable.cancel")}</HeaderCell>
          </Can>
        </TableRow>
      </TableHead>
      <TableBody>
        {backorderLines.map((orderLine, index) =>
          backorderRow(orderLine, index)
        )}
      </TableBody>
    </Table>
  );

  if (!backorderLines || backorderLines.length === 0) {
    return null;
  }

  return <div>{renderTable()}</div>;
}

BackordersTable.propTypes = {
  backorderLines: PropTypes.arrayOf(PropTypes.shape({})),
};

BackordersTable.defaultProps = {
  backorderLines: [],
};

export default BackordersTable;

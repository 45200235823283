import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { IconTextLabel, IconTextButton } from "../../generic"; // eslint-disable-line
import { Cart, More, ArrowUpGrey, ArrowDownGrey } from "../../../images";
import CartTable from "../cartTable/cartTable";
import {
  findCartById,
  sortByLastModified,
} from "../../../utils/cartUtil/cartUtil";
import { findTemplateById } from "../../../utils/templateUtil/templateUtil";
import { CartState } from "../../../redux/reducers";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
  },
  header: {
    paddingTop: theme.spacing(4),
    marginLeft: theme.spacing(3.5),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  dividerContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  addCartButtonContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderLeftWidth: "5px",
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.primary.main,
  },
  marginTopBottom: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  addTemplateButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderLeftWidth: "5px",
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.primary.main,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  paddingBottom: {
    paddingBottom: theme.spacing(2),
  },
  templateShowButton: {
    fontWeight: "bold",
    color: theme.palette.text.disabled,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  arrowIcon: {
    marginLeft: theme.spacing(0.5),
  },
  clearSelectionBorder: {
    borderLeftColor: "transparent",
  },
}));

function ShoppingCartSideMenu({
  selectedCart,
  selectedTemplate,
  onCartSelect,
  onTemplateSelect,
  onNewCartClick,
  onNewTemplateClick,
  newCartButtonDisabled,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { productPricesMap } = useSelector(state => state.product);
  const { carts, cartState } = useSelector(state => state.cart);
  const { orderTemplates } = useSelector(state => state.template);
  const [showTemplates, setShowTemplates] = useState(true);

  const renderTitle = () => (
    <div className={classes.header}>
      <IconTextLabel
        variant="h4"
        icon={<Cart />}
        text={t("shoppingCartAndTemplates")}
        textStyle={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "break-spaces",
        }}
      />
    </div>
  );

  const renderDivider = () => (
    <div className={classes.dividerContainer}>
      <Divider />
    </div>
  );

  // eslint-disable-next-line
  const renderCart = (carts, productPricesMap) => {
    // sort by date
    const sortedCarts = sortByLastModified(carts);

    // check what is showing
    let selectedCartId = selectedCart ? selectedCart.cartId : "";
    if (cartState !== CartState.SHOW_CONTENT) {
      selectedCartId = "";
    }

    // show selection on create button
    const createSelectionBorderClassName =
      cartState === CartState.ADD_NEW ? null : classes.clearSelectionBorder;

    const onSelect = id => {
      const cart = findCartById(id, carts);
      onCartSelect(cart);
    };

    return (
      <div>
        <div
          className={`${classes.addCartButtonContainer} ${createSelectionBorderClassName}`}
        >
          <IconTextButton
            disabled={newCartButtonDisabled}
            variant="button"
            style={{ textTransform: "none", fontWeight: "bold" }}
            icon={<More color="primary" />}
            text={t("addNewCart").toUpperCase()}
            onClick={onNewCartClick}
            textStyle={{ fontWeight: "bold" }}
          />
        </div>
        {renderDivider()}
        <div id="cart-section" className={classes.marginTopBottom}>
          <CartTable
            items={sortedCarts.map(cart => ({
              id: cart.cartId,
              name: cart.name,
              products: cart.products,
              date: cart.updatedDate,
            }))}
            productPricesMap={productPricesMap}
            selectedId={selectedCartId}
            onSelect={id => onSelect(id)}
          />
        </div>
      </div>
    );
  };

  // eslint-disable-next-line
  const renderTemplates = (templates, productPricesMap) => {
    const sortedTemplates = sortByLastModified(templates);

    // check what is showing
    let selectedTemplateId = selectedTemplate
      ? selectedTemplate.templateId
      : "";
    if (cartState !== CartState.SHOW_TEMPLATES) {
      selectedTemplateId = "";
    }

    // show selection on create button
    const createSelectionBorderClassName =
      cartState === CartState.ADD_NEW_TEMPLATE
        ? null
        : classes.clearSelectionBorder;

    const onSelect = id => {
      const template = findTemplateById(id, templates);
      onTemplateSelect(template);
    };

    const templateIcon = showTemplates ? <ArrowUpGrey /> : <ArrowDownGrey />;

    return (
      <div className={classes.paddingBottom}>
        <div
          className={`${classes.addTemplateButton} ${createSelectionBorderClassName}`}
        >
          <IconTextButton
            variant="button"
            style={{ textTransform: "none", fontWeight: "bold" }}
            icon={<More color="primary" />}
            text={t("createNewOrderTemplate").toUpperCase()}
            onClick={onNewTemplateClick}
            textStyle={{ fontWeight: "bold" }}
          />
        </div>
        {renderDivider()}
        <div className={classes.marginTopBottom}>
          <Button
            className={classes.templateShowButton}
            onClick={() => setShowTemplates(!showTemplates)}
          >
            {t("orderTemplates").toUpperCase()}{" "}
            <div className={classes.arrowIcon}>{templateIcon}</div>
          </Button>
        </div>
        <div className={classes.marginBottom} id="template-table">
          {showTemplates && (
            <CartTable
              items={sortedTemplates.map(template => ({
                id: template.templateId,
                name: template.name,
                products: template.products,
                date: template.updatedDate,
              }))}
              productPricesMap={productPricesMap}
              selectedId={selectedTemplateId}
              onSelect={id => onSelect(id)}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {renderTitle()}
      {renderDivider()}
      {renderCart(carts, productPricesMap)}
      {renderDivider()}
      {renderTemplates(orderTemplates, productPricesMap)}
    </div>
  );
}

ShoppingCartSideMenu.propTypes = {
  selectedCart: PropTypes.shape({
    cartId: PropTypes.string,
    name: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.shape({})),
    updatedDate: PropTypes.string,
  }),
  selectedTemplate: PropTypes.shape({
    templateId: PropTypes.string,
    name: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.shape({})),
    updatedDate: PropTypes.string,
  }),
  onCartSelect: PropTypes.func.isRequired,
  onTemplateSelect: PropTypes.func.isRequired,
  onNewCartClick: PropTypes.func.isRequired,
  onNewTemplateClick: PropTypes.func.isRequired,
  newCartButtonDisabled: PropTypes.bool.isRequired,
};

ShoppingCartSideMenu.defaultProps = {
  selectedCart: null,
  selectedTemplate: null,
};

export default ShoppingCartSideMenu;

/* eslint-disable react/no-unstable-nested-components, react/prop-types */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line
import { DynamicSearchField } from "../../generic";
import { isInStock } from "../../../utils/stockUtil/stockUtil";
import {
  FilterType,
  fetchProducts,
  fetchStockStatuses,
  fetchAvailabilityStatuses,
  fetchProductPrices,
  clearProducts,
  analyticsSelectShoppingCart,
  setSelectedFilterType,
  fetchCampaignProducts,
} from "../../../redux/reducers";
import { ProductTable, PRODUCTS_ON_PAGE } from "..";
import ProductCategoryMenu from "../productCategoryMenu/productCategoryMenu";
import { Can, Permission, ANY_CUSTOMER } from "../../auth";
import colors from "../../../theme/colors";
import { findCustomerById } from "../../../utils/customer/customer";
import ProductTabs, { ProductTab } from "./productTabs";
import { createFilter, createCategoriesFilter } from "./filters";

const MIN_SEARCH_CHARACTER_LENGTH = 3;
const MAX_SEARCH_BYTE_LENGTH = 512;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "1%",
    marginRight: "1%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  contentLeft: {
    flex: 8,
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  searchFieldContainer: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
  selectForm: {
    marginTop: theme.spacing(5),
    minWidth: 300,
  },
  productList: {
    marginTop: theme.spacing(2),
  },
  tab: {
    ...theme.typography.h6,
    color: theme.palette.text.hint,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "1.07rem",
  },
  form: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  selectField: {
    background: colors.backgroundGray,
    height: "2.5rem",
  },
  cartSelect: {
    marginRight: theme.spacing(2),
  },
  cartSelectText: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.disabled,
    marginBottom: theme.spacing(2),
  },
  filterSelect: {
    marginTop: "auto",
  },
  selectItem: {
    fontSize: "0.9rem",
  },
  categoryTitle: {
    marginBottom: theme.spacing(2),
  },
  categoryTitleText: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightBold,
  },
  categoriesFiltersContainer: {
    marginTop: theme.spacing(5),
    flexGrow: 1,
  },
}));

function Products() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // get products from redux
  const {
    products,
    productPricesMap,
    fetchingProduct,
    priceFetchIdMap,
    overallProductCount,
    favoriteProductIds,
    campaignProductIds,
    fetchingCampaigns,
    fetchingFavorites,
  } = useSelector(state => state.product);

  const { productStockStatusesMap, stockStatusFetchIdMap } = useSelector(
    state => state.stock
  );
  const { productAvailabilityStatusesMap, availabilityStatusFetchIdMap } =
    useSelector(state => state.availability);
  const { selectedCategories } = useSelector(state => state.productCategory);
  const userData = useSelector(state => state.user.userData);
  const { customers, selectedCustomerId } = useSelector(
    state => state.customer
  );
  const customer = findCustomerById(selectedCustomerId, customers);
  const [searchText, setSearchText] = useState(null);
  const [filter, setFilter] = useState({ type: FilterType.NoFilter });

  // tab handler
  const [currentTab, setCurrentTab] = useState(ProductTab.Products);
  const [categoriesOpen, setCategoriesOpen] = useState(false);

  const loadAdditionalProductData = (
    customerId,
    startIndex,
    stopIndex,
    fetchedProducts
  ) => {
    if (Array.isArray(fetchedProducts)) {
      // get target ids
      const targetProducts = fetchedProducts.slice(startIndex, stopIndex + 1);
      const ids = targetProducts.map(x => x.materialId);
      if (ids.length > 0) {
        // fetch stock statuses
        dispatch(fetchStockStatuses(customerId, ids)).then(stockData => {
          // fetch availability information for products out of stock
          const productsOutOfStock = [];
          (stockData || []).forEach(stockStatus => {
            if (!isInStock(stockStatus)) {
              productsOutOfStock.push(stockStatus.materialId);
            }
          });
          if (productsOutOfStock.length > 0) {
            dispatch(fetchAvailabilityStatuses(customerId, ids));
          }
        });

        // fetch prices
        dispatch(fetchProductPrices(customerId, ids));
      }
    }
  };
  const handleProductTabChange = newValue => {
    const startIndex = 0;
    const stopIndex = PRODUCTS_ON_PAGE * 2 - 1;

    // value changed
    if (newValue !== currentTab) {
      // update
      setCurrentTab(newValue);

      // no need to fetch anything for these
      if (newValue === ProductTab.MostOrdered) {
        return;
      }

      // clear products
      dispatch(clearProducts());

      // check what is activated
      let newFilter = { type: FilterType.NoFilter, value: null };
      let productIds;
      // favorites activated
      if (newValue === ProductTab.Favorites) {
        // no favorites
        if (favoriteProductIds.length === 0) {
          // no point refreshing
          dispatch(setSelectedFilterType(FilterType.Favorites));
          return;
        }
        newFilter = createFilter(FilterType.Favorites);
        productIds = favoriteProductIds;
      } else if (newValue === ProductTab.Campaigns) {
        // no campaigns
        if (campaignProductIds.length === 0) {
          dispatch(setSelectedFilterType(FilterType.Campaigns));
          return;
        }
        newFilter = createFilter(FilterType.Campaigns);
        productIds = campaignProductIds;
      } else if (newValue === ProductTab.Products) {
        newFilter = createCategoriesFilter(selectedCategories);
      }

      // store filter
      setFilter(newFilter);
      dispatch(setSelectedFilterType(newFilter.type));

      dispatch(
        fetchProducts(
          startIndex,
          stopIndex,
          searchText,
          newFilter,
          false,
          productIds
        )
      ).then(fetchedProducts =>
        loadAdditionalProductData(
          selectedCustomerId,
          startIndex,
          stopIndex,
          fetchedProducts
        )
      );
    }
  };

  useEffect(() => {
    // scroll always to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (selectedCustomerId) {
      dispatch(fetchCampaignProducts(selectedCustomerId));
    }
  }, [dispatch, selectedCustomerId]);

  // Cart selection
  const { carts, cartSendError } = useSelector(state => state.cart);
  // eslint-disable-next-line
  const emptyCart = { name: t("none"), id: null };
  const [cartValue, setCartValue] = useState(emptyCart);
  const selectedCart = cartValue.cartId ? cartValue : null;

  // Required for updating the cartValue when cart is empty and language changes
  useEffect(() => {
    if (cartValue.id === null) {
      setCartValue(emptyCart);
    }
  }, [i18n.language]); // eslint-disable-line

  useEffect(() => {
    const HTTP_STATUS_GONE = 410;
    // If the modified cart was expired, clear selection to prevent further errors on non existing cart
    if (
      cartValue.cartId != null &&
      cartSendError != null &&
      cartSendError.status === HTTP_STATUS_GONE
    ) {
      setCartValue(emptyCart);
    }
  }, [cartSendError, cartValue, setCartValue, emptyCart]);

  const handleCartChange = event => {
    dispatch(analyticsSelectShoppingCart(event.target.value));
    setCartValue(event.target.value);
  };

  function CategoryTitle({ text }) {
    return (
      <div className={classes.categoryTitle}>
        <Typography className={classes.categoryTitleText}>{text}</Typography>
      </div>
    );
  }

  const renderCategoriesFilters = () => {
    const onCategoryFiltersUpdated = categories => {
      // update
      const newFilter = createCategoriesFilter(categories);
      setFilter(newFilter);
      dispatch(setSelectedFilterType(newFilter.type));
      // filtered search
      const startIndex = 0;
      const stopIndex = PRODUCTS_ON_PAGE - 1;
      dispatch(
        fetchProducts(startIndex, stopIndex, searchText, newFilter)
        // eslint-disable-next-line
      ).then(products =>
        loadAdditionalProductData(
          selectedCustomerId,
          startIndex,
          stopIndex,
          products
        )
      );
    };

    return (
      <div className={classes.categoriesFiltersContainer}>
        <CategoryTitle text={t("productCategories")} />
        <ProductCategoryMenu
          open={categoriesOpen}
          onOpen={() => setCategoriesOpen(true)}
          onClose={() => setCategoriesOpen(false)}
          onCategoryFiltersUpdated={categories =>
            onCategoryFiltersUpdated(categories)
          }
          searchText={searchText}
        />
      </div>
    );
  };

  const renderProductsTable = (productCount, loadMoreHandler) => (
    <ProductTable
      user={userData}
      customers={customers}
      products={products}
      productPricesMap={productPricesMap}
      productStockStatusesMap={productStockStatusesMap}
      productAvailabilityStatusesMap={productAvailabilityStatusesMap}
      favoriteProductIds={favoriteProductIds}
      selectedCart={selectedCart}
      overallProductCount={productCount || overallProductCount}
      onRequestProducts={loadMoreHandler}
      fetchingProduct={fetchingProduct}
      priceFetchIdMap={priceFetchIdMap}
      stockStatusFetchIdMap={stockStatusFetchIdMap}
      availabilityStatusFetchIdMap={availabilityStatusFetchIdMap}
      searchText={searchText}
      selectedCustomerId={selectedCustomerId}
    />
  );

  const loadMoreFavorites = (startIndex, stopIndex) => {
    const searchFltr = createFilter(FilterType.Favorites);
    setFilter(searchFltr);
    dispatch(
      fetchProducts(
        startIndex,
        stopIndex,
        searchText,
        searchFltr,
        false,
        favoriteProductIds
      )
    ).then(productsList =>
      loadAdditionalProductData(
        selectedCustomerId,
        startIndex,
        stopIndex,
        productsList
      )
    );
  };

  const loadMoreCampaigns = (startIndex, stopIndex) => {
    const searchFltr = createFilter(FilterType.Campaigns);
    setFilter(searchFltr);
    dispatch(
      fetchProducts(
        startIndex,
        stopIndex,
        searchText,
        searchFltr,
        false,
        campaignProductIds
      )
    ).then(productsList =>
      loadAdditionalProductData(
        selectedCustomerId,
        startIndex,
        stopIndex,
        productsList
      )
    );
  };

  const renderFavoriteProducts = () =>
    renderProductsTable(favoriteProductIds.length, loadMoreFavorites);
  const renderCampaignTable = () =>
    renderProductsTable(campaignProductIds.length, loadMoreCampaigns);
  const loadMoreProducts = (startIndex, stopIndex) => {
    // fetch new set of products
    dispatch(fetchProducts(startIndex, stopIndex, searchText, filter, true))
      // eslint-disable-next-line
      .then(products =>
        loadAdditionalProductData(
          selectedCustomerId,
          startIndex,
          stopIndex,
          products
        )
      );
  };

  const renderAllProducts = () => (
    <div>
      <div className={classes.form}>
        {renderCategoriesFilters()}
        <Can
          customerContext={ANY_CUSTOMER}
          user={userData}
          perform={Permission.ORDER_CREATE}
          data={{ customer }}
        >
          <FormControl
            margin="normal"
            variant="filled"
            className={classes.selectForm}
          >
            <Typography className={classes.cartSelectText} noWrap>
              {t("addToCart")}
            </Typography>
            <Select
              className={`${classes.selectField} ${classes.cartSelect}`}
              value={cartValue}
              onChange={handleCartChange}
              input={<OutlinedInput name="cart" />}
              renderValue={value => {
                const text = value.name;
                return (
                  <Typography className={classes.selectItem}>{text}</Typography>
                );
              }}
            >
              <MenuItem value={emptyCart}>{emptyCart.name}</MenuItem>
              {carts.map(cart => (
                <MenuItem key={cart.cartId} value={cart}>
                  {cart.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Can>
      </div>
      {renderProductsTable(null, loadMoreProducts)}
    </div>
  );
  const renderTab = () => {
    switch (currentTab) {
      case ProductTab.Products:
        return renderAllProducts();
      case ProductTab.Favorites:
        return renderFavoriteProducts();
      case ProductTab.Campaigns:
        return renderCampaignTable();
      default:
        return null;
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.contentLeft}>
        <Paper className={classes.container}>
          <div
            id="search-field-container"
            className={classes.searchFieldContainer}
          >
            <DynamicSearchField
              maxByteLength={MAX_SEARCH_BYTE_LENGTH}
              onSearch={text => {
                // if this is initial load (page opened) make sure that category filters are applied
                let searchFilter = filter;
                if (
                  selectedCategories.length &&
                  filter.type === FilterType.NoFilter
                ) {
                  searchFilter = createCategoriesFilter(selectedCategories);
                  setFilter(searchFilter);
                }

                // min length is MIN_SEARCH_CHARACTER_LENGTH chars if there's already products on the list
                if (
                  text &&
                  text.length < MIN_SEARCH_CHARACTER_LENGTH &&
                  products.length !== 0
                ) {
                  return;
                }

                const startIndex = 0;
                const stopIndex = PRODUCTS_ON_PAGE * 2 - 1;
                // search?
                let productIds;
                if (searchFilter.type === "Favorites") {
                  productIds = favoriteProductIds;
                }
                if (searchFilter.type === "Campaigns") {
                  productIds = campaignProductIds;
                }
                if (text) {
                  dispatch(
                    fetchProducts(
                      startIndex,
                      stopIndex,
                      text,
                      searchFilter,
                      false,
                      productIds
                    )
                    // eslint-disable-next-line
                  ).then(products =>
                    loadAdditionalProductData(
                      selectedCustomerId,
                      startIndex,
                      stopIndex,
                      products
                    )
                  );
                  setSearchText(text);
                } else {
                  // clear
                  dispatch(
                    fetchProducts(
                      startIndex,
                      stopIndex,
                      null,
                      searchFilter,
                      false,
                      productIds
                    )
                    // eslint-disable-next-line
                  ).then(products =>
                    loadAdditionalProductData(
                      selectedCustomerId,
                      startIndex,
                      stopIndex,
                      products
                    )
                  );
                  setSearchText(null);
                }
              }}
              placeholder={t("searchPlaceholder")}
              selectedCustomerId={selectedCustomerId}
              onFocus={() => setCategoriesOpen(false)}
            />
          </div>

          <ProductTabs
            campaignDisabled={
              fetchingCampaigns === true && campaignProductIds.length === 0
            }
            favoritesDisabled={
              fetchingFavorites === true && favoriteProductIds.length === 0
            }
            currentTab={currentTab}
            onTabChanged={(event, tab) => handleProductTabChange(tab)}
          />
          {renderTab()}
        </Paper>
      </div>
    </div>
  );
}

export default Products;

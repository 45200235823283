import Delay from "../../../utils/delay/delay";

export const MakingOrderDialog = Object.freeze({
  DIALOG_SHOW: "MAKING_ORDER_DIALOG_SHOW",
  DIALOG_HIDE: "MAKING_ORDER_DIALOG_HIDE",
});

const delay = new Delay();

export const makingOrderDialogShow =
  (title, primaryText, secondaryText) => dispatch => {
    // show
    dispatch({
      type: MakingOrderDialog.DIALOG_SHOW,
      payload: {
        title,
        primaryText,
        secondaryText,
      },
    });
  };

export const makingOrderDialogHide = () => dispatch => {
  // clear any timers
  delay.clear();

  // hide
  dispatch({
    type: MakingOrderDialog.DIALOG_HIDE,
  });
};

const INIT_STATE = {
  show: false,
  title: "",
  primaryText: "",
  secondaryText: "",
};
// eslint-disable-next-line
export const makingOrderDialogReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MakingOrderDialog.DIALOG_SHOW:
      return { ...state, show: true, ...action.payload };
    case MakingOrderDialog.DIALOG_HIDE:
      return {
        ...state,
        show: false,
        title: "",
        primaryText: "",
        secondaryText: "",
      };
    default:
      return state;
  }
};

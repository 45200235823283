import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line
import { TooltipTypography } from "../../generic";

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginLeft: theme.spacing(2.25),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    borderLeftWidth: "5px",
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.primary.main,
    cursor: "pointer",
  },
  rowTextTop: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(1.5),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rowTextBottom: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1.5),
  },
  rowTitle: {
    flexGrow: 1,
  },
  rowItemMargin: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
  },
  rowModified: {
    textTransform: "none",
    color: theme.palette.text.disabled,
    display: "block",
    marginBottom: 0,
  },
  tableCell: {
    borderBottom: "none",
    maxWidth: 0,
    marginBottom: theme.spacing(1),
  },
}));

function CartTable({ items, selectedId, onSelect }) {
  const classes = useStyles();
  const { t } = useTranslation();

  // eslint-disable-next-line
  const renderCartRow = (item, selectedId) => {
    const clearBorderStyle = {
      borderLeftColor: "transparent",
    };

    const selected = selectedId === item.id;

    /*
    ORIGO-1477: Left menu total prices are temporarily removed.
    Leaving the code in place so they can easily be brought back.

    // calculate prices
    const overallPrices = calculateCartProductOverallPrice(item.products, productPricesMap);
    const formattedPrice = formatCurrency(overallPrices.price);
    */
    const countPriceStr = `${item.products.length} ${t(
      "orderLines"
    ).toLowerCase()}`;

    // TODO: use date formatter when available
    const modifiedDate = moment(item.date);
    const today = moment().isSame(modifiedDate, "day");
    const dateStr = `${t("lastModified")} ${
      today ? t("today") : modifiedDate.format("DD.MM.YYYY")
    }`;

    return (
      <TableRow
        hover
        onClick={() => {
          onSelect(item.id);
        }}
        key={item.id}
        id="cart-table"
      >
        <TableCell padding="none" className={classes.tableCell}>
          <div
            className={classes.row}
            style={selected ? null : clearBorderStyle}
            // TODO: update cart id
          >
            <TooltipTypography
              className={`${classes.rowItemMargin} ${classes.rowTitle}`}
              variant="subtitle2"
              noWrap
              tooltip={item.name}
            >
              {item.name}
            </TooltipTypography>

            <Typography
              className={`${classes.rowItemMargin}`}
              variant="body1"
              noWrap
            >
              {countPriceStr}
            </Typography>

            <Typography
              variant="button"
              className={`${classes.rowItemMargin} ${classes.rowModified}`}
              noWrap
            >
              {dateStr}
            </Typography>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Table padding="none">
      <TableBody>
        {items.map(item => renderCartRow(item, selectedId))}
      </TableBody>
    </Table>
  );
}

CartTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          count: PropTypes.number.isRequired,
          price: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  selectedId: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
};

CartTable.defaultProps = {
  onSelect: () => {}, // eslint-disable-line
};

export default CartTable;

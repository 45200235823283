import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { formatCurrency } from "../../../utils/currency/currency";
import Vat from "./vat";

const useStyles = makeStyles(theme => ({
  priceBlock: {
    display: "inline-block",
  },
  priceAndText: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  boldPrice: {
    ...theme.typography.button,
    fontWeight: "bold",
    fontSize: "1.1rem",
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("lg")]: {
      marginRight: theme.spacing(0.5),
    },
  },
  description: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textColorDisabled: {
    color: theme.palette.text.disabled,
  },
  textColorHighlight: {
    color: theme.palette.text.primary,
  },
  vatColor: {
    color: theme.palette.text.disabled,
  },
  largeFontPrice: {
    fontSize: "1.25rem",
  },
  largeFontDescription: {
    alignSelf: "flex-end",
    fontSize: "1rem",
  },
}));

function SinglePrice({
  price,
  priceVat,
  vatRate,
  currency,
  description,
  showVatPercent,
  showVat,
  disabled,
  useLargeFont,
  breakpointValue,
}) {
  const classes = useStyles();
  let priceColor = classes.textColorHighlight;
  let descriptionColor = classes.textColorHighlight;
  let { vatColor } = classes;

  if (disabled === true) {
    priceColor = classes.textColorDisabled;
    descriptionColor = classes.textColorDisabled;
    vatColor = classes.textColorDisabled;
  }

  return (
    <div className={classes.priceBlock}>
      <div className={classes.priceAndText}>
        <Typography
          className={`${classes.boldPrice} ${priceColor} ${
            useLargeFont && classes.largeFontPrice
          }`}
        >
          {formatCurrency(price, currency) || "-"}
        </Typography>
        <Typography
          align="center"
          variant="body2"
          className={`${classes.description} ${descriptionColor} ${
            useLargeFont && classes.largeFontDescription
          }`}
        >
          {description}
        </Typography>
      </div>
      {showVat === true && (
        <Vat
          vatRate={vatRate}
          vatPrice={priceVat}
          currency={currency}
          className={`${vatColor} ${
            useLargeFont && classes.largeFontDescription
          }`}
          showPercent={showVatPercent}
          breakpointValue={breakpointValue}
        />
      )}
    </div>
  );
}

SinglePrice.propTypes = {
  price: PropTypes.string,
  priceVat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vatRate: PropTypes.string,
  currency: PropTypes.string,
  disabled: PropTypes.bool,
  description: PropTypes.string,
  showVatPercent: PropTypes.bool,
  showVat: PropTypes.bool,
  useLargeFont: PropTypes.bool,
  breakpointValue: PropTypes.string,
};

SinglePrice.defaultProps = {
  price: "",
  priceVat: "",
  vatRate: "24",
  currency: "EUR",
  disabled: false,
  description: "",
  showVatPercent: true,
  showVat: true,
  useLargeFont: false,
  breakpointValue: "md",
};

export default SinglePrice;

/* eslint-disable @typescript-eslint/no-shadow */
import React from "react";
// eslint-disable-next-line
import clsx from "clsx";
import PropTypes from "prop-types";
import { Typography, Tooltip, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Badge from "@material-ui/core/Badge";
import { withRouter, Link as RouterLink } from "react-router-dom";
import { useSearchParams } from "@oriola-origo/origo-ui-core";
import { useDispatch } from "react-redux";
import { getFormattedDate } from "../../../../../utils/date/date";
// eslint-disable-next-line
import { SinglePrice, TextLink } from "../../../../generic";
import { Cell, MobileHiddenCell } from "./cells";
import Paths from "../../../../../utils/navigation/navigation";
import {
  getOrderStatusLocalization,
  getOrderShipToCustomerName,
} from "../../../../../utils/orderUtil/orderUtil";
import { setQueryText } from "../../../../../redux/reducers";
import { formatAddress } from "../../../../../utils/customer/customer";
import { canViewProducts } from "../../../../auth/authorization/customerRules";

function OrderHistoryTableRow({ order, classes, height, customer }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const productViewAllowed = canViewProducts(customer);
  const searchParams = useSearchParams();

  const renderTextCellValue = (text, useTooltip) => {
    const toolTipText = useTooltip ? text : "";
    return (
      <Tooltip title={toolTipText || ""}>
        <Typography className={classes.cellTextValue} noWrap>
          {text}
        </Typography>
      </Tooltip>
    );
  };

  const renderDeliveryStatus = order => {
    const localizedStatus = getOrderStatusLocalization(order, t);
    return (
      <div>
        <Typography className={classes.cellTextValue}>
          {localizedStatus}
        </Typography>
      </div>
    );
  };

  const renderBackOrderDeliveryStatus = (order, backOrderCount) => {
    const text = t("backorder");
    searchParams.set("query", order.orderId);
    return (
      <div>
        <Typography className={classes.cellTextValue}>
          <Link
            component={RouterLink}
            to={{
              pathname: Paths.OrdersInvoicesBackOrders,
              search: searchParams.toString(),
            }}
            color="textPrimary"
            onClick={() => dispatch(setQueryText(order.orderId))}
            style={{ textDecoration: "underline" }}
          >
            {text || ""}
          </Link>
          <Badge
            className={classes.badgeContainer}
            classes={{ badge: classes.badge }}
            badgeContent={backOrderCount}
            color="primary"
          />
        </Typography>
      </div>
    );
  };

  const renderPriceCell = (netPrice, totalPrice) => {
    if (productViewAllowed === false) {
      return null;
    }

    return (
      <div>
        <SinglePrice
          price={netPrice}
          priceVat={totalPrice}
          showVatPercent={false}
          breakpointValue="xs"
        />
      </div>
    );
  };

  const renderDeliveryAddress = order => {
    const customerName = getOrderShipToCustomerName(order) || "-";
    return (
      <div>
        <Typography className={classes.cellTextValue}>
          {order.shipTo}
        </Typography>
        <Typography className={classes.cellTextValue}>
          {customerName}
        </Typography>
        {renderTextCellValue(formatAddress(order.shipToAddress))}
      </div>
    );
  };

  const renderOrderRow = () => (
    // TODO: where to get PO number and invoice number

    <div className={clsx(classes.orderRow, classes.rowPadding)}>
      <Cell className={classes.orderDateColumn}>
        {renderTextCellValue(getFormattedDate(order.orderDate))}
      </Cell>
      <MobileHiddenCell className={classes.deliveryAddressColumn}>
        {renderDeliveryAddress(order)}
      </MobileHiddenCell>
      <Cell className={classes.orderNoColumn}>
        <TextLink
          to={`${Paths.OrdersInvoicesOrder}/${order.orderId}`}
          text={order.orderId}
        />
      </Cell>
      <MobileHiddenCell className={classes.poNumberColumn}>
        {renderTextCellValue(order.poNumber || "-", true)}
      </MobileHiddenCell>
      <MobileHiddenCell className={classes.deliveryStatusColumn}>
        {renderDeliveryStatus(order)}
      </MobileHiddenCell>
      <Cell className={classes.totalPriceColumn}>
        {renderPriceCell(order.netPrice, order.totalPrice)}
      </Cell>
    </div>
  );
  const renderBackOrderRow = (order, backOrderCount) => (
    <div className={classes.orderRow}>
      <Cell className={classes.orderDateColumn} />
      <MobileHiddenCell className={classes.deliveryAddressColumn} />
      <Cell className={classes.orderNoColumn} />
      <MobileHiddenCell className={classes.poNumberColumn} />
      <MobileHiddenCell className={classes.deliveryStatusColumn}>
        {renderBackOrderDeliveryStatus(order, backOrderCount)}
      </MobileHiddenCell>
      <Cell className={classes.totalPriceColumn} />
    </div>
  );
  const renderRejectedCount = order => (
    <Typography className={classes.cellTextValue}>{`${t("rejected")} ${
      order.rejectedCount
    }`}</Typography>
  );
  const renderRejectedRow = order => (
    <div className={clsx(classes.orderRow, classes.rejectedRow)}>
      <Cell className={classes.orderDateColumn} />
      <MobileHiddenCell className={classes.deliveryAddressColumn} />
      <Cell className={classes.orderNoColumn} />
      <MobileHiddenCell className={classes.poNumberColumn} />
      <MobileHiddenCell className={classes.deliveryStatusColumn}>
        {renderRejectedCount(order)}
      </MobileHiddenCell>
      <Cell className={classes.totalPriceColumn} />
    </div>
  );
  // hide selection?
  const disableSelection =
    order.hasBackorders === false
      ? { borderLeftColor: "transparent", height }
      : { height };
  return (
    <div className={classes.orderRowWrapper} style={disableSelection}>
      {renderOrderRow()}
      {order.hasBackorders === true &&
        renderBackOrderRow(order, order.backOrderCount)}
      {order.displayRejectedLines && renderRejectedRow(order)}
    </div>
  );
}

OrderHistoryTableRow.propTypes = {
  order: PropTypes.shape({
    orderId: PropTypes.string.isRequired,
    orderDate: PropTypes.string.isRequired,
    shipTo: PropTypes.string.isRequired,
    shipToAddress: PropTypes.shape({}).isRequired,
    poNumber: PropTypes.string,
    netPrice: PropTypes.number.isRequired,
    totalPrice: PropTypes.number.isRequired,
    hasBackorders: PropTypes.bool.isRequired,
    backOrderCount: PropTypes.number.isRequired,
    rejectedCount: PropTypes.number.isRequired,
    displayRejectedLines: PropTypes.bool.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    orderRowWrapper: PropTypes.string.isRequired,
    orderRow: PropTypes.string.isRequired,
    rowPadding: PropTypes.string.isRequired,
    orderDateColumn: PropTypes.string.isRequired,
    deliveryAddressColumn: PropTypes.string.isRequired,
    orderNoColumn: PropTypes.string.isRequired,
    poNumberColumn: PropTypes.string.isRequired,
    deliveryStatusColumn: PropTypes.string.isRequired,
    totalPriceColumn: PropTypes.string.isRequired,
    cellTextValue: PropTypes.string.isRequired,
    badgeContainer: PropTypes.string.isRequired,
    badge: PropTypes.string.isRequired,
    rejectedRow: PropTypes.string.isRequired,
  }).isRequired,
  height: PropTypes.number.isRequired,
  customer: PropTypes.shape({}).isRequired,
};

OrderHistoryTableRow.defaultProps = {};

export default withRouter(OrderHistoryTableRow);

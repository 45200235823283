import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { OriolaColors } from "@oriola-origo/origo-ui-core";
import { getSoonestCampaignExpirationDate } from "../../../utils/price/price";

const useStyles = makeStyles(() => ({
  text: {
    display: "inline",
    fontSize: 14,
    fontWeight: 400,
    color: OriolaColors.Orange,
  },
  date: {
    display: "inline",
    fontSize: 14,
    fontWeight: 400,
    color: OriolaColors.DarkGrey,
  },
}));

function CampaignValid({ priceData }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const expirateDate = getSoonestCampaignExpirationDate(priceData) || "-";
  return (
    <Box>
      <Typography className={classes.text}>{`${t(
        "campaignValid"
      )}:`}</Typography>
      &nbsp;
      <Typography className={classes.date}>{expirateDate}</Typography>
    </Box>
  );
}

CampaignValid.propTypes = {
  priceData: PropTypes.shape({}),
};

CampaignValid.defaultProps = {
  priceData: {},
};

export default CampaignValid;

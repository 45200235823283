import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../utils/currency/currency";
import Vat from "./vat";

const useStyles = makeStyles(theme => ({
  priceBlockBackgroundDisabled: {
    backgroundColor: theme.palette.text.disabled,
    borderWidth: "1px",
    borderColor: theme.palette.text.disabled,
    borderRadius: "5px",
    display: "inline-block",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(0.2),
      paddingRight: theme.spacing(0.2),
    },
  },
  priceBlockBackground: {
    backgroundColor: theme.palette.secondary.main,
    borderWidth: "1px",
    borderColor: theme.palette.secondary.main,
    borderRadius: "5px",
    display: "inline-block",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(0.2),
      paddingRight: theme.spacing(0.2),
    },
  },
  priceAndText: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  boldPrice: {
    ...theme.typography.button,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("lg")]: {
      marginRight: theme.spacing(0.5),
    },
  },
  description: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.text.secondary,
  },
  textColorCampaign: {
    color: theme.palette.text.secondary,
  },
}));

function CampaignPrice({
  campaignPrice,
  campaignPriceVat,
  description,
  vatRate,
  currency,
  showVatPercent,
  showVat,
  disabled,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  let containerClass = classes.priceBlockBackground;
  if (disabled === true) {
    // is campaign but still disabled
    containerClass = classes.priceBlockBackgroundDisabled;
  }

  return (
    <div className={containerClass}>
      <div className={classes.priceAndText}>
        <Typography className={classes.boldPrice}>
          {formatCurrency(campaignPrice, currency) || "-"}
        </Typography>
        <Typography
          align="center"
          variant="body2"
          className={classes.description}
        >
          {description || t("inCampaign")}
        </Typography>
      </div>
      {showVat === true && (
        <Vat
          vatRate={vatRate}
          vatPrice={campaignPriceVat}
          currency={currency}
          className={classes.textColorCampaign}
          showPercent={showVatPercent}
        />
      )}
    </div>
  );
}

CampaignPrice.propTypes = {
  campaignPrice: PropTypes.string.isRequired,
  campaignPriceVat: PropTypes.string.isRequired,
  vatRate: PropTypes.string,
  currency: PropTypes.string,
  disabled: PropTypes.bool,
  description: PropTypes.string,
  showVatPercent: PropTypes.bool,
  showVat: PropTypes.bool,
};

CampaignPrice.defaultProps = {
  vatRate: "24",
  currency: "EUR",
  disabled: false,
  description: "",
  showVatPercent: true,
  showVat: true,
};

export default CampaignPrice;

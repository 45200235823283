import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
// eslint-disable-next-line
import { Progress } from "../../generic";
import { CartSVGIcon } from "../../../images";
import CartAddRemove from "../../generic/addOrRemove/addOrRemove";
import { cartAddProduct } from "../../../redux/reducers";
import CartAddPopup from "../cartAddPopup/cartAddPopup";
import {
  PRODUCT_MAX_COUNT,
  PRODUCT_MIN_COUNT,
} from "../../../utils/cartUtil/cartUtil";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  addRemove: {
    textAlign: "center",
    flex: "0 0 60%",
  },
  cartButton: {
    height: "35px",
    backgroundColor: theme.palette.secondary.dark,
    borderWidth: "1px",
    borderColor: theme.palette.secondary.dark,
    borderRadius: "10px",
    textTransform: "none",
    width: "65px",
    [theme.breakpoints.down("lg")]: {
      padding: "0.25rem 0.5rem",
      minHeight: 0,
      minWidth: 0,
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  cartButtonText: {
    marginLeft: theme.spacing(1),
    display: "block",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  cartContainer: {
    textAlign: "center",
    flex: "0 0 40%",
  },
  listViewSpacing: {
    borderRadius: "8px",
  },
}));

function CartAddButtons({
  userId,
  product,
  onAddToCart,
  selectedCart,
  count,
  onCountChanged,
  useListViewSpacing,
}) {
  const classes = useStyles();
  const { carts, sendingCart } = useSelector(state => state.cart);
  const [showCartPopup, setShowCartPopup] = useState(false);
  const dispatch = useDispatch();
  const [buttonPos, setButtonPos] = useState({ top: 0, left: 0 });
  const addButtonRef = useRef(null);

  const [showProgressIcon, setShowProgressIcon] = useState(
    useListViewSpacing ? false : sendingCart
  );
  const componentIsMounted = useRef(true);
  const progressIconSize = 24;

  // Required for situations where component gets unmounted on changes in Redux store
  useEffect(
    () => () => {
      componentIsMounted.current = false;
    },
    []
  );

  useEffect(() => {
    const rect = addButtonRef?.current.getBoundingClientRect();
    if (rect) {
      setButtonPos({
        top: rect.top,
        left: rect.left - rect.width,
      });
    }
  }, [addButtonRef]);

  // sanity
  if (count <= 0) {
    onCountChanged(1);
  }

  // eslint-disable-next-line
  const doAddProduct = async (userId, cart, product, count) => {
    // add directly
    setShowProgressIcon(true);
    if (!addButtonRef?.current?.disabled) {
      addButtonRef.current.disabled = true;
    }
    const response = await dispatch(
      cartAddProduct(userId, cart.cartId, product, count)
    );
    if (componentIsMounted.current) {
      setShowProgressIcon(false);
    }
    if (response) {
      // inform that product has been added
      onAddToCart(cart, product);

      // reset
      onCountChanged(1);
    }
    if (addButtonRef?.current?.disabled) {
      addButtonRef.current.disabled = false;
    }
  };

  const onAddToCartButton = () => {
    // if there's only one cart or cart is selected
    if (selectedCart || carts.length === 1) {
      const cart = selectedCart || carts[0];

      // add
      doAddProduct(userId, cart, product, count);
    } else {
      // otherwise show popup
      setShowCartPopup(true);
    }
  };

  const onSave = cart => {
    // add
    doAddProduct(userId, cart, product, count);

    // hide
    setShowCartPopup(false);
  };

  return (
    <div className={classes.root}>
      <div
        className={`${classes.addRemove} ${
          useListViewSpacing && classes.listViewSpacing
        }`}
      >
        <CartAddRemove
          value={count}
          handleValueChanged={value => onCountChanged(value)}
          min={PRODUCT_MIN_COUNT}
          max={PRODUCT_MAX_COUNT}
        />
      </div>
      <div className={classes.cartContainer}>
        <Button
          ref={addButtonRef}
          style={{ minWidth: "40px" }}
          id="cart-add-btn"
          className={classes.cartButton}
          onClick={() => onAddToCartButton()}
          // Preventing accidental product addition to cart from users ORIGO-8845
          onKeyPress={e => {
            e.key === "Enter" && e.preventDefault();
          }}
        >
          {showProgressIcon ? (
            <Progress show size={progressIconSize} />
          ) : (
            <CartSVGIcon />
          )}
        </Button>
      </div>
      <CartAddPopup
        show={showCartPopup}
        left={buttonPos.left}
        onSave={cart => onSave(cart)}
        onCancel={() => setShowCartPopup(false)}
      />
    </div>
  );
}

CartAddButtons.propTypes = {
  userId: PropTypes.shape({}).isRequired,
  product: PropTypes.shape({}).isRequired,
  onAddToCart: PropTypes.func,
  selectedCart: PropTypes.shape({}),
  count: PropTypes.number.isRequired,
  onCountChanged: PropTypes.func,
  useListViewSpacing: PropTypes.bool,
};

CartAddButtons.defaultProps = {
  onAddToCart: () => {}, // eslint-disable-line
  selectedCart: null,
  onCountChanged: () => {}, // eslint-disable-line
  useListViewSpacing: false,
};

// extended main view with translate hoc
export default CartAddButtons;

/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import ServerError from "../../500/500";
import Can from "./can";
// eslint-disable-next-line
import { ANY_CUSTOMER } from "..";

function UserAndCustomerProtectedRoute({
  path,
  component,
  failComponent,
  user,
  customers,
  requiredPermission,
  serverError,
  selectedCustomerId,
  exact,
}) {
  if (serverError) {
    return <Route path={path} component={ServerError} />;
  }
  const data = {};
  if (selectedCustomerId === ANY_CUSTOMER) {
    data.customers = customers;
  } else {
    const customer = customers.find(x => x.Kunnr === selectedCustomerId);
    data.customer = customer;
  }
  return (
    <Can
      customerContext={ANY_CUSTOMER}
      user={user}
      perform={requiredPermission}
      ok={() => <Route exact={exact} path={path} component={component} />}
      nok={() => <Route exact={exact} path={path} component={failComponent} />}
      data={data}
    />
  );
}

UserAndCustomerProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  failComponent: PropTypes.elementType.isRequired,
  user: PropTypes.shape({}),
  customers: PropTypes.arrayOf(PropTypes.shape({})),
  requiredPermission: PropTypes.string,
  serverError: PropTypes.shape({}),
  selectedCustomerId: PropTypes.string,
  exact: PropTypes.bool,
};

UserAndCustomerProtectedRoute.defaultProps = {
  serverError: null,
  user: null,
  customers: [],
  requiredPermission: null,
  selectedCustomerId: null,
  exact: false,
};

export default UserAndCustomerProtectedRoute;

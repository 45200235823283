const Paths = {
  Main: "/",
  Products: "/products",
  OrdersInvoices: "/ordersInvoices",
  OrdersInvoicesBackOrders: "/ordersInvoices/backorders",
  OrdersInvoicesOrder: "/ordersInvoices/order",
  OrdersInvoicesHistory: "/ordersInvoices/history",
  OrdersInvoicesInvoices: "/ordersInvoices/invoices",
  OrdersInvoicesRepresentatives: "/ordersInvoices/representatives",
  Documents: "/documents",
  ShoppingCart: "/shoppingCart",
  Messages: "/messages",
  Profile: "/profile",
  FAQ: "/faq",
  ContactUs: "/contactUs",
  RequestDeliveryAddress: "/requestDeliveryAddress",
  Reports: "/reports",
  Versions: "/versions",
  UserInfo: "/userInfo",
};
export default Paths;

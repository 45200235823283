import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line
import { PopupLayout } from "../../../generic";
import {
  backorderPopupHide,
  modifyOrderline,
  notificationShow,
} from "../../../../redux/reducers";
import BackOrdersHeader from "../backOrdersTable/backOrdersHeader";
import styles from "../orderCardTableStyles";
import BackOrdersTableRow from "../backOrdersTable/backOrdersTableRow";

const useStyles = makeStyles(styles);

function EditBackorderPopup() {
  const { showEditPopup, order, orderline, amount } = useSelector(
    state => state.backorderPopup
  );
  const classes = useStyles({ isEditing: true });
  const dispatch = useDispatch();
  const products = useSelector(state => state.product.products);
  const { t } = useTranslation();
  const { productStockStatusesMap } = useSelector(state => state.stock);
  const { sendingBackorder } = useSelector(state => state.order);

  if (!order || !orderline) {
    return null;
  }

  const handleSave = () => {
    dispatch(
      modifyOrderline(order.orderId, orderline.orderLineId, amount)
    ).then(result => {
      if (result) {
        dispatch(notificationShow(t("orderUpdateSent"), null, null, 3000));
      }
      dispatch(backorderPopupHide());
    });
  };
  const onCancel = () => dispatch(backorderPopupHide());

  return (
    <PopupLayout
      width="50%"
      show={showEditPopup}
      onOk={handleSave}
      onCancel={onCancel}
      showLoader={sendingBackorder === true}
    >
      <div className={classes.backorderPopupContainer}>
        <Typography className={classes.backorderPopupTitle}>
          {t("editAmount")}
        </Typography>
        <BackOrdersHeader classes={classes} isEditing order={order} />
        <BackOrdersTableRow
          classes={classes}
          orderline={orderline}
          highlight={false}
          productStockStatusesMap={productStockStatusesMap}
          products={products}
          order={order}
          isEditing
          disabled={sendingBackorder === true}
        />
      </div>
    </PopupLayout>
  );
}

export default EditBackorderPopup;

import { LOCATION_CHANGE } from "connected-react-router";
import { User } from "../redux/reducers/user/user";
import { Product } from "../redux/reducers/product/product";
import { Cart } from "../redux/reducers/cart/cart";
import { Template } from "../redux/reducers/template/template";
import { Analytics } from "../redux/reducers/analytics/analytics";
import { Notification } from "../redux/reducers/notification/notification";
import { Order } from "../redux/reducers/order/order";
import pageViewEvent from "./pageViewEvents";
import { loginUserEvent, logoutUserEvent } from "./authEvents";
import { productFavoriteUpdateEvent, productFetchEvent } from "./productEvents";
import {
  notificationSubscribeStockEvent,
  notificationDeleteStockSubscriptionEvent,
} from "./notificationEvents";
import {
  shoppingCartUpdateEvent,
  shoppingCartSelectEvent,
  shoppingCartCreateEvent,
  shoppingCartRemoveEvent,
  orderTemplateCreateEvent,
  orderTemplateRemoveEvent,
  orderTemplateUpdateEvent,
} from "./cartTemplateEvents";
import ordersFetchEvent from "./ordersEvents";

const eventsMapper = action => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return pageViewEvent;
    case User.USER_SIGN_IN:
      return loginUserEvent;
    case User.USER_SIGN_OUT:
      return logoutUserEvent;
    case Product.UPDATE_FAVORITES:
      return productFavoriteUpdateEvent;
    case Product.FETCH_FINISHED:
      return productFetchEvent;
    case Cart.UPDATE_CART:
      return shoppingCartUpdateEvent;
    case Cart.CREATE:
      return shoppingCartCreateEvent;
    case Cart.DELETE_CART:
      return shoppingCartRemoveEvent;
    case Analytics.SELECT_SHOPPING_CART:
      return shoppingCartSelectEvent;
    case Template.UPDATE:
      return orderTemplateUpdateEvent;
    case Template.CREATE:
      return orderTemplateCreateEvent;
    case Template.REMOVE:
      return orderTemplateRemoveEvent;
    case Notification.SEND_FINISHED:
      return notificationSubscribeStockEvent;
    case Notification.DELETE_NOTIFICATION:
      return notificationDeleteStockSubscriptionEvent;
    case Order.FETCH_ORDERS_FINISHED:
      return ordersFetchEvent;
    default:
      return null;
  }
};

export default eventsMapper;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";
// eslint-disable-next-line
import { OrderFilterType, fetchOrders } from "../../../../redux/reducers";
import OpenOrdersTable from "./openOrdersTable/openOrdersTable";
// eslint-disable-next-line
import { Progress } from "../../../generic";
import { filterOpenOrders } from "../locallyFilterOrders";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    "& > *": {
      marginBottom: theme.spacing(4),
    },
  },
  loadingIndicator: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
}));

function OpenOrders({ searchValue, onItemCountUpdated }) {
  const classes = useStyles();
  const [filteredOpenOrders, setFilteredOpenOrders] = useState([]);
  const { customers } = useSelector(state => state.customer);
  const { fetchingOrders, openOrders } = useSelector(state => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    if (customers.length === 0) {
      // do not fetch orders if there are no customers and query check to avoid duplicate fetch
      return;
    }

    const filterStr = `customerIds=${customers
      .map(c => `${c.customerId}`)
      .join(",")}&viewType=open`;
    const filter = {
      value: filterStr,
      type: OrderFilterType.OpenOrdersBackOrders,
    };
    const startDateDefault = moment().subtract(24, "months").startOf("month");
    const endDateDefault = moment().endOf("day");
    // order search cannot handle large page size when there are many customers
    const pageSize = Math.min(Math.floor(5000 / customers.length), 1000);
    dispatch(
      fetchOrders(0, pageSize - 1, startDateDefault, endDateDefault, "", filter)
    );
  }, [dispatch, customers]);

  useEffect(() => {
    if (typeof onItemCountUpdated === "function") {
      onItemCountUpdated((filteredOpenOrders || []).length);
    }
    // eslint-disable-next-line
  }, [filteredOpenOrders]);

  useEffect(() => {
    setFilteredOpenOrders(filterOpenOrders(openOrders, searchValue));
  }, [openOrders, searchValue]);

  const renderContent = () => {
    if (fetchingOrders) {
      return (
        <div className={classes.loadingIndicator}>
          <Progress show />
        </div>
      );
    }

    return <OpenOrdersTable orders={filteredOpenOrders} />;
  };

  return <div className={classes.root}>{renderContent()}</div>;
}

OpenOrders.propTypes = {
  searchValue: PropTypes.string,
  onItemCountUpdated: PropTypes.func,
};

OpenOrders.defaultProps = {
  searchValue: "",
  onItemCountUpdated: null,
};

export default withRouter(OpenOrders);

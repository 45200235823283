import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Box } from "@material-ui/core";
import CartProductTableHeader from "./cartProductTableHeader";
// eslint-disable-next-line import/no-cycle
import CartProductTableFooter from "./cartProductTableFooter";
import CartProductTableRow from "./cartProductTableRow";

export const PRODUCTS_ON_PAGE = 5;

function CartProductTable({
  products,
  productPricesMap,
  productStockStatusesMap,
  productAvailabilityStatusesMap,
  priceFetchIdMap,
  stockStatusFetchIdMap,
  availabilityStatusFetchIdMap,
  onChangeProductCount,
  onProductRemoved,
  totalSumInfoText,
}) {
  return (
    <div>
      <CartProductTableHeader />
      {products.map(product => (
        <Box key={product}>
          <CartProductTableRow
            cartProduct={product}
            productPricesMap={productPricesMap}
            productStockStatusesMap={productStockStatusesMap}
            stockStatusFetchIdMap={stockStatusFetchIdMap}
            productAvailabilityStatusesMap={productAvailabilityStatusesMap}
            availabilityStatusFetchIdMap={availabilityStatusFetchIdMap}
            priceFetchIdMap={priceFetchIdMap}
            onChangeProductCount={onChangeProductCount}
            onProductRemoved={onProductRemoved}
          />
        </Box>
      ))}
      <CartProductTableFooter
        products={products}
        productPricesMap={productPricesMap}
        totalSumInfoText={totalSumInfoText}
      />
    </div>
  );
}

CartProductTable.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  productPricesMap: PropTypes.shape({}).isRequired,
  productStockStatusesMap: PropTypes.shape({}).isRequired,
  productAvailabilityStatusesMap: PropTypes.shape({}).isRequired,
  priceFetchIdMap: PropTypes.shape({}),
  stockStatusFetchIdMap: PropTypes.shape({}),
  availabilityStatusFetchIdMap: PropTypes.shape({}),
  onChangeProductCount: PropTypes.func,
  onProductRemoved: PropTypes.func,

  style: PropTypes.shape({}),

  totalSumInfoText: PropTypes.string,
};

CartProductTable.defaultProps = {
  onChangeProductCount: () => {}, // eslint-disable-line
  onProductRemoved: () => {}, // eslint-disable-line
  style: {},
  totalSumInfoText: "",
  priceFetchIdMap: {},
  stockStatusFetchIdMap: {},
  availabilityStatusFetchIdMap: {},
};

export default withRouter(CartProductTable);

import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line
import { DropDown } from "../../../../generic";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  dropdown: {
    width: "20%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export const InvoiceFilters = Object.freeze({
  All: "all",
  Marked: "marked",
});

function InvoiceFilterSelect({ onFilterTypeSelect }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const filters = [
    {
      type: InvoiceFilters.All,
      value: t("all"),
    },
    {
      type: InvoiceFilters.Marked,
      value: t("marked"),
    },
  ];
  const [selectedFilter, setSelectedFilter] = useState(filters[0]);

  const handleFilterSelected = value => {
    setSelectedFilter(value);
    onFilterTypeSelect(value.type);
  };

  return (
    <div className={classes.root}>
      <DropDown
        disabled={false}
        className={classes.dropdown}
        value={selectedFilter}
        noSelectionLabel={t("select")}
        formatLabel={c => c.value}
        options={filters}
        onSelect={handleFilterSelected}
        allowSelectNone={false}
        selectNoneLabel={t("selectNone")}
      />
    </div>
  );
}

InvoiceFilterSelect.propTypes = {
  onFilterTypeSelect: PropTypes.func,
};

InvoiceFilterSelect.defaultProps = {
  onFilterTypeSelect: () => {}, // eslint-disable-line
};

export default withRouter(InvoiceFilterSelect);

import React, { useState } from "react";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import { ALLOWED_TAGS, ALLOWED_ATTR } from "../../../utils/html/html";
import { ArrowDown, ArrowUp, Download } from "../../../images";
import NutritionInfo from "../nutritionInfo/nutritionInfo";
import colors from "../../../theme/colors";

const {
  ProductAttribute,
  getLocalizedProductName,
  getMarketingDescriptions,
  getSingleValuedAttribute,
  isPharmaceuticalProduct,
  getNonImageMediaAssets,
  getAttribute,
} = ProductUtil;

const useStyles = makeStyles(theme => ({
  descriptionHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  descriptionHeaderText: {
    flex: 2,
  },
  descriptionHeaderButton: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  descriptionBlock: {
    marginBottom: theme.spacing(2),
  },
  descriptionHeaderDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  greyText: {
    color: colors.secondaryDarkGray,
  },
  attachmentArea: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    marginTop: theme.spacing(1),
  },
  attachmentAreaColumn: {
    flexBasis: "30%",
    flexGrow: "0",
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  attachmentLink: {
    marginLeft: theme.spacing(1),
  },
  hoverHighlight: {
    "&:hover": {
      background: colors.hoverGray,
      fontWeight: "bold",
    },
    borderRadius: "5px",
    background: colors.white,
    color: colors.secondaryNavyBlue,
    boxShadow: "none",
  },
  buttonLabel: {
    color: colors.secondaryNavyBlue,
  },
  attachmentLabel: {
    paddingLeft: theme.spacing(1),
  },
  titleMargin: {
    marginBottom: theme.spacing(0.5),
  },
}));

function ProductDescription({ product }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [showFullDescription, setShowFullDescription] = useState(true);

  const renderDescriptionBlock = (title, body) => {
    // Sanitize content to strip out all non-allowed tags and attributes
    const sanitizedBody = DOMPurify.sanitize(body, {
      ALLOWED_TAGS,
      ALLOWED_ATTR,
    });

    return (
      <div className={classes.descriptionBlock}>
        <Typography
          variant="subtitle2"
          color="textPrimary"
          noWrap
          className={classes.titleMargin}
        >
          {title}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {parse(sanitizedBody)}
        </Typography>
      </div>
    );
  };

  const renderMarketingDescription = () => {
    const productName = getLocalizedProductName(product, i18n.language);
    const marketingDescriptions = getMarketingDescriptions(
      product,
      i18n.language
    );

    // try to find professional
    // eslint-disable-next-line
    let description = marketingDescriptions.find(description =>
      description.customerSegmentation.toLowerCase().includes("professional")
    );
    // if not found, try consumer
    description =
      description ||
      // eslint-disable-next-line
      marketingDescriptions.find(description =>
        description.customerSegmentation.toLowerCase().includes("consumer")
      );
    // go with empty
    description = description || { marketingDescription: "-" };

    return renderDescriptionBlock(
      productName,
      description.marketingDescription
    );
  };

  const renderUsageInstructions = () => {
    const usageInstructions = getSingleValuedAttribute(
      product,
      ProductAttribute.CONSUMER_USAGE_INSTRUCTIONS,
      i18n.language
    );
    if (usageInstructions) {
      return renderDescriptionBlock(t("usageInstructions"), usageInstructions);
    }
    return null;
  };

  const renderIngredients = () => {
    const ingredients = getSingleValuedAttribute(
      product,
      ProductAttribute.INGREDIENTS,
      i18n.language
    );
    if (ingredients) {
      return renderDescriptionBlock(t("ingredients"), ingredients);
    }
    return null;
  };

  const renderStorageInstructions = () => {
    const instructions = getSingleValuedAttribute(
      product,
      "Consumer storage instructions",
      i18n.language
    );
    if (instructions) {
      return renderDescriptionBlock(t("storageInstructions"), instructions);
    }
    return null;
  };

  const renderAllergens = () => {
    if (!isPharmaceuticalProduct(product)) {
      const allergens = getSingleValuedAttribute(
        product,
        ProductAttribute.ALLERGENS,
        i18n.language
      );
      if (allergens) {
        return renderDescriptionBlock(t("allergens"), allergens);
      }
    }
    return null;
  };

  const renderNutritionInformation = () => {
    if (!isPharmaceuticalProduct(product)) {
      return (
        <div className={classes.descriptionBlock}>
          <NutritionInfo product={product} />
        </div>
      );
    }
    return null;
  };

  const renderFreeFrom = () => {
    const freeForms = getAttribute(
      product,
      ProductAttribute.FREE_FROM,
      i18n.language
    );
    if (freeForms != null && freeForms.length > 0) {
      const freeFormsText = freeForms.join(", ");
      return renderDescriptionBlock(t("freeFrom"), freeFormsText);
    }
    return null;
  };

  const addHttpToUrl = link => {
    if (!link.startsWith("http")) {
      return `http://${link}`;
    }
    return link;
  };

  const renderLinks = () => {
    const brandUrl = getSingleValuedAttribute(
      product,
      ProductAttribute.BRAND_URL,
      i18n.language
    );
    const additionalInfoUrl = getSingleValuedAttribute(
      product,
      ProductAttribute.ADDITIONAL_INFO_URL,
      i18n.language
    );
    const externalMedialUrl = getSingleValuedAttribute(
      product,
      ProductAttribute.EXTERNAL_MEDIA_URL,
      i18n.language
    );
    if (brandUrl || additionalInfoUrl || externalMedialUrl) {
      return (
        <div className={classes.descriptionBlock}>
          <Typography
            variant="subtitle2"
            color="textPrimary"
            noWrap
            className={classes.titleMargin}
          >
            {t("links")}
          </Typography>
          {brandUrl && (
            <Button
              classes={{
                root: classes.hoverHighlight,
                label: classes.buttonLabel,
              }}
              variant="contained"
              href={addHttpToUrl(brandUrl)}
              target="_blank"
            >
              {brandUrl}
            </Button>
          )}
          {additionalInfoUrl && (
            <Button
              classes={{
                root: classes.hoverHighlight,
                label: classes.buttonLabel,
              }}
              variant="contained"
              href={addHttpToUrl(additionalInfoUrl)}
              target="_blank"
            >
              {additionalInfoUrl}
            </Button>
          )}
          {externalMedialUrl && (
            <Button
              classes={{
                root: classes.hoverHighlight,
                label: classes.buttonLabel,
              }}
              variant="contained"
              href={addHttpToUrl(externalMedialUrl)}
              target="_blank"
            >
              {externalMedialUrl}
            </Button>
          )}
        </div>
      );
    }
    return null;
  };

  // eslint-disable-next-line
  function Attachment(p) {
    const { attachment } = p;
    return (
      <div className={classes.attachmentAreaColumn}>
        <Button
          classes={{
            root: classes.hoverHighlight,
            label: classes.buttonLabel,
          }}
          variant="contained"
          href={attachment.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Download />
          <div className={classes.attachmentLabel}>
            {t(`attachments.${attachment.type}`)}
          </div>
        </Button>
      </div>
    );
  }
  // eslint-disable-next-line
  function Attachments() {
    const attachments = getNonImageMediaAssets(product);
    if (attachments.length === 0) {
      return null;
    }
    return (
      <div className={classes.descriptionBlock}>
        <Typography
          variant="subtitle2"
          color="textPrimary"
          noWrap
          className={classes.titleMargin}
        >
          {t("productAttachments")}
        </Typography>
        <div className={classes.attachmentArea}>
          {attachments.map(a => (
            <Attachment attachment={a} key={`attachment-${a.url}`} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={classes.descriptionHeader}>
        <div className={classes.descriptionHeaderText}>
          <Typography className={classes.greyText} variant="h5">
            {t("description")}
          </Typography>
        </div>
        <div className={classes.descriptionHeaderButton}>
          <Button onClick={() => setShowFullDescription(!showFullDescription)}>
            {showFullDescription ? <ArrowUp /> : <ArrowDown />}
          </Button>
        </div>
      </div>
      <Divider
        className={classes.descriptionHeaderDivider}
        variant="fullWidth"
      />
      {showFullDescription && (
        <div>
          {renderMarketingDescription()}
          {renderUsageInstructions()}
          {renderIngredients()}
          {renderStorageInstructions()}
          {renderAllergens()}
          {renderNutritionInformation()}
          {renderFreeFrom()}
          {renderLinks()}
          <Attachments />
        </div>
      )}
    </div>
  );
}

ProductDescription.propTypes = {
  product: PropTypes.shape({}).isRequired,
};

ProductDescription.defaultProps = {};

export default ProductDescription;

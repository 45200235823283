import { comparePrices } from "../../../utils/price/price";

const VET_CUSTOMER_GROUP = ["C1", "C2", "C3"];
const OPTICIAN_CUSTOMER_GROUP = "N3";
const HEALTH_SHOP_CUSTOMER_GROUP = "E1";
const BEAUTY_CUSTOMER_GROUP = "N2";
const SPORT_SHOP_CUSTOMER_GROUP = "N4";
const MISC_CUSTOMER_GROUP = "N8";

const LOWER_LIMIT_ORDER_GROUPS = [
  OPTICIAN_CUSTOMER_GROUP,
  HEALTH_SHOP_CUSTOMER_GROUP,
  BEAUTY_CUSTOMER_GROUP,
  SPORT_SHOP_CUSTOMER_GROUP,
  MISC_CUSTOMER_GROUP,
];
const MINIMUM_ORDER_AMOUNT = 300;

export const isCurrentlyValid = customer => {
  if (customer == null) {
    return false;
  }
  return customer.isCurrentlyValid === true;
};

export const isValidInTheFuture = customer => {
  if (customer == null) {
    return false;
  }
  return customer.isValidInTheFuture === true;
};

export const canViewOrders = customer => {
  if (customer == null) {
    return false;
  }
  // basically can view if valid or invalid customer, not if valid in the future
  return (
    (customer.isCurrentlyValid === false &&
      customer.isValidInTheFuture === true) === false
  );
};

export const canViewInvoices = customer => canViewOrders(customer);

export const canMakeOrders = customer => {
  if (customer == null) {
    return false;
  }
  // basically can view if valid or invalid customer, not if valid in the future
  return isCurrentlyValid(customer);
};

export const canMakeBackOrders = customer =>
  canMakeOrders(customer) === true && customer.isBackordersAllowed === true;

export const canViewProducts = customer =>
  isCurrentlyValid(customer) || isValidInTheFuture(customer);

export const isVet = customer => {
  if (customer == null) {
    return false;
  }

  return VET_CUSTOMER_GROUP.includes(customer.CustomerGroup);
};

export const isOptician = customer => {
  if (customer == null) {
    return false;
  }

  return OPTICIAN_CUSTOMER_GROUP === customer.CustomerGroup;
};

export const shouldApplyMinimumAmountOrder = customer =>
  LOWER_LIMIT_ORDER_GROUPS.includes(customer.CustomerGroup);

export const isCustomerOrderSumValid = (customer, currentOrderValue) => {
  if (customer) {
    return shouldApplyMinimumAmountOrder(customer)
      ? comparePrices(currentOrderValue, MINIMUM_ORDER_AMOUNT) > 0
      : true;
  }
  return false;
};

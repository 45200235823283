import Category from "./category";

const EventType = Object.freeze({
  UserLogin: "user-login",
  UserLogout: "user-logout",
});

export const loginUserEvent = action => {
  const { analytics } = action.payload;

  // sanity
  if (analytics == null) {
    return null;
  }

  const userName = analytics.signInData.userData.email;
  return {
    event: EventType.UserLogin,
    eventAction: "Login",
    eventCategory: Category.Authentication,
    eventLabel: `User login ${userName}`,
    eventValue: 0,
  };
};

export const logoutUserEvent = () => ({
  event: EventType.UserLogout,
  eventAction: "Logout",
  eventCategory: Category.Authentication,
  eventLabel: "User logout",
  eventValue: 0,
});

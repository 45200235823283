import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import colors from "../../../theme/colors";
import { Snowflake } from "../../../images";

const { isColdStorageProduct, isFreezerStorageProduct } = ProductUtil;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: colors.backgroundDarkGray,
    borderColor: colors.backgroundDarkGray,
    borderStyle: "solid",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "1.5rem",
  },
  imageContainer: {
    backgroundColor: "white",
    border: "1px solid white",
    borderRadius: "50%",
    marginRight: "1.5rem",
  },
  textContainer: {},
  titleText: {
    ...theme.typography.subtitle2,
    marginBottom: "0.5rem",
  },
  bodyText: {
    ...theme.typography.button,
    textTransform: "none",
  },
}));

// Renders only if there's cold products
function ColdProductNotification({ products }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (products) {
      const containsColdProducts = products.some(
        cartProduct =>
          isColdStorageProduct(cartProduct.product) ||
          isFreezerStorageProduct(cartProduct.product)
      );
      setShow(containsColdProducts);
    }
  }, [products, products.length, setShow]);

  if (show) {
    return (
      <div className={classes.root}>
        <div className={classes.imageContainer}>
          <Snowflake />
        </div>
        <div className={classes.textContainer}>
          <Typography color="textPrimary" className={classes.titleText}>
            {t("coldStorageProductInfoTitle")}
          </Typography>
          <Typography color="textPrimary" className={classes.bodyText}>
            {t("coldStorageProductInfoBody")}
          </Typography>
        </div>
      </div>
    );
  }
  return null;
}

ColdProductNotification.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ColdProductNotification.defaultProps = [];

export default ColdProductNotification;

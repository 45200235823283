import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Colors from "../../theme/colors";
import { Wrench } from "../../images";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(8),
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  flex: {
    display: "flex",
  },
  customerService: {
    marginLeft: theme.spacing(1),
    borderBottom: `solid 2px ${Colors.highlightBlue}`,
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  iconContainer: {
    marginRight: theme.spacing(2),
    width: "4.113rem",
    height: "4.13rem",
    background: Colors.backgroundDarkGray,
    borderRadius: "50%",
    textAlign: "center",
  },
  icon: {
    marginLeft: ".2rem",
    marginTop: "33%",
  },
}));

function ServerError() {
  const classes = useStyles();
  const { t } = useTranslation();

  const titleText = t("somethingWentWrong");
  const descriptionText = t("pleaseTryAgain");
  const infoText = t("problemOccurs");
  const customerService = t("customerService");
  return (
    <div className={classes.root}>
      <div className={classes.flex}>
        <div className={classes.iconContainer}>
          <div className={classes.icon}>
            <Wrench />
          </div>
        </div>
        <div>
          <Typography className={classes.title} variant="h5">
            {titleText}
          </Typography>
          <Typography variant="body1">{descriptionText}</Typography>
          <div className={classes.flex}>
            <Typography variant="body1">{infoText}</Typography>
            <Link className={classes.link} to="contactUs">
              <Typography className={classes.customerService} variant="body1">
                {customerService}
              </Typography>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServerError;

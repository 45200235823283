import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import { FilterType } from "../redux/reducers/product/product";
import Category from "./category";

const { getLocalizedProductName } = ProductUtil;

const EventType = Object.freeze({
  ProductAddFavorite: "product-add-favorite",
  ProductRemoveFavorite: "product-remove-favorite",
  ProductSearch: "product-search",
  ProductFilter: "product-filter",
});

export const productFavoriteUpdateEvent = action => {
  const { analytics } = action.payload;

  // sanity
  if (analytics == null) {
    return null;
  }

  const { add, product } = analytics;
  const localizedName = getLocalizedProductName(product, "en");

  if (add === true) {
    return {
      event: EventType.ProductAddFavorite,
      eventAction: `Add ${localizedName}`,
      eventCategory: Category.Product,
      eventLabel: `Add favorite product ${localizedName}`,
      eventValue: "",
    };
  }
  return {
    event: EventType.ProductRemoveFavorite,
    eventAction: `Remove ${localizedName}`,
    eventCategory: Category.Product,
    eventLabel: `Remove favorite product ${localizedName}`,
    eventValue: "",
  };
};

export const productFetchEvent = action => {
  const { analytics } = action.payload;

  // sanity
  if (analytics == null) {
    return null;
  }

  const { startIndex, queryText, filter, resultCount } = analytics;

  // interested only about the first fetch
  if (startIndex > 0) {
    return null;
  }

  // user is searching something
  if (queryText != null) {
    return {
      event: EventType.ProductSearch,
      eventAction: "Search products",
      eventCategory: Category.Product,
      eventLabel: `Search text ${queryText}, filter ${filter.type}`,
      eventValue: resultCount,
      searchTerm: queryText,
    };
  }
  if (filter.type !== FilterType.NoFilter) {
    // user is filtering something
    return {
      event: EventType.ProductFilter,
      eventAction: "Filter products",
      eventCategory: Category.Product,
      eventLabel: `Filter by ${filter.type}`,
      eventValue: resultCount,
    };
  }
  return null;
};

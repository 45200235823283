import React from "react";
import { useTranslation } from "react-i18next";

function ChangeLanguage() {
  const { i18n } = useTranslation();
  return (
    <div>
      <button type="button" onClick={() => i18n.changeLanguage("fi")}>
        fi
      </button>
      <button type="button" onClick={() => i18n.changeLanguage("en")}>
        en
      </button>
    </div>
  );
}

// extended main view with translate hoc
export default ChangeLanguage;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { Route, withRouter } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line
import Invoices from "./invoices/invoices";
import OrderHistory from "./orderHistory/orderHistory";
import OpenOrders from "./openOrders/openOrders";
import Paths from "../../../utils/navigation/navigation";
import { DateRangePicker, TabBar, DynamicSearchField } from "../../generic";
import { setDateRange, setQueryText } from "../../../redux/reducers";
import BackOrders from "./backOrders/backOrders";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "7.3%",
    marginRight: "7.3%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  container: {
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  headerContainer: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  searchAndDate: {
    display: "flex",
    flexDirection: "row",
    width: "80%",
    alignItems: "center",
  },
  date: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  searchResults: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: theme.palette.text.disabled,
  },
  tabContainer: {
    display: "flex",
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(1),
  },
  orderLink: {
    fontSize: "1.07rem",
    fontWeight: 700,
    color: theme.palette.text.disabled,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

function OrdersInvoices({ history }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { startDate, endDate, query, overallOrderCount } = useSelector(
    state => state.order
  );
  const { selectedCustomerId } = useSelector(state => state.customer);
  const [locallyFilteredItemCount, setLocallyFilteredItemCount] = useState(0);
  const dispatch = useDispatch();
  const { pathname } = history.location;
  const [isDateSelectorHidden, setIsDateSelectorHidden] = useState(false);
  // tab handler
  const [tabValue, setTabValue] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const setDateSelectorVisibility = path => {
    setIsDateSelectorHidden(
      ["/ordersInvoices", "/ordersInvoices/backorders"].includes(path)
    );
  };

  useEffect(() => {
    // scroll always to top
    window.scrollTo(0, 0);

    // set path
    setTabValue(history.location.pathname);
    setDateSelectorVisibility(history.location.pathname);
  }, [history, history.location]);

  const getSearchResultsCount = () => {
    // Search result count varies based on active tab.
    // - Open orders / backorders based on locally filtered dataset length
    // - History based on returned items from the backend
    // - Invoices based on returrned invoices

    if (
      pathname === Paths.OrdersInvoices ||
      pathname === Paths.OrdersInvoicesBackOrders ||
      pathname === Paths.OrdersInvoicesInvoices
    ) {
      return locallyFilteredItemCount;
    }
    return overallOrderCount;
  };

  const getSearchPlaceholder = () => {
    if (
      pathname === Paths.OrdersInvoices ||
      pathname === Paths.OrdersInvoicesBackOrders
    ) {
      return t("ordersSearchPlaceholder");
    }
    if (pathname === Paths.OrdersInvoicesInvoices) {
      return t("invoicesSearchPlaceholder");
    }
    if (pathname === Paths.OrdersInvoicesHistory) {
      return t("historySearchPlaceholder");
    }

    return "";
  };

  const renderHeader = () => {
    const searchResultsStr = `${getSearchResultsCount()} ${t(
      "searchResults"
    ).toLowerCase()}`;
    const searchPlaceholder = getSearchPlaceholder();

    return (
      <div className={classes.headerContainer}>
        <div className={classes.searchAndDate}>
          <DynamicSearchField
            onSearch={text => {
              // update
              if (!isDateSelectorHidden) {
                dispatch(setQueryText(text));
              }
              setSearchValue(text);
            }}
            placeholder={searchPlaceholder}
            selectedCustomerId={selectedCustomerId}
            searchValue={searchValue}
          />
          <div className={classes.date}>
            {!isDateSelectorHidden && (
              <DateRangePicker
                startDate={startDate}
                endDate={query ? moment() : endDate}
                hideStartDate={!!query}
                onDatesSelected={(start, end) => {
                  // update range
                  if (start && end) {
                    dispatch(setDateRange(moment(start), moment(end)));
                  }
                }}
                disabled={!!query}
              />
            )}
          </div>
        </div>
        <Typography className={classes.searchResults} variant="body1">
          {searchResultsStr}
        </Typography>
      </div>
    );
  };

  // navigation helper
  const navigate = path => {
    setTabValue(path);
    history.push(path);
  };

  const handleTabChange = value => {
    // Clear search field and fetch new result every tab change
    setSearchValue("");
    dispatch(setQueryText(""));
    navigate(value);
    setDateSelectorVisibility(value);
    setLocallyFilteredItemCount(0);
  };

  const renderTabs = () => {
    const tabs = [
      {
        label: t("openOrders"),
        value: Paths.OrdersInvoices,
        badgeValue: 0,
      },
      {
        label: t("backorders"),
        value: Paths.OrdersInvoicesBackOrders,
        badgeValue: 0,
      },
      {
        label: t("orderHistory"),
        value: Paths.OrdersInvoicesHistory,
        badgeValue: 0,
      },
      {
        label: t("invoices"),
        value: Paths.OrdersInvoicesInvoices,
        badgeValue: 0,
      },
    ];

    return (
      <div className={classes.tabContainer}>
        <TabBar tabs={tabs} value={tabValue} onValueChanged={handleTabChange} />
      </div>
    );
  };

  const renderTabContent = () => (
    <div>
      <Route exact path={Paths.OrdersInvoices}>
        <OpenOrders
          searchValue={searchValue}
          onItemCountUpdated={itemCount =>
            setLocallyFilteredItemCount(itemCount)
          }
        />
      </Route>
      <Route
        exact
        path={Paths.OrdersInvoicesHistory}
        component={OrderHistory}
      />
      <Route exact path={Paths.OrdersInvoicesBackOrders}>
        <BackOrders
          searchValue={searchValue}
          onItemCountUpdated={itemCount =>
            setLocallyFilteredItemCount(itemCount)
          }
        />
      </Route>
      <Route exact path={Paths.OrdersInvoicesInvoices}>
        <Invoices
          searchValue={searchValue}
          onItemCountUpdated={itemCount =>
            setLocallyFilteredItemCount(itemCount)
          }
        />
      </Route>
    </div>
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.container}>
        {renderHeader()}
        {renderTabs()}
        {renderTabContent()}
      </Paper>
    </div>
  );
}

OrdersInvoices.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

OrdersInvoices.defaultProps = {};

export default withRouter(OrdersInvoices);

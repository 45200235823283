import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import { Typography } from "@material-ui/core";

function TooltipTypography(props) {
  const [overflowed, setOverflowed] = useState(false);
  const { children, tooltip, delay } = props;

  // ref
  let typographyRef;
  // eslint-disable-next-line
  const setTypographyRef = ref => (typographyRef = ref);

  useEffect(() => {
    const overflow = typographyRef.scrollWidth > typographyRef.clientWidth;
    setOverflowed(overflow);
  }, [typographyRef]);

  return (
    <Tooltip
      title={tooltip}
      disableHoverListener={!overflowed}
      enterDelay={delay}
      enterTouchDelay={delay}
    >
      <Typography ref={setTypographyRef} {...props} noWrap>
        {children}
      </Typography>
    </Tooltip>
  );
}

TooltipTypography.propTypes = {
  children: PropTypes.node,
  tooltip: PropTypes.node,
  delay: PropTypes.number,
};

TooltipTypography.defaultProps = {
  children: "",
  tooltip: "",
  delay: 200,
};

export default TooltipTypography;

const checkIfNullOrUndefined = value => value === null || value === undefined;

const checkIfNotEmptyString = value => {
  if (typeof value !== "string") {
    return false;
  }
  return value.trim() !== "";
};

const checkIfFiniteNumber = value => {
  if (typeof value !== "number") {
    return false;
  }
  return !Number.isNaN(value) && Number.isFinite(value);
};

const checkIfNotEmptyArray = value => {
  if (!Array.isArray(value)) {
    return false;
  }
  return value.length !== 0;
};

const checkIfHasValue = value =>
  !checkIfNullOrUndefined(value) &&
  (checkIfNotEmptyString(value) ||
    checkIfFiniteNumber(value) ||
    checkIfNotEmptyArray(value));
export default checkIfHasValue;

const BackorderPopup = Object.freeze({
  BACKORDER_POPUP_SHOW: "BACKORDER_POPUP_SHOW",
  BACKORDER_POPUP_HIDE: "BACKORDER_POPUP_HIDE",
  BACKORDER_DELETE_POPUP_SHOW: "BACKORDER_DELETE_POPUP_SHOW",
  BACKORDER_POPUP_UPDATE_AMOUNT: "BACKORDER_POPUP_UPDATE_AMOUNT",
});

export const backorderPopupShow = (order, orderline, amount) => dispatch => {
  dispatch({
    type: BackorderPopup.BACKORDER_POPUP_SHOW,
    payload: {
      showEditPopup: true,
      showDeletePopup: false,
      order,
      orderline,
      amount,
    },
  });
};

export const backorderPopupHide = () => dispatch => {
  dispatch({
    type: BackorderPopup.BACKORDER_POPUP_HIDE,
  });
};

export const backorderUpdateAmount = amount => dispatch => {
  dispatch({
    type: BackorderPopup.BACKORDER_POPUP_UPDATE_AMOUNT,
    amount,
  });
};

export const backorderDeletePopupShow = (order, orderline) => dispatch => {
  dispatch({
    type: BackorderPopup.BACKORDER_DELETE_POPUP_SHOW,
    payload: {
      showDeletePopup: true,
      showEditPopup: false,
      order,
      orderline,
      amount: 0,
    },
  });
};

const INIT_STATE = {
  showEditPopup: false,
  showDeletePopup: false,
  order: null,
  orderline: null,
  amount: null,
};
// eslint-disable-next-line
export const backorderPopupReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case BackorderPopup.BACKORDER_POPUP_SHOW:
      return { ...state, ...action.payload };
    case BackorderPopup.BACKORDER_POPUP_UPDATE_AMOUNT:
      return { ...state, amount: action.amount };
    case BackorderPopup.BACKORDER_DELETE_POPUP_SHOW:
      return { ...state, ...action.payload };
    case BackorderPopup.BACKORDER_POPUP_HIDE:
      return { ...INIT_STATE };
    default:
      return state;
  }
};

import Category from "./category";
import { OrderFilterType } from "../redux/reducers/order/order";

const EventType = Object.freeze({
  OrdersSearch: "orders-search",
  OrdersFilter: "orders-filter",
});

const ordersFetchEvent = action => {
  const { analytics } = action.payload;

  // sanity
  if (analytics == null) {
    return null;
  }

  const {
    startIndex,
    queryText,
    filter,
    resultCount,
    startDateStr,
    endDateStr,
  } = analytics;

  // interested only about the first fetch
  if (startIndex > 0) {
    return null;
  }

  // user is searching something
  if (queryText != null) {
    return {
      event: EventType.OrdersSearch,
      eventAction: "Search orders",
      eventCategory: Category.Orders,
      eventLabel: `Search text ${queryText}, filter ${filter.type}, startDate ${startDateStr}, endDate ${endDateStr}`,
      eventValue: resultCount,
      searchTerm: queryText,
    };
  }
  if (filter.type !== OrderFilterType.NoFilter) {
    // user is filtering something
    return {
      event: EventType.OrdersFilter,
      eventAction: "Filter orders",
      eventCategory: Category.Orders,
      eventLabel: `Filter ${filter.type}, startDate ${startDateStr}, endDate ${endDateStr}`,
      eventValue: resultCount,
    };
  }
  return null;
};

export default ordersFetchEvent;

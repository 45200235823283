/* eslint-disable import/no-cycle */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import TableRow from "@material-ui/core/TableRow";
import { dateFormatString } from "../../../../../utils/date/date";
import {
  DataCell,
  PriceCell,
  CustomerCell,
  InvoicePdfCell,
  MarkCell,
} from "./cells";
import { fetchInvoicePDF } from "../../../../../redux/reducers";
import { getInvoiceType, InvoiceType } from "./invoiceType";

function InvoiceTableRow({ invoice, marked, onMarkClicked, classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { fetchingInvoice, invoiceId } = useSelector(state => state.invoice);
  const formatInvoiceDate = str => {
    if (str) {
      return moment(str).format(dateFormatString);
    }
    return null;
  };

  const dataURItoBlob = dataURI => {
    // convert base64 to raw binary data held in a string
    const byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  };

  const invoiceType = getInvoiceType(invoice, t);

  const handleDownload = () => {
    dispatch(fetchInvoicePDF(invoice.customerId, invoice.invoiceNumber)).then(
      response => {
        if (response) {
          const blob = dataURItoBlob(response);
          const fileURL = URL.createObjectURL(blob);
          window.open(fileURL, "_blank");
        }
      }
    );
  };

  const isCreditNote = invoiceType.type === InvoiceType.CREDIT;

  return (
    <TableRow>
      <DataCell className={classes.invoiceDate}>
        {formatInvoiceDate(invoice.invoiceDate)}
      </DataCell>
      <CustomerCell invoice={invoice} classes={classes} />
      <DataCell className={classes.documentType}>
        {invoiceType.displayType}
      </DataCell>
      <DataCell className={classes.invoiceNumber}>
        {invoice.invoiceNumber}
      </DataCell>
      <PriceCell
        className={classes.amount}
        invoice={invoice}
        isCreditNote={isCreditNote}
      />
      <InvoicePdfCell
        classes={classes}
        handleDownload={handleDownload}
        invoice={invoice}
        fetching={fetchingInvoice}
        invoiceDownloaded={invoiceId}
      />
      <MarkCell
        className={classes.mark}
        invoice={invoice}
        marked={marked}
        onMarkClicked={onMarkClicked}
      />
    </TableRow>
  );
}

InvoiceTableRow.propTypes = {
  invoice: PropTypes.shape({
    invoiceDate: PropTypes.string,
    invoiceNumber: PropTypes.string,
    invoiceNetAmt: PropTypes.string,
    invoiceType: PropTypes.string,
    customerId: PropTypes.string,
  }),
  classes: PropTypes.shape({
    invoiceDate: PropTypes.string,
    documentType: PropTypes.string,
    invoiceNumber: PropTypes.string,
    amount: PropTypes.string,
    mark: PropTypes.string,
  }),
  marked: PropTypes.bool,
  onMarkClicked: PropTypes.func,
};

InvoiceTableRow.defaultProps = {
  invoice: {},
  classes: {},
  marked: false,
  onMarkClicked: () => {}, // eslint-disable-line
};

export default InvoiceTableRow;

import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import AnnouncementItem from "./announcementItem";
import Colors from "../../../theme/colors";

const useStyles = makeStyles(theme => ({
  announcements: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  announcementsContainer: {
    marginTop: theme.spacing(1),
  },
  link: {
    "&:hover": {
      fontWeight: "bold",
      textDecoration: "none",
    },
    borderRadius: "5px",
    borderBottom: `solid 2px ${Colors.underlineGray}`,
  },
}));

function Announcements({ announcements, showAllUrl }) {
  const classes = useStyles();
  const { t } = useTranslation();
  if (announcements.length < 1) {
    return null;
  }
  const renderAnnouncements = announcements.map(item => (
    <AnnouncementItem key={`${item.url}`} announcement={item} />
  ));
  return (
    <div className={classes.announcements}>
      <div className={classes.headerContainer}>
        <Typography variant="subtitle2" color="textPrimary" noWrap>
          {t("productAnnouncements")}
        </Typography>
        <Link
          href={showAllUrl}
          target="_blank"
          rel="noopener noreferrer"
          color="inherit"
          className={classes.link}
        >
          <Typography variant="body1" color="textPrimary">
            {t("showAll")}
          </Typography>
        </Link>
      </div>
      <div className={classes.announcementsContainer}>
        {renderAnnouncements}
      </div>
    </div>
  );
}

Announcements.propTypes = {
  announcements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showAllUrl: PropTypes.string,
};

Announcements.defaultProps = {
  showAllUrl: "",
};

export default Announcements;

import PropTypes from "prop-types";
import { CanWrapper } from "@oriola-origo/origo-ui-core";
import { isAllowed } from "./authorizationRules";

const Can = CanWrapper(isAllowed);

Can.propTypes = {
  user: PropTypes.shape({}),
  perform: PropTypes.string,
  data: PropTypes.shape({}),
  ok: PropTypes.func,
  nok: PropTypes.func,
  customerContext: PropTypes.string,
};

Can.defaultProps = {
  nok: () => null,
};

export default Can;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ClearIcon from "@material-ui/icons/Clear";
import colors from "../../../theme/colors";
import getProductCategoryLocalizedName from "../../../utils/productCategoryUtil/productCategoryUtil";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    backgroundColor: theme.palette.text.primary,
    borderColor: theme.palette.text.primary,
    color: colors.white,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 30,
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1),
    height: 33,
    fontWeight: "normal",
    fontSize: "1rem",
    "&:hover": {
      color: theme.palette.text.primary,
      backgroundColor: colors.backgroundGray,
      "@media (hover: none)": {
        color: colors.white,
        backgroundColor: theme.palette.text.primary,
      },
    },
    textDecoration: "none",
  },
  clearButton: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
  },
}));

function CategoryQuickButtons({
  categories,
  onCategoryRemoved,
  onClearCategories,
}) {
  const classes = useStyles();
  const { i18n, t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {categories.map(category => {
          const name = getProductCategoryLocalizedName(i18n.language, category);
          const nameAndCount = `${name || "-"} (${category.count})`;
          return (
            <Grid key={category} item>
              <Button
                className={classes.button}
                onClick={() => onCategoryRemoved(category)}
              >
                {nameAndCount}
                <ClearIcon />
              </Button>
            </Grid>
          );
        })}
        {categories.length !== 0 && (
          <Grid item>
            <Button className={classes.clearButton} onClick={onClearCategories}>
              {t("clearSelection")}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

CategoryQuickButtons.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  onCategoryRemoved: PropTypes.func,
  onClearCategories: PropTypes.func,
};

CategoryQuickButtons.defaultProps = {
  categories: [],
  onCategoryRemoved: () => {}, // eslint-disable-line
  onClearCategories: () => {}, // eslint-disable-line
};

export default CategoryQuickButtons;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import Progress from "../progress/progress";
import colors from "../../../theme/colors";

const useStyles = makeStyles(theme => ({
  root: {
    left: "50%",
    top: "25%",
    position: "absolute",
    width: "24%",
    marginLeft: "-12%",
    backgroundColor: colors.white,
    borderRadius: 5,
    boxShadow: theme.shadows[2],
    pointerEvents: "all",
    "&:focus": {
      outline: "none",
    },
  },
  container: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flexGrow: 1,
  },
  icon: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textContainer: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  primaryText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.2),
  },
}));

function MakingOrderDialog({ onClose }) {
  const classes = useStyles();
  const makingOrderDialog = useSelector(state => state.makingOrderDialog);
  return (
    <Modal
      open={makingOrderDialog.show}
      className={classes.modal}
      hideBackdrop
      onClose={onClose}
    >
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.icon}>
            <Progress show={makingOrderDialog.show} size={62} />
          </div>
          <div className={classes.textContainer}>
            <Typography variant="subtitle1" color="textPrimary">
              {makingOrderDialog.title}
            </Typography>
            <Typography
              className={classes.primaryText}
              variant="body2"
              color="textSecondary"
              style={{ fontWeight: "normal" }}
            >
              {makingOrderDialog.primaryText}
            </Typography>
            <Typography
              variant="body2"
              color="textPrimary"
              style={{ textTransform: "normal" }}
            >
              {makingOrderDialog.secondaryText}
            </Typography>
          </div>
        </div>
      </div>
    </Modal>
  );
}

MakingOrderDialog.propTypes = {
  onClose: PropTypes.func,
};

MakingOrderDialog.defaultProps = {
  onClose: () => {}, // eslint-disable-line
};

export default MakingOrderDialog;

import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
// eslint-disable-next-line
import { ConfirmationPopup } from "../../../generic";
import {
  backorderPopupHide,
  modifyOrderline,
  notificationShow,
} from "../../../../redux/reducers";

function DeleteBackorderPopup() {
  const { showDeletePopup, order, orderline } = useSelector(
    state => state.backorderPopup
  );
  const { sendingBackorder } = useSelector(state => state.order);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleDelete = () => {
    dispatch(modifyOrderline(order.orderId, orderline.orderLineId, 0)).then(
      result => {
        if (result) {
          dispatch(
            notificationShow(t("backorderDeleteSuccesful"), null, null, 3000)
          );
        }
        dispatch(backorderPopupHide());
      }
    );
  };
  const onCancel = () => dispatch(backorderPopupHide());
  const title = t("deleteBackorderProduct");
  const description = orderline
    ? `${t("deleteDescription")} ${orderline.productName}?`
    : `${t("deleteDescription")}?`;

  return (
    <ConfirmationPopup
      title={title}
      description={description}
      show={showDeletePopup}
      onOk={handleDelete}
      onCancel={onCancel}
      saveKey="yes"
      cancelKey="no"
      showLoader={sendingBackorder === true}
    />
  );
}

export default DeleteBackorderPopup;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: ({ backgroundColor }) => ({
    backgroundColor,
    textTransform: "uppercase",
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderRadius: theme.spacing(3),
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  }),
}));

function SpecialInformationLabel({ className, backgroundColor, children }) {
  const classes = useStyles({ backgroundColor });
  const rootClasses = [classes.root, className].filter(Boolean).join(" ");
  return <div className={rootClasses}>{children}</div>;
}

SpecialInformationLabel.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

SpecialInformationLabel.defaultProps = {
  className: "",
};

export default SpecialInformationLabel;

import moment from "moment";
import {
  removeInvalidCustomersAndAddresses,
  findCustomerById,
  findShippingAddressById,
} from "../customer/customer";

const isNull = value => value == null;

const nullOrZero = value => value == null || value === 0;

const addValidationError = (isError, errorTag, errors) => {
  if (isError) {
    errors.push(errorTag);
  }
};

export const cartNameExists = (name, carts) => {
  if (Array.isArray(carts)) {
    return (
      carts.map(cart => cart.name.toLowerCase()).indexOf(name.toLowerCase()) !==
      -1
    );
  }
  return false;
};

export const findCartByName = (name, carts) => {
  if (Array.isArray(carts)) {
    return carts.find(cart => cart.name.toLowerCase() === name.toLowerCase());
  }
  return null;
};

export const findCartById = (cartId, carts) => {
  if (Array.isArray(carts)) {
    return carts.find(cart => cart.cartId === cartId);
  }
  return null;
};

export const sortByLastModified = carts => {
  carts.sort((a, b) =>
    moment(a.updatedDate).isSameOrAfter(moment(b.updatedDate)) ? -1 : 1
  );
  return carts;
};

export const findLastModifiedCart = carts => {
  if (Array.isArray(carts) && carts.length > 0) {
    const sortedCarts = sortByLastModified(carts);
    return sortedCarts[0];
  }
  return null;
};

/**
 * Generate next default name for a new shipping cart using a base name plus a number
 * E.g. "Shopping cart 1", "Shopping cart 2" etc.
 * The first free number is found and the new name generetd from that.
 *
 * @param {*} carts All current carts
 * @param {*} defaultNameBase The name base to use for matching against existing carts and generating the new name
 */
export const generateDefaultName = (carts, defaultNameBase) => {
  const defaultNameRegex = new RegExp(`^${defaultNameBase} (\\d+)$`, "i");
  const nextFreeNumber = (carts || [])
    .reduce((acc, c) => {
      // get numbers from the carts whose name matches the default format
      const match = (c.name || "").match(defaultNameRegex);
      return match ? [...acc, +match[1]] : acc;
    }, [])
    .sort()
    .reduce((prev, cur) => {
      // find first unused number
      if (cur - prev > 0) {
        // gap found
        return prev;
      }
      return cur + 1;
    }, 1);
  return `${defaultNameBase} ${nextFreeNumber}`;
};

export const OrderValidationError = {
  CartDataMissing: "CartDataMissing",
  ProductDataMissing: "ProductDataMissing",
  CustomerDataMissing: "CustomerDataMissing",
  CustomerBranchMissing: "CustomerBranchMissing",
  SupplierIdMissing: "SupplierIdMissing",
  ProductQuantityMissing: "ProductQuantityMissing",
  ProductIdMissing: "ProductIdMissing",
  OutOfStockProductsOnly: "OutOfStockProductsOnly",
};

export const localizeValidationErrors = (t, errors) =>
  errors.map(error => {
    switch (error) {
      case OrderValidationError.CartDataMissing:
        return t("invalidCartData");
      case OrderValidationError.CustomerDataMissing:
      case OrderValidationError.CustomerBranchMissing:
      case OrderValidationError.SupplierIdMissing:
        return t("invalidCustomerData");
      case OrderValidationError.ProductDataMissing:
      case OrderValidationError.ProductQuantityMissing:
      case OrderValidationError.ProductIdMissing:
        return t("invalidProductData");
      case OrderValidationError.OutOfStockProductsOnly:
        return t("outOfStockProductsOnly");
      default:
        return "";
    }
  });

export const validateCartCustomerAndShippingAddress = (cart, customers) => {
  if (
    cart == null ||
    customers == null ||
    cart.customer == null ||
    cart.shipToAddress == null
  ) {
    return {
      customerValid: false,
      shippingAddressValid: false,
    };
  }

  const customerId = cart.customer;

  // get valid customers. Remove first any invalid or passivated customers and addresses.
  const validCustomers = removeInvalidCustomersAndAddresses(customers);
  const customer = findCustomerById(customerId, validCustomers);
  const customerValid = customer != null;

  // validate shipping address
  let shippingAddressValid = false;
  if (customer != null) {
    const shippingAddressId = cart.shipToAddress;
    const shippingAddress = findShippingAddressById(
      shippingAddressId,
      customer
    );
    shippingAddressValid = shippingAddress != null;
  }

  return { customerValid, shippingAddressValid };
};

// SPECS: https://api.oriola-cloud.com/#/definitions/OrderPost
export const validateOrder = (
  cart,
  backOrdersAllowed,
  outOfStockProducts,
  customers
) => {
  const errors = [];

  // no cart
  if (isNull(cart)) {
    addValidationError(true, OrderValidationError.CartDataMissing, errors);
    return errors;
  }

  const { customerValid, shippingAddressValid } =
    validateCartCustomerAndShippingAddress(cart, customers);

  // customer id and ship to address
  addValidationError(
    customerValid === false || shippingAddressValid === false,
    OrderValidationError.CustomerDataMissing,
    errors
  );

  // TODO: what is customer branch
  // TODO: what is the supplier id

  // no products
  const cartProducts = cart.products;
  if (nullOrZero(cartProducts.length)) {
    addValidationError(true, OrderValidationError.ProductDataMissing, errors);
    return errors;
  }

  // check products
  cartProducts.forEach(cartProduct => {
    const { count } = cartProduct;
    const { product } = cartProduct;

    // wanted quantity
    addValidationError(
      nullOrZero(count),
      OrderValidationError.ProductQuantityMissing,
      errors
    );

    // id
    addValidationError(
      nullOrZero(product.materialId),
      OrderValidationError.ProductIdMissing,
      errors
    );
  });

  // customer does not allow backorders but they have tried to order a cart that has only out of stock products
  if (
    backOrdersAllowed === false &&
    cart.products.length === outOfStockProducts.length
  ) {
    addValidationError(
      true,
      OrderValidationError.OutOfStockProductsOnly,
      errors
    );
  }

  // remove duplicates
  return Array.from(new Set(errors));
};

export const createOrder = cart => {
  const rows = cart.products.map(cartProduct => {
    const { count } = cartProduct;
    const { product } = cartProduct;

    return {
      productId: product.materialId,
      quantity: count,

      // not used ATM
      // discountAmount: null,
      // not used ATM
      // discountPercentage: null,
    };
  });

  return {
    customerBranch: cart.customer,
    shipToCustomerBranch: cart.shipToAddress,
    shoppingCartId: cart.cartId,
    cartOwner: cart.owner,
    supplier: 0, // supplier has no relevance in eCom orders and is not sent to SAP so sending zero
    rows,
  };
};

export const PRODUCT_MAX_COUNT = 999;
export const PRODUCT_MIN_COUNT = 1;

export const clampProductCount = value => {
  if (value > PRODUCT_MAX_COUNT) {
    return PRODUCT_MAX_COUNT;
  }
  if (value < PRODUCT_MIN_COUNT) {
    return PRODUCT_MIN_COUNT;
  }
  return value;
};

export const resolveCartSendErrorMessage = (status, t) => {
  if (status === 404) {
    return t("cartNotFound");
  }
  if (status === 409) {
    return t("cartSendConflict");
  }
  return t("cartSendFailed");
};

/* eslint-disable @typescript-eslint/no-empty-function,import/no-cycle,jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Trashcan } from "../../../images";
import CartAddRemove from "../../generic/addOrRemove/addOrRemove";
import Paths from "../../../utils/navigation/navigation";
import {
  ProductName,
  Stock,
  UnitPrice,
  TotalPrice,
  Thumbnail,
} from "../../productList";
import {
  PRODUCT_MAX_COUNT,
  PRODUCT_MIN_COUNT,
} from "../../../utils/cartUtil/cartUtil";

const FIXED_ROW_HEIGHT = 100;

const useStyles = makeStyles(theme => ({
  productRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderBottom: `1px solid ${theme.palette.tableDivider.normal}`,
    height: FIXED_ROW_HEIGHT,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    textDecoration: "none",
  },
  fixedRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  column: {
    overflow: "hidden",
    height: FIXED_ROW_HEIGHT - theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  centeredColumn: {
    height: "auto",
    paddingTop: 0,
    textAlign: "center",
  },
  columnImage: {
    flex: "1 0 6%",
    display: "flex",
    justifyContent: "center",
  },
  columnName: {
    flex: "1 0 20%",
    [theme.breakpoints.down("md")]: {
      flex: "1 0 31%",
    },
    [theme.breakpoints.down("sm")]: {
      flex: "1 0 65%",
    },
  },
  columnAvailability: {
    flex: "1 0 16%",
  },
  columnUnitPrice: {
    flex: "1 0 13%",
  },
  columnTotalPrice: {
    flex: "1 0 10%",
    [theme.breakpoints.down("sm")]: {
      flex: "1 0 20%",
    },
  },
  columnAmount: {
    flex: "1 0 12%",
  },
  columnRemove: {
    flex: "1 0 5%",
    [theme.breakpoints.down("sm")]: {
      flex: "1 0 15%",
    },
  },
}));

function CartProductTableRow({
  cartProduct,
  productPricesMap,
  productStockStatusesMap,
  stockStatusFetchIdMap,
  productAvailabilityStatusesMap,
  availabilityStatusFetchIdMap,
  priceFetchIdMap,
  onChangeProductCount,
  onProductRemoved,
}) {
  const classes = useStyles();

  const { product } = cartProduct;
  const priceData = productPricesMap.get(product.materialId);
  const stockStatus = productStockStatusesMap.get(product.materialId);

  const renderControl = (targetProduct, count) => (
    <div>
      <CartAddRemove
        handleValueChanged={value => {
          onChangeProductCount(targetProduct, value);
        }}
        value={count}
        min={PRODUCT_MIN_COUNT}
        max={PRODUCT_MAX_COUNT}
      />
    </div>
  );

  const renderRemove = productId => (
    <IconButton id="remove-trash" onClick={() => onProductRemoved(productId)}>
      <Trashcan />
    </IconButton>
  );

  return (
    <Link
      to={`${Paths.Products}/${product.materialId}`}
      className={classes.productRow}
    >
      <div className={classes.fixedRow}>
        <Hidden smDown>
          <div
            className={`${classes.column} ${classes.centeredColumn} ${classes.columnImage}`}
          >
            <Thumbnail url={product.thumbnailUrl} />
          </div>
        </Hidden>
        <div className={`${classes.column} ${classes.columnName}`}>
          <ProductName product={product} stockStatus={stockStatus} showMsi />
        </div>
        <Hidden smDown>
          <div className={`${classes.column} ${classes.columnAvailability}`}>
            <Stock
              stockStatus={stockStatus}
              fetchingStock={stockStatusFetchIdMap.get(product.materialId)}
              availabilityInformation={productAvailabilityStatusesMap.get(
                product.materialId
              )}
              fetchingAvailability={availabilityStatusFetchIdMap.get(
                product.materialId
              )}
            />
          </div>
        </Hidden>
        <Hidden smDown>
          <div className={`${classes.column} ${classes.columnUnitPrice}`}>
            <UnitPrice
              priceData={priceData}
              fetching={priceFetchIdMap.get(product.materialId)}
              productCount={cartProduct.count}
            />
          </div>
        </Hidden>

        <div className={`${classes.column} ${classes.columnTotalPrice}`}>
          <TotalPrice
            cartProduct={cartProduct}
            productPricesMap={productPricesMap}
          />
        </div>

        <Hidden smDown>
          <div
            className={`${classes.column} ${classes.centeredColumn} ${classes.columnAmount}`}
            // prevent propagation when control clicked
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {renderControl(product, cartProduct.count)}
          </div>
        </Hidden>
        <div
          className={`${classes.column} ${classes.centeredColumn} ${classes.columnRemove}`}
          // prevent propagation when remove clicked
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {renderRemove(product.materialId)}
        </div>
      </div>
    </Link>
  );
}

CartProductTableRow.propTypes = {
  cartProduct: PropTypes.shape({
    product: PropTypes.shape({
      materialId: PropTypes.string,
      thumbnailUrl: PropTypes.string,
    }),
    count: PropTypes.number,
  }),
  productPricesMap: PropTypes.shape({
    get: PropTypes.func,
  }),
  productStockStatusesMap: PropTypes.shape({
    get: PropTypes.func,
  }),
  stockStatusFetchIdMap: PropTypes.shape({
    get: PropTypes.func,
  }),
  productAvailabilityStatusesMap: PropTypes.shape({
    get: PropTypes.func,
  }),
  availabilityStatusFetchIdMap: PropTypes.shape({
    get: PropTypes.func,
  }),
  priceFetchIdMap: PropTypes.shape({
    get: PropTypes.func,
  }),
  onChangeProductCount: PropTypes.func,
  onProductRemoved: PropTypes.func,
};

CartProductTableRow.defaultProps = {
  cartProduct: {
    product: {
      materialId: null,
      thumbnailUrl: null,
    },
    count: null,
  },
  productPricesMap: {
    get: () => {},
  },
  productStockStatusesMap: {
    get: () => {},
  },
  stockStatusFetchIdMap: {
    get: () => {},
  },
  productAvailabilityStatusesMap: {
    get: () => {},
  },
  availabilityStatusFetchIdMap: {
    get: () => {},
  },
  priceFetchIdMap: {
    get: () => {},
  },
  onChangeProductCount: () => {},
  onProductRemoved: () => {},
};

export default CartProductTableRow;

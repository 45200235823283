import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import VisibilitySensor from "react-visibility-sensor";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
// eslint-disable-next-line
import { TooltipTypography, Price } from "../../generic";
import {
  getPrices,
  calculateCartProductPrice,
} from "../../../utils/price/price";
import {
  getFormattedDate,
  formatWeekShort,
  getYear,
} from "../../../utils/date/date";
import { isInStock } from "../../../utils/stockUtil/stockUtil";
import { DefaultProductThumbnail } from "../../../images";
import colors from "../../../theme/colors";
import {
  getAdditionalInformation,
  shouldShowAvailabilityDateInformation,
} from "../../../utils/availabilityUtil/availability";

const {
  getLocalizedProductName,
  getProductContentAmount,
  getMarketer,
  isProductEnteringMarket,
} = ProductUtil;

const NO_VALUE = "n/a";

const useStyles = makeStyles(theme => ({
  fixedFooter: {
    display: "flex",
    flexDirection: "row",
    borderTop: `1px solid ${colors.backgroundGay}`,
    paddingTop: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  inStock: {
    ...theme.typography.button,
    color: "green",
  },
  outOfStock: {
    ...theme.typography.button,
    color: "red",
    textTransform: "none",
  },
  textColorGrey: {
    color: theme.palette.text.disabled,
    paddingTop: "0.2rem",
  },

  productNumber: {
    ...theme.typography.caption,
    color: theme.palette.text.disabled,
    fontSize: "0.75rem",
  },
  productNumberPadding: {
    paddingTop: "0.2rem",
  },
  marketer: {
    ...theme.typography.body2,
    fontSize: "0.875rem",
    color: colors.secondaryNavyBlue,
  },
  vnrMsi: {
    ...theme.typography.caption,
    color: theme.palette.text.disabled,
    fontSize: "0.75rem",
    marginTop: theme.spacing(2),
  },
  waitingData: {
    ...theme.typography.caption,
    color: theme.palette.text.disabled,
  },
  fetchFailed: {
    ...theme.typography.caption,
    color: theme.palette.error.main,
  },
  thumbnail: {
    width: "40px",
    height: "40px",
    objectFit: "scale-down",
  },
  ellipsisText: {
    overflow: "hidden",
    marginRight: theme.spacing(2),
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  enteringMarket: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.disabled,
    fontSize: "0.875rem",
  },
}));

export function Thumbnail({ url, title }) {
  const classes = useStyles();
  const [hasError, setHasError] = useState(false);

  const handleError = () => {
    setHasError(true);
  };

  return (
    <VisibilitySensor>
      {hasError || !url ? (
        <DefaultProductThumbnail className={classes.thumbnail} />
      ) : (
        <img
          className={classes.thumbnail}
          src={url}
          alt={title || ""}
          onError={handleError}
        />
      )}
    </VisibilitySensor>
  );
}

const noValueToNull = value => (value === NO_VALUE ? null : value);

export function ProductName({ product, stockStatus, showMsi, isBackOrder }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const name = getLocalizedProductName(product, i18n.language);
  const contentAmount = getProductContentAmount(product, i18n.language);

  let vnrMsi = null;
  const vnr = noValueToNull(product.vnr);
  const msi = noValueToNull(product.msiCode);

  if (vnr) {
    vnrMsi = `${t("vnr")}: ${vnr}`;
  } else if (msi) {
    vnrMsi = `${t("msi")}: ${msi}`;
  }

  const expirationDate = stockStatus
    ? getFormattedDate(stockStatus.oldestExpDate)
    : t("waitingData");
  const expiration = `${t("expirationDate")}: ${expirationDate}`;
  return (
    <div>
      <TooltipTypography
        className={classes.ellipsisText}
        variant="body1"
        color="textPrimary"
        tooltip={name}
        noWrap
        id="product-name"
      >
        {name}
      </TooltipTypography>
      <Typography variant="body1" color="textPrimary">
        {contentAmount}
      </Typography>
      {showMsi && (
        <Typography className={classes.textColorGrey} variant="body2">
          {vnrMsi}
        </Typography>
      )}
      {!isBackOrder && (
        <Typography className={classes.textColorGrey} variant="body2">
          {expiration}
        </Typography>
      )}
    </div>
  );
}

export const cleanInformation = (info, t, i18n) => {
  let text = `${t("orderByDemand")}`;
  if (info) {
    const tEN = i18n.getFixedT("en");
    const tFI = i18n.getFixedT("fi");
    text = text.concat(
      `  ${info?.replace(tEN("orderByDemand"), "").replace(tFI("orderByDemand"), "")} `
    );
  }
  return text;
};

export function Availability({
  availabilityInformation,
  fetchingAvailability,
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const availabilityDate = availabilityInformation?.availabilityDate;
  const additionalInformation = getAdditionalInformation(
    availabilityInformation,
    i18n
  );

  let availabilityTitle = additionalInformation
    ? ""
    : t("estimatedAvailabilityShort");
  let availabilityValue = additionalInformation
    ? ""
    : t("isUnknown").toLocaleLowerCase();
  if (fetchingAvailability && !availabilityDate) {
    availabilityTitle = t("waitingData");
    availabilityValue = null;
  } else if (availabilityDate) {
    const weekStr = formatWeekShort(availabilityDate);
    const yearStr = getYear(availabilityDate);
    availabilityTitle = `${availabilityTitle}:`;
    availabilityValue = `${weekStr}/${yearStr}`;
  }

  return (
    <div>
      {shouldShowAvailabilityDateInformation(availabilityInformation) && (
        <>
          <Typography variant="body2" className={classes.textColorGrey}>
            {availabilityTitle}
          </Typography>
          <Typography variant="body2" className={classes.textColorGrey}>
            {availabilityValue}
          </Typography>
        </>
      )}
      {additionalInformation && (
        <TooltipTypography
          className={classes.textColorGrey}
          variant="body2"
          tooltip={additionalInformation}
        >
          {additionalInformation}
        </TooltipTypography>
      )}
    </div>
  );
}

export function OutOfStock() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Typography className={classes.outOfStock} noWrap>
      {t("outOfStock")}
    </Typography>
  );
}
export function InStock() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Typography className={classes.inStock} style={{ textTransform: "none" }}>
      {t("inStock")}
    </Typography>
  );
}
export function Stock({
  stockStatus,
  fetchingStock,
  product,
  availabilityInformation,
  fetchingAvailability,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const enteringMarket = isProductEnteringMarket(product);
  // fetching
  if (fetchingStock) {
    return (
      <Typography className={classes.waitingData}>
        {t("waitingData")}
      </Typography>
    );
  }
  if (!fetchingStock && !stockStatus) {
    // fetch finished but no stockStatus
    return (
      <Typography className={classes.fetchFailed}>
        {t("stockSearchFailed")}
      </Typography>
    );
  }
  if (stockStatus) {
    const inStock = isInStock(stockStatus);

    if (inStock) {
      return <InStock />;
    }
    if (enteringMarket) {
      return (
        <Typography className={classes.enteringMarket}>
          {t("enteringMarket")}
        </Typography>
      );
    }
    return (
      <div>
        <OutOfStock />
        <Availability
          availabilityInformation={availabilityInformation}
          fetchingAvailability={fetchingAvailability}
        />
      </div>
    );
  }
  // no data
  return <Typography className={classes.waitingData}>-</Typography>;
}

export function UnitPrice({ priceData, fetching, productCount }) {
  const classes = useStyles();
  const { t } = useTranslation();

  // still fetching?
  if (fetching && !priceData) {
    return (
      <Typography className={classes.waitingData}>
        {t("waitingData")}
      </Typography>
    );
  }
  if (priceData) {
    // parse values
    const prices = getPrices(priceData, productCount);
    return <Price prices={prices} />;
  }
  // no data
  return <Typography className={classes.waitingData}>-</Typography>;
}

export function TotalPrice({ cartProduct, productPricesMap }) {
  const prices = calculateCartProductPrice(cartProduct, productPricesMap);
  if (prices != null) {
    return <Price prices={prices} />;
  }
  return null;
}

export function Marketer({ product }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  // from Search
  const marketer = getMarketer(product, i18n.language);

  let vnrMsi = null;
  const vnr = noValueToNull(product.vnr);
  const msi = noValueToNull(product.msiCode);
  if (vnr) {
    vnrMsi = `${t("vnr")}: ${vnr}`;
  } else if (msi) {
    vnrMsi = `${t("msi")}: ${msi}`;
  }

  return (
    <div>
      {marketer && (
        <Typography className={classes.marketer}>{marketer}</Typography>
      )}
      {vnrMsi && (
        <Typography className={`${classes.vnrMsi}`}>{vnrMsi}</Typography>
      )}
      <Typography className={`${classes.productNumber}`}>
        {`${t("productNo")}: ${product.materialId}`}
      </Typography>
    </div>
  );
}

Thumbnail.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
};

Thumbnail.defaultProps = {
  url: null,
  title: null,
};

ProductName.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    contentAmount: PropTypes.string,
    vnr: PropTypes.string,
    msiCode: PropTypes.string,
  }),
  stockStatus: PropTypes.shape({
    oldestExpDate: PropTypes.string,
  }),
  showMsi: PropTypes.bool,
  isBackOrder: PropTypes.bool,
};

ProductName.defaultProps = {
  product: {
    name: null,
    contentAmount: null,
    vnr: null,
    msiCode: null,
  },
  stockStatus: null,
  showMsi: false,
  isBackOrder: false,
};

Stock.propTypes = {
  stockStatus: PropTypes.shape({
    oldestExpDate: PropTypes.string,
  }),
  fetchingStock: PropTypes.bool,
  product: PropTypes.shape({}),
  availabilityInformation: PropTypes.shape({}),
  fetchingAvailability: PropTypes.bool,
};

Stock.defaultProps = {
  stockStatus: {
    oldestExpDate: null,
  },
  fetchingStock: false,
  product: null,
  availabilityInformation: null,
  fetchingAvailability: false,
};

Availability.propTypes = {
  availabilityInformation: PropTypes.shape({
    availabilityDate: PropTypes.string,
    information: PropTypes.string,
    hasPermanentInformation: PropTypes.bool,
    companies: PropTypes.arrayOf(
      PropTypes.shape({
        deliveryBlockCode: PropTypes.string,
      })
    ),
  }),
  fetchingAvailability: PropTypes.bool,
};

Availability.defaultProps = {
  availabilityInformation: {
    availabilityDate: null,
    information: null,
  },
  fetchingAvailability: false,
};

UnitPrice.propTypes = {
  priceData: PropTypes.shape({}),
  productCount: PropTypes.number,
  fetching: PropTypes.bool,
};

UnitPrice.defaultProps = {
  priceData: null,
  productCount: 0,
  fetching: false,
};
TotalPrice.propTypes = {
  cartProduct: PropTypes.shape({}),
  productPricesMap: PropTypes.shape({}),
};

TotalPrice.defaultProps = {
  cartProduct: null,
  productPricesMap: null,
};

Marketer.propTypes = {
  product: PropTypes.shape({
    materialId: PropTypes.string,
    marketer: PropTypes.string,
    vnr: PropTypes.string,
    msiCode: PropTypes.string,
  }),
};

Marketer.defaultProps = {
  product: {
    materialId: null,
    marketer: null,
    vnr: null,
    msiCode: null,
  },
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AccountIcon from "@material-ui/icons/AccountCircle";
import Typography from "@material-ui/core/Typography";
import MessageIcon from "@material-ui/icons/Message";
import HomeIcon from "@material-ui/icons/Home";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
// eslint-disable-next-line
import { IconTextButton, IconTextLabel, ChangeLanguage } from "../..";
import { CartButton } from "../../../shoppingCart";
import Paths from "../../../../utils/navigation/navigation";

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.background.paper,
  },
  toolbar: {
    marginLeft: "7.3%",
    marginRight: "7.3%",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  title: {},
  button: {
    margin: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  signOutButton: {
    textTransform: "none",
    marginLeft: theme.spacing(1),
  },
}));

// TODO: populate user data

function Header({ history }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(Paths.Products); // TODO: change to main

  // get name from redux
  const { name } = useSelector(state => state.user.userData);

  // navigation helper
  const navigate = path => {
    history.push(path);
  };

  // tab handler
  const handleChange = (event, newValue) => {
    // navigate
    navigate(newValue);

    // update
    setValue(newValue);
  };

  // listen browser button actions
  useEffect(() => {
    // eslint-disable-next-line
    let newPath = `/${window.location.pathname.split("/")[1]}`;
    if (
      newPath === Paths.Main ||
      newPath === Paths.OrdersInvoices ||
      newPath === Paths.Products ||
      newPath === Paths.Documents
    ) {
      setValue(newPath);
    }
  }, [setValue]);

  // TODO: set the name
  return (
    <AppBar className={classes.appBar} elevation={0} position="static">
      <Toolbar className={classes.toolbar} variant="dense">
        <Typography
          className={classes.title}
          variant="caption"
          noWrap
          color="primary"
        >
          eCom
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            label={<IconTextLabel text={t("frontpage")} icon={<HomeIcon />} />}
            value={Paths.Main}
          />
          <Tab
            label={<IconTextLabel text={t("products")} icon={<HomeIcon />} />}
            value={Paths.Products}
          />
          <Tab
            label={
              <IconTextLabel
                text={t("ordersAndInvoices")}
                icon={<HomeIcon />}
              />
            }
            value={Paths.OrdersInvoices}
          />
        </Tabs>
        <div className={classes.grow} />
        <CartButton
          text={t("shoppingCart")}
          onClick={() => navigate(Paths.ShoppingCart)}
        />
        <IconButton
          className={classes.button}
          onClick={() => navigate(Paths.Messages)}
        >
          <MessageIcon />
        </IconButton>
        <IconTextButton
          text={name}
          icon={<AccountIcon />}
          onClick={() => navigate(Paths.Profile)}
        />
        <ChangeLanguage />
        <Button
          className={classes.signOutButton}
          onClick={() => {
            window.location.replace(`${window.location.origin}/logout`);
          }}
        >
          {t("signOut")}
        </Button>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(Header);

import React from "react";
import { Route, useLocation, matchPath } from "react-router-dom";
import PropTypes from "prop-types";
import NotFound from "../../404/404";

function IsEnabled({ children, isRoute, path, enabled }) {
  const location = useLocation();
  const isCorrectPath = matchPath(location.pathname, { path }) !== null;

  if (isRoute) {
    return enabled && isCorrectPath ? (
      children
    ) : (
      <Route path={location.pathname} component={NotFound} />
    );
  }

  return enabled ? children : null;
}

IsEnabled.propTypes = {
  isRoute: PropTypes.bool,
  path: PropTypes.string,
  enabled: PropTypes.bool.isRequired,
  children: PropTypes.node,
};
IsEnabled.defaultProps = {
  isRoute: false,
  path: "",
  children: null,
};

export default IsEnabled;

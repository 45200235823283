/* eslint-disable import/no-cycle */
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import TableRow from "@material-ui/core/TableRow";
import { Typography } from "@material-ui/core";
import {
  DataCell,
  OrderIdCell,
  DeliveryAddressCell,
} from "../../openOrders/openOrdersBackOrdersCells";
import {
  dateFormatString,
  formatWeekShort,
  getYear,
} from "../../../../../utils/date/date";
import { isInStock } from "../../../../../utils/stockUtil/stockUtil";
import { InStock, OutOfStock } from "../../../../productList";
import { SinglePrice, TextLink } from "../../../../generic";
import {
  getBackorderQuantity,
  canEditBackorder,
} from "../../../../../utils/orderUtil/orderUtil";
import {
  DeleteBackOrder,
  ModifyBackorder,
} from "../../../orderCard/orderTableCells/orderTableCells";
import { canViewProducts } from "../../../../auth/authorization/customerRules";

function BackordersTableRow({
  orderLine,
  classes,
  productStockStatus,
  fetchingProductStockStatus,
  productAvailability,
  fetchingProductAvailability,
}) {
  const { t } = useTranslation();
  const { selectedCustomerId, customers } = useSelector(
    state => state.customer
  );
  const customer = customers.find(c => c.customerId === selectedCustomerId);
  const productViewAllowed = canViewProducts(customer);
  // eslint-disable-next-line
  const formatOrderDate = str => {
    if (str) {
      return moment(str).format(dateFormatString);
    }
  };

  const getPONumber = order => order.poNumber || "-";

  // eslint-disable-next-line
  function InOrOutOfStock(p) {
    const { stockStatus, fetchingStock } = p;
    if (fetchingStock) {
      return (
        <Typography className={classes.waitingData}>
          {t("waitingData")}
        </Typography>
      );
    }
    if (!fetchingStock && !stockStatus) {
      // fetch finished but no stockStatus
      return (
        <Typography className={classes.fetchFailed}>
          {t("stockSearchFailed")}
        </Typography>
      );
    }
    if (!stockStatus) {
      return <Typography className={classes.waitingData}>-</Typography>;
    }
    return isInStock(stockStatus) ? <InStock /> : <OutOfStock />;
  }

  // eslint-disable-next-line
  function ProductVnrAvailability(p) {
    // eslint-disable-next-line
    const { orderLine } = p;

    let vnrMsi = "";
    if (orderLine.vnr) {
      vnrMsi = `${t("vnr")}: ${orderLine.vnr} |`;
    } else if (orderLine.msi) {
      vnrMsi = `${t("msi")}: ${orderLine.msi} |`;
    }

    // due to invalid data sent by SAP, some old order lines may be missing product number
    const linkToProductCardDisabled =
      productViewAllowed === false || !orderLine.productId;
    return (
      <DataCell className={classes.productCell}>
        <TextLink
          to={`/products/${orderLine.productId}?customerId=${orderLine.order.customerId}`}
          text={orderLine.productName || "-"}
          mb={1}
          disabled={linkToProductCardDisabled}
        />

        <Typography className={classes.cellSecondaryText}>
          {`${vnrMsi} ${t("productNo")}: ${orderLine.productId || "-"}`}
        </Typography>

        {productViewAllowed === true && (
          <InOrOutOfStock
            stockStatus={productStockStatus}
            fetchingStock={fetchingProductStockStatus}
          />
        )}
      </DataCell>
    );
  }

  // eslint-disable-next-line
  function Availability(p) {
    const { availability, fetching, stockStatus } = p;

    if (!stockStatus || isInStock(stockStatus)) {
      // product is in stock or no stock data, do not display availability
      return <DataCell />;
    }

    if (fetching && !availability) {
      return (
        <Typography className={classes.waitingData}>
          {t("waitingData")}
        </Typography>
      );
    }
    const availabilityDate = availability?.availabilityDate;
    const availabilityStr = availabilityDate
      ? `${formatWeekShort(availabilityDate)}/${getYear(availabilityDate)}`
      : t("backordersTable.estimatedAvailabilityIsUnknown");
    return (
      <DataCell className={classes.availabilityCell}>
        {availabilityStr}
      </DataCell>
    );
  }

  // eslint-disable-next-line
  function OrderLinePrice(p) {
    // eslint-disable-next-line
    const { orderLine } = p;
    const {
      netPrice: net,
      price: gross,
      currency,
      vatPercent: vatRate,
    } = orderLine;

    return (
      <DataCell className={classes.priceCellOrder}>
        <SinglePrice
          price={net}
          priceVat={gross}
          vatRate={vatRate}
          currency={currency}
          breakpointValue="xl"
        />
      </DataCell>
    );
  }

  return (
    <TableRow hover>
      <ProductVnrAvailability orderLine={orderLine} />
      <DataCell className={classes.orderDateCell}>
        {formatOrderDate(orderLine.order.orderDate)}
      </DataCell>
      <DeliveryAddressCell order={orderLine.order} />
      <OrderIdCell order={orderLine.order} />
      <DataCell className={classes.poNumberCell}>
        {getPONumber(orderLine.order)}
      </DataCell>
      <Availability
        availability={productAvailability}
        fetchingAvailability={fetchingProductAvailability}
        stockStatus={productStockStatus}
      />
      <OrderLinePrice orderLine={orderLine} />
      <DataCell className={classes.quantityCell}>
        {(orderLine.isWaitingSapUpdate && (
          <div className={classes.quantityContent}>{t("waitingUpdate")}</div>
        )) || (
          <div className={classes.quantityContent}>
            <div>{getBackorderQuantity(orderLine)}</div>
            {canEditBackorder(orderLine.order) === true && (
              <ModifyBackorder order={orderLine.order} orderline={orderLine} />
            )}
          </div>
        )}
      </DataCell>
      <DataCell className={classes.deleteOrderline}>
        {!orderLine.isWaitingSapUpdate &&
          canEditBackorder(orderLine.order) === true && (
            <DeleteBackOrder
              order={orderLine.order}
              orderline={orderLine}
              classes={classes}
            />
          )}
      </DataCell>
    </TableRow>
  );
}

BackordersTableRow.propTypes = {
  orderLine: PropTypes.shape({
    order: PropTypes.shape({
      customerId: PropTypes.string,
      orderDate: PropTypes.string,
      poNumber: PropTypes.string,
    }),
    productId: PropTypes.string,
    productName: PropTypes.string,
    vnr: PropTypes.string,
    msi: PropTypes.string,
    netPrice: PropTypes.number,
    price: PropTypes.number,
    currency: PropTypes.string,
    vatPercent: PropTypes.number,
    isWaitingSapUpdate: PropTypes.bool,
  }),
  classes: PropTypes.shape({
    productCell: PropTypes.string,
    cellSecondaryText: PropTypes.string,
    availabilityCell: PropTypes.string,
    priceCellOrder: PropTypes.string,
    quantityCell: PropTypes.string,
    deleteOrderline: PropTypes.string,
    orderDateCell: PropTypes.string,
    poNumberCell: PropTypes.string,
    waitingData: PropTypes.string,
    fetchFailed: PropTypes.string,
    quantityContent: PropTypes.string,
  }),
  productStockStatus: PropTypes.shape({}),
  fetchingProductStockStatus: PropTypes.bool,
  productAvailability: PropTypes.shape({}),
  fetchingProductAvailability: PropTypes.bool,
};

BackordersTableRow.defaultProps = {
  orderLine: {},
  classes: {},
  productStockStatus: {},
  fetchingProductStockStatus: false,
  productAvailability: {},
  fetchingProductAvailability: false,
};

export default BackordersTableRow;

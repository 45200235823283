import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line
import { TooltipTypography } from "../../generic";

const useStyles = makeStyles(theme => ({
  productDetailRowHighlight: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  productDetailRow: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  cellLeft: {
    width: "37%",
  },
  cellMid: {
    width: "calc(63% - 85px)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  cellRight: {
    width: "80px",
    paddingRight: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    columnGap: "10px",
    "& p": {
      display: "flex",
    },
  },
  icon: {
    "& img": {
      "max-width": "36px",
    },
  },
}));

function ProductDetailRow({ title, value, highlight, icons }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const className = highlight
    ? classes.productDetailRowHighlight
    : classes.productDetailRow;
  return (
    <div className={className}>
      <div className={classes.cellLeft}>
        <TooltipTypography
          variant="subtitle2"
          color="textPrimary"
          tooltip={title}
          noWrap
        >
          {title}
        </TooltipTypography>
      </div>
      <div className={classes.cellMid}>
        <TooltipTypography
          variant="body1"
          color="textPrimary"
          tooltip={value}
          noWrap
        >
          {value}
        </TooltipTypography>
      </div>

      {/* eslint-disable-next-line */}
      {!!icons?.length && (
        <div className={classes.cellRight}>
          {/* eslint-disable-next-line */}
          {icons.map(i => (
            <Tooltip title={t(i.key)} key={i.key}>
              <Typography
                className={classes.icon}
                variant="subtitle2"
                color="textPrimary"
              >
                <img src={i.img} alt={t(i.key)} />
              </Typography>
            </Tooltip>
          ))}
        </div>
      )}
    </div>
  );
}

ProductDetailRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.shape({}),
  highlight: PropTypes.bool,
  icons: PropTypes.shape({
    key: PropTypes.string,
    img: PropTypes.string,
  }),
};

ProductDetailRow.defaultProps = {
  title: "",
  value: "",
  highlight: false,
  icons: null,
};

export default ProductDetailRow;

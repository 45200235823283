import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SinglePrice from "./singlePrice";
import BestPrice from "./bestPrice";
import {
  calculateVatPrice,
  PRICE_TYPE_DISCOUNT,
} from "../../../utils/price/price";
import { formatCurrency } from "../../../utils/currency/currency";

const useStyles = makeStyles(theme => ({
  scaleCampaign: {
    backgroundColor: theme.palette.secondary.main,
    borderWidth: "1px",
    borderColor: theme.palette.secondary.main,
    borderRadius: "5px",
    color: "white",
    fontWeight: "bold",
    marginRight: "2px",
    padding: "2px 6px",
    fontSize: "10px",
    marginBottom: "2px",
  },
}));

function Price({ prices }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { bestQuantityBasedDiscount = [] } = prices;

  const showBestPrice =
    prices.bestPrice && parseFloat(prices.bestPrice) < parseFloat(prices.price);

  const vatPrice = showBestPrice
    ? calculateVatPrice(prices.bestPrice, prices.vatRate)
    : calculateVatPrice(prices.price, prices.vatRate);
  const renderPrice = () => {
    if (showBestPrice) {
      return (
        <BestPrice
          bestPrice={prices.bestPrice}
          originalPrice={prices.price}
          vatPrice={vatPrice}
          vatRate={prices.vatRate}
        />
      );
    }
    return (
      <SinglePrice
        price={prices.price}
        priceVat={calculateVatPrice(prices.price, prices.vatRate)}
        vatRate={prices.vatRate}
      />
    );
  };

  const renderLabels = () => (
    <Box display="flex" flexWrap="wrap">
      {showBestPrice && prices.bestPriceType === PRICE_TYPE_DISCOUNT && (
        <Typography
          align="center"
          variant="body2"
          className={classes.scaleCampaign}
        >
          {t("yearlyDiscount")}
        </Typography>
      )}
      {bestQuantityBasedDiscount.map(entry => (
        <Typography
          align="center"
          variant="body2"
          className={classes.scaleCampaign}
          key={entry.price}
        >{`${entry.quantity} ${t("pcs")} à ${formatCurrency(
          entry.price
        )}`}</Typography>
      ))}
    </Box>
  );

  return (
    <>
      {renderPrice()}
      {renderLabels()}
    </>
  );
}

Price.propTypes = {
  prices: PropTypes.shape({
    price: PropTypes.string.isRequired,
    bestPrice: PropTypes.string,
    bestPriceType: PropTypes.string,
    bestQuantityBasedDiscount: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number,
        price: PropTypes.string,
      })
    ),
    vatRate: PropTypes.number.isRequired,
  }).isRequired,
};

Price.defaultProps = {};

export default Price;

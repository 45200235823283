import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
// eslint-disable-next-line import/no-cycle
import { SinglePrice } from "../../generic";
import colors from "../../../theme/colors";
import { calculateCartProductOverallPrice } from "../../../utils/price/price";

const useStyles = makeStyles(theme => ({
  fixedFooter: {
    display: "flex",
    flexDirection: "row",
    borderTop: `1px solid ${colors.backgroundDarkGray}`,
    paddingTop: theme.spacing(2),
  },
  fixedFooterLeft: {
    flex: "1 0 60%",
    [theme.breakpoints.down("md")]: {
      flex: "1 0 70%",
    },
    [theme.breakpoints.down("sm")]: {
      flex: "1 0 65%",
    },
  },
  textHorizontalMargin: {
    marginRight: theme.spacing(2),
  },
  boldText: {
    fontWeight: "bold",
  },
  fixedFooterRight: {
    flex: "1 0 30%",
    [theme.breakpoints.down("sm")]: {
      flex: "1 0 35%",
    },
    display: "flex",
    justifyContent: "space-evenly",
  },
  grow: {
    flexGrow: 1,
  },
  totalSumInfoText: {
    ...theme.typography.body2,
    color: theme.palette.text.primary,
  },
  footerAmount: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  textSmallMargin: {
    marginRight: theme.spacing(1),
  },
}));

function CartProductTableFooter({
  products,
  productPricesMap,
  totalSumInfoText,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const overallPrices = calculateCartProductOverallPrice(
    products,
    productPricesMap
  );
  const totalCount = products.map(x => x.count).reduce((a, b) => a + b, 0);

  return (
    <div className={classes.fixedFooter}>
      <div className={classes.fixedFooterLeft}>
        <div className={classes.footerProductCount}>
          <Typography
            variant="button"
            color="textPrimary"
            noWrap
            className={classes.textHorizontalMargin}
            style={{ textTransform: "none" }}
          >
            {t("orderLines")}
          </Typography>
          <Typography
            id="order-lines"
            variant="button"
            color="textPrimary"
            noWrap
            className={classes.boldText}
          >
            {products.length}
          </Typography>
          <Typography
            variant="button"
            color="textPrimary"
            noWrap
            className={classes.textHorizontalMargin}
            style={{ textTransform: "none", float: "right" }}
          >
            {t("total")}
          </Typography>
        </div>
      </div>

      <div className={classes.fixedFooterRight}>
        <div className={classes.grow}>
          <SinglePrice
            price={overallPrices.price}
            priceVat={overallPrices.priceVat}
            showVatPercent={false}
          />
          {totalSumInfoText && (
            <Box mt={0.5}>
              <Typography className={classes.totalSumInfoText}>
                {totalSumInfoText}
              </Typography>
            </Box>
          )}
        </div>
        <div className={classes.footerAmount}>
          <Typography
            variant="button"
            color="textPrimary"
            noWrap
            className={classes.textSmallMargin}
            style={{ textTransform: "none" }}
          >
            {t("totalAmount")}
          </Typography>
          <Typography
            variant="button"
            color="textPrimary"
            noWrap
            className={classes.boldText}
          >
            {totalCount}
          </Typography>
        </div>
      </div>
    </div>
  );
}

CartProductTableFooter.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})),
  productPricesMap: PropTypes.shape({}),
  totalSumInfoText: PropTypes.string,
};

CartProductTableFooter.defaultProps = {
  products: [],
  productPricesMap: {},
  totalSumInfoText: "",
};

export default CartProductTableFooter;

/* eslint-disable import/no-cycle */
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import {
  fetchProductById,
  fetchReplacementProducts,
  fetchProductPrices,
  setFavoriteProduct,
  fetchStockByProductId,
  fetchAvailabilityByProductId,
  notificationShow,
  notificationHide,
  subscribeStockNotification,
  fetchSubscribedNotificationsPerCustomer,
  deleteNotificationSubscription,
  NotificationType,
  fetchAnnouncements,
  templateAddProduct,
} from "../../../redux/reducers";
import {
  Compare,
  Favorite,
  FavoriteBorder,
  More,
  No,
  Yes,
  DefaultProductImage,
  ShoppingCartRound,
  NotiOn,
  NotiOff,
} from "../../../images";

import { getPrices } from "../../../utils/price/price";
import { isInStock } from "../../../utils/stockUtil/stockUtil";
import ProductDetailsTable from "../productDetailsTable/productDetailsTable";
import {
  IconTextButton,
  IconTextLabel,
  NotificationPopup,
  Progress,
  ConfirmationPopup,
  ErrorPopup,
  TooltipTypography,
  Price,
} from "../../generic";
import { CartAddButtons } from "../../shoppingCart";
import { TemplateAddPopup } from "../../orders";
import {
  getFormattedDate,
  formatWeekLong,
  getYear,
} from "../../../utils/date/date";
import ProductDescription from "../productDescription/productDescription";
import {
  Can,
  Permission,
  ShowOnlyInDevelopment,
  ANY_CUSTOMER,
} from "../../auth";
import colors from "../../../theme/colors";
import Announcements from "../announcements/announcements";
import SpecialBadges from "../specialBadges/specialBadges";
import { findCustomerById } from "../../../utils/customer/customer";
import {
  addOrRemoveItemsToArray,
  shouldDisableStockNotificationConfirmButton,
  getExistingNotificationsStatus,
  prepareNotificationIdsToDelete,
  prepareUniqueEmailsForStockNotifications,
} from "../../../utils/stockNotificationUtil";
import {
  isProductDiscontinued,
  isProductExpired,
} from "../../../utils/productLifecycle/productLifecycle";
import SpecialInformationLabel from "../specialInformationLabel/specialInformationLabel";
import {
  getAdditionalInformation,
  shouldShowAvailabilityDateInformation,
} from "../../../utils/availabilityUtil/availability";

const {
  getLocalizedProductName,
  isReturnable,
  getAuthorizationType,
  getTemporaryLicenseDates,
  getDeliveryBlockData,
  getProductContentAmount,
  getStrengthStr,
  getStrength2Str,
  getMainItemImageUrl,
  isProductEnteringMarket,
} = ProductUtil;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    marginLeft: "7.3%",
    marginRight: "7.3%",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  content: {
    paddingTop: theme.spacing(2),
    display: "flex",
  },
  notAvailable: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
  },
  leftContainer: {
    width: "30%",
  },
  leftContent: {
    position: "relative",
    marginTop: theme.spacing(17.5),
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
  },
  rightContainer: {
    width: "70%",
  },
  rightContent: {
    marginRight: theme.spacing(8),
  },
  header: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  headerContainer: {
    display: "flex",
    alignItems: "baseline",
    overflow: "hidden",
    marginRight: theme.spacing(8),
  },
  headerText: {
    marginRight: theme.spacing(2),
  },
  headerAmountText: {
    whiteSpace: "nowrap",
  },
  grow: {
    flexGrow: 1,
  },
  imageContainer: {
    marginBottom: theme.spacing(2),
    width: "100%",
    height: "34.8vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  defaultImageContainer: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    width: "100%",
    height: "34.8vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  noImageText: {
    color: colors.secondaryGray,
    fontSize: "0.875rem",
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "scale-down",
  },
  defaultImage: {
    maxHeight: "32%",
    maxWidth: "32%",
    objectFit: "scale-down",
    [theme.breakpoints.down("md")]: {
      maxHeight: "60%",
      maxWidth: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "80%",
      maxWidth: "80%",
    },
  },
  stock: {
    marginBottom: theme.spacing(2),
  },
  stockStateReason: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.primary,
  },
  expiryBlock: {
    marginBottom: theme.spacing(4),
  },
  expiryDate: {
    marginTop: theme.spacing(0.5),
    fontSize: "1rem",
  },
  productDetails: {
    display: "flex",
    flexDirection: "row",
  },
  productDetailsLeft: {
    width: "100%",
  },
  productDescriptionContainer: {
    marginTop: theme.spacing(4),
  },
  enteringMarketBlock: {
    marginBottom: theme.spacing(2),
  },
  enteringMarket: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.disabled,
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  priceBlock: {
    marginBottom: theme.spacing(2),
  },
  controls: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginBottom: theme.spacing(1),
  },
  replacementProduct: {
    marginTop: theme.spacing(2),
  },
  replacementProductImage: {
    width: "50px",
    height: "50px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    textTransform: "none",
    height: "2.5rem",
    fontSize: "1rem",
  },
  secondaryButton: {
    height: "39px",
    background: colors.backgroundGray,
    boxShadow: "rgba(0, 0, 1, 0.25)",
    borderRadius: "4px",
    marginTop: "15px",
    "& p": {
      fontFamily: "Verdana",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "17px",
      color: colors.secondaryNavyBlue,
    },
  },
  primaryButton: {
    /* Primary button with icon */
    height: "39px",
    background: colors.secondaryNavyBlue,
    boxShadow: "rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    marginTop: "15px",
    "& p": {
      fontFamily: "Verdana",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "17px",
      color: colors.white,
    },
  },
  boldText: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
  notifyButtons: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  textFieldMargins: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  temporaryLicense: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  temporaryLicenseTitle: {
    marginBottom: theme.spacing(0.5),
  },
  compareProducts: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(2),
  },
  notificationProgress: {
    marginTop: theme.spacing(2),
  },
  bottomMargin: {
    marginBottom: theme.spacing(1),
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  controlsBlock: {
    marginBottom: theme.spacing(4),
  },
  underlineHighlight: {
    borderBottom: `4px solid ${colors.primaryOrange}`,
    display: "inline-block",
  },
  fetchStockFailed: {
    color: theme.palette.error.main,
  },
  campaignLabel: {
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    background: "rgba(255, 191, 85, 0.85);",
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: "28px",
    position: "absolute",
    top: theme.spacing(-12),
    left: theme.spacing(-4),
    fontWeight: "bold",
    fontSize: "2rem",
  },
  specialLabel: {
    position: "absolute",
    top: theme.spacing(-12),
    left: theme.spacing(-8),
  },
  campaignLabelText: {
    fontSize: "2rem",
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  removedLabelText: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
}));

const isProduct = product => Object.keys(product).length > 0;

const isProductExpiredOrDiscontinued = product =>
  product && (isProductExpired(product) || isProductDiscontinued(product));

function ProductCard({ match /* , history */ }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  // TODO: these are added later
  // const [notifyEmail, setNotifyEmail] = useState(false);
  // const [notifySms, setNotifySms] = useState(false);
  const [cartProductCount, setCartProductCount] = useState(1);
  const [showReplacements, setShowReplacements] = useState(false);
  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [addTemplatePos, setAddTemplatePos] = useState({ top: 0, bottom: 0 });
  const [addTemplateHeight, setAddTemplateHeight] = useState(0);
  const [imageLoadError, setImageLoadError] = useState(false);
  const [productFound, setProductFound] = useState(true);
  const dispatch = useDispatch();

  // get product data
  const product = useSelector(state => state.product.product);
  const isExpiredOrDiscontinuedProduct =
    isProductExpiredOrDiscontinued(product);

  const {
    replacementProducts,
    favoriteProductIds,
    productPricesMap,
    productFetchByIdError,
  } = useSelector(state => state.product);

  // get product stock
  const { productStockStatusesMap, fetchingStock } = useSelector(
    state => state.stock
  );
  const productStockData = productStockStatusesMap.get(product.materialId);

  // get product availability
  const { productAvailabilityStatusesMap, fetchingAvailability } = useSelector(
    state => state.availability
  );
  const productAvailabilityData = productAvailabilityStatusesMap.get(
    product.materialId
  );

  const userData = useSelector(state => state.user.userData);
  const { userId } = userData;
  const { customers, selectedCustomerId } = useSelector(
    state => state.customer
  );
  const customer = findCustomerById(selectedCustomerId, customers);
  const customerIds = customers
    .filter(c => !c.isPassivated)
    .map(c => c.customerId);

  let templateButtonRef;
  // eslint-disable-next-line
  const setTemplateButtonRef = ref => (templateButtonRef = ref);

  const mainItemImageUrl = getMainItemImageUrl(product);
  // eslint-disable-next-line
  const { productId } = match.params;
  // Prepare unique emails for stock notification (personal & pharmacy emails if available)
  const emails = prepareUniqueEmailsForStockNotifications(
    userData,
    customers,
    selectedCustomerId
  );

  const { notifications, notificationSending, notificationFetching } =
    useSelector(state => state.notification);
  const [showPopup, setShowPopup] = useState(false);
  // Store separate emails for subscription or unsubscription
  const [emailsForStockNotification, setEmailsForStockNotification] = useState({
    subscription: [],
    unsubscription: [],
  });
  const [checkBoxStatus, setCheckBoxStatus] = useState({});
  const [originalCheckBoxStatus, setOriginalCheckboxStatus] = useState({});
  const { announcements, showAllUrl } = useSelector(
    state => state.announcements
  );
  const [errorPopup, setShowErrorPopup] = useState(false);

  useEffect(() => {
    if (selectedCustomerId) {
      dispatch(fetchSubscribedNotificationsPerCustomer(customerIds, productId));
    }
    // eslint-disable-next-line
  }, [selectedCustomerId, dispatch]);

  useEffect(() => {
    setCheckBoxStatus(getExistingNotificationsStatus(notifications, productId));
    // eslint-disable-next-line
  }, [notifications, setCheckBoxStatus]);

  useEffect(() => {
    setOriginalCheckboxStatus(
      getExistingNotificationsStatus(notifications, productId)
    );
    // eslint-disable-next-line
  }, [notifications, setOriginalCheckboxStatus]);

  useEffect(() => {
    // update product. Note! this will return product price also
    dispatch(fetchProductById(productId));

    if (selectedCustomerId) {
      // fetch price for the product
      dispatch(fetchProductPrices(selectedCustomerId, [productId]));
      // fetch stock
      dispatch(fetchStockByProductId(selectedCustomerId, productId));
      // fetch availability
      dispatch(fetchAvailabilityByProductId(selectedCustomerId, productId));

      // update replacements
      dispatch(fetchReplacementProducts(productId));
    }

    // scroll always to top
    window.scrollTo(0, 0);
  }, [dispatch, productId, selectedCustomerId]);

  useEffect(() => {
    if (productFetchByIdError != null) {
      setProductFound(false);
    }
  }, [productFetchByIdError, setProductFound]);

  useEffect(() => {
    // Fetch 3 latest annoucements
    if (product.materialId) {
      const filters = `?materialId=${product.materialId}&limit=3&language=${i18n.language}`;
      dispatch(fetchAnnouncements(filters));
    }
    // eslint-disable-next-line
  }, [product, dispatch]);
  /*
  // navigation helper
  const goBack = () => {
    history.goBack();
  };
  */

  const renderBackHeader = () => null;

  const renderSpecialInformationLabel = (
    priceData,
    // eslint-disable-next-line
    isExpiredOrDiscontinuedProduct
  ) => {
    if (isExpiredOrDiscontinuedProduct) {
      return (
        <SpecialInformationLabel
          className={classes.specialLabel}
          backgroundColor={colors.errorRedBackground}
        >
          <Typography variant="body1" className={classes.removedLabelText}>
            {t("lifecycleEndedProduct")}
          </Typography>
        </SpecialInformationLabel>
      );
    }
    if (priceData?.campaign) {
      return (
        <SpecialInformationLabel
          className={classes.specialLabel}
          backgroundColor="rgba(255, 191, 85, 0.85)"
        >
          <Typography variant="body1" className={classes.campaignLabelText}>
            {t("campaign")}
          </Typography>
        </SpecialInformationLabel>
      );
    }
    return null;
  };

  const renderProductImage = () =>
    !imageLoadError && mainItemImageUrl ? (
      <div className={classes.imageContainer}>
        <img
          alt={t("productPicture")}
          src={mainItemImageUrl}
          className={classes.image}
          onError={() => setImageLoadError(true)}
        />
      </div>
    ) : (
      <div className={classes.defaultImageContainer}>
        <Hidden mdDown>
          <Typography className={classes.noImageText}>
            {t("noPicture")}
          </Typography>
        </Hidden>
        <DefaultProductImage className={classes.defaultImage} />
      </div>
    );

  const renderInStock = stockData => {
    if (fetchingStock) {
      return null;
    }
    if (!fetchingStock && !stockData) {
      return (
        <div className={classes.stock}>
          <Typography className={classes.fetchStockFailed}>
            {t("stockSearchFailed")}
          </Typography>
        </div>
      );
    }

    // out of stock
    const inStock = isInStock(stockData);
    if (!inStock) {
      return null;
    }

    return (
      <div className={classes.stock}>
        <IconTextLabel text={t("inStock")} icon={<Yes />} variant="subtitle2" />
      </div>
    );
  };

  const renderOutOfStock = (stockData, availabilityInformation) => {
    // null or fetching
    if (stockData == null || fetchingStock) {
      return null;
    }

    // is in stock
    const inStock = isInStock(stockData);
    if (inStock) {
      return null;
    }

    const mergedAvailabilityInformation = {
      ...availabilityInformation,
      companies: product.companies,
    };

    const additionalInformation = getAdditionalInformation(
      mergedAvailabilityInformation,
      i18n
    );

    let availabilityDateStr = "";
    if (shouldShowAvailabilityDateInformation(mergedAvailabilityInformation)) {
      availabilityDateStr = additionalInformation
        ? ""
        : t("estimatedAvailabilityIsUnknown");

      const availabilityDate = availabilityInformation?.availabilityDate;
      if (fetchingAvailability && !availabilityDate) {
        availabilityDateStr = t("waitingData");
      } else if (availabilityDate) {
        const weekStr = formatWeekLong(availabilityDate);
        const yearStr = getYear(availabilityDate);
        availabilityDateStr = `${t(
          "estimatedAvailability"
        )} ${weekStr}/${yearStr}`;
      }
    }

    return (
      <div className={classes.stock}>
        <IconTextLabel
          text={t("outOfStock")}
          icon={<No />}
          variant="subtitle2"
        />
        <Typography className={classes.stockStateReason} variant="body1">
          {availabilityDateStr}
        </Typography>
        {additionalInformation != null && (
          <Typography className={classes.stockStateReason} variant="body1">
            {additionalInformation}
          </Typography>
        )}
      </div>
    );
  };

  const renderStockProgress = () => (
    <div>
      <Progress className={classes.progress} show={fetchingStock} size={20} />
    </div>
  );

  // eslint-disable-next-line
  const renderExpirationDate = productStockData => {
    if (!productStockData) {
      return null;
    }

    const dateStr = getFormattedDate(productStockData.oldestExpDate);
    return (
      <div className={classes.expiryBlock}>
        <Typography
          className={classes.expiryDate}
          color="textPrimary"
          variant="body1"
        >
          {t("expirationDate")}: {dateStr}
        </Typography>
      </div>
    );
  };

  // eslint-disable-next-line
  const renderNonReturnableAndDeliverable = product => {
    // F_110 Returnable is “Yes”
    // F_293 Delivery Block is “yes”

    let nonReturnable = false;
    const returnable = isReturnable(product);
    if (returnable != null) {
      nonReturnable = !returnable;
    }

    const deliveryBlockData = getDeliveryBlockData(product);
    /* CODES 
    Z5 Scarcity
    Z6 Pre-Order
    Z7 TT on Demand
    ZD Discontinued
    ZE Entering market
    ZP Phasing out
    ZS Sellable
    */
    let nonDeliverable = false;
    if (deliveryBlockData?.deliveryBlockCode != null) {
      nonDeliverable = deliveryBlockData.deliveryBlockCode !== "ZS";
    }

    return (
      <div>
        {nonReturnable === true && (
          <Typography
            className={`${classes.textFieldMargins} ${classes.underlineHighlight}`}
            variant="subtitle2"
            color="secondary"
          >
            {t("nonReturnable")}
          </Typography>
        )}
        {nonDeliverable === true && (
          <Typography
            className={classes.textFieldMargins}
            variant="subtitle2"
            color="secondary"
          >
            {t("nonDeliverable")}
          </Typography>
        )}
      </div>
    );
  };
  // eslint-disable-next-line
  const renderAuthorization = product => {
    // Show these if values in F_143 field
    let authorizationType = getAuthorizationType(product, i18n.language);
    if (authorizationType == null) {
      return null;
    }
    if (authorizationType === "Non-registered" && i18n.language === "en") {
      authorizationType = t(authorizationType);
    }
    // valid found
    return (
      <Typography
        className={classes.textFieldMargins}
        variant="subtitle2"
        color="textPrimary"
      >
        {authorizationType}
      </Typography>
    );
  };

  // eslint-disable-next-line
  const renderLicenceStartEndDate = product => {
    const licence = getTemporaryLicenseDates(product);
    if (
      licence &&
      licence.temporaryLicense === true &&
      licence.temporaryLicenseStartDate &&
      licence.temporaryLicenseEndDate
    ) {
      // Temporary license end date and start date (Fields F_280 and F_281 - show if values)
      const dates = `${getFormattedDate(
        licence.temporaryLicenseStartDate
      )} - ${getFormattedDate(licence.temporaryLicenseEndDate)}`;
      return (
        <div className={classes.temporaryLicense}>
          <Typography
            className={classes.temporaryLicenseTitle}
            variant="subtitle2"
            color="textPrimary"
          >
            {t("temporaryLicence")}
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {dates}
          </Typography>
        </div>
      );
    }
    return null;
  };

  const renderPrices = priceData => {
    if (!priceData) {
      return null;
    }
    const prices = getPrices(priceData, cartProductCount);

    return (
      <div className={classes.priceBlock}>
        <Price prices={prices} />
      </div>
    );
  };

  // eslint-disable-next-line
  const renderCartControls = product => {
    // Don't render cart controls if product is entering market (ORIGO-854)
    if (!product.companies || isProductEnteringMarket(product)) {
      return null;
    }

    return (
      <div>
        <CartAddButtons
          userId={userId}
          product={product}
          // eslint-disable-next-line
          onAddToCart={(cart, product) => {
            dispatch(
              notificationShow(
                t("productHasBeenAdded"),
                getLocalizedProductName(product, i18n.language),
                `${t("toCart").toLowerCase()} ${cart.name}`,
                3000
              )
            );
          }}
          // need to track since for the template (origo-454)
          onCountChanged={count => setCartProductCount(count)}
          count={cartProductCount}
        />
        <Divider className={classes.divider} variant="fullWidth" />
      </div>
    );
  };

  // eslint-disable-next-line
  const renderReplacementProduct = product => {
    const productName = getLocalizedProductName(product, i18n.language);
    return (
      <div className={classes.replacementProduct}>
        <IconTextLabel
          icon={
            <img
              alt=""
              className={classes.replacementProductImage}
              src={require("../../../images/tehobakt.jpg")}
            />
          }
          text={productName}
          color="textPrimary"
          variant="body1"
          size="small"
        />
      </div>
    );
  };

  // eslint-disable-next-line
  const renderEnteringMarketLabel = product => {
    const enteringMarket = isProductEnteringMarket(product);
    if (!enteringMarket) {
      return null;
    }
    return (
      <div className={classes.enteringMarketBlock}>
        <Typography className={classes.enteringMarket} variant="body1">
          {t("enteringMarket")}
        </Typography>
      </div>
    );
  };

  // eslint-disable-next-line
  const renderReplacementsBlock = (stockData, replacementProducts) => {
    // null or loading
    if (stockData == null || fetchingStock) {
      return null;
    }

    // in stock
    if (isInStock(stockData)) {
      return null;
    }

    if (replacementProducts.length > 0) {
      const replacementsText = `${t("productReplacements")} (${
        replacementProducts.length
      })`;
      const restReplacements = replacementProducts.slice(1);
      return (
        <div>
          <Divider className={classes.divider} variant="fullWidth" />
          <Typography variant="subtitle2" color="textPrimary">
            {replacementsText}
          </Typography>
          {renderReplacementProduct(replacementProducts[0])}
          {showReplacements &&
            // eslint-disable-next-line
            restReplacements.map(product => renderReplacementProduct(product))}
          {replacementProducts.length > 1 && (
            <Button
              className={classes.button}
              variant="contained"
              onClick={() => setShowReplacements(!showReplacements)}
            >
              {showReplacements ? t("hide") : t("showAll")}
            </Button>
          )}
        </div>
      );
    }
    return null;
  };

  const handleChange = event => {
    const { value, checked } = event.target;
    if (checked) {
      setEmailsForStockNotification({
        subscription: addOrRemoveItemsToArray(
          emailsForStockNotification.subscription,
          value,
          "add-subscription",
          notifications
        ),
        unsubscription: addOrRemoveItemsToArray(
          emailsForStockNotification.unsubscription,
          value
        ),
      });
      setCheckBoxStatus({
        ...checkBoxStatus,
        [value]: checked,
      });
    } else {
      setEmailsForStockNotification({
        subscription: addOrRemoveItemsToArray(
          emailsForStockNotification.subscription,
          value
        ),
        unsubscription: addOrRemoveItemsToArray(
          emailsForStockNotification.unsubscription,
          value,
          "add-unsubscription"
        ),
      });
      const newCheckboxStatus = { ...checkBoxStatus };
      delete newCheckboxStatus[value];
      setCheckBoxStatus(newCheckboxStatus);
    }
  };

  const getProductStrength = targetProduct => {
    // Get first strength variant that is not empty string
    const strength = [
      getStrengthStr(targetProduct, i18n.language),
      getStrength2Str(targetProduct, i18n.language),
    ]
      .map(s => s?.trim())
      .filter(Boolean)[0];
    return strength ?? "";
  };

  const getProductVnrOrMsi = targetProduct =>
    targetProduct.vnr ?? targetProduct.msiCode ?? "";

  const subscribeEmail = () => {
    const productLocalizedName = getLocalizedProductName(
      product,
      i18n.language
    );
    const productPackageSize = getProductContentAmount(product, i18n.language);
    const productStrength = getProductStrength(product);
    const productVnrOrMsiCode = getProductVnrOrMsi(product);

    dispatch(
      subscribeStockNotification({
        userId,
        customerId: selectedCustomerId,
        notificationType: NotificationType.EMAIL,
        productId,
        productName: productLocalizedName,
        productPackageSize,
        productStrength,
        productVnrOrMsiCode,
        siteUrl: window.location.href,
        emails: emailsForStockNotification.subscription,
      })
    ).then(result => {
      if (result) {
        dispatch(notificationShow(t("notificationAdded"), null, null, 3000));
        setShowPopup(false);
        setEmailsForStockNotification({ subscription: [], unsubscription: [] });
      }
    });
  };

  const handleNotifyClick = () => {
    const notificationIdsToDelete = prepareNotificationIdsToDelete(
      notifications,
      productId,
      emailsForStockNotification.unsubscription
    );

    if (notificationIdsToDelete.length !== 0) {
      dispatch(
        deleteNotificationSubscription(
          userId,
          notificationIdsToDelete.join(",")
        )
      ).then(result => {
        if (result) {
          if (emailsForStockNotification.subscription.length !== 0) {
            subscribeEmail();
          } else {
            dispatch(
              fetchSubscribedNotificationsPerCustomer(customerIds, productId)
            );
            setShowPopup(false);
            setEmailsForStockNotification({
              subscription: [],
              unsubscription: [],
            });
          }
        }
      });
    } else if (emailsForStockNotification.subscription.length !== 0) {
      subscribeEmail();
    }
  };

  const renderStockSubscriptionPopup = () => {
    const content = {
      onChange: handleChange,
      contentType: "stockNotification",
      emails,
      checkboxItemsStatus: checkBoxStatus,
      notifications,
      user: userData,
    };
    return (
      <ConfirmationPopup
        title={t("stockNotificationPopupTitle")}
        description={t("stockNotificationPopupDesc")}
        iconType="subscription"
        show={showPopup}
        onOk={handleNotifyClick}
        saveKey="confirm"
        onCancel={() => {
          setShowPopup(false);
          setEmailsForStockNotification({
            subscription: [],
            unsubscription: [],
          });
        }}
        disableSave={shouldDisableStockNotificationConfirmButton(
          originalCheckBoxStatus,
          checkBoxStatus
        )}
        content={content}
      />
    );
  };

  const showNotificationPopup = () => {
    setShowPopup(true);
  };

  const renderNotifyButtons = () => {
    const notificationSent = notifications.some(
      n => n.email === userData.email
    );
    const showProgress = notificationFetching || notificationSending;
    if (showProgress) {
      return (
        <Progress
          className={classes.notificationProgress}
          show={showProgress}
          size={20}
        />
      );
    }
    return (
      <Button
        className={
          !notificationSent ? classes.secondaryButton : classes.primaryButton
        }
        variant="contained"
        onClick={showNotificationPopup}
        disableElevation
        startIcon={!notificationSent ? <NotiOn /> : <NotiOff />}
      >
        {!notificationSent ? (
          <p>{t("notify")}</p>
        ) : (
          <p>{t("cancelNotification")}</p>
        )}
      </Button>
    );
  };
  const renderNotifyBlock = stockData => {
    // TODO: these ones are added at some point so saving them
    /*
     <div className={classes.notifyButtons}>
          <CheckboxTextLabel
            text={t('viaEmail')}
            checked={notifyEmail}
            onChange={event => setNotifyEmail(event.target.checked)}
            customColor="grey"
          />
          <CheckboxTextLabel
            text={t('viaSms')}
            checked={notifySms}
            onChange={event => setNotifySms(event.target.checked)}
            customColor="grey"
          />
        </div>
    */

    // null or loading
    if (stockData == null || fetchingStock) {
      return null;
    }

    // in stock
    if (isInStock(stockData)) {
      return null;
    }

    return (
      <div>
        <Typography className={classes.boldText} variant="body1">
          {t("notifyWhenProductInStock")}
        </Typography>
        {renderNotifyButtons()}
        <Divider className={classes.divider} variant="fullWidth" />
      </div>
    );
  };

  // eslint-disable-next-line
  const renderAddControls = product => {
    const showTemplatePopUp = () => {
      const rect = templateButtonRef.getBoundingClientRect();
      setAddTemplatePos({ top: rect.top, left: rect.left });
      setShowAddTemplate(true);
    };
    // is favorite
    const isFavorite = favoriteProductIds.includes(product.materialId);
    const productEnteringMarket = isProductEnteringMarket(product);

    return (
      <div className={classes.controlsBlock}>
        <div className={classes.controls}>
          <div className={classes.bottomMargin}>
            <IconTextButton
              variant="body1"
              text={isFavorite ? t("removeFromFavorites") : t("addToFavorites")}
              icon={isFavorite ? <Favorite /> : <FavoriteBorder />}
              onClick={() =>
                dispatch(setFavoriteProduct(userId, product, !isFavorite))
              }
            />
          </div>
          {!productEnteringMarket && product.companies && (
            <Can
              customerContext={ANY_CUSTOMER}
              user={userData}
              perform={Permission.ORDER_CREATE}
              data={{ customer }}
            >
              <div className={classes.bottomMargin}>
                <IconTextButton
                  variant="body1"
                  text={t("addToOrderTemplate")}
                  icon={<More />}
                  onClick={() => showTemplatePopUp()}
                  buttonRef={setTemplateButtonRef}
                />
              </div>
            </Can>
          )}
        </div>
        <Divider variant="fullWidth" />
      </div>
    );
  };

  const renderCompareProducts = () => (
    <div className={classes.compareProducts}>
      <IconTextButton
        variant="body1"
        text={t("compareWithOtherProducts")}
        icon={<Compare />}
      />
    </div>
  );

  // eslint-disable-next-line
  const renderProductHeader = product => {
    const productName = getLocalizedProductName(product, i18n.language);
    const contentAmount = getProductContentAmount(product, i18n.language);
    return (
      <div className={classes.header}>
        <div className={classes.headerContainer}>
          <TooltipTypography
            align="center"
            variant="h4"
            color="textPrimary"
            className={classes.headerText}
            tooltip={productName}
            noWrap
            id="product-header"
          >
            {productName}
          </TooltipTypography>
          <Typography
            align="center"
            variant="h6"
            color="textPrimary"
            className={classes.headerAmountText}
          >
            {contentAmount}
          </Typography>
        </div>
        <SpecialBadges product={product} />
      </div>
    );
  };

  const renderNotificationPopup = () => (
    <NotificationPopup
      id="notification-popup"
      icon={<ShoppingCartRound />}
      onClose={() => dispatch(notificationHide())}
    />
  );

  // eslint-disable-next-line
  const renderProductDetails = product => (
    <div className={classes.productDetails}>
      <div className={classes.productDetailsLeft}>
        <ProductDetailsTable product={product} />
      </div>
    </div>
  );

  // eslint-disable-next-line
  const renderProductDescriptions = product => (
    <div className={classes.productDescriptionContainer}>
      <ProductDescription product={product} />
    </div>
  );

  // eslint-disable-next-line
  const onSave = (template, product) => {
    setShowAddTemplate(false);
    dispatch(
      templateAddProduct(userId, template, product, cartProductCount)
    ).then(response => {
      if (response) {
        dispatch(
          notificationShow(
            `${t("productHasBeenAdded")} ${t("toOrderTemplate").toLowerCase()}`,
            getLocalizedProductName(product, i18n.language),
            `${t("toOrderTemplate").toLowerCase()} ${template.name}`,
            3000
          )
        );
      }
    });
  };

  // eslint-disable-next-line
  const renderTemplatePopup = product => (
    <TemplateAddPopup
      top={addTemplatePos.top - addTemplateHeight}
      left={addTemplatePos.left}
      show={showAddTemplate}
      onSave={template => onSave(template, product)}
      onCancel={() => setShowAddTemplate(false)}
      popupRef={ref => {
        if (addTemplateHeight === 0 && ref) {
          setAddTemplateHeight(ref.getBoundingClientRect().height);
        }
      }}
    />
  );
  const renderErrorPopup = () => (
    <ErrorPopup
      title={errorPopup.title}
      description={errorPopup.description}
      show={errorPopup.show}
      onOk={errorPopup.onOk}
      onCancel={() => setShowErrorPopup(false)}
      saveKey={errorPopup.saveKey}
      hideOkButton={errorPopup.hideOkButton}
      cancelKey={errorPopup.cancelKey}
    />
  );

  // eslint-disable-next-line
  function CardContent() {
    const priceData = productPricesMap.get(product.materialId);
    return (
      <div className={classes.content}>
        <div className={classes.leftContainer}>
          {renderBackHeader()}
          <div className={classes.leftContent}>
            {isProduct(product) &&
              renderSpecialInformationLabel(
                priceData,
                isExpiredOrDiscontinuedProduct
              )}
            {renderProductImage()}
            {productFound === true &&
              isExpiredOrDiscontinuedProduct === false && (
                <>
                  {renderInStock(productStockData)}
                  {renderOutOfStock(productStockData, productAvailabilityData)}
                  {renderEnteringMarketLabel(product)}
                  {renderStockProgress()}
                  {renderNonReturnableAndDeliverable(product)}
                  {renderAuthorization(product)}
                  {renderLicenceStartEndDate(product)}
                  {renderExpirationDate(productStockData)}
                  {renderPrices(priceData)}
                  <Can
                    customerContext={ANY_CUSTOMER}
                    user={userData}
                    perform={Permission.ORDER_CREATE}
                    data={{ customer }}
                  >
                    {renderCartControls(product)}
                  </Can>
                  <ShowOnlyInDevelopment>
                    {renderReplacementsBlock(
                      productStockData,
                      replacementProducts
                    )}
                  </ShowOnlyInDevelopment>
                  {renderStockSubscriptionPopup()}
                  {renderNotifyBlock(productStockData)}
                  {renderAddControls(product)}
                  <Announcements
                    announcements={announcements}
                    showAllUrl={showAllUrl}
                  />
                  <ShowOnlyInDevelopment>
                    {renderCompareProducts()}
                  </ShowOnlyInDevelopment>
                  {renderTemplatePopup(product)}
                </>
              )}
          </div>
        </div>
        <div className={classes.rightContainer}>
          {renderProductHeader(product)}
          {renderNotificationPopup()}
          {renderErrorPopup()}
          <div className={classes.rightContent}>
            {productFound === true && renderProductDetails(product)}
            {productFound === true && renderProductDescriptions(product)}
          </div>
        </div>
      </div>
    );
  }

  // eslint-disable-next-line
  function NotAvailable() {
    return (
      <div className={classes.notAvailable}>
        <Typography variant="h5">{`${t(
          "productNotFound"
        ).toUpperCase()}`}</Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {productFound === false && <NotAvailable />}
      <CardContent />
    </div>
  );
}

ProductCard.propTypes = {
  match: PropTypes.shape({}),
  history: PropTypes.shape({}),
};

ProductCard.defaultProps = {
  match: {},
  history: {},
};

export default withRouter(ProductCard);

const EventType = Object.freeze({
  PageView: "pageview",
});

const pageViewEvent = () => ({
  hitType: EventType.PageView,
  // page details are filled automatically
  eventAction: "",
  eventCategory: "",
  eventLabel: "",
  eventValue: 0,
});

export default pageViewEvent;

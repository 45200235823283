/* eslint-disable consistent-return */
import moment from "moment";
// eslint-disable-next-line
import { RestService } from "../../../components/generic";
import ensureTrailingSlash from "../../../utils/url/url";
import Delay from "../../../utils/delay/delay";
import { convertProductOrRecordToCartProduct } from "../../../utils/mapper/mapper";
import { CartTemplateEvents } from "../../../analytics/cartTemplateEvents";

export const Template = Object.freeze({
  UPDATE: "UPDATE_TEMPLATE",
  CREATE: "TEMPLATE_CREATE",
  REMOVE: "TEMPLATE_REMOVE",
  FETCH_STARTED: "TEMPLATE_FETCH_STARTED",
  FETCH_FINISHED: "TEMPLATE_FETCH_FINISHED",
  FETCH_ERROR: "TEMPLATE_FETCH_ERROR",
  SEND_STARTED: "TEMPLATE_SEND_STARTED",
  SEND_FINISHED: "TEMPLATE_SEND_FINISHED",
  SEND_ERROR: "TEMPLATE_SEND_ERROR",
  CLEAR_ALL_ERRORS: "CLEAR_ALL_ERRORS",
});

const delay = new Delay();
const baseUrl = ensureTrailingSlash(process.env.REACT_APP_ECOM_SERVICE);
const templateApi = ensureTrailingSlash(process.env.REACT_APP_TEMPLATE_API);

const getTemplateCopy = (state, templateId) => {
  const orderTemplates = state.template.orderTemplates.slice(0);
  const index = orderTemplates
    .map(template => template.templateId)
    .indexOf(templateId);
  return orderTemplates[index];
};

const doSendAsync = async (dispatch, userId, template) => {
  const path = `${baseUrl + templateApi + userId}/save/${template.templateId}`;

  try {
    // start
    dispatch({ type: Template.SEND_STARTED });

    const updatedTemplate = await RestService.post(path, template);

    // finished
    dispatch({ type: Template.SEND_FINISHED });

    return updatedTemplate;
  } catch (error) {
    // TODO: error handling
    dispatch({ type: Template.SEND_ERROR, payload: error });
  }
};

// -- ACTIONS --

export const templateCreate = (userId, template) => async dispatch => {
  const path = `${baseUrl + templateApi + userId}/create`;

  try {
    // start
    dispatch({ type: Template.SEND_STARTED });

    const createdTemplate = await RestService.post(path, template);

    // finished
    dispatch({ type: Template.SEND_FINISHED });

    // update
    dispatch({
      type: Template.CREATE,
      payload: {
        template: createdTemplate,
        analytics: {
          template: createdTemplate,
        },
      },
    });

    return createdTemplate;
  } catch (error) {
    // TODO: error handling
    dispatch({ type: Template.SEND_ERROR, payload: error });
  }
};

export const templateDelete = (userId, template) => async dispatch => {
  const path = `${baseUrl + templateApi + userId}/delete/${
    template.templateId
  }`;

  try {
    // start
    dispatch({ type: Template.SEND_STARTED });

    await RestService.delete(path);

    // finished
    dispatch({ type: Template.SEND_FINISHED });

    // update
    dispatch({
      type: Template.REMOVE,
      payload: { template, analytics: { template } },
    });

    return template;
  } catch (error) {
    // TODO: error handling
    dispatch({ type: Template.SEND_ERROR, payload: error });
  }
};

export const templateFetchAll = userId => async dispatch => {
  const path = baseUrl + templateApi + userId;

  try {
    // start
    dispatch({ type: Template.FETCH_STARTED });

    const templates = await RestService.get(path);

    // update
    dispatch({
      type: Template.FETCH_FINISHED,
      payload: templates,
    });

    return templates;
  } catch (error) {
    // TODO: error handling
    dispatch({ type: Template.FETCH_ERROR, payload: error });
  }
};

const doUpdateTemplateProductCount = (
  state,
  templateId,
  product,
  count,
  resetCount
) => {
  const template = getTemplateCopy(state, templateId);

  // update updatedDate when template is modified
  template.updatedDate = moment().toISOString();

  // check if there's already this product
  const productIndex = template.products
    .map(templateProduct => templateProduct.product.materialId)
    .indexOf(product.materialId);

  if (productIndex !== -1) {
    // update count
    if (resetCount) {
      template.products[productIndex].count = count;
    } else {
      template.products[productIndex].count += count;
    }
  } else {
    // convert to cart product. Can be record or full product
    const cartProduct = convertProductOrRecordToCartProduct(product);

    // add
    template.products.push({
      product: cartProduct,
      count,
    });
  }
  return template;
};

export const templateAddProduct =
  (userId, template, product, count) => (dispatch, getState) => {
    const updatedTemplate = doUpdateTemplateProductCount(
      getState(),
      template.templateId,
      product,
      count,
      false
    );

    // update to local state
    dispatch({
      type: Template.UPDATE,
      payload: {
        template: updatedTemplate,
        analytics: {
          updateType: CartTemplateEvents.AddProduct,
          template: updatedTemplate,
          product,
          count,
        },
      },
    });

    // delay send (to handle heavy mouse clickers)
    return delay.run(
      () => doSendAsync(dispatch, userId, updatedTemplate),
      1000
    );
  };

export const templateSetProductCount =
  (userId, templateId, product, count) => (dispatch, getState) => {
    const template = doUpdateTemplateProductCount(
      getState(),
      templateId,
      product,
      count,
      true
    );

    // update to local state
    dispatch({
      type: Template.UPDATE,
      payload: {
        template,
        analytics: {
          updateType: CartTemplateEvents.SetProductCount,
          template,
          product,
          count,
        },
      },
    });

    // delay send (to handle heavy mouse clickers)
    delay.run(() => {
      // save
      doSendAsync(dispatch, userId, template);
    }, 1000);

    // resolve right-away since in the local state
    return Promise.resolve();
  };

export const templateRemoveProduct =
  (userId, templateId, productId) => (dispatch, getState) => {
    // update products
    const template = getTemplateCopy(getState(), templateId);

    // update updatedDate when template is modified
    template.updatedDate = moment().toISOString();

    // check if there's already this product
    const productIndex = template.products
      .map(templateProduct => templateProduct.product.materialId)
      .indexOf(productId);

    // for analytics
    const templateProduct = template.products[productIndex];

    // remove
    template.products.splice(productIndex, 1);

    // update to local state
    dispatch({
      type: Template.UPDATE,
      payload: {
        template,
        analytics: {
          updateType: CartTemplateEvents.RemoveProduct,
          template,
          product: templateProduct.product,
        },
      },
    });

    // delay send (to handle heavy mouse clickers)
    delay.run(() => {
      // save
      doSendAsync(dispatch, userId, template);
    }, 1000);

    // resolve right-away since in the local state
    return Promise.resolve();
  };

export const templateSetName =
  (userId, templateId, name) => (dispatch, getState) => {
    // find target template and update
    const template = getTemplateCopy(getState(), templateId);
    template.name = name;

    // update to local state
    dispatch({
      type: Template.UPDATE,
      payload: {
        template,
        analytics: {
          updateType: CartTemplateEvents.SetName,
          template,
        },
      },
    });

    // update state
    return doSendAsync(dispatch, userId, template);
  };

export const templateSetNote =
  (userId, templateId, note) => (dispatch, getState) => {
    // find target template and update
    const template = getTemplateCopy(getState(), templateId);
    template.note = note;

    // update to local state
    dispatch({
      type: Template.UPDATE,
      payload: {
        template,
        analytics: {
          updateType: CartTemplateEvents.SetNote,
          template,
        },
      },
    });

    // update state
    return doSendAsync(dispatch, userId, template);
  };

export const templateSetCustomerAndShippingAddress =
  (userId, templateId, customerId, shippingAddressId) =>
  (dispatch, getState) => {
    // update note
    const template = getTemplateCopy(getState(), templateId);
    template.customer = customerId;
    template.shipToAddress = shippingAddressId;

    // update to local state
    dispatch({
      type: Template.UPDATE,
      payload: {
        template,
        analytics: {
          updateType: CartTemplateEvents.SetCustomerAndShippingAddress,
          template,
        },
      },
    });

    // save
    return doSendAsync(dispatch, userId, template);
  };

// -- REDUCER --

const INIT_STATE = {
  orderTemplates: [],
  fetchingTemplate: false,
  templateFetchError: null,
  sendingTemplate: false,
  templateSendError: null,
};
// eslint-disable-next-line
export const templateReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Template.CREATE: {
      const orderTemplates = state.orderTemplates.slice(0);
      const { template } = action.payload;
      orderTemplates.push(template);
      return { ...state, orderTemplates };
    }
    case Template.CLEAR_ALL_ERRORS: {
      return { ...state, templateFetchError: null, templateSendError: null };
    }
    case Template.UPDATE: {
      // find and update
      const { template } = action.payload;
      const orderTemplates = state.orderTemplates.slice(0);
      const index = orderTemplates
        .map(tempTemplate => tempTemplate.templateId)
        .indexOf(template.templateId);
      orderTemplates[index] = template;
      return { ...state, orderTemplates };
    }
    case Template.REMOVE: {
      const { template } = action.payload;
      const { templateId } = template;
      const orderTemplates = state.orderTemplates.slice(0);
      const index = orderTemplates
        .map(tempTemplate => tempTemplate.templateId)
        .indexOf(templateId);
      orderTemplates.splice(index, 1);
      return { ...state, orderTemplates };
    }
    case Template.FETCH_STARTED:
      return { ...state, fetchingTemplate: true, templateFetchError: null };
    case Template.FETCH_FINISHED: {
      const templates = action.payload;
      return { ...state, fetchingTemplate: false, orderTemplates: templates };
    }
    case Template.FETCH_ERROR:
      return {
        ...state,
        fetchingTemplate: false,
        templateFetchError: action.payload,
      };
    case Template.SEND_STARTED:
      return { ...state, sendingTemplate: true, templateSendError: null };
    case Template.SEND_FINISHED:
      return { ...state, sendingTemplate: false };
    case Template.SEND_ERROR:
      return {
        ...state,
        sendingTemplate: false,
        templateSendError: action.payload,
      };
    default:
      return state;
  }
};

import colors from "../../../theme/colors";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(5),
  },
  table: {
    minWidth: 750,
    maxWidth: 1600,
  },
  thumbnail: {
    width: "20px",
    height: "20px",
    marginRight: theme.spacing(1),
  },
  tableHeaderText: {
    textTransform: "none",
    color: theme.palette.text.disabled,
    width: "100%",
    fontSize: "0.85rem",
  },
  productRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottom: `solid 1px ${colors.backgroundDarkGray}`,
    paddingTop: "0.6rem",
    paddingBottom: "0.6rem",
  },
  columnImage: {
    width: "4%",
  },
  columnName: {
    width: "40%",
  },
  columnUnitPrice: {
    width: "12%",
  },
  columnOrdered: {
    width: "12%",
  },
  columnDelivered: {
    width: "12%",
  },
  columnAmount: props => ({
    marginLeft: props.isEditing ? "4rem" : 0,
    width: props.isEditing ? "20%" : "12%",
  }),
  columnDelete: {
    width: "12%",
    display: "flex",
    justifyContent: "center",
  },
  amountText: {
    ...theme.typography.body1,
    color: colors.secondaryNavyBlue,
  },
  tableHeader: {
    ...theme.typography.body1,
    color: colors.secondaryDarkGray,
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  highlightRow: {
    backgroundColor: theme.palette.background.default,
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  backorderPopupContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(6),
  },
  backorderPopupTitle: {
    fontSize: "1.2rem",
    color: colors.secondaryNavyBlue,
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
  },
  quantityContent: {
    display: "flex",
    justifyContent: "center",
  },
  deleteBackorder: {
    padding: 0,
    marginTop: -4,
    marginLeft: -4,
  },
  waitingUpdate: {
    color: theme.palette.text.secondaryNavyBlue,
    fontSize: "0.875rem",
  },
});

export default styles;

/* eslint-disable import/no-cycle */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import {
  OrderProductName,
  DeleteBackOrder,
  ModifyBackorder,
} from "../orderTableCells/orderTableCells";
// eslint-disable-next-line
import { SinglePrice, AddOrRemove } from "../../../generic";
import { Thumbnail } from "../../../productList/productTable/tableCells";
import {
  getBackorderQuantity,
  canEditBackorder,
} from "../../../../utils/orderUtil/orderUtil";
import { backorderUpdateAmount } from "../../../../redux/reducers";
import { canViewProducts } from "../../../auth/authorization/customerRules";

const { getThumbnailUrl } = ProductUtil;

function BackOrdersTableRow({
  classes,
  productStockStatusesMap,
  products,
  orderline,
  highlight,
  isEditing,
  order,
  disabled,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let product = products.find(x => x.materialId === orderline.productId);
  let stockStatus = productStockStatusesMap.get(orderline.productId);
  const { selectedCustomerId, customers } = useSelector(
    state => state.customer
  );
  const customer = customers.find(c => c.customerId === selectedCustomerId);
  const productViewAllowed = canViewProducts(customer);

  if (!product) product = {};
  if (!stockStatus)
    stockStatus = productStockStatusesMap.get(product.materialId);

  const rootClass = highlight ? classes.highlightRow : undefined;
  const thumbnailUrl = getThumbnailUrl(product);
  const { amount } = useSelector(state => state.backorderPopup);
  const [amountValue, setAmountValue] = useState(Number(amount));

  const handleCountChange = value => {
    setAmountValue(value);
    dispatch(backorderUpdateAmount(value));
  };

  const renderQuantity = () => {
    if (orderline.isWaitingSapUpdate) {
      return <div className={classes.waitingUpdate}>{t("waitingUpdate")}</div>;
    }
    return isEditing ? (
      <AddOrRemove
        handleValueChanged={count => handleCountChange(count)}
        value={amountValue}
        min={0}
        max={Number(getBackorderQuantity(orderline))} // can only decrease value
        disabled={disabled}
      />
    ) : (
      <div className={classes.quantityContent}>
        <div>{getBackorderQuantity(orderline)}</div>
        {canEditBackorder(order) === true && (
          <ModifyBackorder order={order} orderline={orderline} />
        )}
      </div>
    );
  };

  return (
    <div className={rootClass}>
      <div className={`${classes.minRowHeight} ${classes.productRow}`}>
        {!isEditing && (
          <div className={classes.columnImage}>
            <Thumbnail url={thumbnailUrl} />
          </div>
        )}
        <div className={`${classes.column} ${classes.columnName}`}>
          <OrderProductName
            product={product}
            stockStatus={stockStatus}
            isBackOrder
            showMsi
            order={orderline}
            productViewAllowed={productViewAllowed}
          />
        </div>
        <div className={`${classes.column} ${classes.columnUnitPrice}`}>
          <SinglePrice
            price={orderline.netPrice}
            priceVat={orderline.price}
            currency={orderline.currency}
            vatRate={orderline.vatPercent}
          />
        </div>
        <div className={`${classes.column} ${classes.columnAmount}`}>
          {renderQuantity()}
        </div>
        {!isEditing && !orderline.isWaitingSapUpdate && (
          <div className={classes.columnDelete}>
            {canEditBackorder(order) === true && (
              <DeleteBackOrder
                order={order}
                orderline={orderline}
                classes={classes}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
BackOrdersTableRow.propTypes = {
  classes: PropTypes.shape({
    highlightRow: PropTypes.string,
    minRowHeight: PropTypes.string,
    productRow: PropTypes.string,
    columnImage: PropTypes.string,
    columnName: PropTypes.string,
    columnUnitPrice: PropTypes.string,
    columnAmount: PropTypes.string,
    columnDelete: PropTypes.string,
    quantityContent: PropTypes.string,
    waitingUpdate: PropTypes.string,
    column: PropTypes.string,
  }).isRequired,
  productStockStatusesMap: PropTypes.shape({
    get: PropTypes.func,
  }).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      materialId: PropTypes.string,
    })
  ).isRequired,
  orderline: PropTypes.shape({
    productId: PropTypes.string,
    isWaitingSapUpdate: PropTypes.bool,
    price: PropTypes.number,
    currency: PropTypes.string,
    netPrice: PropTypes.number,
    vatPercent: PropTypes.number,
  }).isRequired,
  highlight: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool,
  order: PropTypes.shape({
    orderId: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
};
BackOrdersTableRow.defaultProps = {
  isEditing: false,
  disabled: false,
};
export default BackOrdersTableRow;

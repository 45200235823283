import React from "react";
import PropTypes from "prop-types";
import { Button, IconTextLabel, Progress } from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ImportExcelIcon } from "../../../../images";
// eslint-disable-next-line
import { fetchExportToExcelUrl } from "../../../../redux/reducers/order/order";

function ExportReport({ classes, customerIds, searchValue }) {
  const { fetchingExcelReport } = useSelector(state => state.order);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const exportToExcel = () => {
    dispatch(
      fetchExportToExcelUrl(customerIds, searchValue || "", i18n.language)
    )
      .then(response => {
        window.open(response);
      })
      .catch(error => {
        throw new Error("Error when importing the excel", error);
      });
  };

  return (
    <div className={classes.exportButtonWrapper}>
      {fetchingExcelReport ? (
        <Progress className={classes.exportLoader} show />
      ) : (
        <Button
          className={classes.exportButton}
          onClick={exportToExcel}
          id="import-excel-button"
        >
          <IconTextLabel
            icon={<ImportExcelIcon />}
            text={t("exportToExcel")}
            variant="body1"
          />
        </Button>
      )}
    </div>
  );
}

ExportReport.propTypes = {
  customerIds: PropTypes.arrayOf(PropTypes.string),
  searchValue: PropTypes.string,
  classes: PropTypes.shape({
    exportButtonWrapper: PropTypes.string,
    exportButton: PropTypes.string,
    exportLoader: PropTypes.string,
  }),
};

ExportReport.defaultProps = {
  customerIds: [],
  searchValue: "",
  classes: {
    exportButtonWrapper: "",
    exportButton: "",
    exportLoader: "",
  },
};

export default ExportReport;

import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "@oriola-origo/origo-ui-core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import { Search } from "../../../images";
import colors from "../../../theme/colors";

const computeByteLength = s => new TextEncoder().encode(s).length;

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    maxWidth: 900,
  },
  searchField: {
    backgroundColor: colors.backgroundGray,
    borderRadius: 10,
    "& fieldset": {
      borderRadius: 10,
      border: "solid 2px rgba(203, 203, 203, 0.2)",
    },
  },
  searchFieldInput: {
    "&.Mui-focused": {
      backgroundColor: colors.white,
    },
    height: "3.5rem",
  },
}));

function DynamicSearchField({
  onSearch,
  placeholder,
  selectedCustomerId,
  onFocus,
  searchValue,
  maxByteLength,
}) {
  const classes = useStyles();
  const isFirstRender = useRef(true);
  const { pathname, search } = useLocation();
  const searchParams = useSearchParams();
  const [value, setValue] = useState(searchValue || searchParams.get("query"));
  const inputRef = React.createRef();

  useEffect(() => {
    let searchQuery = searchParams.get("query");
    if (!searchQuery) {
      searchQuery = "";
    }
    if (computeByteLength(searchQuery) < maxByteLength) {
      setValue(searchQuery);
    }
  }, [search]); // eslint-disable-line
  useEffect(() => {
    // use timer to debounce the search
    let timer;
    // fetch products immediately if it is the initial render
    if (isFirstRender.current) {
      onSearch(value);
      isFirstRender.current = false;
    } else {
      timer = setTimeout(() => {
        onSearch(value);
        // Keep organizationId in query params in case of page refresh
        const organizationId =
          searchParams.get("organizationId") || selectedCustomerId;
        // Must use window.history instead of react-router history to avoid triggering page reload.
        window.history.replaceState(
          null,
          null,
          `${pathname}?organizationId=${organizationId}&query=${value}`
        );
      }, 800);
    }
    // on cleanup (unmount), clear timer
    return () => {
      clearTimeout(timer);
      isFirstRender.current = false;
    };
  }, [value]); // eslint-disable-line

  const clearInput = () => {
    setValue("");
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const hasValidSearchLength = searchText => {
    if (searchText == null) {
      return true;
    }
    return computeByteLength(searchText) < maxByteLength;
  };

  return (
    <div className={classes.root}>
      <TextField
        id="outlined-dense"
        inputRef={inputRef}
        placeholder={placeholder}
        className={classes.searchField}
        margin="none"
        variant="outlined"
        fullWidth
        value={value}
        onChange={e => {
          if (hasValidSearchLength(e.target.value)) {
            setValue(e.target.value);
          }
        }}
        onFocus={onFocus}
        InputProps={{
          className: classes.searchFieldInput,
          endAdornment: (
            <InputAdornment position="end">
              {value !== "" && (
                <IconButton size="small" onClick={() => clearInput()}>
                  <ClearIcon />
                </IconButton>
              )}
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

DynamicSearchField.propTypes = {
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  selectedCustomerId: PropTypes.string,
  onFocus: PropTypes.func,
  searchValue: PropTypes.string,
  maxByteLength: PropTypes.number,
};

DynamicSearchField.defaultProps = {
  onSearch: () => {}, // eslint-disable-line
  placeholder: "",
  selectedCustomerId: "",
  onFocus: () => {}, // eslint-disable-line
  searchValue: "",
  maxByteLength: 1024,
};

export default DynamicSearchField;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
// eslint-disable-next-line
import { Progress } from "..";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(12),
  },
  root: {
    position: "fixed",
    width: "17%",
    backgroundColor: theme.palette.background.paper,
    borderWidth: "1px",
    borderRadius: 5,
    borderColor: theme.palette.background.paper,
    borderStyle: "solid",
    boxShadow: theme.shadows[2],
    padding: theme.spacing(1),
    outline: "none",
    zIndex: 100,
  },
  message: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  closeButton: {
    textTransform: "none",
  },
  content: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    zIndex: 1,
  },
  progress: {
    marginTop: theme.spacing(3),
  },
  buttons: {},
  cancelButton: {
    height: "2.5rem",
    textTransform: "none",
    marginRight: theme.spacing(4),
    width: "25%",
  },
  saveButton: {
    height: "2.5rem",
    textTransform: "none",
    width: "25%",
  },
  fontSizeSmall: {
    fontSize: "1.8rem",
  },
}));

function ProgressModal({ show, message }) {
  const classes = useStyles();

  if (show) {
    return (
      <Paper className={classes.modal}>
        <div className={classes.root}>
          <div className={classes.content}>
            <Progress className={classes.progress} size={45} show />
            <Typography
              className={classes.message}
              variant="h6"
              color="textPrimary"
            >
              {message}
            </Typography>
          </div>
        </div>
      </Paper>
    );
  }
  return null;
}

ProgressModal.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string,
};

ProgressModal.defaultProps = {
  message: "Loading...",
};

export default ProgressModal;

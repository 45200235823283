import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import moment from "moment";
import styles from "./styles";
import { formatCurrency } from "../../../../../utils/currency/currency";
import { PDF, ActiveFlag, InactiveFlag } from "../../../../../images/index";
// eslint-disable-next-line
import { Progress } from "../../../../generic";

export const HeaderCell = withStyles(styles)(props => {
  const { classes, children, className } = props;
  return (
    <TableCell className={clsx(classes.tableHeadCell, className)}>
      {children}
    </TableCell>
  );
});

HeaderCell.propTypes = {
  classes: PropTypes.shape({}),
  children: PropTypes.node,
};

HeaderCell.defaultProps = {};

export const DataCell = withStyles(styles)(props => {
  const { classes, children, className } = props;
  return (
    <TableCell className={clsx(classes.tableDataCell, className)}>
      {children}
    </TableCell>
  );
});

DataCell.propTypes = {
  classes: PropTypes.shape({}),
  children: PropTypes.node,
};

DataCell.defaultProps = {};

export const PriceCell = withStyles(styles)(props => {
  const { classes, invoice, className, isCreditNote } = props;
  const { t } = useTranslation();
  const prefix = isCreditNote ? <span>&minus;</span> : null;
  return (
    <DataCell className={className}>
      <div className={classes.netAmount}>
        {prefix}
        {formatCurrency(Math.abs(invoice.invoiceNetAmt))}
      </div>
      <div className={classes.cellSecondaryText}>
        {t("invoiceTable.gross")} {prefix}
        {formatCurrency(Math.abs(invoice.invoiceTotalAmt))}
      </div>
    </DataCell>
  );
});

export const CustomerCell = withStyles(styles)(props => {
  const { invoice, classes } = props;
  const name = [invoice.name1, invoice.name2].filter(x => x).join(" ");
  return (
    <DataCell className={classes.customerCell}>
      <Typography className={classes.cellText}>{invoice.customerId}</Typography>
      <Typography className={classes.cellText}>{name}</Typography>
    </DataCell>
  );
});

export const InvoicePdfCell = withStyles(styles)(props => {
  const { classes, handleDownload, fetching, invoice, invoiceDownloaded } =
    props;

  // ORIGO-5133 invoice archive does not have invoices before this date
  const foundFromArchive = moment(invoice.invoiceDate).isSameOrAfter(
    "2021-04-14"
  );
  const fetchingThisPdf = invoiceDownloaded === invoice.invoiceNumber;

  const showPdf = foundFromArchive && !fetching;
  const showDisabledPdf = foundFromArchive && fetching && !fetchingThisPdf;
  const showProgress = fetching && fetchingThisPdf;

  return (
    <TableCell
      className={clsx(classes.tableDataCell, classes.pdf)}
      align="center"
    >
      {showPdf && (
        // eslint-disable-next-line
        <div
          onClick={() => handleDownload()}
          className={classes.allowedDownload}
        >
          <PDF />
        </div>
      )}
      {showProgress && <Progress show />}
      {showDisabledPdf && (
        <div className={classes.notAllowedDownload}>
          <PDF />
        </div>
      )}
    </TableCell>
  );
});

InvoicePdfCell.propTypes = {
  classes: PropTypes.shape({}),
  handleDownload: PropTypes.func.isRequired,
};

export const MarkCell = withStyles(styles)(props => {
  const { classes, marked, onMarkClicked, invoice } = props;
  return (
    <DataCell className={classes.customerCell}>
      <IconButton onClick={() => onMarkClicked(invoice, !marked)}>
        {marked === true && <ActiveFlag />}
        {marked === false && <InactiveFlag />}
      </IconButton>
    </DataCell>
  );
});

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
// eslint-disable-next-line
import { ProductDetailRow } from "../../productCard";
import { getFormattedDate } from "../../../utils/date/date";
import {
  getOrderShipToCustomerName,
  getOrderedItemCount,
} from "../../../utils/orderUtil/orderUtil";
import { formatAddress } from "../../../utils/customer/customer";
import { formatCurrency } from "../../../utils/currency/currency";

const useStyles = makeStyles(theme => ({
  tableRoot: {
    maxWidth: 1000,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
  },
}));

function OrderViewTable({ order }) {
  const classes = useStyles();
  const { t } = useTranslation();

  let rowCounter = 1;
  // eslint-disable-next-line
  const highlight = () => ++rowCounter % 2 === 0;
  const customerName = getOrderShipToCustomerName(order) || "-";
  // eslint-disable-next-line
  const tableRow = (title, value, highlight, icon, key) => (
    <ProductDetailRow
      key={key || undefined}
      title={title}
      value={value}
      highlight={highlight}
      icon={icon}
    />
  );
  const vatStr = order.totalPrice
    ? `, ${t("vat")} ${formatCurrency(order.totalPrice, order.currency)}`
    : "";
  const netStr = order.netPrice
    ? formatCurrency(order.netPrice, order.currency)
    : "";

  return (
    <div className={classes.tableRoot}>
      {tableRow(t("customerNumber"), order.customerId, highlight())}
      {tableRow(t("customerName"), customerName, highlight())}
      {tableRow(
        t("deliveryAddress"),
        formatAddress(order.shipToAddress),
        highlight()
      )}
      {tableRow(t("orderDate"), getFormattedDate(order.orderDate), highlight())}
      {tableRow(t("orderNumber"), order.orderId, highlight())}
      {tableRow(t("totalAmount"), getOrderedItemCount(order), highlight())}
      {tableRow(t("totalSumWithFees"), `${netStr}${vatStr}`, highlight())}
    </div>
  );
}

OrderViewTable.propTypes = {
  order: PropTypes.shape({
    orderId: PropTypes.string,
    orderDate: PropTypes.string,
    customerId: PropTypes.string,
    shipToAddress: PropTypes.shape({
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      postalCode: PropTypes.string,
    }),
    totalPrice: PropTypes.number,
    netPrice: PropTypes.number,
    currency: PropTypes.string,
  }),
};

OrderViewTable.defaultProps = {
  order: {},
};
export default OrderViewTable;

import {
  Authorizer,
  EcomRoles as Role,
} from "@oriola-origo/origo-common-client-lib";
import {
  isCurrentlyValid,
  canViewOrders,
  canMakeBackOrders,
  canMakeOrders,
  canViewProducts,
  isVet,
} from "./customerRules";

export const ANY_CUSTOMER = "ANY_CUSTOMER";

/* Permission rules for roles:
 * prop name = role
 * static = a list of static permissions used in this application to restrict access / visibility
 * dynamic = functions for determining access based on data
 */
export const Permission = Object.freeze({
  PRODUCTS_VIEW: "products:view",
  ORDER_VIEW: "order:view",
  ORDER_CREATE: "order:create",
  ORDER_MODIFY_BACKORDER: "order:modifyBackorder",
  ORDER_ADD_DELIVERY_ADDRESS: "order:addDeliveryAddress",
});

const isBackorderModifyAllowed = (_p, _c, data) => {
  if (!data || !isCurrentlyValid(data.selectedCustomer)) {
    return false;
  }

  let allow = false;
  if (data.customers) {
    allow = data.customers.some(
      customer => canMakeBackOrders(customer) === true
    );
  } else if (data.customer) {
    allow = canMakeBackOrders(data.customer) === true;
  }

  return allow;
};

const isOrderViewAllowed = (_p, _c, data) => {
  if (!data || !data.customer) {
    return false;
  }

  return canViewOrders(data.customer);
};

const isOrderCreateAllowed = (_p, _c, data) => {
  if (!data || !data.customer) {
    return false;
  }

  return canMakeOrders(data.customer);
};

export const isProductsViewAllowed = (_p, _c, data) => {
  if (!data || !data.customer) {
    return false;
  }

  return canViewProducts(data.customer);
};

export const isDeliveryAddressAddAllowed = (_p, _c, data) => {
  if (!data || !(data.customer || data.customers)) {
    return false;
  }

  return (data.customers || [data.customer]).some(c => isVet(c));
};

const rules = {
  [Role.ECOM_ORIOLA_PRODUCTINFO_VIEW]: {
    static: [],
    dynamic: {
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_VET_ORDER_EDIT]: {
    static: [],
    dynamic: {
      [Permission.ORDER_MODIFY_BACKORDER]: isBackorderModifyAllowed,
      [Permission.ORDER_VIEW]: isOrderViewAllowed,
      [Permission.ORDER_CREATE]: isOrderCreateAllowed,
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
      [Permission.ORDER_ADD_DELIVERY_ADDRESS]: isDeliveryAddressAddAllowed,
    },
  },
  [Role.ECOM_VET_ORDER_VIEW]: {
    static: [],
    dynamic: {
      [Permission.ORDER_VIEW]: isOrderViewAllowed,
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_VET_PRODUCTINFO_VIEW]: {
    static: [],
    dynamic: {
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_VET_PRODUCTINFO_VIEW_NO_ORG]: {
    static: [],
    dynamic: {
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_PHARMACIST_ORDER_EDIT]: {
    static: [],
    dynamic: {
      [Permission.ORDER_MODIFY_BACKORDER]: isBackorderModifyAllowed,
      [Permission.ORDER_VIEW]: isOrderViewAllowed,
      [Permission.ORDER_CREATE]: isOrderCreateAllowed,
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_PHARMACIST_ORDER_VIEW]: {
    static: [],
    dynamic: {
      [Permission.ORDER_VIEW]: isOrderViewAllowed,
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_PHARMACIST_PRODUCTINFO_VIEW]: {
    static: [],
    dynamic: {
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_PHARMACIST_PRODUCTINFO_VIEW_NOORG]: {
    static: [],
    dynamic: {
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_HEALTHSHOPS_ORDER_EDIT]: {
    static: [],
    dynamic: {
      [Permission.ORDER_MODIFY_BACKORDER]: isBackorderModifyAllowed,
      [Permission.ORDER_VIEW]: isOrderViewAllowed,
      [Permission.ORDER_CREATE]: isOrderCreateAllowed,
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_HEALTHSHOPS_ORDER_VIEW]: {
    static: [],
    dynamic: {
      [Permission.ORDER_VIEW]: isOrderViewAllowed,
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_HEALTHSHOPS_PRODUCTINFO_VIEW]: {
    static: [],
    dynamic: {
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_PHARMA_ORDER_VIEW]: {
    static: [],
    dynamic: {
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_PHARMA_PRODUCTINFO_VIEW]: {
    static: [],
    dynamic: {
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_SUPPLIER_PRODUCTINFO_VIEW]: {
    static: [],
    dynamic: {
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_HEALTHCARE_PRODUCTINFO_VIEW]: {
    static: [],
    dynamic: {
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_HEALTHCARE_ORDER_VIEW]: {
    static: [],
    dynamic: {
      [Permission.ORDER_VIEW]: isOrderViewAllowed,
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_HEALTHCARE_ORDER_EDIT]: {
    static: [],
    dynamic: {
      [Permission.ORDER_MODIFY_BACKORDER]: isBackorderModifyAllowed,
      [Permission.ORDER_VIEW]: isOrderViewAllowed,
      [Permission.ORDER_CREATE]: isOrderCreateAllowed,
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_OPTICIAN_PRODUCTINFO_VIEW]: {
    static: [],
    dynamic: {
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_OPTICIAN_ORDER_VIEW]: {
    static: [],
    dynamic: {
      [Permission.ORDER_VIEW]: isOrderViewAllowed,
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
  [Role.ECOM_OPTICIAN_ORDER_EDIT]: {
    static: [],
    dynamic: {
      [Permission.ORDER_MODIFY_BACKORDER]: isBackorderModifyAllowed,
      [Permission.ORDER_VIEW]: isOrderViewAllowed,
      [Permission.ORDER_CREATE]: isOrderCreateAllowed,
      [Permission.PRODUCTS_VIEW]: isProductsViewAllowed,
    },
  },
};

const authorizer = new Authorizer(rules, ANY_CUSTOMER);

export const isAllowed = (user, permission, customerContext, data) =>
  authorizer.isAllowed(user, permission, customerContext, data);

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line
import clsx from "clsx";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Paths from "../../../utils/navigation/navigation";
import { Info } from "../../../images";
import DropDown from "../dropDown/dropDown";
import OrigoLink from "../origoLink/origoLink";
import {
  ShowOnlyInDevelopment,
  Permission,
  isAllowed,
  Can,
  ANY_CUSTOMER,
} from "../../auth";
import { sortAddressAlphabetically } from "../../../utils/customer/customer";

const useStyles = makeStyles(theme => ({
  rowContainer: {
    marginTop: theme.spacing(4),
  },
  controlContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  fieldTitleText: {
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(2),
  },
  fieldTitleWithInfoContainer: {
    display: "flex",
    alignItems: "center",
  },
  dropdown: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

function CustomerAndShippingAddressSelect({
  customers,
  onSelect,
  className,
  value,
  history,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedCustomer, setSelectedCustomer] = useState(undefined);
  const [selectedShippingAddress, setSelectedShippingAddress] =
    useState(undefined);
  const [addressSelectionDisabled, setAddressSelectionDisabled] =
    useState(undefined);
  const [customerSelectionDisabled, setCustomerSeletionDisabled] =
    useState(undefined);
  const [editableCustomers, setEditableCustomers] = useState([]);
  // Customer data is required for app to work correctly
  const userData = useSelector(state => state.user.userData);

  // sort addresses alphabetically
  const alphabeticallyOrderedAddresses = selectedCustomer
    ? selectedCustomer.deliveryAddresses.sort(sortAddressAlphabetically)
    : [];

  // populate
  useEffect(() => {
    if (customers && customers.length === 1) {
      // User has only one customer. Set selected customer to the only available customer and disable customer selection dropdown.
      setCustomerSeletionDisabled(true);
    } else {
      // User has multiple customers. Enable customer selection dropdown.
      setCustomerSeletionDisabled(false);
    }

    // set customer
    setSelectedCustomer(value.customer);

    if (
      selectedCustomer &&
      selectedCustomer.deliveryAddresses &&
      selectedCustomer.deliveryAddresses.length === 1 &&
      value.shippingAddress != null
    ) {
      // Selected customer has only one delivery address. Set that one as selected and disable the address dropdown.
      setAddressSelectionDisabled(true);
    } else {
      // Selected customer has two or more delivery addresses. Enable the address dropdown.
      setAddressSelectionDisabled(false);
    }

    // set shipping address
    setSelectedShippingAddress(value.shippingAddress);
  }, [value, customers, selectedCustomer]);

  useEffect(() => {
    const data = (customers || []).filter(c =>
      isAllowed(userData, Permission.ORDER_CREATE, c.customerId, {
        customer: c,
      })
    );
    setEditableCustomers(data);
  }, [userData, customers]);

  const customersEqual = (c1, c2) => {
    if (!c1) {
      return !c2;
    }
    if (!c2) {
      return !c1;
    }

    return c1.customerId === c2.customerId;
  };

  const handleCustomerSelected = customer => {
    setSelectedCustomer(customer);
    let shippingAddress = selectedShippingAddress;
    if (!customersEqual(selectedCustomer, customer)) {
      shippingAddress =
        customer &&
        customer.deliveryAddresses &&
        customer.deliveryAddresses.length === 1
          ? customer.deliveryAddresses[0]
          : undefined;
      setSelectedShippingAddress(shippingAddress);
    }
    onSelect(customer, shippingAddress);
  };

  const handleShippingAddressSelected = shippingAddress => {
    setSelectedShippingAddress(shippingAddress);
    onSelect(selectedCustomer, shippingAddress);
  };

  const renderCustomerSelect = () => (
    <div className={classes.rowContainer}>
      <Typography className={classes.fieldTitleText} variant="subtitle2" noWrap>
        {t("customer")}
      </Typography>
      <div className={classes.controlContainer}>
        <DropDown
          disabled={customerSelectionDisabled}
          className={classes.dropdown}
          value={selectedCustomer}
          noSelectionLabel={t("select")}
          formatLabel={c => `${c.customerId} ${c.name}`}
          options={editableCustomers}
          onSelect={handleCustomerSelected}
          allowSelectNone={false}
          selectNoneLabel={t("selectNone")}
        />
      </div>
    </div>
  );

  const renderShippingAddressSelect = () => (
    <div className={classes.rowContainer}>
      <div className={classes.fieldTitleWithInfoContainer}>
        <Typography
          className={classes.fieldTitleText}
          variant="subtitle2"
          noWrap
        >
          {t("shippingAddress")}
        </Typography>
        <ShowOnlyInDevelopment>
          <Info />
        </ShowOnlyInDevelopment>
      </div>

      <div className={classes.controlContainer}>
        <DropDown
          disabled={addressSelectionDisabled}
          className={classes.dropdown}
          value={selectedShippingAddress}
          noSelectionLabel={t("select")}
          formatLabel={a => a.address}
          options={alphabeticallyOrderedAddresses || []}
          onSelect={x => handleShippingAddressSelected(x)}
          allowSelectNone
          selectNoneLabel={t("selectNone")}
        />

        <Can
          user={userData}
          customerContext={ANY_CUSTOMER}
          perform={Permission.ORDER_ADD_DELIVERY_ADDRESS}
          data={{ customer: selectedCustomer }}
        >
          <Hidden smDown>
            <OrigoLink
              text={t("addNewShippingAddress")}
              onClick={() => history.push(Paths.RequestDeliveryAddress)}
            />
          </Hidden>
        </Can>
      </div>
    </div>
  );

  return (
    <div className={clsx(classes.root, className)}>
      {renderCustomerSelect()}
      {renderShippingAddressSelect()}
    </div>
  );
}

CustomerAndShippingAddressSelect.propTypes = {
  value: PropTypes.shape({
    customer: PropTypes.shape({
      customerId: PropTypes.string,
      name: PropTypes.string,
      deliveryAddresses: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    shippingAddress: PropTypes.shape({
      address: PropTypes.string,
    }),
  }),
  onSelect: PropTypes.func,
  className: PropTypes.string,
  customers: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

CustomerAndShippingAddressSelect.defaultProps = {
  value: {},
  onSelect: () => {}, // eslint-disable-line
  className: undefined,
  customers: [],
  history: undefined,
};

export default withRouter(CustomerAndShippingAddressSelect);

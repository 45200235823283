import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import CheckBoxTextLabel from "../checkboxTextLabel/checkboxTextLabel";
import colors from "../../../theme/colors";

const useStyles = makeStyles(theme => ({
  optionText: {
    color: "black",
    textTransform: "none",
  },
  optionTitle: {
    color: theme.palette.text.disabled,
  },
  menuItem: {
    fontSize: "0.875rem",
    fontWeight: "normal",
    color: colors.secondaryNavyBlue,
  },
}));

function DropDownMultiSelect({
  className,
  disabled,
  multiple,
  noSelectionLabel,
  onSelect,
  options,
  value,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderOption = (option, index) => (
    <div key={`multi-select-option-${index}`} value={option.value}>
      <MenuItem classes={{ root: classes.menuItem }}>
        <CheckBoxTextLabel
          text={option.name}
          checked={option.checked}
          customColor="grey"
        />
      </MenuItem>
      {option.title && (
        <MenuItem>
          <Typography
            className={classes.optionTitle}
            color="textPrimary"
            variant="button"
          >
            {option.title}
          </Typography>
        </MenuItem>
      )}
    </div>
  );

  const onChange = event => {
    onSelect(event);
  };

  const defaultValue = multiple ? [] : "";
  return (
    <FormControl variant="outlined" className={clsx(classes.root, className)}>
      <Select
        disabled={disabled || options.length === 0}
        multiple={multiple}
        variant="outlined"
        value={value || defaultValue}
        displayEmpty
        onChange={onChange}
        className={classes.select}
        input={<OutlinedInput margin="dense" />}
        renderValue={() => {
          const text = t(noSelectionLabel);
          return (
            <Typography className={classes.optionText} variant="button">
              {text}
            </Typography>
          );
        }}
      >
        {options.map((option, idx) => renderOption(option, idx + 1))}
      </Select>
    </FormControl>
  );
}

DropDownMultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
  ),
  onSelect: PropTypes.func,
  value: PropTypes.shape({}).isRequired,
  noSelectionLabel: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
};

DropDownMultiSelect.defaultProps = {
  options: [],
  onSelect: () => {}, // eslint-disable-line
  noSelectionLabel: "No Selection",
  className: "",
  disabled: false,
  multiple: false,
};

export default DropDownMultiSelect;

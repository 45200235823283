/* eslint-disable import/no-cycle */
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import TableRow from "@material-ui/core/TableRow";
import { dateFormatString } from "../../../../../utils/date/date";
import {
  DataCell,
  OrderIdCell,
  DeliveryAddressCell,
} from "../openOrdersBackOrdersCells";
import SinglePrice from "../../../../generic/price/singlePrice";

function OpenOrdersTableRow({ order, classes }) {
  // eslint-disable-next-line
  function OrderPriceCell(p) {
    // eslint-disable-next-line
    const { order } = p;
    const { netPrice: net, totalPrice: gross } = order;
    const priceOK = +net >= 0 && +gross >= 0;

    return (
      <DataCell>
        {priceOK && (
          <SinglePrice price={net} priceVat={gross} showVatPercent={false} />
        )}
        {!priceOK && "-"}
      </DataCell>
    );
  }

  // eslint-disable-next-line
  const formatOrderDate = str => {
    if (str) {
      return moment(str).format(dateFormatString);
    }
  };
  // eslint-disable-next-line
  const getProductCount = order => {
    if (!order) {
      return "-";
    }
    return (order.items || []).reduce((sumOfQuantities, line) => {
      const q = +line.orderQuantity;
      return sumOfQuantities + (Number.isFinite(q) ? q : 0);
    }, 0);
  };

  // eslint-disable-next-line
  const getPONumber = order => order.poNumber || "-";

  return (
    <TableRow hover>
      <DataCell className={classes.orderLineCountCell}>
        {order.items.length}
      </DataCell>
      <DataCell className={classes.productCountCell}>
        {getProductCount(order)}
      </DataCell>
      <DataCell className={classes.orderDateCell}>
        {formatOrderDate(order.orderDate)}
      </DataCell>
      <DeliveryAddressCell order={order} />
      <OrderIdCell order={order} />
      <DataCell className={classes.poNumberCell}>{getPONumber(order)}</DataCell>
      <OrderPriceCell className={classes.priceCellOrder} order={order} />
    </TableRow>
  );
}

OpenOrdersTableRow.propTypes = {
  order: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape()),
    orderDate: PropTypes.string,
    poNumber: PropTypes.string,
    netPrice: PropTypes.string,
    totalPrice: PropTypes.string,
  }),
  classes: PropTypes.shape({
    orderLineCountCell: PropTypes.string,
    productCountCell: PropTypes.string,
    orderDateCell: PropTypes.string,
    poNumberCell: PropTypes.string,
    priceCellOrder: PropTypes.string,
  }),
};

OpenOrdersTableRow.defaultProps = {
  order: {},
  classes: {},
};

export default OpenOrdersTableRow;

/* eslint-disable consistent-return */
// eslint-disable-next-line
import { RestService } from "../../../components/generic";
import ensureTrailingSlash from "../../../utils/url/url";

export const Category = Object.freeze({
  FETCH_STARTED: "CATEGORY_FETCH_STARTED",
  FETCH_FINISHED: "CATEGORY_FETCH_FINISHED",
  FETCH_ERROR: "CATEGORY_FETCH_ERROR",
  FETCH_COUNT_STARTED: "CATEGORY_FETCH_COUNT_STARTED",
  FETCH_COUNT_FINISHED: "CATEGORY_FETCH_COUNT_FINISHED",
  FETCH_COUNT_ERROR: "CATEGORY_FETCH_COUNT_ERROR",
  CLEAR: "CATEGORY_CLEAR",
  SET_SELECTED: "CATEGORY_SET_SELECTED",
});

const baseUrl = ensureTrailingSlash(process.env.REACT_APP_ECOM_SERVICE);
const productApi = process.env.REACT_APP_PRODUCT_API;

let abortController;
const getCallAbortController = () => {
  abortController = new AbortController();
  return abortController;
};

const abortPreviousCall = () => {
  if (abortController) {
    abortController.abort();
  }
};

export const subCategoriesGet = path =>
  RestService.get(path, {
    signal: getCallAbortController().signal,
  });

export const fetchSubCategories =
  (category, query) => async (dispatch, getState) => {
    abortPreviousCall();
    try {
      const parentCategoryId = category.id;
      const encodedQuery = query ? encodeURIComponent(query) : "";

      dispatch({ type: Category.FETCH_STARTED, payload: parentCategoryId });

      const path = `${baseUrl}${productApi}categories/${parentCategoryId}?query=${encodedQuery}`;
      const subCategories = await subCategoriesGet(path);

      // find parent and add
      const categoryMap = new Map(getState().productCategory.categoryMap);
      categoryMap.set(parentCategoryId, {
        id: parentCategoryId,
        parentId: category.parentId,
        subCategories,
      });

      dispatch({
        type: Category.FETCH_FINISHED,
        payload: categoryMap,
      });

      return categoryMap;
    } catch (error) {
      if (!RestService.isCancelError(error)) {
        dispatch({ type: Category.FETCH_ERROR, payload: error });
      }
    }
  };

export const updateSelectedCategoryCounts =
  (categories, query) => async dispatch => {
    try {
      // sanity
      if (categories.length === 0) {
        return [];
      }

      const encodedQuery = query ? encodeURIComponent(query) : "";
      dispatch({ type: Category.FETCH_COUNT_STARTED });

      const categoryIdStr = categories.map(c => c.id).join(",");
      const path = `${baseUrl}${productApi}categories/product/count?categoryIds=${categoryIdStr}&query=${encodedQuery}`;
      const categoryIdWithCounts = await RestService.get(path);

      // update
      const updated = categories.map(category => {
        const idAndCount = categoryIdWithCounts.find(
          item => item.id === category.id
        );
        if (idAndCount != null) {
          return { ...category, count: idAndCount.count };
        }
        return category;
      });

      // fetch finished
      dispatch({ type: Category.FETCH_COUNT_FINISHED });

      // update selected
      dispatch({ type: Category.SET_SELECTED, payload: updated });

      return updated;
    } catch (error) {
      dispatch({ type: Category.FETCH_COUNT_ERROR, payload: error });
    }
  };

export const clearCategories = () => ({
  type: Category.CLEAR,
});

export const selectCategories = categories => ({
  type: Category.SET_SELECTED,
  payload: categories,
});

const INIT_STATE = {
  categoryMap: new Map(),
  categoryFetchingId: null,
  categoryFetchError: null,
  selectedCategories: [],
  fetchingCategoryCount: false,
  categoryCountFetchError: null,
};
// eslint-disable-next-line
export const productCategoryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Category.FETCH_STARTED: {
      return {
        ...state,
        categoryFetchingId: action.payload,
        categoryFetchError: null,
      };
    }
    case Category.FETCH_FINISHED: {
      return {
        ...state,
        categoryFetchingId: null,
        categoryMap: action.payload,
      };
    }
    case Category.FETCH_ERROR:
      return {
        ...state,
        categoryFetchingId: null,
        categoryFetchError: action.payload,
      };
    case Category.FETCH_COUNT_STARTED:
      return {
        ...state,
        fetchingCategoryCount: true,
        categoryCountFetchError: null,
      };
    case Category.FETCH_COUNT_FINISHED:
      return { ...state, fetchingCategoryCount: false };
    case Category.FETCH_COUNT_ERROR:
      return {
        ...state,
        fetchingCategoryCount: false,
        categoryCountFetchError: action.payload,
      };
    case Category.CLEAR:
      return { ...state, categoryMap: new Map() };
    case Category.SET_SELECTED: {
      return { ...state, selectedCategories: action.payload };
    }
    default:
      return state;
  }
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Paths from "../../utils/navigation/navigation";
import { OrigoTextButton, Progress, NotificationPopup } from "../generic";
import DropDown from "../generic/dropDown/dropDown";
import colors from "../../theme/colors";
import {
  sendAddressRequestNotification,
  notificationShow,
  notificationHide,
} from "../../redux/reducers";
import { LoadingGears } from "../../images";
import { isVet } from "../auth/authorization/customerRules";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    marginLeft: "7.3%",
    marginRight: "7.3%",
    alignContent: "center",
    justifyContent: "center",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  content: {
    paddingTop: theme.spacing(2),
    display: "flex",
    margin: `${theme.spacing(4)}px ${theme.spacing(4)}px`,
    flexDirection: "column",
    minWidth: "550px",
  },
  formField: {
    flexDirection: "column",
    display: "flex",
    marginBottom: theme.spacing(5),
  },
  fieldTitle: {
    color: colors.secondaryDarkGray,
    fontWeight: "bold",
  },
  mainTitleContainer: {
    marginBottom: theme.spacing(5),
  },
  mainTitleText: {
    fontSize: "1.5rem",
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
  subTitleText: {
    fontSize: "1.25rem",
    color: colors.secondaryDarkGray,
    fontWeight: "bold",
  },
  row: {
    flexDirection: "row",
  },
  dropdown: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  spinner: {
    position: "absolute",
    left: theme.spacing(15),
  },
  text: {
    fontSize: "0.9rem",
    marginBottom: "25px",
  },
}));

function FormFieldLabel({ title }) {
  const classes = useStyles();
  return (
    <Typography
      className={classes.fieldTitle}
      variant="body1"
      color="textPrimary"
    >
      {title}
    </Typography>
  );
}

function FormInputField({
  title,
  value,
  onChange = () => {}, // eslint-disable-line
  maxLength = 30,
  showError = false,
  isDisabled = false,
  isMultiline = false,
}) {
  const classes = useStyles();
  return (
    <div className={classes.formField}>
      <FormFieldLabel title={title} />
      <TextField
        className={classes.text}
        multiline={isMultiline}
        rows={4}
        disabled={isDisabled}
        error={showError}
        autoFocus={false}
        margin="dense"
        size="small"
        variant="outlined"
        value={value}
        onChange={onChange}
        inputProps={{ maxLength }}
      />
    </div>
  );
}

function RequestDeliveryAddress({ history }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userData = useSelector(state => state.user.userData);
  const customers = useSelector(state => state.customer.customers).filter(c =>
    isVet(c)
  );

  const [selectedCustomer, setSelectedCustomer] = useState(undefined);
  const [streetInputValue, setStreetInputValue] = useState("");
  const [cityInputValue, setCityInputValue] = useState("");
  const [postalCodeInputValue, setPostalCodeInputValue] = useState("");
  const [additionalInfoInputValue, setAdditionalInfoInputValue] = useState("");
  const [allowNotification, setAllowNotification] = useState(true);
  const { notificationSending } = useSelector(state => state.notification);
  const [requestSent, setRequestSent] = useState(false);

  const STREET_MAX_LENGTH = 200;
  const CITY_MAX_LENGTH = 100;
  const POSTAL_CODE_MAX_LENGTH = 10;
  const ADDITIONAL_INFO_MAX_LENGTH = 500;

  useEffect(() => {
    if (customers.length) {
      setSelectedCustomer(customers[0]);
    }
  }, [customers]);

  useEffect(() => {
    // scroll always to top
    window.scrollTo(0, 0);
  }, []);

  const validateAddressField = () => streetInputValue.length > 0;

  const validatePostalCodeField = () =>
    postalCodeInputValue.length > 0 && postalCodeInputValue.match(/^[0-9]+$/);

  const validateCityField = () => cityInputValue.length > 0;

  const validateForm = () =>
    validateAddressField() && validatePostalCodeField() && validateCityField();

  const submitForm = () => {
    const newDeliveryAddressData = {
      name: userData.name,
      organisation: selectedCustomer,
      street: streetInputValue,
      city: cityInputValue,
      postalCode: postalCodeInputValue,
      additionalInfo: additionalInfoInputValue,
      allowNotification,
    };
    dispatch(sendAddressRequestNotification(newDeliveryAddressData)).then(
      response => {
        if (response) {
          dispatch(
            notificationShow(
              t("requestDeliveryAddress.requestSentShort"),
              t("requestDeliveryAddress.requestSent"),
              null,
              5000
            )
          );
          setRequestSent(true);
        }
      }
    );
  };

  const renderNotificationPopup = () => (
    <NotificationPopup
      icon={<LoadingGears />}
      onClose={() => dispatch(notificationHide())}
    />
  );

  const handleCancel = () => {
    // Check if able to navigate back without leaving site
    if (
      window.history.length > 1 &&
      document.referrer.indexOf(window.location.host) !== -1
    ) {
      // Navigate back
      history.goBack();
    } else {
      // Navigate to main page
      history.push(Paths.Main);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {!requestSent && (
          <div>
            <div className={classes.mainTitleContainer}>
              <Typography
                className={classes.mainTitleText}
                variant="body1"
                color="textPrimary"
              >
                {t("requestDeliveryAddress.title")}
              </Typography>
            </div>
            <FormInputField
              title={t("requestDeliveryAddress.yourName")}
              value={userData.name}
              isDisabled
            />
            <div className={classes.formField}>
              <FormFieldLabel
                title={t("requestDeliveryAddress.yourOrganisation")}
              />
              <DropDown
                disabled={customers.length <= 1}
                className={classes.dropdown}
                value={selectedCustomer}
                noSelectionLabel={t("select")}
                formatLabel={c => `${c.customerId} ${c.name}`}
                options={customers}
                onSelect={customer => setSelectedCustomer(customer)}
                allowSelectNone={false}
                selectNoneLabel={t("selectNone")}
              />
            </div>

            <div className={classes.mainTitleContainer}>
              <Typography
                className={classes.subTitleText}
                variant="body1"
                color="textPrimary"
              >
                {t("requestDeliveryAddress.newDeliveryAddressInfo")}
              </Typography>
            </div>
            <FormInputField
              title={t("requestDeliveryAddress.streetAndHouseNumber")}
              value={streetInputValue}
              showError={!validateAddressField()}
              maxLength={STREET_MAX_LENGTH}
              onChange={e => setStreetInputValue(e.target.value)}
            />
            <FormInputField
              title={t("requestDeliveryAddress.postalCode")}
              value={postalCodeInputValue}
              showError={!validatePostalCodeField()}
              maxLength={POSTAL_CODE_MAX_LENGTH}
              onChange={e => setPostalCodeInputValue(e.target.value)}
            />
            <FormInputField
              title={t("requestDeliveryAddress.city")}
              value={cityInputValue}
              showError={!validateCityField()}
              maxLength={CITY_MAX_LENGTH}
              onChange={e => setCityInputValue(e.target.value)}
            />

            <FormInputField
              title={t("requestDeliveryAddress.additionalInfo")}
              value={additionalInfoInputValue}
              isMultiline
              maxLength={ADDITIONAL_INFO_MAX_LENGTH}
              onChange={e => setAdditionalInfoInputValue(e.target.value)}
            />

            <div className={classes.formField}>
              <FormFieldLabel
                title={t("requestDeliveryAddress.notifyMeWhenAddressAdded")}
              />
              <RadioGroup
                className={classes.row}
                value={String(allowNotification)}
                onChange={e => {
                  setAllowNotification(e.target.value === "true");
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={t("requestDeliveryAddress.yes")}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={t("requestDeliveryAddress.no")}
                />
              </RadioGroup>
            </div>

            <div className={classes.row}>
              <OrigoTextButton disabled={false} onClick={() => handleCancel()}>
                {t("requestDeliveryAddress.cancel")}
              </OrigoTextButton>
              <OrigoTextButton
                color="secondary"
                disabled={!validateForm()}
                onClick={submitForm}
              >
                {t("requestDeliveryAddress.send")}
                <Progress
                  size={35}
                  show={notificationSending}
                  className={classes.spinner}
                />
              </OrigoTextButton>
            </div>
          </div>
        )}
        {requestSent && (
          <div>
            <div className={classes.mainTitleContainer}>
              <Typography
                className={classes.mainTitleText}
                variant="body1"
                color="textPrimary"
              >
                {t("requestDeliveryAddress.thankyou")}
              </Typography>
            </div>
            <Typography
              className={classes.text}
              variant="body2"
              color="textPrimary"
            >
              {t("requestDeliveryAddress.requestSent")}
            </Typography>
            <div className={classes.row}>
              <OrigoTextButton disabled={false} onClick={() => handleCancel()}>
                {t("requestDeliveryAddress.goBack")}
              </OrigoTextButton>
            </div>
          </div>
        )}
        {renderNotificationPopup()}
      </div>
    </div>
  );
}

RequestDeliveryAddress.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
};

RequestDeliveryAddress.defaultProps = {
  history: {
    goBack: () => {}, // eslint-disable-line
    push: () => {}, // eslint-disable-line
  },
};

FormInputField.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  showError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMultiline: PropTypes.bool,
};

FormInputField.defaultProps = {
  onChange: () => {}, // eslint-disable-line
  maxLength: 30,
  showError: false,
  isDisabled: false,
  isMultiline: false,
};
FormFieldLabel.propTypes = {
  title: PropTypes.string.isRequired,
};

export default withRouter(RequestDeliveryAddress);

import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import { Search } from "../../../images";
import colors from "../../../theme/colors";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    maxWidth: 750,
  },
  searchField: {
    backgroundColor: colors.backgroundGray,
    borderRadius: 10,
    "& fieldset": {
      borderRadius: 10,
      border: "solid 2px rgba(203, 203, 203, 0.2)",
    },
  },
}));

function SearchField({ onSearch, placeholder }) {
  const classes = useStyles();
  const [value, setValue] = useState("");
  // eslint-disable-next-line
  const doSearch = value => {
    onSearch(value);
  };

  return (
    <div className={classes.root}>
      <TextField
        id="outlined-dense"
        placeholder={placeholder}
        className={classes.searchField}
        margin="none"
        variant="outlined"
        fullWidth
        value={value}
        onChange={e => setValue(e.target.value)}
        onKeyPress={e => {
          if (e.key === "Enter") {
            doSearch(value);
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {value !== "" && (
                <IconButton size="small" onClick={() => setValue("")}>
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton size="small" onClick={() => doSearch(value)}>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

SearchField.propTypes = {
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
};

SearchField.defaultProps = {
  onSearch: () => {}, // eslint-disable-line
  placeholder: "",
};

export default SearchField;

/* eslint-disable consistent-return */
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
// eslint-disable-next-line
import { RestService } from "../../../components/generic";
import ensureTrailingSlash from "../../../utils/url/url";
import Delay from "../../../utils/delay/delay";

const { ProductAttribute, getAttribute } = ProductUtil;

export const Product = Object.freeze({
  FETCH_STARTED: "FETCH_PRODUCTS_STARTED",
  FETCH_FINISHED: "FETCH_PRODUCTS_FINISHED",
  FETCH_ERROR: "FETCH_PRODUCTS_ERROR",
  FETCH_BY_ID_STARTED: "FETCH_BY_ID_STARTED",
  FETCH_BY_ID_FINISHED: "FETCH_BY_ID_FINISHED",
  FETCH_BY_ID_ERROR: "FETCH_BY_ID_ERROR",
  FETCH_REPLACEMENTS_STARTED: "FETCH_REPLACEMENTS_STARTED",
  FETCH_REPLACEMENTS_FINISHED: "FETCH_REPLACEMENTS_FINISHED",
  FETCH_REPLACEMENTS_ERROR: "FETCH_REPLACEMENTS_ERROR",
  SELECT_PRODUCT: "SELECT_PRODUCT",
  SET_SELECTED_FILTER_TYPE: "SET_SELECTED_FILTER_TYPE",
  FETCH_PRICE_STARTED: "FETCH_PRICE_STARTED",
  FETCH_PRICE_FINISHED: "FETCH_PRICE_FINISHED",
  FETCH_PRICE_ERROR: "FETCH_PRICE_ERROR",
  UPDATE_PRICE_FETCH_IDS: "UPDATE_PRICE_FETCH_IDS",
  UPDATE_FAVORITES: "UPDATE_FAVORITES",
  FETCH_FAVORITES_STARTED: "FETCH_FAVORITES_STARTED",
  FETCH_FAVORITES_FINISHED: "FETCH_FAVORITES_FINISHED",
  FETCH_FAVORITES_ERROR: "FETCH_FAVORITES_ERROR",
  SEND_FAVORITES_STARTED: "SEND_FAVORITES_STARTED",
  SEND_FAVORITES_FINISHED: "SEND_FAVORITES_FINISHED",
  SEND_FAVORITES_ERROR: "SEND_FAVORITES_ERROR",
  CLEAR_PRODUCTS: "CLEAR_PRODUCTS",
  UPDATE_ROW_COUNT: "UPDATE_ROW_COUNT",
  CLEAR_ALL_ERRORS: "CLEAR_ALL_ERRORS",
  FETCH_CAMPAIGNS_STARTED: "FETCH_CAMPAIGNS_STARTED",
  FETCH_CAMPAIGNS_FINISHED: "FETCH_CAMPAIGNS_FINISHED",
  FETCH_CAMPAIGNS_ERROR: "FETCH_CAMPAIGNS_ERROR",
});

// -- ACTIONS

const baseUrl = ensureTrailingSlash(process.env.REACT_APP_ECOM_SERVICE);
const ecomApi = ensureTrailingSlash(process.env.REACT_APP_ECOM_API);
const productSearchApi = process.env.REACT_APP_ECOM_PRODUCT_SEARCH_API;
const productPriceApi = process.env.REACT_APP_ECOM_PRODUCT_PRICE_API;
const productApi = process.env.REACT_APP_PRODUCT_API;

const delay = new Delay();

export const FilterType = Object.freeze({
  NoFilter: "NoFilter",
  Favorites: "Favorites",
  Pharmaceuticals: "Pharmaceuticals",
  NonPharmaceuticals: "NonPharmaceuticals",
  Campaigns: "Campaigns",
  Category: "Category",
});

export const selectProduct = product => ({
  type: Product.SELECT_PRODUCT,
  payload: product,
});

// Algolia filters: https://www.algolia.com/doc/api-reference/api-parameters/filters/

let abortController;
const getCallAbortController = () => {
  abortController = new AbortController();
  return abortController;
};

const abortPreviousCall = () => {
  if (abortController) {
    abortController.abort();
  }
};
export const searchProductsGet = searchData => {
  const { query, pageSize, startIndex, filters } = searchData;
  const path = `${baseUrl}${productSearchApi}?query=${encodeURIComponent(
    query
  )}&pageSize=${pageSize}&startIndex=${startIndex}&filters=${filters}`;
  return RestService.get(path, {
    signal: getCallAbortController().signal,
  });
};

export const searchProductsPOST = searchData => {
  const path = `${baseUrl}${productSearchApi}`;
  return RestService.post(path, searchData, {
    signal: getCallAbortController().signal,
  });
};

export const fetchProducts =
  (
    startIndex,
    stopIndex,
    queryText,
    filter = { type: FilterType.NoFilter },
    forceAppend = false,
    productIds = []
  ) =>
  async (dispatch, getState) => {
    abortPreviousCall();
    try {
      // start
      dispatch({
        type: Product.FETCH_STARTED,
      });

      const pageSize = stopIndex - startIndex + 1;
      const query = queryText || "";
      const filters = filter.value || "";
      const searchData = {
        query,
        startIndex,
        pageSize,
        filters,
        productIds,
      };
      let result;

      if (filter.type === FilterType.NoFilter) {
        result = await searchProductsGet(searchData);
      } else {
        result = await searchProductsPOST(searchData);
      }

      const overallProductCount = result.overallCount;
      let foundProducts = result.hits;

      // append if not starting from zero index
      if (startIndex > 0 || forceAppend) {
        // get existing and append results
        const products = getState().product.products.slice(0);
        foundProducts = [...products, ...result.hits];
      }
      if (filter.type !== getState().product.selectedFilterType) {
        return;
      }

      // update products
      dispatch({
        type: Product.FETCH_FINISHED,
        payload: {
          products: foundProducts,
          overallProductCount,
          analytics: {
            startIndex,
            queryText,
            filter,
            resultCount: overallProductCount,
          },
        },
      });

      return foundProducts;
    } catch (error) {
      if (RestService.isCancelError(error) === false) {
        dispatch({ type: Product.FETCH_ERROR, payload: error });
      }
      return [];
    }
  };

// clear existing product array
export const clearProducts = () => ({
  type: Product.CLEAR_PRODUCTS,
});

export const fetchProductById = productId => async dispatch => {
  try {
    // start
    dispatch({ type: Product.FETCH_BY_ID_STARTED });

    const query = new URLSearchParams({
      includeExpiredAndDiscontinued: true,
    });
    const path = `${baseUrl}product/v1/${productId}?${query.toString()}`;
    const product = await RestService.get(path);

    // update
    dispatch({
      type: Product.FETCH_BY_ID_FINISHED,
      payload: product,
    });

    return product;
  } catch (error) {
    // TODO: error handling
    dispatch({ type: Product.FETCH_BY_ID_ERROR, payload: error });
  }
};

export const fetchProductPrices =
  (customerId, materialIds) => async dispatch => {
    try {
      // mark individual ids to be fetched to overcome flickering problem
      const priceFetchIdMap = new Map();

      // mark the ids to be fetched
      materialIds.forEach(id => {
        priceFetchIdMap.set(id, true);
      });
      dispatch({
        type: Product.UPDATE_PRICE_FETCH_IDS,
        payload: priceFetchIdMap,
      });

      // start
      dispatch({ type: Product.FETCH_PRICE_STARTED });

      const path = `${baseUrl}${productPriceApi}?customerId=${
        customerId || ""
      }&materialIds=${materialIds.join(",")}`;

      // fetch

      const prices = await RestService.get(path);

      // update
      dispatch({
        type: Product.FETCH_PRICE_FINISHED,
        payload: prices,
      });

      // remove ids
      dispatch({ type: Product.UPDATE_PRICE_FETCH_IDS, payload: new Map() });

      return prices;
    } catch (error) {
      dispatch({ type: Product.FETCH_PRICE_ERROR, payload: error });
    }
  };

export const fetchReplacementProducts = id => (dispatch, getState) => {
  // dispatch start
  dispatch({
    type: Product.FETCH_REPLACEMENTS_STARTED,
  });

  // TODO: implement fetching from API and remove below

  // check "cache"
  let result = [];
  const { products } = getState().product;
  const forProduct = products.find(product => product.materialId === id);
  if (forProduct) {
    const activeIngredient = getAttribute(
      forProduct,
      ProductAttribute.CONTENT_SUBSTANCE_NAME
    );
    if (activeIngredient) {
      result = products.filter(product => {
        const aI = getAttribute(
          product,
          ProductAttribute.CONTENT_SUBSTANCE_NAME
        );
        return (
          activeIngredient === aI &&
          forProduct.materialId !== product.materialId
        );
      });
    }
  }

  dispatch({
    type: Product.FETCH_REPLACEMENTS_FINISHED,
    payload: result,
  });
};

export const fetchFavoriteProducts = userId => async dispatch => {
  const path = `${baseUrl + ecomApi}favorite/product/${userId}`;
  try {
    // start
    dispatch({ type: Product.FETCH_FAVORITES_STARTED });

    const favoriteProductIds = await RestService.get(path);

    // finished
    dispatch({
      type: Product.FETCH_FAVORITES_FINISHED,
      payload: favoriteProductIds,
    });

    return favoriteProductIds;
  } catch (error) {
    // TODO: error handling
    dispatch({ type: Product.FETCH_FAVORITES_ERROR, payload: error });
  }
};

const doSendFavoriteProductIds = async (
  dispatch,
  userId,
  favoriteProductIds
) => {
  const path = `${baseUrl + ecomApi}favorite/product/${userId}`;

  try {
    // start
    dispatch({ type: Product.SEND_FAVORITES_STARTED });

    const favorites = await RestService.post(path, favoriteProductIds);

    // finished
    dispatch({ type: Product.SEND_FAVORITES_FINISHED });

    return favorites;
  } catch (error) {
    // TODO: error handling
    dispatch({ type: Product.SEND_FAVORITES_ERROR, payload: error });
  }
};

export const setFavoriteProduct =
  (userId, product, value) => (dispatch, getState) => {
    const favoriteProductIds = getState().product.favoriteProductIds.slice(0);

    const productId = product.materialId;
    // adding or removing
    if (value) {
      favoriteProductIds.push(productId);
    } else {
      const index = favoriteProductIds.indexOf(productId);
      favoriteProductIds.splice(index, 1);
    }

    // update to local state
    dispatch({
      type: Product.UPDATE_FAVORITES,
      payload: {
        ids: favoriteProductIds,
        analytics: {
          add: value,
          product,
        },
      },
    });

    // delay send (to handle heavy mouse clickers)
    delay.run(() => {
      doSendFavoriteProductIds(dispatch, userId, favoriteProductIds);
    }, 1000);
  };

export const fetchProductsByIds =
  (startIndex, productIds) => async (dispatch, getState) => {
    const trimmedProductIds = productIds.filter(id => id);
    if (trimmedProductIds.length === 0) {
      dispatch({
        type: Product.FETCH_FINISHED,
        payload: {
          products: [],
          overallProductCount: 0,
        },
      });
      return [];
    }
    const strProductIds = trimmedProductIds.join(",");
    const path = `${baseUrl}${productApi}byIds/?materialIds=${strProductIds}`;

    try {
      // start
      dispatch({ type: Product.FETCH_STARTED });

      const result = await RestService.get(path);

      // page can have multiple infinityLists => separate fetches needs to be stored
      // get existing and append results
      const products = getState().product.products.slice(0);
      const foundProducts = Array.from(new Set([...products, ...result]));
      const overallProductCount = foundProducts.length;

      // update products
      dispatch({
        type: Product.FETCH_FINISHED,
        payload: {
          products: foundProducts,
          overallProductCount,
        },
      });

      return foundProducts;
    } catch (error) {
      // TODO: error handling
      dispatch({ type: Product.FETCH_ERROR, payload: error });
    }
  };

// Store row count for product list in case of scrolling (for cart add buttons)
export const updateProductRowCount = (productId, count) => dispatch =>
  dispatch({ type: Product.UPDATE_ROW_COUNT, payload: { productId, count } });

export const fetchCampaignProducts = customerId => async dispatch => {
  try {
    // start
    dispatch({ type: Product.FETCH_CAMPAIGNS_STARTED });

    const path = `${baseUrl}${productPriceApi}campaigns?customerId=${customerId}`;
    const campaignProductIds = await RestService.get(path);

    // finished
    dispatch({
      type: Product.FETCH_CAMPAIGNS_FINISHED,
      payload: campaignProductIds,
    });

    return campaignProductIds;
  } catch (error) {
    dispatch({ type: Product.FETCH_CAMPAIGNS_ERROR, payload: error });
  }
};

export const setSelectedFilterType = tab => dispatch => {
  dispatch({
    type: Product.SET_SELECTED_FILTER_TYPE,
    payload: tab,
  });
};

// -- REDUCER --

const INIT_STATE = {
  // list of products
  products: [],
  overallProductCount: null,
  // single product used in fetching by id or selecting
  product: {},
  // replacement products
  replacementProducts: [],
  // users favorite products
  favoriteProductIds: [],
  campaignProductIds: [],
  selectedFilterType: FilterType.NoFilter,
  // product price map
  productPricesMap: new Map(),
  // fetching id array
  priceFetchIdMap: new Map(),
  fetchingProduct: false,
  productFetchError: null,
  fetchingProductById: false,
  productFetchByIdError: null,
  fetchingReplacement: false,
  replacementFetchError: null,
  fetchingFavorites: false,
  favoritesFetchError: null,
  sendingFavorites: false,
  favoritesSendError: false,
  fetchingProductPrice: false,
  productPriceFetchError: null,
  fetchingCampaigns: false,
  campaignsFetchError: null,
};
// eslint-disable-next-line
export const productReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Product.CLEAR_PRODUCTS: {
      return {
        ...state,
        products: [],
        overallProductCount: 0,
        fetchingProduct: false,
      };
    }
    case Product.CLEAR_ALL_ERRORS: {
      return {
        ...state,
        favoritesSendError: null,
        favoritesFetchError: null,
        productFetchError: null,
        replacementFetchError: null,
        productPriceFetchError: null,
        productFetchByIdError: null,
      };
    }
    case Product.UPDATE_FAVORITES: {
      return { ...state, favoriteProductIds: action.payload.ids };
    }
    case Product.FETCH_FAVORITES_STARTED: {
      return { ...state, fetchingFavorites: true, favoritesFetchError: null };
    }
    case Product.FETCH_FAVORITES_FINISHED: {
      return {
        ...state,
        fetchingFavorites: false,
        favoriteProductIds: action.payload,
      };
    }
    case Product.FETCH_FAVORITES_ERROR: {
      return {
        ...state,
        fetchingFavorites: false,
        favoritesFetchError: action.payload,
      };
    }
    case Product.SEND_FAVORITES_STARTED:
      return { ...state, sendingFavorites: true, favoritesSendError: null };
    case Product.SEND_FAVORITES_FINISHED:
      return { ...state, sendingFavorites: false };
    case Product.SEND_FAVORITES_ERROR:
      return {
        ...state,
        sendingFavorites: false,
        favoritesSendError: action.payload,
      };
    case Product.FETCH_BY_ID_STARTED: {
      return {
        ...state,
        fetchingProductById: true,
        productFetchByIdError: null,
        product: {},
      };
    }
    case Product.FETCH_BY_ID_FINISHED: {
      return { ...state, fetchingProductById: false, product: action.payload };
    }
    case Product.FETCH_BY_ID_ERROR: {
      return {
        ...state,
        fetchingProductById: false,
        productFetchByIdError: action.payload,
      };
    }
    case Product.SELECT_PRODUCT: {
      return { ...state, product: action.payload };
    }
    case Product.SET_SELECTED_FILTER_TYPE: {
      return { ...state, selectedFilterType: action.payload };
    }
    case Product.FETCH_REPLACEMENTS_STARTED: {
      return {
        ...state,
        fetchingReplacement: true,
        replacementFetchError: null,
      };
    }
    case Product.FETCH_REPLACEMENTS_FINISHED: {
      return {
        ...state,
        fetchingReplacement: false,
        replacementProducts: action.payload,
      };
    }
    case Product.FETCH_REPLACEMENTS_ERROR: {
      return {
        ...state,
        fetchingReplacement: false,
        replacementFetchError: action.payload,
      };
    }
    case Product.FETCH_PRICE_STARTED: {
      return {
        ...state,
        fetchingProductPrice: true,
        productPriceFetchError: null,
      };
    }
    case Product.FETCH_PRICE_FINISHED: {
      const productPricesMap = new Map(state.productPricesMap);

      // update
      const updatedPrices = action.payload;
      updatedPrices.forEach(price => {
        productPricesMap.set(price.materialId, price);
      });

      return { ...state, fetchingProductPrice: false, productPricesMap };
    }
    case Product.FETCH_PRICE_ERROR: {
      return {
        ...state,
        fetchingProductPrice: false,
        productPriceFetchError: action.payload,
      };
    }
    case Product.UPDATE_PRICE_FETCH_IDS: {
      return { ...state, priceFetchIdMap: action.payload };
    }
    case Product.FETCH_STARTED:
      return { ...state, fetchingProduct: true, productFetchError: null };
    case Product.FETCH_FINISHED: {
      const { products, overallProductCount } = action.payload;
      return {
        ...state,
        fetchingProduct: false,
        products,
        overallProductCount,
      };
    }
    case Product.FETCH_ERROR:
      return {
        ...state,
        fetchingProduct: false,
        productFetchError: action.payload,
      };
    case Product.UPDATE_ROW_COUNT: {
      const products = state.products.map(product => {
        if (product.materialId === action.payload.productId) {
          return { ...product, rowCount: action.payload.count };
        }
        return product;
      });
      return { ...state, products };
    }
    case Product.FETCH_CAMPAIGNS_STARTED: {
      return { ...state, fetchingCampaigns: true, campaignsFetchError: null };
    }
    case Product.FETCH_CAMPAIGNS_FINISHED: {
      return {
        ...state,
        fetchingCampaigns: false,
        campaignProductIds: action.payload,
      };
    }
    case Product.FETCH_CAMPAIGNS_ERROR: {
      return {
        ...state,
        fetchingCampaigns: false,
        campaignsFetchError: action.payload,
      };
    }
    default:
      return state;
  }
};

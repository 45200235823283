const LZUTF8 = require("lzutf8");

const decompress = data => LZUTF8.decompress(data, { inputEncoding: "Base64" });

const compress = data => LZUTF8.compress(data, { outputEncoding: "Base64" });

module.exports = {
  decompress,
  compress,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line
import BackOrdersTableRow from "./backOrdersTableRow";
import BackOrdersHeader from "./backOrdersHeader";
// eslint-disable-next-line
import { InfinityList } from "../../../generic";
import {
  fetchStockStatuses,
  fetchProductsByIds,
} from "../../../../redux/reducers";
import styles from "../orderCardTableStyles";

const VISIBLE_ITEMS = 10;

const useStyles = makeStyles(styles);

function BackOrdersTable({ orders, order, productViewAllowed }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const products = useSelector(state => state.product.products);

  const { productStockStatusesMap } = useSelector(state => state.stock);
  const { t } = useTranslation();
  const [visibleOrders, setVisibleOrders] = useState(
    orders.slice(0, VISIBLE_ITEMS)
  );
  const highlight = index => index % 2 === 0;
  const maxVisibleItems =
    orders.length < VISIBLE_ITEMS ? orders.length : VISIBLE_ITEMS;
  const title = t("backorder");

  // Load initial product/stock data
  useEffect(
    () /* eslint-disable */ => {
      handleLoadMore(0, VISIBLE_ITEMS);
    },
    []
  ); /* eslint-enable */

  const rowRenderer = ({ key, index, style }) => {
    const orderline = orders[index];
    return (
      orderline && (
        <div style={style} key={key}>
          <BackOrdersTableRow
            classes={classes}
            orderline={orderline}
            productStockStatusesMap={productStockStatusesMap}
            products={products}
            highlight={highlight(index)}
            order={order}
          />
        </div>
      )
    );
  };

  const loadAdditionalProductData = (
    customerId,
    startIndex,
    stopIndex,
    // eslint-disable-next-line
    orders
  ) => {
    if (productViewAllowed === false) {
      return;
    }

    if (Array.isArray(orders)) {
      // get target ids
      const targetOrders = orders.slice(startIndex, stopIndex + 1);
      const targetIds = targetOrders.map(x => x.productId);
      const ids = Array.from(new Set(targetIds));
      if (ids.length > 0) {
        dispatch(fetchProductsByIds(startIndex, ids));
        dispatch(fetchStockStatuses(customerId, ids));
      }
    }
  };

  const handleLoadMore = (start, stop) => {
    loadAdditionalProductData(orders[0].customerId, start, stop, orders);
    const orderArray = [...visibleOrders, ...orders.slice(start, stop + 1)];
    setVisibleOrders(Array.from(new Set(orderArray)));
  };

  return (
    <div className={classes.root}>
      <Typography
        className={classes.tableHeader}
      >{`${title} (${orders.length})`}</Typography>
      <div className={classes.table}>
        <BackOrdersHeader classes={classes} order={order} />
        <InfinityList
          items={visibleOrders}
          visibleItemCount={maxVisibleItems}
          itemOverallCount={orders.length}
          rowHeight={105}
          rowSpacing={1}
          isLoading={false}
          onRenderRow={rowRenderer}
          onLoad={(start, stop) => handleLoadMore(start, stop)}
        />
      </div>
    </div>
  );
}

BackOrdersTable.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string,
      customerId: PropTypes.string,
    })
  ).isRequired,
  order: PropTypes.shape({
    customerId: PropTypes.string,
  }),
  productViewAllowed: PropTypes.bool.isRequired,
};

BackOrdersTable.defaultProps = {
  order: {},
};

export default BackOrdersTable;

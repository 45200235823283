/* eslint-disable import/no-cycle */
import {
  isOrderLineOpen,
  isOrderLineBackorder,
} from "../orderLineUtil/orderLineUtil";
import getNumber from "../getNumber/getNumber";

const Decimal = require("decimal.js-light");
const { formatCustomerName } = require("../customer/customer");

export const OrderStatus = Object.freeze({
  OPEN: "OPEN",
  DELIVERED: "DELIVERED",
  BACKORDER: "BACKORDER",
  REJECTED: "REJECTED",
  UNDELIVERABLE: "UNDELIVERABLE",
  UNDEFINED: "UNDEFINED",
});

const LINE_STATUSES = Object.freeze({
  backorder: "backorder",
  processing: "processing",
  delivered: "delivered",
  rejected: "rejected",
});

const ORDER_TYPE_ZSRP = "ZSRP";

export const isDelivered = line =>
  line.lineStatuses.some(x => x.status === LINE_STATUSES.delivered);

export const isBackorder = line =>
  line.lineStatuses
    ? line.lineStatuses.some(x => x.status === LINE_STATUSES.backorder)
    : line.backOrders === "Y" && line.rejected !== "Y";

const isRejected = line =>
  line.lineStatuses
    ? line.lineStatuses.some(x => x.status === LINE_STATUSES.rejected)
    : line.rejected === "Y";

const hasOrderLines = order => order && Array.isArray(order.items);
const searchFilter = (line, query) =>
  (line.productName && line.productName.toLowerCase().includes(query)) ||
  (line.productId && line.productId.includes(query)) ||
  (line.vnr && line.vnr.includes(query));

const filterOrderLines = (orderLines, query) =>
  query !== null
    ? orderLines.filter(line => searchFilter(line, query.toLowerCase()))
    : orderLines;

export const getOrderShipToCustomerName = order => {
  if (!order || !order.shipToAddress) {
    return null;
  }

  return formatCustomerName({
    name1: order.shipToAddress.name1,
    name2: order.shipToAddress.name2,
  });
};

export const getOrderStatusLocalization = (order, t) =>
  order && typeof order.status === "string"
    ? t(order.status.toLowerCase())
    : "-";

const getOrderLineProductNameForSorting = orderLine =>
  orderLine && orderLine.productName ? orderLine.productName.toUpperCase() : "";

export const sortByProductName = (a, b) => {
  const aName = getOrderLineProductNameForSorting(a);
  const bName = getOrderLineProductNameForSorting(b);
  if (aName === bName) {
    return 0;
  }
  return aName < bName ? -1 : +1;
};
export const isOrderStatusOpen = order => order.status === OrderStatus.OPEN;

export const getBackOrdersForOrder = order =>
  hasOrderLines(order) ? order.items.filter(isBackorder) : [];

export const getBackOrdersForOderSortedByName = (order, query = null) => {
  if (!hasOrderLines(order)) {
    return [];
  }
  const backorders = getBackOrdersForOrder(order).sort(sortByProductName);
  return filterOrderLines(backorders, query);
};

const isProcessingLine = line =>
  line.lineStatuses.some(x => x.status === LINE_STATUSES.processing);

export const getProcessingOrderLines = (order, query = null) => {
  if (!hasOrderLines(order)) {
    return [];
  }
  const delivered = order.items
    .filter(line => isProcessingLine(line))
    .sort(sortByProductName);

  return filterOrderLines(delivered, query);
};

export const getDeliveredForOrder = (order, query = null) => {
  if (!hasOrderLines(order)) {
    return [];
  }
  const delivered = order.items
    .filter(line => isDelivered(line))
    .sort(sortByProductName);

  return filterOrderLines(delivered, query);
};

export const getRejectedOrderLines = (order, query = null) => {
  if (!hasOrderLines(order)) {
    return [];
  }
  const rejected = order.items
    .filter(line => isRejected(line))
    .sort(sortByProductName);

  return filterOrderLines(rejected, query);
};
export const getOrderStatuses = (order, t) => {
  const backOrders = getBackOrdersForOrder(order);
  const delivered = getDeliveredForOrder(order);
  const processing = getProcessingOrderLines(order);
  const rejectedCount = getRejectedOrderLines(order).length;
  const statusArray = [];

  // Display status only if there are backorders
  if (backOrders.length > 0) {
    statusArray.push(`${t("backorder")} (${backOrders.length})`);
  }
  // Display status only if there are waiting to be collected items
  if (processing.length > 0) {
    statusArray.push(`${t("processing")} (${processing.length})`);
  }
  // Display status only if there are delivered items
  if (delivered.length > 0) {
    statusArray.push(`${t("delivered")} (${delivered.length})`);
  }
  if (rejectedCount > 0) {
    statusArray.push(`${t("cancelled")} (${rejectedCount})`);
  }

  return statusArray.join(" | ");
};

const getOrderLinePrices = orderLines => {
  const prices = orderLines.reduce(
    (acc, order) => ({
      total: order.lineValue ? acc.total.plus(order.lineValue) : acc.total,
      net: order.netLineTotal ? acc.net.plus(order.netLineTotal) : acc.net,
    }),
    {
      total: new Decimal(0.0),
      net: new Decimal(0.0),
    }
  );
  return { total: prices.total.toFixed(2), net: prices.net.toFixed(2) };
};

export const calculateOverallOrderLinePrice = order =>
  hasOrderLines(order) ? getOrderLinePrices(order.items).total : 0;

export const calculateBackOrderPrices = order => {
  const backOrders = getBackOrdersForOrder(order);
  return getOrderLinePrices(backOrders);
};

export const calculateRejectedPrices = order => {
  const rejectedOrderLines = getRejectedOrderLines(order);
  return getOrderLinePrices(rejectedOrderLines);
};
export const getOrderLineItemCount = order =>
  hasOrderLines(order)
    ? order.items.map(x => x.orderQuantity || 0).reduce((a, b) => a + b, 0)
    : 0;

export const getOrderedItemCount = order =>
  hasOrderLines(order)
    ? order.items
        .map(o => getNumber(o.orderQuantity))
        .reduce((a, b) => a + b, 0)
    : 0;
export const getOrderLineCount = order =>
  hasOrderLines(order) ? order.items.length : 0;

export const getBackorderQuantity = orderLine => {
  if (!isBackorder(orderLine)) {
    return 0;
  }
  /* Returns open quantity + confirmed quantity
   * This may not always be quite correct - confirmed quantity may be backorder if there is a deliver block
   * but might also still be delivered on the same day.
   */
  const openQty = getNumber(orderLine.openQuantity);
  const confirmedQty = getNumber(orderLine.confirmedQuantity);
  const quantity = openQty + confirmedQty;
  return quantity.toFixed(0);
};

export const getDeliveredQuantity = orderLine => {
  const delQty = getNumber(orderLine.delQuantity);
  const invQty = getNumber(orderLine.invQuantity);
  return delQty + invQty;
};

export const canEditBackorder = order =>
  (order || {}).orderType !== ORDER_TYPE_ZSRP;

export const isOpenOrderViewType = order => {
  const orderLines = (order && order.items) || [];
  const result = orderLines.reduce(
    (acc, line) => ({
      openOrderLineCount: isOrderLineOpen(line)
        ? acc.openOrderLineCount + 1
        : acc.openOrderLineCount,
      backOrderLineCount: isOrderLineBackorder(line)
        ? acc.backOrderLineCount + 1
        : acc.backOrderLineCount,
    }),
    { openOrderLineCount: 0, backOrderLineCount: 0 }
  );

  // Order is open if there's open and backorder lines. It's not open if all the lines are backorder lines or there's some other types present.
  const { openOrderLineCount, backOrderLineCount } = result;
  return (
    openOrderLineCount + backOrderLineCount === orderLines.length &&
    backOrderLineCount < orderLines.length
  );
};

const createSuperOrder = (order, backOrderLines) => {
  // add ready calculated info to order data
  const backOrderCount = backOrderLines.length;
  const backOrderPrice =
    backOrderCount > 0 ? calculateBackOrderPrices(order) : {};
  const rejectedCount = getRejectedOrderLines(order).length;
  const displayRejectedLines = rejectedCount > 0 && order.status !== "REJECTED";
  const rejectedPrice = displayRejectedLines
    ? calculateRejectedPrices(order)
    : {};
  return {
    ...order,
    backOrderCount,
    backOrderPrice,
    rejectedPrice,
    displayRejectedLines,
    rejectedCount,
  };
};

export const preProcessOrderResults = (orders, overallOrderCount) =>
  orders.reduce(
    (acc, order) => {
      // get lines populated with corresponding order
      const backOrderLines = getBackOrdersForOrder(order);
      const superOrder = createSuperOrder(order, backOrderLines);
      acc.orders.push(superOrder);

      // add order reference to backorder lines
      const backOrderLinesWithOrders = backOrderLines.map(backOrderLine => ({
        ...backOrderLine,
        order: superOrder,
      }));
      acc.backOrderLines.push(...backOrderLinesWithOrders);

      // is it actually open and not in the history view
      if (isOpenOrderViewType(superOrder)) {
        acc.openOrders.push(superOrder);
      } else if (isOrderStatusOpen(superOrder)) {
        // if order is in open state still even though not accepted on the list
        // reduce the count since this is removed from the open list
        acc.filteredOrderCount -= 1;
      }

      return acc;
    },
    {
      orders: [],
      openOrders: [],
      backOrderLines: [],
      filteredOrderCount: overallOrderCount,
    }
  );

export const combineOrderGroups = (existingOrderGroups, newOrderGroups) => ({
  orders: existingOrderGroups.orders.concat(newOrderGroups.orders),
  openOrders: existingOrderGroups.openOrders.concat(newOrderGroups.openOrders),
  backOrderLines: existingOrderGroups.backOrderLines.concat(
    newOrderGroups.backOrderLines
  ),
});

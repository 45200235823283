import React from "react";
import PropTypes from "prop-types";
import Hidden from "@material-ui/core/Hidden";

export function Cell({ className, children }) {
  return <div className={className}>{children}</div>;
}

Cell.propTypes = {
  className: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

Cell.defaultProps = {
  className: {},
};

export function MobileHiddenCell({ className, children }) {
  return (
    <Hidden smDown>
      <div className={className}>{children}</div>
    </Hidden>
  );
}

MobileHiddenCell.propTypes = {
  className: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

MobileHiddenCell.defaultProps = {
  className: {},
};

/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import {
  OrderFilterType,
  fetchOrders,
  notificationHide,
  fetchStockStatuses,
  fetchAvailabilityStatuses,
} from "../../../../redux/reducers";
import BackordersTable from "./backordersTable/backordersTable";
// eslint-disable-next-line
import { Progress, NotificationPopup } from "../../../generic";
import { getStockIdChunks } from "../../../../utils/stockUtil/stockUtil";
import EditBackorderPopup from "../../orderCard/editBackorderPopup/editBackorderPopup";
import DeleteBackorderPopup from "../../orderCard/deleteBackorderPopup/deleteBackorderPopup";
import { ShoppingCartRound, TrashcanRound } from "../../../../images";
import { canViewProducts } from "../../../auth/authorization/customerRules";
import { findCustomerById } from "../../../../utils/customer/customer";
import ExportReport from "./exportReport";
import { filterBackOrders } from "../locallyFilterOrders";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    "& > *": {
      marginBottom: theme.spacing(4),
    },
  },
  loadingIndicator: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  exportButtonWrapper: {
    display: "flex",
    justifyContent: "end",
  },
  exportButton: {
    backgroundColor: "transparent",
    paddingRight: 0,
  },
  exportLoader: {
    marginRight: theme.spacing(3),
  },
}));

function BackOrders({ searchValue, onItemCountUpdated }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { selectedCustomerId, customers } = useSelector(
    state => state.customer
  );
  const { fetchingOrders, backOrderLines } = useSelector(state => state.order);
  const [filteredBackOrderLines, setFilteredBackOrderLines] = useState([]);
  const { title } = useSelector(state => state.notificationPopup);
  const dispatch = useDispatch();
  const customer = findCustomerById(selectedCustomerId, customers);
  const customerCanViewProducts = canViewProducts(customer);

  useEffect(() => {
    if (customers.length === 0) {
      // do not fetch orders if there are no customers and query check to avoid duplicate fetch
      return;
    }

    const filterStr = `customerIds=${customers
      .map(c => `${c.customerId}`)
      .join(",")}&viewType=open`;
    const filter = {
      value: filterStr,
      type: OrderFilterType.OpenOrdersBackOrders,
    };
    const startDateDefault = moment().subtract(24, "months").startOf("month");
    const endDateDefault = moment().endOf("day");
    // order search cannot handle large page size when there are many customers
    const pageSize = Math.min(Math.floor(5000 / customers.length), 1000);
    dispatch(
      fetchOrders(0, pageSize - 1, startDateDefault, endDateDefault, "", filter)
    );
  }, [dispatch, customers]);

  useEffect(() => {
    // no right to see products, don't fetch related stuff
    if (customerCanViewProducts === false || backOrderLines.length === 0) {
      return;
    }

    const productIds = Object.values(
      backOrderLines.reduce((acc, l) => {
        acc[l.productId] = l.productId;
        return acc;
      }, {})
    );

    const productIdsChunked = getStockIdChunks(productIds);
    productIdsChunked.forEach(idChunk => {
      dispatch(fetchStockStatuses(selectedCustomerId, idChunk));
      dispatch(fetchAvailabilityStatuses(selectedCustomerId, idChunk));
    });
  }, [dispatch, backOrderLines, selectedCustomerId, customerCanViewProducts]);

  useEffect(() => {
    setFilteredBackOrderLines(filterBackOrders(backOrderLines, searchValue));
  }, [backOrderLines, searchValue]);

  useEffect(() => {
    if (typeof onItemCountUpdated === "function") {
      onItemCountUpdated((filteredBackOrderLines || []).length);
    }
    // eslint-disable-next-line
  }, [filteredBackOrderLines]);

  const getNotificationIcon = () =>
    title === t("backorderDeleteSuccesful") ? (
      <TrashcanRound />
    ) : (
      <ShoppingCartRound />
    );

  const renderContent = () => {
    if (fetchingOrders) {
      return (
        <div className={classes.loadingIndicator}>
          <Progress show />
        </div>
      );
    }
    const customerIds = customers.map(c => c.customerId);

    return (
      <>
        <div className={classes.exportButtonWrapper}>
          <ExportReport
            customerIds={customerIds}
            classes={classes}
            searchValue={searchValue}
          />
        </div>

        <BackordersTable backorderLines={filteredBackOrderLines} />
        <EditBackorderPopup />
        <DeleteBackorderPopup />
        <NotificationPopup
          icon={getNotificationIcon()}
          onClose={() => dispatch(notificationHide())}
        />
      </>
    );
  };

  return <div className={classes.root}>{renderContent()}</div>;
}

BackOrders.propTypes = {
  searchValue: PropTypes.string,
  onItemCountUpdated: PropTypes.func,
};

BackOrders.defaultProps = {
  searchValue: "",
  onItemCountUpdated: () => {}, // eslint-disable-line
};

export default withRouter(BackOrders);

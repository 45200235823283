import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line
import { InfinityList } from "../../../generic";
import { fetchProductsByIds } from "../../../../redux/reducers";
import DeliveredTableRow from "./deliveredTableRow";
import styles from "../orderCardTableStyles";

const VISIBLE_ITEMS = 10;

const useStyles = makeStyles(styles);
function DeliveredTable({
  orders,
  backOrders,
  title,
  orderlineType,
  productViewAllowed,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const products = useSelector(state => state.product.products);

  const { t } = useTranslation();
  const highlight = index => index % 2 === 0;
  const [visibleOrders, setVisibleOrders] = useState(
    orders.slice(0, VISIBLE_ITEMS)
  );
  const maxVisibleItems =
    orders.length < VISIBLE_ITEMS ? orders.length : VISIBLE_ITEMS;

  // Load initial product/stock data
  useEffect(
    () /* eslint-disable */ => {
      handleLoadMore(0, VISIBLE_ITEMS);
    },
    []
  ); /* eslint-enable */

  const renderHeaderRow = () => (
    <div>
      <div className={classes.productRow}>
        <div className={classes.columnImage} />
        <div className={`${classes.column} ${classes.columnName}`}>
          <Typography className={classes.tableHeaderText}>{`
              ${t("product")} | 
              ${t("vnrmsi")} | 
              ${t("oriolaProductNumber")}
              `}</Typography>
        </div>
        <div className={`${classes.column} ${classes.columnUnitPrice}`}>
          <Typography className={classes.tableHeaderText}>
            {t("unitPrice")}
          </Typography>
        </div>
        <div className={`${classes.column} ${classes.columnOrdered}`}>
          <Typography align="center" className={classes.tableHeaderText}>
            {t("orderedAmount")}
          </Typography>
        </div>
        <div className={`${classes.column} ${classes.columnDelivered}`}>
          <Typography
            align="center"
            className={classes.tableHeaderText}
          >{`${title} ${t("amount").toLowerCase()}`}</Typography>
        </div>
      </div>
    </div>
  );

  const rowRenderer = ({ key, index, style }) => {
    const order = orders[index];
    return (
      order && (
        <div style={style} key={key}>
          <DeliveredTableRow
            classes={classes}
            order={order}
            products={products}
            backOrders={backOrders}
            highlight={highlight(index)}
            orderlineType={orderlineType}
          />
        </div>
      )
    );
  };

  const loadAdditionalProductData = (
    customerId,
    startIndex,
    stopIndex,
    // eslint-disable-next-line
    orders
  ) => {
    if (productViewAllowed === false) {
      return;
    }

    if (Array.isArray(orders)) {
      // get target ids
      const targetOrders = orders.slice(startIndex, stopIndex + 1);
      const targetIds = targetOrders.map(x => x.productId);
      const ids = Array.from(new Set(targetIds));
      if (ids.length > 0) {
        dispatch(fetchProductsByIds(startIndex, ids));
      }
    }
  };

  const handleLoadMore = (start, stop) => {
    loadAdditionalProductData(orders[0].customerId, start, stop, orders);
    const orderArray = [...visibleOrders, ...orders.slice(start, stop + 1)];
    setVisibleOrders(Array.from(new Set(orderArray)));
  };

  return (
    <div className={classes.root}>
      <Typography
        className={classes.tableHeader}
      >{`${title} (${orders.length})`}</Typography>
      <div className={classes.table}>
        {renderHeaderRow()}
        <InfinityList
          items={visibleOrders}
          visibleItemCount={maxVisibleItems}
          itemOverallCount={orders.length}
          rowHeight={63}
          rowSpacing={1}
          isLoading={false}
          onRenderRow={rowRenderer}
          onLoad={(start, stop) => handleLoadMore(start, stop)}
        />
      </div>
    </div>
  );
}

DeliveredTable.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      customerId: PropTypes.number,
      productId: PropTypes.number,
    })
  ).isRequired,
  backOrders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
  orderlineType: PropTypes.string.isRequired,
  productViewAllowed: PropTypes.bool.isRequired,
};

export default DeliveredTable;

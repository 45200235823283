import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Hidden, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  fixedHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.tableDivider.header}`,
    paddingBottom: theme.spacing(1),
    width: "100%",
  },
  columnImage: {
    flex: "1 0 6%",
    display: "flex",
    justifyContent: "center",
  },
  columnName: {
    flex: "1 0 20%",
    [theme.breakpoints.down("md")]: {
      flex: "1 0 31%",
    },
    [theme.breakpoints.down("sm")]: {
      flex: "1 0 65%",
    },
  },
  tableHeaderText: {
    ...theme.typography.button,
    textTransform: "none",
    color: theme.palette.text.disabled,
  },
  columnAvailability: {
    flex: "1 0 16%",
  },
  columnUnitPrice: {
    flex: "1 0 13%",
  },
  columnTotalPrice: {
    flex: "1 0 10%",
    [theme.breakpoints.down("sm")]: {
      flex: "1 0 20%",
    },
  },
  columnAmount: {
    flex: "1 0 12%",
  },
  columnRemove: {
    flex: "1 0 5%",
    [theme.breakpoints.down("sm")]: {
      flex: "1 0 15%",
    },
  },
  centeredHeaderText: {
    textAlign: "center",
  },
}));

function CartProductTableHeader() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.fixedHeader}>
      <Hidden smDown>
        <div className={classes.columnImage} />
      </Hidden>
      <div className={classes.columnName}>
        <Typography className={classes.tableHeaderText}>
          {`${t("product")} | ${t("vnrmsi")} | ${t("expirationDate")}`}
        </Typography>
      </div>
      <Hidden smDown>
        <div className={classes.columnAvailability}>
          <Typography className={classes.tableHeaderText}>
            {t("availability")}
          </Typography>
        </div>
      </Hidden>
      <Hidden smDown>
        <div className={classes.columnUnitPrice}>
          <Typography className={classes.tableHeaderText}>
            {t("unitPrice")}
          </Typography>
        </div>
      </Hidden>
      <div className={classes.columnTotalPrice}>
        <Typography className={classes.tableHeaderText}>
          {t("totalPrice")}
        </Typography>
      </div>
      <Hidden smDown>
        <div
          className={`${classes.columnAmount} ${classes.centeredHeaderText}`}
        >
          <Typography className={classes.tableHeaderText}>
            {t("amount")}
          </Typography>
        </div>
      </Hidden>
      <div className={`${classes.columnRemove} ${classes.centeredHeaderText}`}>
        <Typography className={classes.tableHeaderText}>
          {t("remove")}
        </Typography>
      </div>
    </div>
  );
}

export default CartProductTableHeader;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  text: {
    textTransform: "none",
    color: theme.palette.text.primary,
  },
  underline: {
    textDecoration: "underline",
  },
}));

function TextLink({ text, to, variant, disabled, ...containerProps }) {
  const classes = useStyles();

  const renderText = () => {
    const underlineClass = disabled === false ? classes.underline : null;
    return (
      <Typography
        variant={variant}
        className={`${classes.text} ${underlineClass}`}
      >
        {text}
      </Typography>
    );
  };

  return (
    <Box {...containerProps}>
      {disabled === false && <Link to={to}>{renderText()}</Link>}
      {disabled === true && renderText()}
    </Box>
  );
}

TextLink.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
};

TextLink.defaultProps = {
  variant: "button",
  disabled: false,
};

export default TextLink;

/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Can, Permission, ANY_CUSTOMER } from "../../../auth";
import { findCustomerById } from "../../../../utils/customer/customer";

function BackOrdersHeader({ classes, isEditing, order }) {
  const { t } = useTranslation();
  const userData = useSelector(state => state.user.userData);
  const { customers, selectedCustomerId } = useSelector(
    state => state.customer
  );
  const customer = findCustomerById(order.soldTo, customers) || {};
  const selectedCustomer = customers.find(x => x.Kunnr === selectedCustomerId);
  return (
    <div>
      <div className={classes.productRow}>
        {!isEditing && <div className={classes.columnImage} />}
        <div className={`${classes.column} ${classes.columnName}`}>
          <Typography className={classes.tableHeaderText}>{`
              ${t("product")} |
              ${t("vnrmsi")} |
              ${t("oriolaProductNumber")} |
              ${t("availability")}
              `}</Typography>
        </div>
        <div className={`${classes.column} ${classes.columnUnitPrice}`}>
          <Typography className={classes.tableHeaderText}>
            {t("price")}
          </Typography>
        </div>
        <div className={`${classes.column} ${classes.columnAmount}`}>
          <Can
            user={userData}
            customerContext={ANY_CUSTOMER}
            perform={Permission.ORDER_MODIFY_BACKORDER}
            data={{ customer, selectedCustomer }}
            ok={() => (
              <Typography align="center" className={classes.tableHeaderText}>
                {t("backordersTable.amountEdit")}
              </Typography>
            )}
            nok={() => (
              <Typography align="center" className={classes.tableHeaderText}>
                {t("amount")}
              </Typography>
            )}
          />
        </div>
        <Can
          user={userData}
          customerContext={ANY_CUSTOMER}
          perform={Permission.ORDER_MODIFY_BACKORDER}
          data={{ customer, selectedCustomer }}
        >
          {!isEditing && (
            <div className={classes.columnDelete}>
              <Typography align="center" className={classes.tableHeaderText}>
                {t("cancel")}
              </Typography>
            </div>
          )}
        </Can>
      </div>
    </div>
  );
}
BackOrdersHeader.propTypes = {
  classes: PropTypes.shape({
    productRow: PropTypes.string,
    columnImage: PropTypes.string,
    columnName: PropTypes.string,
    columnUnitPrice: PropTypes.string,
    columnAmount: PropTypes.string,
    columnDelete: PropTypes.string,
    tableHeaderText: PropTypes.string,
    column: PropTypes.string,
  }).isRequired,
  isEditing: PropTypes.bool,
  order: PropTypes.shape({
    soldTo: PropTypes.string,
  }).isRequired,
};
BackOrdersHeader.defaultProps = {
  isEditing: false,
};
export default BackOrdersHeader;

import React from "react";
import Icon from "@material-ui/core/Icon";
import PropTypes from "prop-types";
import colors from "../theme/colors";

export { ReactComponent as Edit } from "./edit/edit.svg";
export { ReactComponent as TrashcanRound } from "./trashcanRound/trashcanRound.svg";
export { ReactComponent as CartSVGIcon } from "./cart/cart.svg";

const styles = {
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  shoppingCartIcon: {
    width: "67px",
    height: "67px",
  },
  loadingGears: {
    width: "67px",
    height: "67px",
  },
  image: {},
  arrowRight: {
    transform: "rotate(90deg)",
  },
  imageGrey: {
    filter: `opacity(.5) drop-shadow(0 0 0 ${colors.secondaryDarkGray})`,
  },
  categories: {
    width: "18px",
    height: "21px",
  },
  wrench: {
    width: "25px",
    height: "25px",
  },
  flag: {
    width: "19px",
    height: "19px",
  },
  imageDisabled: {
    opacity: "0.5",
  },
};

export function DefaultProductThumbnail(props) {
  return (
    <img
      alt="Default product"
      src={require("./thumbnail/thumbnail.png").default}
      srcSet={`
            ${require("./thumbnail/thumbnail@2x.png")} 2x, 
            ${require("./thumbnail/thumbnail@3x.png")} 3x
          `}
      {...props}
    />
  );
}

export function DefaultProductImage(props) {
  return (
    <img
      alt="Default product"
      src={require("./defaultProductImage/defaultProductImage.png").default}
      srcSet={`
            ${require("./defaultProductImage/defaultProductImage@2x.png")} 2x, 
            ${require("./defaultProductImage/defaultProductImage@3x.png")} 3x
          `}
      {...props}
    />
  );
}

export function Categories() {
  return (
    <img
      style={styles.categories}
      alt="categories"
      src={require("./categories/categories.png").default}
      srcSet={`
            ${require("./categories/categories@2x.png")} 2x, 
            ${require("./categories/categories@3x.png")} 3x
          `}
    />
  );
}

export function Organic() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="organic"
        src={require("./organic/organic.png").default}
        srcSet={`
            ${require("./organic/organic@2x.png")} 2x, 
            ${require("./organic/organic@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Calendar() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="calendar"
        src={require("./calendar/calendar.png").default}
        srcSet={`
            ${require("./calendar/calendar@2x.png")} 2x, 
            ${require("./calendar/calendar@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function ShoppingCartRound() {
  return (
    <Icon style={styles.shoppingCartIcon}>
      <img
        style={styles.image}
        alt="shopping cart round"
        src={require("./shoppingCartRound/shoppingCartRound.png").default}
        srcSet={`
            ${require("./shoppingCartRound/shoppingCartRound@2x.png")} 2x, 
            ${require("./shoppingCartRound/shoppingCartRound@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function LoadingGears() {
  return (
    <Icon style={styles.loadingGears}>
      <img
        style={styles.image}
        alt="loading gears"
        src={require("./loadingGears/Gears.png").default}
        srcSet={`
            ${require("./loadingGears/Gears@2x.png")} 2x, 
            ${require("./loadingGears/Gears@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Trashcan() {
  return (
    <Icon style={styles.icon}>
      <img
        alt="trashcan"
        src={require("./trashcan/trashcan.png").default}
        srcSet={`
            ${require("./trashcan/trashcan@2x.png")} 2x, 
            ${require("./trashcan/trashcan@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function ArrowUpGrey() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.imageGrey}
        alt="arrow up"
        src={require("./arrowUp/arrowUp.png").default}
        srcSet={`
            ${require("./arrowUp/arrowUp@2x.png")} 2x, 
            ${require("./arrowUp/arrowUp@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function ArrowDownGrey() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.imageGrey}
        alt="arrow down"
        src={require("./arrowDown/arrowDown.png").default}
        srcSet={`
            ${require("./arrowDown/arrowDown@2x.png")} 2x, 
            ${require("./arrowDown/arrowDown@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Note() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="note"
        src={require("./note/note.png").default}
        srcSet={`
            ${require("./note/note@2x.png")} 2x, 
            ${require("./note/note@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function ArrowUp() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="arrow up"
        src={require("./arrowUp/arrowUp.png").default}
        srcSet={`
            ${require("./arrowUp/arrowUp@2x.png")} 2x, 
            ${require("./arrowUp/arrowUp@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function ArrowRight() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.arrowRight}
        alt="arrow up"
        src={require("./arrowUp/arrowUp.png").default}
        srcSet={`
            ${require("./arrowUp/arrowUp@2x.png")} 2x, 
            ${require("./arrowUp/arrowUp@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function ArrowDown() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="arrow down"
        src={require("./arrowDown/arrowDown.png").default}
        srcSet={`
            ${require("./arrowDown/arrowDown@2x.png")} 2x, 
            ${require("./arrowDown/arrowDown@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Compare() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="compare"
        src={require("./compare/compare.png").default}
        srcSet={`
            ${require("./compare/compare@2x.png")} 2x, 
            ${require("./compare/compare@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function No() {
  return (
    <img
      style={{ width: "30px", height: "30px" }}
      alt="no"
      src={require("./no/no.png").default}
      srcSet={`
            ${require("./no/no@2x.png")} 2x, 
            ${require("./no/no@3x.png")} 3x
          `}
    />
  );
}

export function Yes() {
  return (
    <img
      style={styles.image}
      alt="yes"
      src={require("./yes/yes.png").default}
      srcSet={`
            ${require("./yes/yes@2x.png")} 2x, 
            ${require("./yes/yes@3x.png")} 3x
          `}
    />
  );
}

export function More() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="more"
        src={require("./more/more.png").default}
        srcSet={`
            ${require("./more/more@2x.png")} 2x, 
            ${require("./more/more@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Less() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="less"
        src={require("./less/less.png").default}
        srcSet={`
            ${require("./less/less@2x.png")} 2x, 
            ${require("./less/less@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Snowflake() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="snowflake"
        src={require("./snowflake/snowflake.png").default}
        srcSet={`
            ${require("./snowflake/snowflake@2x.png")} 2x, 
            ${require("./snowflake/snowflake@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Favorite() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="favorite"
        src={require("./favorite/favorite.png").default}
        srcSet={`
            ${require("./favorite/favorite@2x.png")} 2x, 
            ${require("./favorite/favorite@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function FavoriteBorder() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="favorite border"
        src={require("./favoriteBorder/favoriteBorder.png").default}
        srcSet={`
            ${require("./favoriteBorder/favoriteBorder@2x.png")} 2x, 
            ${require("./favoriteBorder/favoriteBorder@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Cart() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="cart"
        src={require("./cart/cart.png").default}
        srcSet={`
            ${require("./cart/cart@2x.png")} 2x, 
            ${require("./cart/cart@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function CartPlus() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="cart plus"
        src={require("./cartp/cartp.png").default}
        srcSet={`
            ${require("./cartp/cartp@2x.png")} 2x, 
            ${require("./cartp/cartp@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function CartMinus() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="cart minus"
        src={require("./cartm/cartm.png").default}
        srcSet={`
            ${require("./cartm/cartm@2x.png")} 2x, 
            ${require("./cartm/cartm@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Minus({ disabled }) {
  return (
    <Icon style={styles.icon}>
      <img
        style={disabled === true ? styles.imageDisabled : null}
        alt="minus"
        src={require("./minus/minus.png").default}
        srcSet={`
            ${require("./minus/minus@2x.png")} 2x, 
            ${require("./minus/minus@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

Minus.propTypes = {
  disabled: PropTypes.bool,
};

Minus.defaultProps = {
  disabled: false,
};

export function Plus({ disabled }) {
  return (
    <Icon style={styles.icon}>
      <img
        style={disabled === true ? styles.imageDisabled : null}
        alt="plus"
        src={require("./plus/plus.png").default}
        srcSet={`
            ${require("./plus/plus@2x.png")} 2x, 
            ${require("./plus/plus@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

Plus.propTypes = {
  disabled: PropTypes.bool,
};

Plus.defaultProps = {
  disabled: false,
};

export function Info() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="info"
        src={require("./info/info.png").default}
        srcSet={`
            ${require("./info/info@2x.png")} 2x, 
            ${require("./info/info@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Search() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="search"
        src={require("./search/search.png").default}
        srcSet={`
            ${require("./search/search@2x.png")} 2x, 
            ${require("./search/search@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Narcotics() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="narcotics"
        src={require("./narcotic/narcotic.png").default}
        srcSet={`
            ${require("./narcotic/narcotic@2x.png")} 2x, 
            ${require("./narcotic/narcotic@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Animal() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="veterinary"
        src={require("./animal/animal.png").default}
        srcSet={`
            ${require("./animal/animal@2x.png")} 2x, 
            ${require("./animal/animal@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Biohazard() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="biohazard"
        src={require("./biohazard/biohazard.png").default}
        srcSet={`
            ${require("./biohazard/biohazard@2x.png")} 2x, 
            ${require("./biohazard/biohazard@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Download() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="download"
        src={require("./download/download.png").default}
        srcSet={`
            ${require("./download/download@2x.png")} 2x, 
            ${require("./download/download@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Wrench() {
  return (
    <img
      style={styles.wrench}
      alt="wrench"
      src={require("./wrench/wrench.png").default}
      srcSet={`
            ${require("./wrench/wrench@2x.png")} 2x, 
            ${require("./wrench/wrench@3x.png")} 3x
          `}
    />
  );
}

export function ActiveFlag() {
  return (
    <img
      style={styles.flag}
      alt="active flag"
      src={require("./activeFlag/activeFlag.png").default}
      srcSet={`
            ${require("./activeFlag/activeFlag@2x.png")} 2x, 
            ${require("./activeFlag/activeFlag@3x.png")} 3x
          `}
    />
  );
}

export function InactiveFlag() {
  return (
    <img
      style={styles.flag}
      alt="inactive flag"
      src={require("./inactiveFlag/inactiveFlag.png").default}
      srcSet={`
            ${require("./inactiveFlag/inactiveFlag@2x.png")} 2x, 
            ${require("./inactiveFlag/inactiveFlag@3x.png")} 3x
          `}
    />
  );
}

export function PDF() {
  return (
    <img
      style={styles.image}
      alt="pdf"
      src={require("./pdf/pdf.png").default}
      srcSet={`
            ${require("./pdf/pdf@2x.png")} 2x, 
            ${require("./pdf/pdf@3x.png")} 3x
          `}
    />
  );
}

export function ImportExcelIcon({ style }) {
  return (
    <img
      style={style}
      alt="import-icon"
      src={require("./import/import.svg").default}
    />
  );
}

ImportExcelIcon.propTypes = {
  style: PropTypes.shape({}),
};

ImportExcelIcon.defaultProps = {
  style: {},
};

export function UncheckedCheckbox() {
  return (
    <img
      style={styles.icon}
      alt="unchecked checkbox"
      src={require("./checkBox/unchecked/rectangle.png").default}
      srcSet={`
            ${require("./checkBox/unchecked/rectangle@2x.png")} 2x, 
            ${require("./checkBox/unchecked/rectangle@3x.png")} 3x
          `}
    />
  );
}

export function CheckedCheckbox() {
  return (
    <img
      style={styles.icon}
      alt="checked checkbox"
      src={require("./checkBox/checked/checkboxEnabled.png").default}
      srcSet={`
            ${require("./checkBox/checked/checkboxEnabled@2x.png")} 2x, 
            ${require("./checkBox/checked/checkboxEnabled@3x.png")} 3x
          `}
    />
  );
}

export function DisabledCheckbox() {
  return (
    <img
      style={styles.icon}
      alt="disabled checkbox"
      src={require("./checkBox/disabled/checkboxDisabled.png").default}
      srcSet={`
            ${require("./checkBox/disabled/checkboxDisabled@2x.png")} 2x, 
            ${require("./checkBox/disabled/checkboxDisabled@3x.png")} 3x
          `}
    />
  );
}

export function NotiOn() {
  return (
    <img
      style={styles.icon}
      alt="enabled notification"
      src={require("./notification/on/bell.svg").default}
    />
  );
}

export function NotiOff() {
  return (
    <img
      style={styles.icon}
      alt="disabled notification"
      src={require("./notification/off/crossBell.svg").default}
    />
  );
}

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Typography, IconButton } from "@material-ui/core";
import { useSelector } from "react-redux";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles(theme => ({
  modal: {
    pointerEvents: "none",
  },
  root: {
    right: theme.spacing(14),
    top: theme.spacing(16),
    position: "absolute",
    width: "23%",
    backgroundColor: theme.palette.secondary.main,
    borderWidth: "2px",
    borderRadius: 5,
    borderColor: theme.palette.secondary.main,
    borderStyle: "solid",
    boxShadow: theme.shadows[2],
    display: "flex",
    flexDirection: "row",
    pointerEvents: "all",
  },
  container: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexGrow: 1,
  },
  icon: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textContainer: {
    marginLeft: theme.spacing(2),
  },
  primaryText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.2),
  },
}));

function NotificationPopup({ onClose, icon }) {
  const classes = useStyles();
  const notification = useSelector(state => state.notificationPopup);

  return (
    <Modal
      open={notification.show}
      className={classes.modal}
      disableEnforceFocus
      disableAutoFocus
      hideBackdrop
      onClose={onClose}
    >
      <div className={classes.root} id="notification-popup">
        <div className={classes.container}>
          <div className={classes.icon}>{icon}</div>
          <div className={classes.textContainer}>
            <Typography variant="subtitle1" color="textSecondary">
              {notification.title}
            </Typography>
            <Typography
              className={classes.primaryText}
              variant="body2"
              color="textSecondary"
              style={{ fontWeight: "normal" }}
            >
              {notification.primaryText}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ textTransform: "normal" }}
            >
              {notification.secondaryText}
            </Typography>
          </div>
        </div>
        <div>
          <IconButton
            size="small"
            padding="none"
            margin="none"
            onClick={() => onClose()}
          >
            <ClearIcon fontSize="small" style={{ color: "white" }} />
          </IconButton>
        </div>
      </div>
    </Modal>
  );
}

NotificationPopup.propTypes = {
  onClose: PropTypes.func,
  icon: PropTypes.element,
};

NotificationPopup.defaultProps = {
  onClose: () => {}, // eslint-disable-line
  icon: "",
};

export default NotificationPopup;

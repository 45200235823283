/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line
import ProductCategoryRow from "./productCategoryRow";
import colors from "../../../theme/colors";
import { Progress } from "../../generic";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    background: "white",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
    pointerEvents: "all",
    zIndex: 100,
    height: "70%",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    marginTop: theme.spacing(1.5),
  },
  clearButton: {
    marginBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    ...theme.typography.body1,
    color: theme.palette.text.primary,
  },
  titleTextContainer: {
    borderBottom: `solid 1px ${colors.backgroundDarkGray}`,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  title: {
    ...theme.typography.body2,
    color: theme.palette.text.disabled,
  },
  loader: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rowContainer: {
    overflowY: "scroll",
  },
}));

function ProductCategoryMenuList({
  left,
  top,
  width,
  title,
  levelIndex,
  categories,
  onOpenClick,
  selected,
  onSelectClick,
  onClearClick,
  showClearButton,
  fetching,
  fetchError,
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [openedId, setOpenedId] = useState(null);
  const [sortedCategories, setSortedCategories] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line
    const sortedCategories = categories.slice(0);
    const lang = i18n.language.toUpperCase();
    sortedCategories.sort((c1, c2) => {
      const prop = `structureName${lang}`;
      const name1 = c1[prop];
      const name2 = c2[prop];
      return name1.localeCompare(name2);
    });
    setSortedCategories(sortedCategories);
  }, [categories, setSortedCategories, i18n]);

  const handleOpenClick = category => {
    if (openedId == null) {
      setOpenedId(category.id);
    } else {
      const newOpenedId = openedId !== category.id ? category.id : null;
      setOpenedId(newOpenedId);
    }

    onOpenClick(levelIndex, category);
  };

  function Loader() {
    return (
      <div className={classes.loader}>
        <Progress show />
      </div>
    );
  }

  function FetchError() {
    return (
      <div className={classes.loader}>
        <Typography variant="body1" color="error">
          {t("networkError")}
        </Typography>
      </div>
    );
  }

  const renderCategories = () => (
    <div className={classes.rowContainer}>
      {sortedCategories.map(category => (
        <ProductCategoryRow
          key={category.id}
          opened={openedId === category.id}
          // eslint-disable-next-line
          onOpenClick={category => handleOpenClick(category)}
          category={category}
          selected={selected.find(c => c.id === category.id) != null}
          // eslint-disable-next-line
          onSelectClick={(category, autoClose) =>
            onSelectClick(category, autoClose)
          }
          disableOpen={category.hasChildren === false}
        />
      ))}
    </div>
  );

  return (
    <div className={classes.root} style={{ left, top, width }}>
      <div className={classes.header}>
        {showClearButton === true && (
          <Button className={classes.clearButton} onClick={onClearClick}>
            {t("clearSelection")}
          </Button>
        )}
        <div className={classes.titleTextContainer}>
          <Typography className={classes.title}>
            {title.toUpperCase()}
          </Typography>
        </div>
      </div>

      {fetching === true && fetchError == null && <Loader />}
      {fetchError != null && <FetchError />}
      {fetching === false && fetchError == null && renderCategories()}
    </div>
  );
}

ProductCategoryMenuList.propTypes = {
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  levelIndex: PropTypes.number.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  onOpenClick: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectClick: PropTypes.func.isRequired,
  onClearClick: PropTypes.func,
  showClearButton: PropTypes.bool,
  fetching: PropTypes.bool,
  fetchError: PropTypes.shape({}),
};

ProductCategoryMenuList.defaultProps = {
  categories: [],
  onClearClick: () => {}, // eslint-disable-line
  showClearButton: false,
  fetching: false,
  fetchError: null,
};

export default ProductCategoryMenuList;

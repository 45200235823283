import { RestService } from "../../../components/generic"; // eslint-disable-line
import ensureTrailingSlash from "../../../utils/url/url";

export const Versions = Object.freeze({
  FETCH_VERSIONS_STARTED: "FETCH_VERSIONS_STARTED",
  FETCH_VERSIONS_FINISHED: "FETCH_VERSIONS_FINISHED",
  FETCH_VERSIONS_ERROR: "FETCH_VERSIONS_ERROR",
});

const baseUrl = ensureTrailingSlash(process.env.REACT_APP_ECOM_SERVICE);
const ecomApi = ensureTrailingSlash(process.env.REACT_APP_ECOM_API);

// -- ACTIONS --
// eslint-disable-next-line
export const fetchVersions = () => async dispatch => {
  const path = `${baseUrl}${ecomApi}versions`;

  try {
    dispatch({ type: Versions.FETCH_VERSIONS_STARTED });
    const result = await RestService.get(path);

    dispatch({
      type: Versions.FETCH_VERSIONS_FINISHED,
      payload: result,
    });

    return result;
  } catch (error) {
    // TODO: error handling
    dispatch({ type: Versions.FETCH_VERSIONS_ERROR, payload: error });
  }
};
// -- REDUCER --

const INIT_STATE = {
  versions: {},
  fetchingVersions: false,
  versionsFetchError: null,
};

// eslint-disable-next-line
export const versionsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Versions.FETCH_VERSIONS_STARTED:
      return { ...state, fetchingVersions: true, versionsFetchError: null };
    case Versions.FETCH_VERSIONS_FINISHED: {
      return { ...state, fetchingVersions: false, versions: action.payload };
    }
    case Versions.FETCH_VERSIONS_ERROR: {
      return {
        ...state,
        fetchingVersions: false,
        versionsFetchError: action.payload,
      };
    }
    default:
      return state;
  }
};

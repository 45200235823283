/* eslint-disable consistent-return */
import ensureTrailingSlash from "../../../utils/url/url";
// eslint-disable-next-line
import { RestService } from "../../../components/generic";

export const Stock = Object.freeze({
  FETCH_STARTED: "STOCK_FETCH_STARTED",
  FETCH_FINISHED: "STOCK_FETCH_FINISHED",
  FETCH_ERROR: "STOCK_FETCH_ERROR",
  FETCH_BY_ID_STARTED: "STOCK_FETCH_BY_ID_STARTED",
  FETCH_BY_ID_FINISHED: "STOCK_FETCH_BY_ID_FINISHED",
  FETCH_BY_ID_ERROR: "STOCK_FETCH_BY_ID_ERROR",
  UPDATE_STOCK_STATUS_FETCH_IDS: "UPDATE_STOCK_STATUS_FETCH_IDS",
  CLEAR_ALL_ERRORS: "CLEAR_ALL_ERRORS",
});

const baseUrl = ensureTrailingSlash(process.env.REACT_APP_ECOM_SERVICE);
const stockApi = ensureTrailingSlash(process.env.REACT_APP_STOCK_API);
// -- ACTIONS --

export const fetchStockStatuses =
  (customerId, materialIds) => async dispatch => {
    const cId = customerId || "";
    const trimmedMaterialIds = materialIds.filter(id => id);
    if (trimmedMaterialIds.length === 0) {
      return [];
    }
    const path = `${baseUrl}${stockApi}?customerId=${cId}&materialIds=${trimmedMaterialIds.join(
      ","
    )}`;

    try {
      // mark individual ids to be fetched to overcome flickering problem
      const stockStatusFetchIdMap = new Map();

      // mark the ids to be fetched
      trimmedMaterialIds.forEach(id => {
        stockStatusFetchIdMap.set(id, true);
      });
      dispatch({
        type: Stock.UPDATE_STOCK_STATUS_FETCH_IDS,
        payload: stockStatusFetchIdMap,
      });

      // start
      dispatch({ type: Stock.FETCH_STARTED });

      const stockData = await RestService.get(path);

      // update
      dispatch({
        type: Stock.FETCH_FINISHED,
        payload: stockData,
      });

      // remove ids
      dispatch({
        type: Stock.UPDATE_STOCK_STATUS_FETCH_IDS,
        payload: new Map(),
      });

      return stockData;
    } catch (error) {
      // mark the fetching ends
      dispatch({
        type: Stock.UPDATE_STOCK_STATUS_FETCH_IDS,
        payload: new Map(),
      });
      dispatch({ type: Stock.FETCH_ERROR, payload: error });
    }
  };

export const fetchStockByProductId = (customerId, materialId) =>
  fetchStockStatuses(customerId, [materialId]);

// -- REDUCER --

const INIT_STATE = {
  productStockStatusesMap: new Map(),
  stockStatusFetchIdMap: new Map(),
  fetchingStock: false,
  stockFetchError: null,
};
// eslint-disable-next-line
export const stockService = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Stock.FETCH_STARTED:
      return { ...state, fetchingStock: true, stockFetchError: null };
    case Stock.CLEAR_ALL_ERRORS: {
      return { ...state, stockFetchError: null };
    }
    case Stock.FETCH_FINISHED: {
      const productStockStatusesMap = new Map(state.productStockStatusesMap);
      const newStatuses = action.payload;

      // update statuses
      newStatuses.forEach(status => {
        productStockStatusesMap.set(status.materialId, status);
      });

      return { ...state, fetchingStock: false, productStockStatusesMap };
    }
    case Stock.FETCH_ERROR:
      return {
        ...state,
        fetchingStock: false,
        stockFetchError: action.payload,
      };
    case Stock.FETCH_BY_ID_STARTED:
      return { ...state, fetchingStock: true, stockFetchError: null };
    case Stock.FETCH_BY_ID_FINISHED: {
      const productStockStatusesMap = new Map(state.productStockStatusesMap);
      const status = action.payload;

      // update
      productStockStatusesMap.set(status.materialId, status);

      return { ...state, fetchingStock: false, productStockStatusesMap };
    }
    case Stock.FETCH_BY_ID_ERROR:
      return {
        ...state,
        fetchingStock: false,
        stockFetchError: action.payload,
      };
    case Stock.UPDATE_STOCK_STATUS_FETCH_IDS: {
      return { ...state, stockStatusFetchIdMap: action.payload };
    }
    default:
      return state;
  }
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
  },
  value: {
    ...theme.typography.body1,
    marginTop: theme.spacing(1),
  },
  title: {
    ...theme.typography.body1,
    marginTop: theme.spacing(2),
    fontWeight: "bold",
  },
  marginLeft: {
    marginTop: theme.spacing(1),
  },
}));

function UserInfo() {
  const classes = useStyles();
  const { userData, tokenData, sessionId } = useSelector(state => state.user);
  const { customers } = useSelector(state => state.customer);

  const customer =
    customers.find(c => c.customerId === userData.defaultOrganizationId) || {};
  const { isCurrentlyValid, isValidInTheFuture } = customer;

  const formatDateTime = date => {
    if (date != null) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }
    return "-";
  };

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1">{userData.name}</Typography>
      <Typography className={classes.value}>{userData.userId}</Typography>
      <Typography className={classes.value}>{userData.email}</Typography>
      <Typography
        className={classes.value}
      >{`Language: ${userData.language}`}</Typography>
      <Typography
        className={classes.value}
      >{`Session id: ${sessionId}`}</Typography>
      <Typography
        className={classes.value}
      >{`Token expire time: ${formatDateTime(
        tokenData.expiryTime
      )}`}</Typography>
      <Typography className={classes.title}>Roles</Typography>
      {userData.roles.map(role => (
        <Typography key={role} className={classes.value}>
          {role}
        </Typography>
      ))}
      <Typography className={classes.title}>Organizations</Typography>
      {userData.organizationIds.map(org => {
        const text =
          userData.defaultOrganizationId === org ? `${org} (selected)` : org;
        return (
          <Typography key={org} className={classes.value}>
            {text}
          </Typography>
        );
      })}
      <Typography
        className={classes.value}
      >{`isCurrentlyValid: ${isCurrentlyValid} `}</Typography>
      <Typography
        className={classes.value}
      >{`isValidInTheFuture: ${isValidInTheFuture} `}</Typography>

      <Typography className={classes.title}>Profit centers</Typography>
      {userData.profitCenters.map(pc => (
        <Typography key={pc} className={classes.value}>
          {pc}
        </Typography>
      ))}
    </div>
  );
}

export default UserInfo;

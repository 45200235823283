export const InvoiceType = Object.freeze({
  DEBIT: "Debit",
  CREDIT: "Credit",
});

const getInvoiceTypeForInvoiceList = (invoice, t) => {
  // Invoice list can contain both Debit and Credit entries.
  // If the amount is negative, return Credit, otherwise Debit
  const netAmt = (invoice && invoice.invoiceNetAmt) || "";
  if (netAmt.charAt(0) === "-") {
    return {
      type: InvoiceType.CREDIT,
      displayType: t("invoiceTable.creditNote"),
    };
  }
  return { type: InvoiceType.DEBIT, displayType: t("invoiceTable.invoice") };
};

export const getInvoiceType = (invoice, t) => {
  switch (invoice.invoiceType) {
    case "ZG2": // OKD Credit Memo
    case "ZLG": // Credit Memo List
    case "ZPG2": // OKD-PharmCo Cr.Memo
    case "ZPGG": // OKD PCo CrList
    case "ZRE": // OKD Returns Credit
      return {
        type: InvoiceType.CREDIT,
        displayType: t("invoiceTable.creditNote"),
      };
    case "ZF1": // OKD Sundry Inv
    case "ZLS": // OKD Sndry IL.
    case "ZSL2": // OKD Sundry Db. Memo
      return {
        type: InvoiceType.DEBIT,
        displayType: t("invoiceTable.serviceInvoice"),
      };
    case "ZSG2": // OKD Sundry Cr Memo
    case "ZSLR": // OKD Sundry Cr List.
      return {
        type: InvoiceType.CREDIT,
        displayType: t("invoiceTable.serviceCreditInvoice"),
      };
    case "ZLR": // OKD Invoice List
      return getInvoiceTypeForInvoiceList(invoice, t);
    default:
      return {
        type: InvoiceType.DEBIT,
        displayType: t("invoiceTable.invoice"),
      };
  }
};

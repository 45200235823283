import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import Progress from "../progress/progress";
import colors from "../../../theme/colors";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(0, 0, 0, 0.75)",
  },
  root: props => ({
    width: "480px",
    maxWidth: props.maxWidth,
    backgroundColor: theme.palette.background.paper,
    borderWidth: "1px",
    borderRadius: 4,
    borderColor: theme.palette.background.paper,
    borderStyle: "solid",
    boxShadow: theme.shadows[2],
    padding: "16px 24px",
    outline: "none",
    boxSizing: "border-box",
    top: 0,
    left: 0,
  }),
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButton: {
    textTransform: "none",
    minWidth: "20px",
  },
  content: {
    marginBottom: "16px",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  title: {
    fontWeight: "bold",
  },
  cancelButton: {
    height: "2.5rem",
    textTransform: "none",
    marginRight: theme.spacing(4),
    paddingRight: "2rem",
    paddingLeft: "2rem",
    background: colors.backgroundGray,
    boxShadow: "rgba(0, 0, 1, 0.25)",
    borderRadius: "4px",
    color: colors.secondaryNavyBlue,
  },
  saveButton: {
    height: "2.5rem",
    textTransform: "none",
    paddingRight: "2rem",
    paddingLeft: "2rem",
    boxShadow: "rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    "&:disabled": {
      background: colors.secondaryDarkGray,
      color: colors.white,
    },
  },
  fontSizeSmall: {
    fontSize: "1.8rem",
    fill: "#E0DFDF",
  },
}));

function PopupLayout({
  show,
  top,
  left,
  bottom,
  right,
  children,
  onOk,
  onCancel,
  disableSave,
  hideOkButton,
  popupRef,
  cancelKey,
  saveKey,
  width,
  maxWidth,
  showLoader,
  title,
}) {
  const classes = useStyles({ width, maxWidth });
  const { t } = useTranslation();

  return (
    <Modal
      open={show}
      onClose={onCancel}
      hideBackdrop
      className={classes.modal}
    >
      <div
        className={classes.root}
        style={{ top, left, bottom, right }}
        ref={popupRef}
        id="popup"
      >
        <div className={classes.header}>
          <Typography
            className={classes.title}
            variant="h6"
            color="textPrimary"
          >
            {title}
          </Typography>
          <Button
            size="small"
            className={classes.closeButton}
            onClick={onCancel}
          >
            <ClearIcon
              fontSize="small"
              classes={{ fontSizeSmall: classes.fontSizeSmall }}
            />
          </Button>
        </div>
        <div className={classes.content}>
          {children}
          <div className={classes.buttons}>
            <Button
              className={classes.cancelButton}
              variant="contained"
              onClick={onCancel}
              disableElevation
              disabled={showLoader === true}
            >
              {t(`${cancelKey}`)}
            </Button>
            {!hideOkButton && (
              <Button
                className={classes.saveButton}
                variant="contained"
                color="secondary"
                onClick={onOk}
                disableElevation
                disabled={disableSave}
              >
                {showLoader === true && <Progress show size={20} />}
                {showLoader === false && t(`${saveKey}`)}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

PopupLayout.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  bottom: PropTypes.number,
  right: PropTypes.number,
  children: PropTypes.node,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  show: PropTypes.bool,
  disableSave: PropTypes.bool,
  hideOkButton: PropTypes.bool,
  popupRef: PropTypes.func,
  cancelKey: PropTypes.string,
  saveKey: PropTypes.string,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  showLoader: PropTypes.bool,
  title: PropTypes.string,
};

PopupLayout.defaultProps = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  children: null,
  onOk: () => {}, // eslint-disable-line
  onCancel: () => {}, // eslint-disable-line
  show: false,
  disableSave: false,
  hideOkButton: false,
  popupRef: () => {}, // eslint-disable-line
  cancelKey: "cancel",
  saveKey: "save",
  width: "440px",
  maxWidth: "90%",
  showLoader: false,
  title: "",
};

export default PopupLayout;

const moment = require("moment");

const LIFECYCLE_DISCONTINUED = "Discontinued";

const isProductDiscontinued = ({ companies }) => {
  const firstCompany = Array.isArray(companies) && companies[0];
  return (
    !firstCompany || firstCompany.lifecyclePerCompany === LIFECYCLE_DISCONTINUED
  );
};

const isProductExpired = ({ channels }) => {
  const channel = Array.isArray(channels) && channels[0];
  if (!channel) {
    return false;
  }

  const { webshopVisibilityEndDate: endDate } = channel;
  return !!endDate && moment() > moment(endDate);
};

module.exports = {
  LIFECYCLE_DISCONTINUED,
  isProductExpired,
  isProductDiscontinued,
};

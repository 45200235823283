import Category from "./category";

const EventType = Object.freeze({
  NotificationStockSubscribe: "notification-stock-subscribe",
  NotificationStockDeleteSubscription: "notification-stock-delete-subscription",
});

export const notificationSubscribeStockEvent = action => {
  const { analytics } = action.payload;

  // sanity
  if (analytics == null) {
    return null;
  }

  const { productId, productName, notificationType } = analytics;

  return {
    event: EventType.NotificationStockSubscribe,
    eventAction: `${productName} ${productId}`,
    eventCategory: Category.Notification,
    eventLabel: `Subscribe product: ${productName} ${productId} with notification type: ${notificationType}`,
    eventValue: 0,
  };
};

export const notificationDeleteStockSubscriptionEvent = action => {
  const { analytics } = action.payload;

  // sanity
  if (analytics == null) {
    return null;
  }

  const { productId, productName, notificationType } = analytics;

  return {
    event: EventType.NotificationStockDeleteSubscription,
    eventAction: `${productName} ${productId}`,
    eventCategory: Category.Notification,
    eventLabel: `Delete subscription of product: ${productName} ${productId} with notification type: ${notificationType}`,
    eventValue: 0,
  };
};

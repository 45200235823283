const objectMapper = require("object-mapper");

const NO_VALUE = "";
const supportedLanguages = ["english", "finnish", "swedish"];

const getLocalizedNames = values => {
  // get value by language
  const getName = (language, localizedNames) => {
    // find localized name
    for (let i = 0; i < localizedNames.length; i += 1) {
      const item = localizedNames[i];
      const itemLanguage = item.language;
      if (language.toLowerCase() === itemLanguage.toLowerCase()) {
        return item.marketingName || "";
      }
    }
    return "";
  };

  if (Array.isArray(values)) {
    return supportedLanguages.map(language => ({
      language,
      value: getName(language, values),
    }));
  }
  return [];
};

const getSortName = values => {
  // there needs to be one name for sorting
  const localizedNames = getLocalizedNames(values);
  const result = localizedNames.filter(
    localizedName => localizedName.value !== ""
  );
  if (result.length > 0) {
    return result[0].value;
  }
  return null;
};

const getManufacturer = value => {
  if (Array.isArray(value) && value.length > 0) {
    const manufacturer = value[0].manufacturerOfProduct;
    return manufacturer || NO_VALUE;
  }
  return NO_VALUE;
};

const valueOrEmpty = value => value || NO_VALUE;

const getContentAmount = value => {
  const getSingleValue = array => {
    if (Array.isArray(array) && array.length > 0) {
      return array[0].value;
    }
    return null;
  };

  // combine values by language
  const doGetContentAmount = (attributes, language) => {
    const grossContentAmountArr = attributes.filter(
      a =>
        a.attribute.toLowerCase() === "gross content amount" &&
        a.language.toLowerCase() === language
    );
    const grossContentUnitArr = attributes.filter(
      a =>
        a.attribute.toLowerCase() === "gross content unit" &&
        a.language.toLowerCase() === language
    );
    const grossContentFactorArr = attributes.filter(
      a =>
        a.attribute.toLowerCase() === "gross content factor" &&
        a.language.toLowerCase() === language
    );

    const grossContentAmount = getSingleValue(grossContentAmountArr);
    const grossContentUnit = getSingleValue(grossContentUnitArr);
    const grossContentFactor = getSingleValue(grossContentFactorArr);

    // there's valid value
    if (
      grossContentAmount != null ||
      grossContentUnit != null ||
      grossContentFactor != null
    ) {
      const strFactor =
        +grossContentFactor > 1 ? `${+grossContentFactor} x ` : "";
      return `${strFactor}${grossContentAmount || ""} ${
        grossContentUnit || ""
      }`;
    }
    return "";
  };

  if (Array.isArray(value) && value.length > 0) {
    // get all languages
    return supportedLanguages.map(language => ({
      language,
      value: doGetContentAmount(value, language),
    }));
  }
  return null;
};

const getMaterialGroup = valueArray => {
  const materialGroups = valueArray.filter(
    value => value.structure === "InitialPrimaryMaintenanceStructure"
  );
  if (materialGroups && materialGroups.length > 0) {
    return materialGroups[0].value || NO_VALUE;
  }
  return NO_VALUE;
};

const getMaterialGroupPrefix = valueArray => {
  const value = getMaterialGroup(valueArray);
  if (value !== NO_VALUE) {
    return value.substring(0, 3);
  }
  return NO_VALUE;
};

const productToRecord = {
  // use material id as a object id and material id
  materialId: [
    {
      key: "objectID",
      transform: value => value,
    },
    {
      key: "materialId",
      transform: value => value,
    },
  ],
  vnr: {
    key: "vnr",
    transform: valueOrEmpty,
  },
  msiCode: {
    key: "msiCode",
    transform: valueOrEmpty,
  },
  languageChannels: [
    {
      key: "localizedName",
      transform: getLocalizedNames,
    },
    {
      key: "sortName",
      transform: getSortName,
    },
  ],
  attributes: {
    key: "contentAmount",
    transform: getContentAmount,
  },
  purchases: { key: "manufacturer", transform: getManufacturer },
  mediaAssets: "mediaAssets",
  structureGroupAssignments: [
    {
      key: "materialGroup",
      transform: getMaterialGroup,
    },
    {
      key: "materialGroupPrefix",
      transform: getMaterialGroupPrefix,
    },
  ],
};
const STORED_ATTRIBUTES = ["storage temperature", "gross content free text"];
const productToCartProduct = {
  materialId: "materialId",
  vnr: "vnr",
  msiCode: "msiCode",
  languageChannels: [
    {
      key: "localizedName",
      transform: getLocalizedNames,
    },
  ],
  attributes: [
    {
      key: "contentAmount",
      transform: getContentAmount,
    },
    {
      key: "attributes",
      transform: value =>
        value
          ? value.filter(a =>
              STORED_ATTRIBUTES.includes(a.attribute.toLowerCase())
            )
          : null,
    },
  ],
  mediaAssets: {
    key: "thumbnailUrl",
    transform: value =>
      value
        ? value.find(
            asset => asset.type.toLowerCase() === "main item image thumbnail"
          )?.url
        : null,
  },
};

const recordToCartProduct = {
  materialId: "materialId",
  vnr: {
    key: "vnr",
    transform: value => (value === "n/a" ? "" : value),
  },
  msiCode: {
    key: "msiCode",
    transform: value => (value === "n/a" ? "" : value),
  },
  localizedName: "localizedName",
  contentAmount: {
    key: "contentAmount",
    transform: value => value,
  },
};

export const convertProductToRecord = product =>
  objectMapper(product, productToRecord);

export const convertProductToCartProduct = product =>
  objectMapper(product, productToCartProduct);

export const convertRecordToCartProduct = record =>
  objectMapper(record, recordToCartProduct);

export const convertProductOrRecordToCartProduct = item => {
  // is this record
  if (Array.isArray(item.localizedName)) {
    // Mapping record to cartProduct is now obsolete,
    // as products list records are converted to full products before mapping.
    // Leaving this logic here for now, but we might consider completely removing it later.
    return convertRecordToCartProduct(item);
  }
  return convertProductToCartProduct(item);
};

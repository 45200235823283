import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import {
  formatCurrency,
  formatVatRate,
} from "../../../utils/currency/currency";

const useStyles = makeStyles(theme => ({
  root: props => ({
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down(props.breakpointValue)]: {
      flexDirection: "column",
    },
  }),
  price: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("lg")]: {
      marginRight: theme.spacing(0.5),
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  vatPrice: props => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down(props.breakpointValue)]: {
      marginTop: 0,
    },
  }),
}));

function Vat({
  vatRate,
  vatPrice,
  currency,
  showPercent,
  className,
  breakpointValue,
}) {
  const classes = useStyles({ breakpointValue });
  const { t } = useTranslation();
  const vatStr = showPercent
    ? `${t("vat")} ${formatVatRate(vatRate)}`
    : `${t("vat")}`;

  return (
    <div className={classes.root}>
      <Typography variant="body2" className={`${classes.price} ${className}`}>
        {vatStr}
      </Typography>

      <Typography
        variant="body2"
        className={`${classes.vatPrice} ${className}`}
      >
        {formatCurrency(vatPrice, currency) || "-"}
      </Typography>
    </div>
  );
}

Vat.propTypes = {
  vatRate: PropTypes.string,
  vatPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
  className: PropTypes.string,
  showPercent: PropTypes.bool,
  breakpointValue: PropTypes.string,
};

Vat.defaultProps = {
  vatRate: "0",
  vatPrice: "0",
  currency: "EUR",
  className: "",
  showPercent: false,
  breakpointValue: "lg",
};

export default Vat;

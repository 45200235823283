const fixAdditionalInformation = (info, i18n) => {
  const { t, getFixedT } = i18n;
  let text = `${t("orderByDemand")}`;
  if (info) {
    const tEN = getFixedT("en");
    const tFI = getFixedT("fi");
    text = text.concat(
      `  ${info?.replace(tEN("orderByDemand"), "").replace(tFI("orderByDemand"), "")} `
    );
  }
  return text;
};

export const isOrderOnDemandProduct = ({ companies }) =>
  (companies && companies[0]?.deliveryBlockCode === "Z7") ?? false;

export const getAdditionalInformation = (availabilityInformation, i18n) =>
  isOrderOnDemandProduct(availabilityInformation ?? {})
    ? fixAdditionalInformation(availabilityInformation?.information, i18n)
    : availabilityInformation?.information;

export const shouldShowAvailabilityDateInformation =
  availabilityInformation => {
    const { hasPermanentInformation } = availabilityInformation ?? {};
    return (
      !hasPermanentInformation &&
      !isOrderOnDemandProduct(availabilityInformation ?? {})
    );
  };

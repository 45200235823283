import moment from "moment";
import i18n from "i18next";

export const dateFormatString = "DD.MM.YYYY";
const dateTimeFormat = "DD.MM.YYYY LT";
// TODO: Passing format as parameter should be useless as all API:s should use dateformat ISO8601.
// However currently product api uses different dateformat so to suppress warnings we pass in the format.

// Parameters are the date to be formatted and the format that the date is in currently (defaults to formats that moment accepts)
const isValidDate = (date, format) =>
  date !== undefined && moment(date, format).isValid();
export const getFormattedDate = (date, format) =>
  isValidDate(date, format)
    ? moment(date, format).format(dateFormatString)
    : i18n.t("noDate");

export const getFormattedDateTime = (date, format) =>
  isValidDate(date, format)
    ? moment(date, format).format(dateTimeFormat)
    : i18n.t("noDate");

const formatWeek = (date, label) =>
  isValidDate(date) ? `${label} ${moment(date).isoWeek()}` : "";
export const formatWeekShort = date =>
  formatWeek(date, i18n.t("weekShort").toLowerCase());

export const formatWeekLong = date =>
  formatWeek(date, i18n.t("week").toLowerCase());

export const getYear = date =>
  isValidDate(date) ? `${moment(date).isoWeekYear()}` : "";

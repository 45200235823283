import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import { Snowflake, Narcotics, Animal, Biohazard } from "../../../images";
// eslint-disable-next-line
import { TooltipTypography } from "../../generic";

const {
  ProductAttribute,
  isColdStorageProduct,
  isFreezerStorageProduct,
  getBooleanAttribute,
  isDisplayNarcoticsWarning,
} = ProductUtil;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  badge: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  badgeText: {
    ...theme.typography.button,
    textTransform: "none",
    color: theme.palette.primary,
    marginLeft: theme.spacing(0.5),
  },
}));

function SpecialBadges({ product }) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const lang = i18n.language;
  const coldStorage = isColdStorageProduct(product);
  const freezerStorage = isFreezerStorageProduct(product);
  const narcotics = isDisplayNarcoticsWarning(product);
  const isVeterinaryPharmaceutical = getBooleanAttribute(
    product,
    ProductAttribute.VETERINARY_PHARMACEUTICAL,
    lang
  );
  const biohazard = getBooleanAttribute(
    product,
    ProductAttribute.BIOHAZARD,
    lang
  );
  // eslint-disable-next-line
  function Badge({ icon, text, show }) {
    if (show === true) {
      return (
        <div className={classes.badge}>
          {icon}
          <TooltipTypography tooltip={text} className={classes.badgeText}>
            {text}
          </TooltipTypography>
        </div>
      );
    }
    return null;
  }

  return (
    <div className={classes.root}>
      <Badge icon={<Snowflake />} text={t("coldStorage")} show={coldStorage} />
      <Badge
        icon={<Snowflake />}
        text={t("freezerStorage")}
        show={freezerStorage}
      />
      <Badge icon={<Narcotics />} text={t("narcotics")} show={narcotics} />
      <Badge icon={<Biohazard />} text={t("biohazard")} show={biohazard} />
      <Badge
        icon={<Animal />}
        text={t("animalProduct")}
        show={isVeterinaryPharmaceutical}
      />
    </div>
  );
}

SpecialBadges.propTypes = {
  product: PropTypes.shape({}).isRequired,
  icon: PropTypes.shape({}),
};
SpecialBadges.defaultProps = {
  icon: null,
};

// extended main view with translate hoc
export default SpecialBadges;

/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch } from "react-redux";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Favorite, FavoriteBorder } from "../../../images";
// eslint-disable-next-line
import { CartAddButtons } from "../../shoppingCart";
import {
  ProductName,
  Stock,
  Marketer,
  UnitPrice,
  Thumbnail,
} from "./tableCells";
import { Can, Permission, ANY_CUSTOMER } from "../../auth";
import { updateProductRowCount } from "../../../redux/reducers/index";
import CampaignValid from "./campaignValid";

const { isProductEnteringMarket } = ProductUtil;

function ProductTableRow({
  product,
  favorite,
  linkTo,
  handleFavoriteClick,
  selectedCart,
  user,
  customer,
  stockStatus,
  availabilityInformation,
  priceData,
  classes,
  onAddToCart,
  fetchingStock,
  fetchingAvailability,
  fetchingPrice,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userId } = user;
  const [productCount, setProductCount] = useState(1);
  const handleProductCount = count => {
    setProductCount(count);
    dispatch(updateProductRowCount(product.materialId, count));
  };
  const productEnteringMarket = isProductEnteringMarket(product);
  const inCampaign = priceData && priceData.campaign;

  useEffect(() => {
    product.rowCount ? setProductCount(product.rowCount) : setProductCount(1);
  }, [product.rowCount]);

  return (
    <Link
      id="product-link"
      to={linkTo}
      className={`${classes.column} ${classes.productRow}`}
    >
      <div className={classes.columnImage}>
        <div className={classes.imageContainer}>
          {inCampaign && (
            <Typography variant="body2" className={classes.campaignLabel}>
              {t("campaign")}
            </Typography>
          )}
          <Thumbnail url={product.thumbnailUrl} title={product.title} />
        </div>
      </div>
      <div className={`${classes.column} ${classes.columnName}`}>
        <ProductName product={product} stockStatus={stockStatus} />
      </div>
      <div className={`${classes.column} ${classes.columnMarketer}`}>
        <Marketer product={product} stockStatus={stockStatus} />
      </div>
      <div className={`${classes.column} ${classes.columnAvailability}`}>
        <Stock
          product={product}
          stockStatus={stockStatus}
          fetchingStock={fetchingStock}
          availabilityInformation={availabilityInformation}
          fetchingAvailability={fetchingAvailability}
        />
        {inCampaign && <CampaignValid priceData={priceData} />}
      </div>
      <div className={`${classes.column} ${classes.columnUnitPrice}`}>
        <UnitPrice
          priceData={priceData}
          fetching={fetchingPrice}
          productCount={productCount}
        />
      </div>
      <div
        className={`${classes.column} ${classes.columnFavourite} ${classes.centeredColumnContent}`}
      >
        <IconButton
          id="favorite-btn"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            handleFavoriteClick(userId, product, !favorite);
          }}
        >
          {favorite ? <Favorite /> : <FavoriteBorder />}
        </IconButton>
      </div>
      <Can
        customerContext={ANY_CUSTOMER}
        user={user}
        perform={Permission.ORDER_CREATE}
        data={{ customer }}
      >
        <div
          className={`${classes.column} ${classes.columnAddCart} ${classes.centeredColumnContent}`}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {productEnteringMarket === false && (
            <CartAddButtons
              userId={userId}
              product={product}
              selectedCart={selectedCart}
              onAddToCart={onAddToCart}
              onCountChanged={count => handleProductCount(count)}
              count={productCount}
              useListViewSpacing
            />
          )}
        </div>
      </Can>
    </Link>
  );
}

ProductTableRow.propTypes = {
  product: PropTypes.shape({
    materialId: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    title: PropTypes.string,
    rowCount: PropTypes.number,
  }).isRequired,
  linkTo: PropTypes.string,
  handleFavoriteClick: PropTypes.func,
  onAddToCart: PropTypes.func,
  favorite: PropTypes.bool,
  selectedCart: PropTypes.shape({}),
  user: PropTypes.shape({
    userId: PropTypes.string,
  }),
  customer: PropTypes.shape({
    customerId: PropTypes.string,
  }),
  stockStatus: PropTypes.shape({
    stockStatus: PropTypes.string,
    fetching: PropTypes.bool,
  }),
  availabilityInformation: PropTypes.shape({
    availabilityInformation: PropTypes.string,
    fetching: PropTypes.bool,
  }),
  priceData: PropTypes.shape({
    priceData: PropTypes.string,
    fetching: PropTypes.bool,
    campaign: PropTypes.shape({
      valid: PropTypes.bool,
      validFrom: PropTypes.string,
      validTo: PropTypes.string,
    }),
  }),
  classes: PropTypes.shape({
    column: PropTypes.string,
    columnImage: PropTypes.string,
    columnName: PropTypes.string,
    columnMarketer: PropTypes.string,
    columnAvailability: PropTypes.string,
    columnUnitPrice: PropTypes.string,
    columnFavourite: PropTypes.string,
    columnAddCart: PropTypes.string,
    centeredColumnContent: PropTypes.string,
    productRow: PropTypes.string,
    imageContainer: PropTypes.string,
    campaignLabel: PropTypes.string,
  }).isRequired,
  fetchingStock: PropTypes.bool,
  fetchingAvailability: PropTypes.bool,
  fetchingPrice: PropTypes.bool,
};

ProductTableRow.defaultProps = {
  linkTo: "",
  handleFavoriteClick: () => {}, // eslint-disable-line
  onAddToCart: () => {}, // eslint-disable-line
  favorite: false,
  selectedCart: {},
  user: {},
  customer: {},
  stockStatus: {},
  availabilityInformation: {},
  priceData: {},
  fetchingStock: false,
  fetchingAvailability: false,
  fetchingPrice: false,
};

export default ProductTableRow;

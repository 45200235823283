import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
// eslint-disable-next-line
import ProductTableRow from "./productTableRow";
import { InfinityList, NotificationPopup } from "../../generic";
import Paths from "../../../utils/navigation/navigation";
import {
  setFavoriteProduct,
  notificationShow,
  notificationHide,
} from "../../../redux/reducers";
import { Can, Permission, ANY_CUSTOMER } from "../../auth";
import colors from "../../../theme/colors";
import { ShoppingCartRound } from "../../../images";
import { findCustomerById } from "../../../utils/customer/customer";

const { getLocalizedProductName } = ProductUtil;

export const PRODUCTS_ON_PAGE = 15;
export const FIXED_ROW_HEIGHT = 100;
export const ROW_SPACING = 1;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    minWidth: 750,
  },
  noProductsContainer: {
    height: "50vh",
    minHeight: "250px",
    textAlign: "center",
    marginTop: theme.spacing(8),
  },
  headerWrapper: {
    marginTop: theme.spacing(4),
  },
  // table
  thumbnail: {
    width: "20px",
    height: "20px",
  },
  tableHeaderText: {
    textTransform: "none",
    color: theme.palette.text.disabled,
    width: "100%",
    fontSize: "0.85rem",
  },
  headerRow: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderBottom: `solid 1px ${colors.backgroundDarkGray}`,
    paddingTop: "0.4rem",
    paddingBottom: "0.4rem",
  },
  productRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderBottom: `solid 1px ${colors.backgroundDarkGray}`,
    height: FIXED_ROW_HEIGHT,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    position: "relative",
  },
  column: {
    overflow: "hidden",
    height: FIXED_ROW_HEIGHT - theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  headerLabel: {
    overflow: "hidden",
    paddingBottom: 0,
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  columnImage: {
    flex: "1 0 5%",
  },
  columnName: {
    flex: "1 0 28%",
  },
  columnAvailability: {
    flex: "1 0 12%",
    marginRight: theme.spacing(2),
  },
  columnUnitPrice: {
    flex: "1 0 13.5%",
  },
  columnMarketer: {
    flex: "1 0 12%",
    marginRight: theme.spacing(2),
  },
  columnFavourite: {
    flex: "1 0 4%",
    textAlign: "center",
    padding: 0,
  },
  columnAmount: {
    flex: "1 0 10%",
  },
  columnAddCart: {
    flex: "1 0 15%",
    marginRight: theme.spacing(1),
  },
  addCartAmountHeader: {
    display: "flex",
    flexDirection: "row",
  },
  amountHeader: {
    textAlign: "center",
    flex: "0 0 60%",
  },
  addCartHeader: {
    flex: "0 0 40%",
    textAlign: "center",
  },
  centeredColumnContent: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
    height: "auto",
  },
  campaignLabel: {
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    background: "rgba(255, 191, 85, 0.85);",
    padding: theme.spacing(0.5),
    borderRadius: "8px",
    position: "absolute",
    top: "4px",
    left: 0,
    fontWeight: "bold",
  },
}));

function ProductTable({
  user,
  customers,
  products,
  productPricesMap,
  productStockStatusesMap,
  productAvailabilityStatusesMap,
  favoriteProductIds,
  overallProductCount,
  selectedCart,
  onRequestProducts,
  fetchingProduct,
  priceFetchIdMap,
  stockStatusFetchIdMap,
  availabilityStatusFetchIdMap,
  searchText,
  selectedCustomerId,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const customer = findCustomerById(selectedCustomerId, customers);
  // click handler for favorite
  const handleFavoriteClick = (userId, product, favorite) => {
    dispatch(setFavoriteProduct(userId, product, favorite));
  };

  const renderNotificationPopup = () => (
    <NotificationPopup
      icon={<ShoppingCartRound />}
      onClose={() => dispatch(notificationHide())}
    />
  );

  const headerRenderer = () => (
    <div className={classes.headerRow}>
      <div className={classes.columnImage} />
      <div className={`${classes.headerLabel} ${classes.columnName}`}>
        <Typography className={classes.tableHeaderText}>
          {t("productName")}
        </Typography>
      </div>
      <div className={`${classes.headerLabel} ${classes.columnMarketer}`}>
        <Typography className={classes.tableHeaderText}>
          {t("marketerVnrMsi")}
        </Typography>
      </div>
      <div className={`${classes.headerLabel} ${classes.columnAvailability}`}>
        <Typography className={classes.tableHeaderText}>
          {t("availability")}
        </Typography>
      </div>
      <div className={`${classes.headerLabel} ${classes.columnUnitPrice}`}>
        <Typography className={classes.tableHeaderText}>
          {t("price")}
        </Typography>
      </div>
      <div className={`${classes.headerLabel} ${classes.columnFavourite}`}>
        <Typography className={classes.tableHeaderText}>
          {t("favorite")}
        </Typography>
      </div>
      <Can
        customerContext={ANY_CUSTOMER}
        user={user}
        perform={Permission.ORDER_CREATE}
        data={{ customer }}
      >
        <div className={`${classes.headerLabel} ${classes.columnAddCart}`}>
          <div className={classes.addCartAmountHeader}>
            <Typography
              className={`${classes.amountHeader} ${classes.tableHeaderText}`}
            >
              {t("amount")}
            </Typography>
            <Typography
              className={`${classes.addCartHeader} ${classes.tableHeaderText}`}
            >
              {t("addToCart")}
            </Typography>
          </div>
        </div>
      </Can>
    </div>
  );

  // https://github.com/bvaughn/react-virtualized/blob/master/docs/List.md#rowrenderer
  const rowRenderer = ({ key, style, index }) => {
    const product = products[index];
    return (
      <div key={key} style={style}>
        <ProductTableRow
          product={product}
          favorite={favoriteProductIds.includes(product.materialId)}
          linkTo={`${Paths.Products}/${product.materialId}?organizationId=${selectedCustomerId}`}
          handleFavoriteClick={handleFavoriteClick}
          selectedCart={selectedCart}
          user={user}
          customer={customer}
          stockStatus={productStockStatusesMap.get(product.materialId)}
          availabilityInformation={productAvailabilityStatusesMap.get(
            product.materialId
          )}
          priceData={productPricesMap.get(product.materialId)}
          classes={classes}
          // eslint-disable-next-line
          onAddToCart={(cart, product) => {
            dispatch(
              notificationShow(
                t("productHasBeenAdded"),
                getLocalizedProductName(product, i18n.language),
                `${t("toCart").toLowerCase()} ${cart.name}`,
                3000
              )
            );
          }}
          fetchingStock={stockStatusFetchIdMap.get(product.materialId) === true}
          fetchingAvailability={
            availabilityStatusFetchIdMap.get(product.materialId) === true
          }
          fetchingPrice={priceFetchIdMap.get(product.materialId) === true}
        />
      </div>
    );
  };

  const renderEmptyList = () => (
    <div className={classes.noProductsContainer}>
      <Typography variant="body1" color="textPrimary">
        {t("noSearchResultsFound")}
      </Typography>
    </div>
  );

  return (
    <div className={classes.root} id="product-table">
      {headerRenderer()}
      {searchText &&
      searchText.length !== 0 &&
      products.length === 0 &&
      !fetchingProduct ? (
        renderEmptyList()
      ) : (
        <InfinityList
          items={products}
          visibleItemCount={PRODUCTS_ON_PAGE}
          itemOverallCount={overallProductCount}
          rowHeight={FIXED_ROW_HEIGHT}
          rowSpacing={ROW_SPACING}
          isLoading={fetchingProduct}
          onRenderRow={rowRenderer}
          onLoad={onRequestProducts}
        />
      )}
      {renderNotificationPopup()}
    </div>
  );
}

ProductTable.propTypes = {
  user: PropTypes.shape({}),
  customers: PropTypes.instanceOf(Array),
  products: PropTypes.instanceOf(Array),
  productPricesMap: PropTypes.shape({
    get: PropTypes.func,
  }),
  productStockStatusesMap: PropTypes.shape({
    get: PropTypes.func,
  }),
  productAvailabilityStatusesMap: PropTypes.shape({
    get: PropTypes.func,
  }),
  favoriteProductIds: PropTypes.instanceOf(Array),
  overallProductCount: PropTypes.number,
  selectedCart: PropTypes.shape({}),
  onRequestProducts: PropTypes.func,
  fetchingProduct: PropTypes.bool,
  priceFetchIdMap: PropTypes.shape({
    get: PropTypes.func,
  }),
  stockStatusFetchIdMap: PropTypes.shape({
    get: PropTypes.func,
  }),
  availabilityStatusFetchIdMap: PropTypes.shape({
    get: PropTypes.func,
  }),
  searchText: PropTypes.string,
  selectedCustomerId: PropTypes.string,
};

ProductTable.defaultProps = {
  user: null,
  customers: [],
  products: [],
  productPricesMap: new Map(),
  productStockStatusesMap: new Map(),
  productAvailabilityStatusesMap: new Map(),
  favoriteProductIds: [],
  overallProductCount: 0,
  selectedCart: null,
  onRequestProducts: () => {}, // eslint-disable-line
  fetchingProduct: false,
  priceFetchIdMap: new Map(),
  stockStatusFetchIdMap: new Map(),
  availabilityStatusFetchIdMap: new Map(),
  searchText: "",
  selectedCustomerId: "",
};

export default withRouter(ProductTable);

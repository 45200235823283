import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  tabContainer: {
    marginRight: theme.spacing(3),
  },
  tabText: {
    color: theme.palette.text.disabled,
    "&:hover": {
      backgroundColor: "transparent",
    },
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "1.07rem",
    textTransform: "uppercase",
    borderBottomWidth: "2px",
    borderBottomStyle: "solid",
    borderBottomColor: "transparent",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: 0,
    paddingRight: 0,
  },
  tabSelected: {
    color: theme.palette.text.primary,
    borderBottomColor: theme.palette.primary.main,
  },
  badgeContainer: {
    paddingRight: theme.spacing(1.7),
  },
  badge: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "0.75rem",
  },
}));

function TabBar({ tabs, value, onValueChanged }) {
  const classes = useStyles();

  const renderTab = (tab, selected) => {
    const className = selected ? classes.tabSelected : null;
    return (
      <div className={classes.tabContainer}>
        <Badge
          className={classes.badgeContainer}
          classes={{ badge: classes.badge }}
          badgeContent={tab.badgeValue}
          color="primary"
        >
          <Button
            className={`${classes.tabText} ${className}`}
            disableRipple
            onClick={() => onValueChanged(tab.value)}
            component={Link}
            to={tab.value}
          >
            {tab.label}
          </Button>
        </Badge>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {tabs.map(tab => (
        <div key={tab.value}>{renderTab(tab, tab.value === value)}</div>
      ))}
    </div>
  );
}

TabBar.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({})),
  value: PropTypes.string,
  onValueChanged: PropTypes.func,
};

TabBar.defaultProps = {
  tabs: [],
  value: null,
  onValueChanged: () => {}, // eslint-disable-line
};

export default TabBar;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PopupLayout from "../popupLayout/popupLayout";

const useStyles = makeStyles(theme => ({
  description: {
    color: theme.palette.text.disabled,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(7),
    fontWeight: "bold",
  },
  title: {
    fontWeight: "bold",
    marginTop: theme.spacing(1),
  },
}));

function ErrorPopup({
  title,
  description,
  show,
  top,
  left,
  bottom,
  right,
  onOk,
  onCancel,
  popupRef,
  saveKey,
  disableSave,
  hideOkButton,
  cancelKey,
}) {
  const classes = useStyles();

  return (
    <PopupLayout
      show={show}
      left={left}
      top={top}
      right={right}
      bottom={bottom}
      onOk={onOk}
      onCancel={onCancel}
      popupRef={popupRef}
      saveKey={saveKey}
      disableSave={disableSave}
      hideOkButton={hideOkButton}
      cancelKey={cancelKey}
    >
      <Typography className={classes.title} variant="h6" color="textPrimary">
        {title}
      </Typography>
      <Typography
        component="div"
        className={`${classes.description} ${classes.bold}`}
        variant="body1"
      >
        {description}
      </Typography>
    </PopupLayout>
  );
}

ErrorPopup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.element,
  ]),
  top: PropTypes.number,
  left: PropTypes.number,
  bottom: PropTypes.number,
  right: PropTypes.number,
  show: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  popupRef: PropTypes.func,
  saveKey: PropTypes.string,
  hideOkButton: PropTypes.bool,
  cancelKey: PropTypes.string,
  disableSave: PropTypes.bool,
};

ErrorPopup.defaultProps = {
  title: "",
  description: "",
  top: null,
  left: null,
  bottom: null,
  right: null,
  show: false,
  onOk: null,
  onCancel: null,
  popupRef: null,
  saveKey: "Save",
  hideOkButton: false,
  cancelKey: "cancel",
  disableSave: false,
};

export default ErrorPopup;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { fetchVersions } from "../../redux/reducers";
import packageJson from "../../../package.json";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(8),
  },
  table: {
    width: "50%",
  },
  cell: {
    fontSize: "1.2rem",
  },
  header: {
    marginBottom: "1rem",
  },
}));

function Versions() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { versions } = useSelector(state => state.versions);
  useEffect(() => {
    dispatch(fetchVersions());
  }, [dispatch]);
  return (
    <div className={classes.root}>
      <Typography className={classes.header} variant="h4">
        Versions
      </Typography>
      <Table className={classes.table} aria-label="versions table">
        <TableBody>
          <TableRow>
            <TableCell className={classes.cell} align="right">
              origo-ecom-client
            </TableCell>
            <TableCell className={classes.cell}>
              {packageJson.version}
            </TableCell>
          </TableRow>
          {Object.values(versions).map((row, idx) => (
            <TableRow key={row.version}>
              <TableCell className={classes.cell} align="right">
                {Object.keys(versions)[idx]}
              </TableCell>
              <TableCell className={classes.cell}>{row.version}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default Versions;

import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { withRouter } from "react-router-dom";
// eslint-disable-next-line
import { Stock } from "../../../productList";
import Paths from "../../../../utils/navigation/navigation";
import {
  backorderPopupShow,
  backorderDeletePopupShow,
} from "../../../../redux/reducers";
import { Trashcan, Edit } from "../../../../images";
import { getBackorderQuantity } from "../../../../utils/orderUtil/orderUtil";
import { Can, Permission, ANY_CUSTOMER } from "../../../auth";
import { findCustomerById } from "../../../../utils/customer/customer";
// eslint-disable-next-line
import { TextLink } from "../../../generic";

const useStyles = makeStyles(theme => ({
  inStock: {
    color: "green",
  },
  outOfStock: {
    color: "red",
  },
  textColorGrey: {
    color: theme.palette.text.disabled,
    paddingTop: "0.2rem",
  },
  modifyBackorder: {
    paddingTop: 2,
  },
  productNameLink: {
    fontSize: theme.typography.body1.fontSize,
    color: "green",
  },
}));

// Customers are able to place orders through their own ERP systems.
// Unfortunately, they can add products that are not in Oriola's distribution and the entire order goes into error in SAP.
// In order to process the order in SAP, customer service replaces this invalid product with dummy product with certain id.
// This dummy product cannot be fetched from DB (returns 404) and to avoid errors, we need to disable the link to the product card.
const isDummyProduct = productId => productId === "10043145";

export const OrderProductName = withRouter(
  ({ product, stockStatus, isBackOrder, order, productViewAllowed }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    // sanity
    if (!order) {
      return null;
    }

    const name = order.productName;
    const productNumber = order.productId || product.materialId;
    // due to invalid data sent by SAP, some old order lines may be missing product number
    const productNumberWithLabel = `${t("productNo")}: ${productNumber || "-"}`;
    const vnr = order.vnr || product.vnr;
    const msi = order.msi || product.msiCode;

    let vnrMsi;
    // In case of "dummy product", provide the explanation for disabling hyperlink
    if (isDummyProduct(productNumber)) {
      vnrMsi = `${t("productDoesNotExist")} | ${productNumberWithLabel}`;
    } else if (vnr) {
      vnrMsi = `${t("vnr")}: ${vnr} | ${productNumberWithLabel}`;
    } else if (msi) {
      vnrMsi = `${t("msi")}: ${msi} | ${productNumberWithLabel}`;
    } else {
      vnrMsi = productNumberWithLabel;
    }
    // TODO: this should be delivery date not expiration date, data still missing from SAP
    // let expirationDate = stockStatus.expirationDate;
    // const expiration = `${t('expirationDate')}: ${getFormattedDate(expirationDate)}`;

    const linkToProductCardDisabled =
      productViewAllowed === false ||
      !productNumber ||
      isDummyProduct(productNumber);
    return (
      <div>
        <TextLink
          to={`${Paths.Products}/${productNumber}`}
          text={name}
          variant="body1"
          disabled={linkToProductCardDisabled}
        />
        <Typography className={classes.textColorGrey} variant="body2">
          {vnrMsi}
        </Typography>

        {/*! isBackOrder && (
        <Typography className={classes.textColorGrey} variant="body2">
          {expiration}
        </Typography>
      ) */}
        {isBackOrder && productViewAllowed && !linkToProductCardDisabled && (
          <Stock stockStatus={stockStatus} isBackOrder />
        )}
      </div>
    );
  }
);

export function DeleteBackOrder({ order, orderline, classes }) {
  const dispatch = useDispatch();
  const handleDelete = () =>
    dispatch(backorderDeletePopupShow(order, orderline));
  const userData = useSelector(state => state.user.userData);
  const { customers, selectedCustomerId } = useSelector(
    state => state.customer
  );
  const customer = findCustomerById(order.soldTo, customers);
  const selectedCustomer = findCustomerById(selectedCustomerId, customers);
  return (
    <Can
      user={userData}
      customerContext={ANY_CUSTOMER}
      perform={Permission.ORDER_MODIFY_BACKORDER}
      data={{ customer, selectedCustomer }}
    >
      <div>
        <IconButton className={classes.deleteBackorder} onClick={handleDelete}>
          <Trashcan />
        </IconButton>
      </div>
    </Can>
  );
}

export function ModifyBackorder({ order, orderline }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleModify = () =>
    dispatch(
      backorderPopupShow(order, orderline, getBackorderQuantity(orderline))
    );
  const userData = useSelector(state => state.user.userData);
  const { customers, selectedCustomerId } = useSelector(
    state => state.customer
  );
  const customer = findCustomerById(order.soldTo, customers);
  const selectedCustomer = findCustomerById(selectedCustomerId, customers);
  return (
    <Can
      user={userData}
      customerContext={ANY_CUSTOMER}
      perform={Permission.ORDER_MODIFY_BACKORDER}
      data={{ customer, selectedCustomer }}
    >
      <div>
        <IconButton className={classes.modifyBackorder} onClick={handleModify}>
          <Edit />
        </IconButton>
      </div>
    </Can>
  );
}

ModifyBackorder.propTypes = {
  order: PropTypes.shape({
    soldTo: PropTypes.string,
  }),
  orderline: PropTypes.shape({}),
  classes: PropTypes.shape({
    modifyBackorder: PropTypes.string,
  }),
};

ModifyBackorder.defaultProps = {
  order: {},
  orderline: {},
  classes: {},
};
DeleteBackOrder.propTypes = {
  order: PropTypes.shape({
    soldTo: PropTypes.string,
  }),
  orderline: PropTypes.shape({}),
  classes: PropTypes.shape({
    deleteBackorder: PropTypes.string,
  }),
};

DeleteBackOrder.defaultProps = {
  order: {},
  orderline: {},
  classes: {},
};

OrderProductName.propTypes = {
  product: PropTypes.shape({}),
  stockStatus: PropTypes.shape({}),
  showMsi: PropTypes.bool,
  isBackOrder: PropTypes.bool,
  history: PropTypes.shape({}),
  productViewAllowed: PropTypes.bool,
};

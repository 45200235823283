export const Analytics = Object.freeze({
  SELECT_SHOPPING_CART: "ANALYTICS_SELECT_SHOPPING_CART",
});

export const analyticsSelectShoppingCart = cart => ({
  type: Analytics.SELECT_SHOPPING_CART,
  payload: {
    // for consistency use the analytics object
    analytics: {
      name: cart.name,
    },
  },
});

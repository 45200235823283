/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useSelector, useDispatch } from "react-redux";
import Hidden from "@material-ui/core/Hidden";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import {
  templateSetName,
  templateSetNote,
  templateDelete,
  templateSetCustomerAndShippingAddress,
  templateSetProductCount,
  templateRemoveProduct,
  fetchAvailabilityStatuses,
  fetchStockStatuses,
  fetchProductPrices,
  cartAddFromTemplate,
} from "../../../redux/reducers";
import { getDefaultCurrencySymbol } from "../../../utils/currency/currency";
import { CartProductTable } from "../../shoppingCart";
import { PRODUCTS_ON_PAGE } from "../../shoppingCart/cartProductTable/cartProductTable";
import { calculateCartProductOverallPrice } from "../../../utils/price/price";
import {
  CustomerAndShippingAddressSelect,
  ConfirmationPopup,
  MemoInput,
  Progress,
} from "../../generic";
import {
  findCustomerById,
  findShippingAddressById,
  removeInvalidCustomersAndAddresses,
} from "../../../utils/customer/customer";
import Paths from "../../../utils/navigation/navigation";
import CartAddPopup from "../../shoppingCart/cartAddPopup/cartAddPopup";
import ColdProductNotification from "../../shoppingCart/coldProductNotification/coldProductNotification";
import { isInStock } from "../../../utils/stockUtil/stockUtil";
import colors from "../../../theme/colors";

const { getLocalizedProductName } = ProductUtil;

const useStyles = makeStyles(theme => ({
  products: {
    width: "100%",
  },
  productsHeader: {
    display: "flex",
    alignItems: "flex-end",
    paddingTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  headerText: {
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  memoContainer: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  cartText: {
    color: theme.palette.text.disabled,
  },
  productsContainer: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  productsTable: {
    marginTop: theme.spacing(2),
  },
  bottomControlsContainer: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  customerAndAddressContainer: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
  customerSelect: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: theme.spacing(0.5),
    },
  },
  buttonsContainer: {
    paddingBottom: theme.spacing(4),
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
  },
  button: {
    textTransform: "none",
    backgroundColor: colors.backgroundGray,
  },
  orderButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(8),
  },
  progress: {
    marginLeft: theme.spacing(2),
  },
  error: {
    marginTop: theme.spacing(1),
  },
}));

function OrderTemplate({
  selectedTemplate,
  setSelectedTemplate,
  history,
  handleAddToCart,
}) {
  const classes = useStyles();

  const { productPricesMap, priceFetchIdMap } = useSelector(
    state => state.product
  );
  const { productStockStatusesMap, stockStatusFetchIdMap } = useSelector(
    state => state.stock
  );
  const { productAvailabilityStatusesMap, availabilityStatusFetchIdMap } =
    useSelector(state => state.availability);
  const { userId } = useSelector(state => state.user.userData);
  const customers = useSelector(state => state.customer.customers);
  const { sendingTemplate, templateSendError, orderTemplates } = useSelector(
    state => state.template
  );
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const currencySymbol = getDefaultCurrencySymbol();
  const [validCustomers, setValidCustomers] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [popupPos, setPopupPos] = useState({ top: 0, left: 0 });
  const [popupHeight, setPopupHeight] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState(undefined);
  const [selectedShippingAddress, setSelectedShippingAddress] =
    useState(undefined);
  const [noteValue, setNoteValue] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [showCartPopup, setShowCartPopup] = useState(false);

  // calculate overall prices
  const overallPrices = selectedTemplate
    ? calculateCartProductOverallPrice(
        selectedTemplate.products,
        productPricesMap
      )
    : { price: 0, priceVat: 0 };

  let deleteButtonRef;
  let saveButtonRef;
  // eslint-disable-next-line
  const setDeleteButtonRef = ref => (deleteButtonRef = ref);
  // eslint-disable-next-line
  const setSaveButtonRef = ref => (saveButtonRef = ref);

  useEffect(() => {
    // scroll always to top
    window.scrollTo(0, 0);
  }, []);

  const loadAdditionalProductData = (customerId, products) => {
    if (Array.isArray(products)) {
      // get product ids
      const ids = products.map(cartProduct => cartProduct.product.materialId);
      if (ids.length) {
        // fetch stock statuses
        dispatch(fetchStockStatuses(customerId, ids)).then(stockData => {
          // fetch availability information for products out of stock
          const productsOutOfStock = [];
          stockData.forEach(stockStatus => {
            if (!isInStock(stockStatus)) {
              productsOutOfStock.push(stockStatus.materialId);
            }
          });
          if (productsOutOfStock.length > 0) {
            dispatch(fetchAvailabilityStatuses(customerId, ids));
          }
        });
      }
    }
  };

  // Initialize
  useEffect(() => {
    if (selectedTemplate) {
      // eslint-disable-next-line
      const validCustomers = removeInvalidCustomersAndAddresses(customers);
      setValidCustomers(validCustomers);

      // set note
      setNoteValue(selectedTemplate.note);

      // set name
      setTemplateName(selectedTemplate.name);

      // Sort products by name
      selectedTemplate.products.sort((a, b) =>
        getLocalizedProductName(a.product, i18n.language)?.localeCompare(
          getLocalizedProductName(b.product, i18n.language)
        )
      );

      // check customer and shipping address and populate
      const customerId = selectedTemplate.customer;

      if (customerId) {
        const customer = findCustomerById(customerId, validCustomers);
        const shippingAddressId = selectedTemplate.shipToAddress;
        const shippingAddress = findShippingAddressById(
          shippingAddressId,
          customer
        );
        setSelectedCustomer(customer);
        setSelectedShippingAddress(shippingAddress);
      } else {
        setSelectedCustomer(null);
        setSelectedShippingAddress(null);
      }

      const ids = selectedTemplate.products.map(
        cartProduct => cartProduct.product.materialId
      );
      if (ids.length) {
        dispatch(fetchProductPrices(customerId, ids));
      }

      // fetch initial products data (double the size)
      const startIndex = 0;
      const stopIndex = Math.min(
        PRODUCTS_ON_PAGE * 2 - 1,
        selectedTemplate.products.length
      );
      const displayedProducts = selectedTemplate.products.slice(
        startIndex,
        stopIndex + 1
      );
      loadAdditionalProductData(customerId, displayedProducts);
    }
  }, [selectedTemplate, customers, dispatch, setValidCustomers]); // eslint-disable-line

  if (!selectedTemplate) {
    return null;
  }

  const renderHeader = template => {
    const products = template ? template.products : [];
    const name = template ? template.name : "";

    const countPriceStr = `${products.length} ${t(
      "orderLines"
    ).toLowerCase()}, ${overallPrices.price} ${currencySymbol}`;

    return (
      <div className={classes.productsHeader}>
        <Typography
          variant="h4"
          color="textPrimary"
          className={classes.headerText}
          noWrap
        >
          {name}
        </Typography>
        <Hidden smDown>
          <Typography
            variant="h6"
            color="textPrimary"
            className={classes.headerText}
            noWrap
          >
            {countPriceStr}
          </Typography>
        </Hidden>
        <Progress
          className={classes.progress}
          show={sendingTemplate}
          size={20}
        />
      </div>
    );
  };

  const renderNameControl = () => {
    const title = t("orderTemplateName");
    const placeholder = t("writeOrderTemplateName");
    const info = null;
    return (
      <div className={classes.memoContainer}>
        <MemoInput
          title={title}
          placeholder={placeholder}
          info={info}
          value={templateName || ""}
          onChange={event => setTemplateName(event.target.value)}
          onBlur={event =>
            dispatch(
              templateSetName(
                userId,
                selectedTemplate.templateId,
                event.target.value || t("unnamedTemplate")
              )
            )
          }
          maxLength={30}
        />
      </div>
    );
  };

  const renderMemoControl = () => {
    const title = `${t("yourMemo")} (${t("optional").toLowerCase()})`;
    const placeholder = t("writeMemo");
    const info = t("thisVisibleOnlyYou");
    return (
      <div className={classes.memoContainer}>
        <MemoInput
          title={title}
          placeholder={placeholder}
          info={info}
          value={noteValue || ""}
          onChange={event => setNoteValue(event.target.value)}
          onBlur={event =>
            dispatch(
              templateSetNote(
                userId,
                selectedTemplate.templateId,
                event.target.value
              )
            )
          }
          maxLength={250}
        />
      </div>
    );
  };
  // eslint-disable-next-line
  const renderProducts = (userId, template, productPricesMap) => {
    if (template == null) {
      return null;
    }

    const products = template.products || [];

    return (
      <div className={classes.productsContainer}>
        <Typography className={classes.cartText} variant="subtitle2" noWrap>
          {t("cart")}
        </Typography>
        <div className={classes.productsTable}>
          <CartProductTable
            products={products}
            productsCount={products.length}
            productPricesMap={productPricesMap}
            productStockStatusesMap={productStockStatusesMap}
            productAvailabilityStatusesMap={productAvailabilityStatusesMap}
            priceFetchIdMap={priceFetchIdMap}
            stockStatusFetchIdMap={stockStatusFetchIdMap}
            availabilityStatusFetchIdMap={availabilityStatusFetchIdMap}
            onChangeProductCount={(product, value) =>
              dispatch(
                templateSetProductCount(
                  userId,
                  template.templateId,
                  product,
                  value
                )
              )
            }
            onProductRemoved={productId =>
              dispatch(
                templateRemoveProduct(userId, template.templateId, productId)
              )
            }
          />
        </div>
      </div>
    );
  };

  const renderDeleteOrderTemplatePopup = () => {
    const onDelete = () => {
      const templateCount = orderTemplates.length;
      dispatch(templateDelete(userId, selectedTemplate)).then(response => {
        // success?
        if (response) {
          // if template count was 1 and delete succeeded, there's no more
          if (templateCount <= 1) {
            // fallback to products page, yes this was wanted on origo-451
            history.push(Paths.Products);
          } else {
            // clear for next
            setSelectedTemplate(null);
          }
        }
      });
      setShowDeletePopup(false);
    };

    const title = t("deleteTemplate");
    const description = `${t("deleteDescription")} ${selectedTemplate.name}`;
    return (
      <ConfirmationPopup
        title={title}
        description={description}
        show={showDeletePopup}
        top={popupPos.top - popupHeight}
        left={popupPos.left}
        onOk={() => onDelete()}
        onCancel={() => setShowDeletePopup(false)}
        saveKey="delete"
        popupRef={ref => {
          if (ref) {
            const rect = ref.getBoundingClientRect();
            if (popupHeight !== rect.height) {
              setPopupHeight(rect.height);
            }
          }
        }}
        cart={selectedTemplate}
      />
    );
  };

  const renderCustomerAndShippingAddressSelection = () => (
    <div className={classes.customerAndAddressContainer}>
      <CustomerAndShippingAddressSelect
        customers={validCustomers}
        className={classes.customerSelect}
        value={{
          customer: selectedCustomer,
          shippingAddress: selectedShippingAddress,
        }}
        onSelect={(customer, shippingAddress) => {
          setSelectedCustomer(customer);
          setSelectedShippingAddress(shippingAddress);

          const customerId =
            customer && customer.customerId ? customer.customerId : null;

          // update
          dispatch(
            templateSetCustomerAndShippingAddress(
              userId,
              selectedTemplate.templateId,
              customerId,
              (shippingAddress && shippingAddress.ShipTo) || null
            )
          );
        }}
      />
    </div>
  );

  const onAddToCartButton = () => {
    const rect = saveButtonRef.getBoundingClientRect();
    setPopupPos({ top: rect.top, left: rect.left });
    // Always show cart popup when adding from order template
    setShowCartPopup(true);
  };

  const renderBottomButtons = () => {
    const handlePopUpPos = () => {
      const rect = deleteButtonRef.getBoundingClientRect();
      setPopupPos({ top: rect.top, left: rect.left });
    };
    // eslint-disable-next-line
    const showDeletePopup = () => {
      handlePopUpPos();
      setShowDeletePopup(true);
    };

    return (
      <div className={classes.buttonsContainer}>
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            onClick={() => showDeletePopup()}
            ref={setDeleteButtonRef}
          >
            {t("delete")}
          </Button>
          <Button
            className={`${classes.button} ${classes.orderButton}`}
            onClick={onAddToCartButton}
            ref={setSaveButtonRef}
          >
            {t("addToCart")}
          </Button>
        </div>
        {templateSendError && (
          <Typography className={classes.error} color="error">
            {`${t("networkError")} ${templateSendError.status}`}
          </Typography>
        )}
      </div>
    );
  };

  const renderBottomControls = () => (
    <div className={classes.bottomControlsContainer}>
      {renderCustomerAndShippingAddressSelection()}
      {renderBottomButtons()}
    </div>
  );

  const onSave = (cart, isNewCart) => {
    dispatch(
      cartAddFromTemplate(userId, selectedTemplate, cart, isNewCart)
    ).then(response => {
      // On success navigate to cart
      if (response) {
        handleAddToCart(cart);
      } else {
        // hide
        setShowCartPopup(false);
      }
    });
  };

  // eslint-disable-next-line
  const renderColdStorageNotification = selectedTemplate => (
    <div className={classes.contentContainer}>
      <ColdProductNotification
        // eslint-disable-next-line
        products={selectedTemplate ? selectedTemplate.products : []}
      />
    </div>
  );

  return (
    <Paper className={classes.products}>
      {renderHeader(selectedTemplate)}
      {renderNameControl()}
      {renderMemoControl()}
      {renderProducts(userId, selectedTemplate, productPricesMap)}
      {renderColdStorageNotification(selectedTemplate)}
      {renderBottomControls()}
      {renderDeleteOrderTemplatePopup()}
      <CartAddPopup
        show={showCartPopup}
        left={popupPos.left}
        onSave={(cart, isNewCart) => onSave(cart, isNewCart)}
        onCancel={() => setShowCartPopup(false)}
        template={selectedTemplate}
      />
    </Paper>
  );
}

OrderTemplate.propTypes = {
  selectedTemplate: PropTypes.shape({
    templateId: PropTypes.string,
    name: PropTypes.string,
    customer: PropTypes.string,
    shipToAddress: PropTypes.string,
    note: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        product: PropTypes.shape({
          materialId: PropTypes.string,
        }),
        count: PropTypes.number,
      })
    ),
  }),
  setSelectedTemplate: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  handleAddToCart: PropTypes.func,
};

OrderTemplate.defaultProps = {
  selectedTemplate: null,
  setSelectedTemplate: () => {}, // eslint-disable-line
  history: null,
  handleAddToCart: () => {}, // eslint-disable-line
};

export default withRouter(OrderTemplate);

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { More } from "../../../images";
import {
  cartNameExists,
  findLastModifiedCart,
  generateDefaultName,
} from "../../../utils/cartUtil/cartUtil";
import {
  findCustomerById,
  getDefaultDeliveryAddress,
} from "../../../utils/customer/customer";
// eslint-disable-next-line import/no-cycle
import { PopupLayout, Progress } from "../../generic";
import { cartCreate } from "../../../redux/reducers";

const useStyles = makeStyles(theme => ({
  selectTitle: {
    color: theme.palette.text.disabled,
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  select: {
    backgroundColor: theme.palette.background.default,
  },
  menuItemText: {
    color: theme.palette.text.disabled,
    textTransform: "none",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  createButton: {
    marginBottom: theme.spacing(3),
    textTransform: "none",
    paddingLeft: 0,
  },
  createButtonText: {
    marginLeft: theme.spacing(1),
  },
  createContainer: {
    marginBottom: theme.spacing(3),
  },
  createLabel: {
    color: theme.palette.text.disabled,
  },
  createInputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  createInput: {
    width: "80%",
    marginRight: theme.spacing(1),
  },
  inputError: {
    color: "red",
    backgroundColor: theme.palette.background.paper,
  },
}));

function CartAddPopup({ show, left, onSave, onCancel, template = null }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { carts, sendingCart } = useSelector(state => state.cart);
  const { userId } = useSelector(state => state.user.userData);
  const { selectedCustomerId, customers } = useSelector(
    state => state.customer
  );
  const dispatch = useDispatch();
  const [selectedCart, setSelectedCart] = useState("empty");
  const [showInput, setShowInput] = useState(false);
  const [newShoppingCartName, setNewShoppingCartName] = useState("");
  const [inputError, setInputError] = useState(null);

  useEffect(() => {
    // update selected cart
    const cart = findLastModifiedCart(carts);
    if (cart) {
      setSelectedCart(cart);
    } else {
      setSelectedCart("empty");
    }

    // set new cart name
    const name = generateDefaultName(carts, t("shoppingCartDefaultName"));
    setNewShoppingCartName(name);
  }, [carts, setSelectedCart, setNewShoppingCartName, t]);

  const disableSave = () =>
    newShoppingCartName == null && selectedCart === "empty";

  const handleSave = () => {
    // create new shopping cart?
    if (newShoppingCartName && showInput) {
      if (!cartNameExists(newShoppingCartName, carts)) {
        // create
        const customer = findCustomerById(selectedCustomerId, customers);
        const deliveryAddress = getDefaultDeliveryAddress(customer);
        const newCart = {
          name: newShoppingCartName,
          customer: selectedCustomerId,
          shipToAddress: deliveryAddress ? deliveryAddress.ShipTo : undefined,
        };
        dispatch(cartCreate(userId, newCart)).then(createdCart => {
          if (createdCart) {
            template ? onSave(createdCart, true) : onSave(createdCart);
          } else {
            setInputError(`${t("networkError")}`);
          }
        });
      } else {
        setInputError(t("nameAlreadyUsed"));
      }
    } else {
      onSave(selectedCart);
    }
  };

  const renderCreateCartButton = () => (
    <Button className={classes.createButton} onClick={() => setShowInput(true)}>
      <More />
      <div className={classes.createButtonText}>{t("createNewCart")}</div>
    </Button>
  );

  const renderCreateInput = () => {
    const onChange = event => {
      // if user selects cart and then
      // starts to create new one. Clear.
      setSelectedCart("empty");
      setInputError(null);
      setNewShoppingCartName(event.target.value);
    };

    return (
      <div className={classes.createContainer}>
        <Typography className={classes.createLabel} variant="body1">
          {t("createNewCart")}
        </Typography>
        <div className={classes.createInputContainer}>
          <TextField
            className={classes.createInput}
            margin="dense"
            placeholder={t("writeShoppingCartName")}
            size="small"
            variant="outlined"
            value={newShoppingCartName}
            onChange={event => onChange(event)}
            inputProps={{ maxLength: 30 }}
          />
          <Progress show={sendingCart} />
        </div>
      </div>
    );
  };

  const onCartSelected = event => {
    // if the user chooses cart from the list
    // and has input open, close it and clear
    setShowInput(false);
    setNewShoppingCartName(null);
    setSelectedCart(event.target.value);
  };

  // sort carts alphabetically
  const sortedCarts = carts.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <PopupLayout
      show={show}
      left={left}
      onOk={() => handleSave()}
      onCancel={() => onCancel()}
      disableSave={disableSave()}
      saveKey="add"
    >
      <Typography variant="h6" color="textPrimary">
        {t("addToCart")}
      </Typography>
      <Typography className={classes.selectTitle} variant="body1">
        {t("selectCart")}
      </Typography>
      <FormControl className={classes.formControl}>
        <Select
          disableUnderline
          value={selectedCart}
          displayEmpty
          onChange={event => onCartSelected(event)}
          className={classes.select}
          renderValue={value => {
            let text = t("select");
            if (value !== "empty") {
              text = value.name;
            }
            return (
              <Typography
                className={classes.menuItemText}
                color="textPrimary"
                variant="button"
              >
                {text}
              </Typography>
            );
          }}
        >
          {sortedCarts.map(cart => (
            <MenuItem key={cart.cartId} value={cart}>
              <Typography
                className={classes.menuItemText}
                color="textPrimary"
                variant="button"
              >
                {cart.name}
              </Typography>
            </MenuItem>
          ))}
        </Select>
        {inputError && (
          <FormHelperText className={classes.inputError}>
            {inputError}
          </FormHelperText>
        )}
      </FormControl>

      {!showInput && renderCreateCartButton()}
      {showInput && renderCreateInput()}
    </PopupLayout>
  );
}

CartAddPopup.propTypes = {
  left: PropTypes.number,
  show: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  template: PropTypes.shape({}),
};

CartAddPopup.defaultProps = {
  left: 0,
  show: false,
  onSave: () => {}, // eslint-disable-line
  onCancel: () => {}, // eslint-disable-line
  template: null,
};

export default CartAddPopup;

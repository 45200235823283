import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Paths from "../../../../utils/navigation/navigation";

const useStyles = makeStyles(() => ({
  placeholder: {
    height: "69px",
    width: "100%",
  },
}));

function OrigoHeader({ history }) {
  const classes = useStyles();
  const { tokenData } = useSelector(state => state.user);
  const [loaded, setLoaded] = useState(false);

  let divRef;
  const setRef = ref => (divRef = ref); // eslint-disable-line

  useEffect(() => {
    const unlisten = history.listen(() => {
      // force refresh if the path changes. (history.push is used)
      setLoaded(false);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  // Force refresh if token changes
  useEffect(() => {
    setLoaded(false);
  }, [tokenData]);

  useEffect(() => {
    if (!loaded) {
      const script = document.createElement("script");
      script.src = process.env.REACT_APP_ORIGO_HEADER_URL;
      script.async = true;
      divRef.appendChild(script);
      script.onload = () => {
        setLoaded(true);
      };
    }
  }, [tokenData, setLoaded, divRef, loaded]);

  const { Origo } = window;
  const { origin } = window.location;

  const getActiveTab = () => {
    const pathName = window.location.pathname;
    if (pathName === "/" || pathName.includes(Paths.Products)) {
      return Origo.Header.MenuItem.PRODUCTS;
    }
    if (pathName.includes(Paths.Reports)) {
      return Origo.Header.MenuItem.REPORTS;
    }
    if (pathName.includes(Paths.OrdersInvoices)) {
      return Origo.Header.MenuItem.ORDERS;
    }
    return null;
  };

  // onOrganizationChange can be used to handle organization change
  return (
    <div ref={setRef}>
      {loaded && (
        <Origo.Header
          accessToken={tokenData.accessToken}
          activeMenuItem={getActiveTab()}
          urls={{
            products: `${origin}${Paths.Products}`,
            reports: `${origin}${Paths.Reports}`,
            orders: `${origin}${Paths.OrdersInvoices}`,
            cart: `${origin}${Paths.ShoppingCart}`,
          }}
        />
      )}
      {!loaded && <div className={classes.placeholder} />}
    </div>
  );
}

OrigoHeader.propTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func.isRequired,
  }).isRequired,
};

OrigoHeader.defaultProps = {};

export default withRouter(OrigoHeader);

import moment from "moment";

export const templateNameExists = (name, templates) => {
  if (Array.isArray(templates)) {
    return (
      templates
        .map(template => template.name.toLowerCase())
        .indexOf(name.toLowerCase()) !== -1
    );
  }
  return false;
};

export const findTemplateByName = (name, templates) => {
  if (Array.isArray(templates)) {
    return templates.find(
      template => template.name.toLowerCase() === name.toLowerCase()
    );
  }
  return null;
};

export const findTemplateById = (templateId, templates) => {
  if (Array.isArray(templates)) {
    return templates.find(template => template.templateId === templateId);
  }
  return null;
};

export const sortByLastModified = carts => {
  carts.sort((a, b) =>
    moment(a.updatedDate).isSameOrAfter(moment(b.updatedDate)) ? -1 : 1
  );
  return carts;
};

export const findLastModifiedTemplate = templates => {
  if (Array.isArray(templates) && templates.length > 0) {
    const sortedCarts = sortByLastModified(templates);
    return sortedCarts[0];
  }
  return null;
};

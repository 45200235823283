/* eslint-disable consistent-return */
import ensureTrailingSlash from "../../../utils/url/url";
// eslint-disable-next-line
import { RestService } from "../../../components/generic";

export const availability = Object.freeze({
  FETCH_STARTED: "AVAILABILITY_FETCH_STARTED",
  FETCH_FINISHED: "AVAILABILITY_FETCH_FINISHED",
  FETCH_ERROR: "AVAILABILITY_FETCH_ERROR",
  FETCH_BY_ID_STARTED: "AVAILABILITY_FETCH_BY_ID_STARTED",
  FETCH_BY_ID_FINISHED: "AVAILABILITY_FETCH_BY_ID_FINISHED",
  FETCH_BY_ID_ERROR: "AVAILABILITY_FETCH_BY_ID_ERROR",
  UPDATE_AVAILABILITY_STATUS_FETCH_IDS: "UPDATE_AVAILABILITY_STATUS_FETCH_IDS",
  CLEAR_ALL_ERRORS: "CLEAR_ALL_ERRORS",
});

const baseUrl = ensureTrailingSlash(process.env.REACT_APP_ECOM_SERVICE);
const availabilityApi = ensureTrailingSlash(
  process.env.REACT_APP_AVAILABILITY_API
);
// -- ACTIONS --

export const fetchAvailabilityStatuses =
  (customerId, materialIds) => async dispatch => {
    const cId = customerId || "";
    const trimmedMaterialIds = materialIds.filter(id => id);
    if (trimmedMaterialIds.length === 0) {
      return [];
    }
    const path = `${baseUrl}${availabilityApi}?customerId=${cId}&materialIds=${trimmedMaterialIds.join(
      ","
    )}`;

    try {
      // mark individual ids to be fetched to overcome flickering problem
      const availabilityStatusFetchIdMap = new Map();

      // mark the ids to be fetched
      trimmedMaterialIds.forEach(id => {
        availabilityStatusFetchIdMap.set(id, true);
      });
      dispatch({
        type: availability.UPDATE_AVAILABILITY_STATUS_FETCH_IDS,
        payload: availabilityStatusFetchIdMap,
      });

      // start
      dispatch({ type: availability.FETCH_STARTED });

      const availabilityData = await RestService.get(path);

      // update
      dispatch({
        type: availability.FETCH_FINISHED,
        payload: availabilityData,
      });

      // remove ids
      dispatch({
        type: availability.UPDATE_AVAILABILITY_STATUS_FETCH_IDS,
        payload: new Map(),
      });

      return availabilityData;
    } catch (error) {
      dispatch({ type: availability.FETCH_ERROR, payload: error });
    }
  };

export const fetchAvailabilityByProductId = (customerId, materialId) =>
  fetchAvailabilityStatuses(customerId, [materialId]);

// -- REDUCER --

const INIT_STATE = {
  productAvailabilityStatusesMap: new Map(),
  availabilityStatusFetchIdMap: new Map(),
  fetchingAvailability: false,
  availabilityFetchError: null,
};
// eslint-disable-next-line
export const availabilityService = (state = INIT_STATE, action) => {
  switch (action.type) {
    case availability.FETCH_STARTED:
      return {
        ...state,
        fetchingAvailability: true,
        availabilityFetchError: null,
      };
    case availability.CLEAR_ALL_ERRORS: {
      return { ...state, availabilityFetchError: null };
    }
    case availability.FETCH_FINISHED: {
      const productAvailabilityStatusesMap = new Map(
        state.productAvailabilityStatusesMap
      );
      const newStatuses = action.payload;

      // update statuses
      newStatuses.forEach(status => {
        productAvailabilityStatusesMap.set(status.productId, status);
      });

      return {
        ...state,
        fetchingAvailability: false,
        productAvailabilityStatusesMap,
      };
    }
    case availability.FETCH_ERROR:
      return {
        ...state,
        fetchingAvailability: false,
        availabilityFetchError: action.payload,
      };
    case availability.FETCH_BY_ID_STARTED:
      return {
        ...state,
        fetchingAvailability: true,
        availabilityFetchError: null,
      };
    case availability.FETCH_BY_ID_FINISHED: {
      const productAvailabilityStatusesMap = new Map(
        state.productAvailabilityStatusesMap
      );
      const status = action.payload;

      // update
      productAvailabilityStatusesMap.set(status.productId, status);

      return {
        ...state,
        fetchingAvailability: false,
        productAvailabilityStatusesMap,
      };
    }
    case availability.FETCH_BY_ID_ERROR:
      return {
        ...state,
        fetchingAvailability: false,
        availabilityFetchError: action.payload,
      };
    case availability.UPDATE_AVAILABILITY_STATUS_FETCH_IDS: {
      return { ...state, availabilityStatusFetchIdMap: action.payload };
    }
    default:
      return state;
  }
};

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { MuiStartEndDatePicker } from "@oriola-origo/origo-ui-core";

function DateRangePicker({
  startDate,
  endDate,
  disabled,
  hideStartDate,
  onDatesSelected,
  ...containerProps
}) {
  const dateFormat = "YYYY-MM-DD";
  const { t } = useTranslation();
  const handleDatesSelected = (momentStartDate, momentEndDate) => {
    const startDateStr =
      momentStartDate != null ? momentStartDate.format(dateFormat) : null;
    const endDateStr =
      momentEndDate != null ? momentEndDate.format(dateFormat) : null;
    onDatesSelected(startDateStr, endDateStr);
  };

  const getStartDateValue = () => {
    if (hideStartDate || startDate == null) {
      return null;
    }
    return moment(startDate);
  };

  return (
    <MuiStartEndDatePicker
      startDateValue={getStartDateValue()}
      endDateValue={endDate != null ? moment(endDate) : null}
      startDateLabel={t("startDate")}
      endDateLabel={t("endDate")}
      onDatesSelected={handleDatesSelected}
      disabled={disabled}
      {...containerProps}
      startDateProps={{ disableFuture: true }}
      endDateProps={{ disableFuture: true }}
    />
  );
}

DateRangePicker.propTypes = {
  onDatesSelected: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  hideStartDate: PropTypes.bool,
};

DateRangePicker.defaultProps = {
  disabled: false,
  hideStartDate: false,
};

export default DateRangePicker;

import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import Category from "./category";

const { getLocalizedProductName } = ProductUtil;

const EventType = Object.freeze({
  ShoppingCartSelect: "shopping-cart-select",
  ShoppingCartAddProduct: "shopping-cart-add-product",
  ShoppingCartRemoveProduct: "shopping-cart-remove-product",
  ShoppingCartSetProductCount: "shopping-cart-set-product-count",
  ShoppingCartCreate: "shopping-cart-create",
  ShoppingCartRemove: "shopping-cart-remove",
  ShoppingCartSetNote: "shopping-cart-set-note",
  ShoppingCartSetName: "shopping-cart-set-name",
  ShoppingCartSetCustomerAndShippingAddress:
    "shopping-cart-set-customer-and-shipping-address",
  TemplateAddProduct: "order-template-add-product",
  TemplateRemoveProduct: "order-template-remove-product",
  TemplateSetProductCount: "order-template-set-product-count",
  TemplateCreate: "order-template-create",
  TemplateRemove: "order-template-remove",
  TemplateSetNote: "order-template-set-note",
  TemplateSetName: "order-template-set-name",
  TemplateSetCustomerAndShippingAddress:
    "order-template-set-customer-and-shipping-address",
});

export const CartTemplateEvents = Object.freeze({
  Create: "Create",
  Remove: "Remove",
  AddProduct: "AddProduct",
  RemoveProduct: "RemoveProduct",
  SetProductCount: "SetProductCount",
  SetNote: "SetNote",
  SetName: "SetName",
  SetCustomerAndShippingAddress: "SetCustomerAndShippingAddress",
  CreateFromTemplate: "CreateFromTemplate",
});

export const shoppingCartSelectEvent = action => {
  const { analytics } = action.payload;

  // sanity
  if (analytics == null) {
    return null;
  }

  return {
    event: EventType.ShoppingCartSelect,
    eventAction: `Select shopping cart ${analytics.name}`,
    eventCategory: Category.ShoppingCart,
    eventLabel: `Select shopping cart ${analytics.name}`,
    eventValue: 0,
  };
};

export const shoppingCartUpdateEvent = action => {
  const { analytics } = action.payload;

  // sanity
  if (analytics == null) {
    return null;
  }

  const { updateType, cart, product, count } = analytics;

  if (updateType === CartTemplateEvents.AddProduct) {
    const localizedName = getLocalizedProductName(product, "en");
    return {
      event: EventType.ShoppingCartAddProduct,
      eventAction: `Add ${localizedName}`,
      eventCategory: Category.ShoppingCart,
      eventLabel: `Add product ${localizedName} to shopping cart ${cart.name}`,
      eventValue: count,
    };
  }
  if (updateType === CartTemplateEvents.RemoveProduct) {
    const localizedName = getLocalizedProductName(product, "en");
    return {
      event: EventType.ShoppingCartRemoveProduct,
      eventAction: `Remove ${localizedName}`,
      eventCategory: Category.ShoppingCart,
      eventLabel: `Remove product ${localizedName} from shopping cart ${cart.name}`,
      eventValue: 0,
    };
  }
  if (updateType === CartTemplateEvents.SetProductCount) {
    const localizedName = getLocalizedProductName(product, "en");
    return {
      event: EventType.ShoppingCartSetProductCount,
      eventAction: `Set product count ${localizedName}`,
      eventCategory: Category.ShoppingCart,
      eventLabel: `Set product count ${localizedName} to shopping cart ${cart.name}`,
      eventValue: count,
    };
  }
  if (updateType === CartTemplateEvents.SetName) {
    return {
      event: EventType.ShoppingCartSetName,
      eventAction: "Update shopping cart name",
      eventCategory: Category.ShoppingCart,
      eventLabel: `Update shopping cart name to  ${cart.name}`,
      eventValue: 0,
    };
  }
  if (updateType === CartTemplateEvents.SetNote) {
    return {
      event: EventType.ShoppingCartSetNote,
      eventAction: "Update shopping cart note",
      eventCategory: Category.ShoppingCart,
      eventLabel: `Update shopping cart note to ${cart.name}`,
      eventValue: 0,
    };
  }
  if (updateType === CartTemplateEvents.SetCustomerAndShippingAddress) {
    return {
      event: EventType.ShoppingCartSetCustomerAndShippingAddress,
      eventAction: "Update order template customer and shipping address",
      eventCategory: Category.ShoppingCart,
      eventLabel: `Update order template customer and shipping address for cart ${cart.name}`,
      eventValue: 0,
    };
  }
  return null;
};

export const shoppingCartCreateEvent = action => {
  const { analytics } = action.payload;

  // sanity
  if (analytics == null) {
    return null;
  }

  const { cart } = analytics;

  return {
    event: EventType.ShoppingCartCreate,
    eventAction: "Create shopping cart",
    eventCategory: Category.ShoppingCart,
    eventLabel: `Create new shopping cart ${cart.name}`,
    eventValue: 0,
  };
};

export const shoppingCartRemoveEvent = action => {
  const { analytics } = action.payload;

  // sanity
  if (analytics == null) {
    return null;
  }

  const { cart } = analytics;

  return {
    event: EventType.ShoppingCartRemove,
    eventAction: "Remove shopping cart",
    eventCategory: Category.ShoppingCart,
    eventLabel: `Remove shopping cart ${cart.name}`,
    eventValue: 0,
  };
};

export const orderTemplateUpdateEvent = action => {
  const { analytics } = action.payload;

  // sanity
  if (analytics == null) {
    return null;
  }

  const { updateType, template, product, count } = analytics;

  if (updateType === CartTemplateEvents.AddProduct) {
    const localizedName = getLocalizedProductName(product, "en");
    return {
      event: EventType.TemplateAddProduct,
      eventAction: `Add ${localizedName}`,
      eventCategory: Category.OrderTemplate,
      eventLabel: `Add product ${localizedName} to order template ${template.name}`,
      eventValue: count,
    };
  }
  if (updateType === CartTemplateEvents.RemoveProduct) {
    const localizedName = getLocalizedProductName(product, "en");
    return {
      event: EventType.TemplateRemoveProduct,
      eventAction: `Remove ${localizedName}`,
      eventCategory: Category.OrderTemplate,
      eventLabel: `Remove product ${localizedName} to order template ${template.name}`,
      eventValue: 0,
    };
  }
  if (updateType === CartTemplateEvents.SetProductCount) {
    const localizedName = getLocalizedProductName(product, "en");
    return {
      event: EventType.TemplateSetProductCount,
      eventAction: `Set product count ${localizedName}`,
      eventCategory: Category.OrderTemplate,
      eventLabel: `Set product count ${localizedName} to order template ${template.name}`,
      eventValue: count,
    };
  }
  if (updateType === CartTemplateEvents.SetName) {
    return {
      event: EventType.TemplateSetProductCount,
      eventAction: "Update order template name",
      eventCategory: Category.OrderTemplate,
      eventLabel: `Update order template name to ${template.name}`,
      eventValue: 0,
    };
  }
  if (updateType === CartTemplateEvents.SetNote) {
    return {
      event: EventType.TemplateSetNote,
      eventAction: "Update order template note",
      eventCategory: Category.OrderTemplate,
      eventLabel: `Update order template note to ${template.note}`,
      eventValue: 0,
    };
  }
  if (updateType === CartTemplateEvents.SetCustomerAndShippingAddress) {
    return {
      event: EventType.TemplateSetCustomerAndShippingAddress,
      eventAction: "Update order template customer and shipping address",
      eventCategory: Category.OrderTemplate,
      eventLabel: `Update order template customer and shipping address for template ${template.name}`,
      eventValue: 0,
    };
  }
  return null;
};

export const orderTemplateCreateEvent = action => {
  const { analytics } = action.payload;

  // sanity
  if (analytics == null) {
    return null;
  }

  const { template } = analytics;

  return {
    event: EventType.TemplateCreate,
    eventAction: "Create new order template",
    eventCategory: Category.OrderTemplate,
    eventLabel: `Create ${template.name} with product count ${template.products.length}`,
    eventValue: template.products.length,
  };
};

export const orderTemplateRemoveEvent = action => {
  const { analytics } = action.payload;

  // sanity
  if (analytics == null) {
    return null;
  }

  const { template } = analytics;

  return {
    event: EventType.TemplateRemove,
    eventAction: "Remove order template",
    eventCategory: Category.OrderTemplate,
    eventLabel: `Remove order template ${template.name}`,
    eventValue: 0,
  };
};

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  checkbox: {
    padding: 0,
  },
  text: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

function CheckboxTextLabel({
  text,
  checked,
  onChange,
  color,
  customColor,
  variant,
  textColor,
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Checkbox
        style={{ color: customColor }}
        className={classes.checkbox}
        checked={checked}
        onChange={onChange}
      />
      <Typography
        variant={variant}
        className={classes.text}
        color={color}
        style={{ color: textColor }}
        noWrap
      >
        {text}
      </Typography>
    </div>
  );
}

CheckboxTextLabel.propTypes = {
  text: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  color: PropTypes.string,
  customColor: PropTypes.string,
  variant: PropTypes.string,
  textColor: PropTypes.string,
};

CheckboxTextLabel.defaultProps = {
  text: "",
  checked: false,
  onChange: () => {}, // eslint-disable-line
  color: "textPrimary",
  customColor: null,
  variant: "inherit",
  textColor: null,
};

export default CheckboxTextLabel;

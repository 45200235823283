const MAX_PRODUCTS_IN_STOCK_FETCH = 50;
export const isInStock = stockData =>
  stockData && stockData.quantity > 0 && stockData.message === "";

export const getStockIdChunks = productIds =>
  productIds.reduce((acc, id, idx) => {
    if (idx % MAX_PRODUCTS_IN_STOCK_FETCH === 0) {
      acc.push([]);
    }
    acc[acc.length - 1].push(id);
    return acc;
  }, []);

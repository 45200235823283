/* eslint-disable import/no-cycle,react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { useSelector, useDispatch } from "react-redux";
import Hidden from "@material-ui/core/Hidden";
import { useSearchParams } from "@oriola-origo/origo-ui-core";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  findLastModifiedCart,
  findCartById,
} from "../../../utils/cartUtil/cartUtil";
import {
  findLastModifiedTemplate,
  findTemplateById,
} from "../../../utils/templateUtil/templateUtil";
import {
  CartState,
  setCartState,
  notificationShow,
  notificationHide,
} from "../../../redux/reducers";
import { MAX_NUMBER_OF_SHOPPING_CARTS } from "../../../settings";
import ShoppingCart from "../shoppingCart/shoppingCart";
import NewShoppingCart from "../newShoppingCart/newShoppingCart";
import ShoppingCartSideMenu from "../shoppingCartSideMenu/shoppingCartSideMenu";
import { OrderTemplate, NewOrderTemplate } from "../../orders";
import { NotificationPopup } from "../../generic";
import colors from "../../../theme/colors";
import { ShoppingCartRound } from "../../../images";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  leftContent: {
    width: "20%",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingTop: 0,
      marginTop: 0,
      marginBottom: 0,
    },
  },
  rightContent: {
    width: "80%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingRight: "7.3%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingRight: 0,
      paddingTop: 0,
      marginTop: 0,
      marginBottom: 0,
    },
  },
  leftControlContainer: {
    backgroundColor: colors.backgroundGray,
    marginRight: theme.spacing(2),
    height: "100%",
  },
  products: {
    width: "100%",
  },
  divider: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
}));

function ShoppingCartContainer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const baseUrl = useLocation().pathname;
  const { carts, cartState } = useSelector(state => state.cart);
  const { orderTemplates } = useSelector(state => state.template);
  const [selectedCart, setSelectedCart] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [urlSelectedCart, setUrlSelectedCart] = useState(null);
  const [urlSelectedTemplate, setUrlSelectedTemplate] = useState(null);
  const searchParams = useSearchParams();

  useEffect(() => {
    // scroll always to top
    window.scrollTo(0, 0);

    const cartUrlId = searchParams.get("cart");
    const templateUrlId = searchParams.get("template");

    if (cartUrlId) {
      setUrlSelectedCart(cartUrlId);
      dispatch(setCartState(CartState.SHOW_CONTENT));
    }

    if (templateUrlId) {
      setUrlSelectedTemplate(templateUrlId);
      dispatch(setCartState(CartState.SHOW_TEMPLATES));
    }
  }, []); // eslint-disable-line

  const updateUrl = (type, value) => {
    // Keep organizationId in query params
    const organizationId = searchParams.get("organizationId");
    // Must use window.history instead of react-router history to avoid triggering page reload.
    window.history.replaceState(
      null,
      null,
      `${baseUrl}?organizationId=${organizationId}&${type}=${value}`
    );
  };

  useEffect(() => {
    // init cart
    if (selectedCart == null) {
      const cart = findLastModifiedCart(carts);
      setSelectedCart(cart);
      if (cart && cartState === CartState.SHOW_CONTENT) {
        updateUrl("cart", cart.cartId);
      }
    } else {
      // update reference since something might have changed on redux
      setSelectedCart(findCartById(selectedCart.cartId, carts));
    }

    // init template
    if (selectedTemplate == null) {
      const template = findLastModifiedTemplate(orderTemplates);
      setSelectedTemplate(template);
      if (template && cartState === CartState.SHOW_TEMPLATES) {
        updateUrl("template", template.templateId);
      }
    } else {
      // update reference since something might have changed on redux
      setSelectedTemplate(
        findTemplateById(selectedTemplate.templateId, orderTemplates)
      );
    }

    if (carts.length > 0 && urlSelectedCart) {
      setSelectedCart(findCartById(urlSelectedCart, carts));
      updateUrl("cart", urlSelectedCart);
      setUrlSelectedCart(null);
    }

    if (orderTemplates.length > 0 && urlSelectedTemplate) {
      setSelectedTemplate(
        findTemplateById(urlSelectedTemplate, orderTemplates)
      );
      updateUrl("template", urlSelectedTemplate);
      setUrlSelectedTemplate(null);
    }
  }, [
    carts,
    orderTemplates,
    selectedCart,
    selectedTemplate,
    urlSelectedCart,
    urlSelectedTemplate,
  ]);

  const onCartSelect = cart => {
    updateUrl("cart", cart.cartId);
    setSelectedCart(cart);
    dispatch(setCartState(CartState.SHOW_CONTENT));
  };

  const onTemplateSelect = template => {
    updateUrl("template", template.templateId);
    setSelectedTemplate(template);
    dispatch(setCartState(CartState.SHOW_TEMPLATES));
  };

  // eslint-disable-next-line
  const renderSideMenu = (selectedCart, selectedTemplate) => {
    const showAddNewCart = () => {
      dispatch(setCartState(CartState.ADD_NEW));
    };

    const addCartDisabled =
      cartState === CartState.ADD_NEW ||
      carts.length >= MAX_NUMBER_OF_SHOPPING_CARTS;

    return (
      <ShoppingCartSideMenu
        selectedCart={selectedCart}
        selectedTemplate={selectedTemplate}
        onNewCartClick={() => showAddNewCart()}
        onNewTemplateClick={() =>
          dispatch(setCartState(CartState.ADD_NEW_TEMPLATE))
        }
        onCartSelect={cart => onCartSelect(cart)}
        onTemplateSelect={template => onTemplateSelect(template)}
        newCartButtonDisabled={addCartDisabled}
      />
    );
  };

  const renderNotificationPopup = () => (
    <NotificationPopup
      id="notification-popup"
      icon={<ShoppingCartRound />}
      onClose={() => dispatch(notificationHide())}
    />
  );

  const showTemplatePopup = cart =>
    dispatch(
      notificationShow(
        t("productsAdded"),
        t("templateAddedCart"),
        `${t("toCart").toLowerCase()} ${cart.name}`,
        3000
      )
    );

  const renderCart = () => {
    switch (cartState) {
      case CartState.ADD_NEW:
        return (
          <NewShoppingCart
            onCreated={() => {
              // clear selection, useEffect will set newest one
              updateUrl("cart", "");
              setSelectedCart(null);
              dispatch(setCartState(CartState.SHOW_CONTENT));
            }}
            onCancel={() => {
              dispatch(setCartState(CartState.SHOW_CONTENT));
            }}
          />
        );
      case CartState.ADD_NEW_TEMPLATE: {
        return (
          <NewOrderTemplate
            onCreated={() => {
              // clear selection, useEffect will set newest one
              updateUrl("template", "");
              setSelectedTemplate(null);
              dispatch(setCartState(CartState.SHOW_TEMPLATES));
            }}
            onCancel={() => {
              dispatch(setCartState(CartState.SHOW_TEMPLATES));
            }}
          />
        );
      }
      case CartState.SHOW_TEMPLATES:
        return (
          <OrderTemplate
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            handleAddToCart={cart => {
              showTemplatePopup(cart);
              onCartSelect(cart);
            }}
          />
        );

      case CartState.SHOW_CONTENT:
      default:
        return (
          <div>
            <ShoppingCart
              selectedCart={selectedCart}
              setSelectedCart={setSelectedCart}
            />
            {renderNotificationPopup()}
          </div>
        );
    }
  };

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <div className={classes.leftContent}>
          <Paper className={classes.leftControlContainer}>
            {renderSideMenu(selectedCart, selectedTemplate)}
          </Paper>
        </div>
      </Hidden>
      <div className={classes.rightContent}>
        <div className={classes.products}>{renderCart()}</div>
      </div>
    </div>
  );
}

export default ShoppingCartContainer;

import React from "react";
import { Route, useLocation, matchPath } from "react-router-dom";
import PropTypes from "prop-types";
import NotFound from "../../404/404";

const isDevEnv = process.env.REACT_APP_STAGE === "dev";

function ShowOnlyInDevelopment({ children, isRoute, path }) {
  const location = useLocation();
  const isCorrectPath = matchPath(location.pathname, { path }) !== null;

  if (isRoute) {
    return isDevEnv && isCorrectPath ? (
      children
    ) : (
      <Route path={location.pathname} component={NotFound} />
    );
  }

  return isDevEnv ? children : null;
}

ShowOnlyInDevelopment.propTypes = {
  isRoute: PropTypes.bool,
  path: PropTypes.string,
  children: PropTypes.node,
};
ShowOnlyInDevelopment.defaultProps = {
  isRoute: false,
  path: "",
  children: null,
};

export default ShowOnlyInDevelopment;

import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line
import { mapValues } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line
import { DropDownMultiSelect } from "../../../generic";

const SHOW_ALL = "showAll";

const useStyles = makeStyles(theme => ({
  filters: {
    minWidth: "12rem",
    marginBottom: theme.spacing(2),
  },
}));

function OrderFilterMenu({ filters, setFilters }) {
  const classes = useStyles();

  const handleFilterChange = event => {
    const filtersCopy = { ...filters };
    const selectedValue = event.target.value[0];
    filtersCopy[selectedValue].checked = !filtersCopy[selectedValue].checked;
    // If showAll is clicked, set all filters by that value
    if (selectedValue === SHOW_ALL) {
      setFilters(
        mapValues(filtersCopy, value => ({
          ...value,
          checked: filtersCopy[SHOW_ALL].checked,
        }))
      );
    } else {
      const filtersWithoutShowAll = Object.values(filtersCopy).filter(
        f => f.value !== SHOW_ALL
      );
      const checkedCount = filtersWithoutShowAll
        .map(x => x.checked)
        .filter(b => b === true).length;
      // Set show all value depeding on filters
      if (filtersWithoutShowAll.length === checkedCount) {
        filtersCopy[SHOW_ALL].checked = true;
      } else {
        filtersCopy[SHOW_ALL].checked = false;
      }
      setFilters(filtersCopy);
    }
  };

  return (
    <DropDownMultiSelect
      className={classes.filters}
      options={Object.values(filters)}
      onSelect={handleFilterChange}
      multiple
    />
  );
}

OrderFilterMenu.propTypes = {
  filters: PropTypes.shape({}).isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default OrderFilterMenu;

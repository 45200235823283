/* eslint-disable @typescript-eslint/no-empty-function,import/no-cycle */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { templateNameExists } from "../../../utils/templateUtil/templateUtil";
import { Progress, PopupLayout } from "../../generic";
import { templateCreate } from "../../../redux/reducers";

const useStyles = makeStyles(theme => ({
  selectTitle: {
    color: theme.palette.text.disabled,
    marginTop: theme.spacing(3),
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  input: {
    width: "100%",
    marginRight: theme.spacing(1),
  },
  inputError: {
    color: "red",
    backgroundColor: theme.palette.background.paper,
  },
}));

function SaveTemplatePopup({
  show,
  top,
  left,
  bottom,
  right,
  onCreated,
  onCancel,
  popupRef,
  cart,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { orderTemplates, sendingTemplate } = useSelector(
    state => state.template
  );
  const { userId } = useSelector(state => state.user.userData);
  const dispatch = useDispatch();
  const [templateName, setTemplateName] = useState("");
  const [inputError, setInputError] = useState(null);

  const disableSave = () => templateName === "";

  const handleSave = () => {
    if (!templateNameExists(templateName, orderTemplates)) {
      const template = {
        name: templateName,
        note: cart.note || "",
        customer: cart.customer,
        shipToAddress: cart.shipToAddress,
        additionalDeliveryInfo: cart.additionalDeliveryInfo || "",
        products: cart.products,
      };

      // create
      dispatch(templateCreate(userId, template)).then(createdTemplate => {
        if (createdTemplate) {
          onCreated(createdTemplate);
          setTemplateName("");
        } else {
          setInputError(`${t("networkError")}`);
        }
      });
    } else {
      setInputError(t("nameAlreadyUsed"));
    }
  };

  const onChange = event => {
    setInputError(null);
    setTemplateName(event.target.value);
  };

  return (
    <PopupLayout
      show={show}
      left={left}
      top={top}
      right={right}
      bottom={bottom}
      onOk={() => handleSave()}
      onCancel={() => onCancel()}
      disableSave={disableSave()}
      popupRef={popupRef}
    >
      <Typography variant="h6" color="textPrimary">
        {t("saveAsOrderTemplate")}
      </Typography>
      <Typography className={classes.selectTitle} variant="body1">
        {t("orderTemplateName")}
      </Typography>
      <div className={classes.inputContainer}>
        <TextField
          className={classes.input}
          margin="dense"
          placeholder={t("writeOrderTemplateName")}
          size="small"
          variant="outlined"
          value={templateName}
          onChange={event => onChange(event)}
          error={inputError != null}
          helperText={inputError}
          inputProps={{ maxLength: 30 }}
          id="order-template-name-input"
        />
        <Progress show={sendingTemplate} />
      </div>
    </PopupLayout>
  );
}

SaveTemplatePopup.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  bottom: PropTypes.number,
  right: PropTypes.number,
  show: PropTypes.bool,
  onCreated: PropTypes.func,
  onCancel: PropTypes.func,
  popupRef: PropTypes.func,
  cart: PropTypes.shape({
    note: PropTypes.string,
    customer: PropTypes.shape({}),
    shipToAddress: PropTypes.shape({}),
    additionalDeliveryInfo: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

SaveTemplatePopup.defaultProps = {
  top: null,
  left: null,
  bottom: null,
  right: null,
  show: false,
  onCreated: () => {},
  onCancel: () => {},
  popupRef: () => {},
  cart: {},
};

export default SaveTemplatePopup;

/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  fetchInvoices,
  fetchInvoicesByIds,
  fetchFavoriteInvoices,
  setFavoriteInvoice,
} from "../../../../redux/reducers";
import InvoiceTable from "./invoiceTable/invoiceTable";
// eslint-disable-next-line
import { Progress } from "../../../generic";
import InvoiceFilterSelect, {
  InvoiceFilters,
} from "./invoiceFilterSelect/invoiceFilterSelect";
import { filterInvoices } from "../locallyFilterOrders";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(4),
  },
  loadingIndicator: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  invoiceFilter: {
    marginBottom: theme.spacing(4),
  },
}));

function Invoices({ searchValue, onItemCountUpdated }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const { invoices, favoriteInvoiceIds, fetchingInvoices } = useSelector(
    state => state.invoice
  );
  const customers = useSelector(state => state.customer.customers);
  const { startDate, endDate } = useSelector(state => state.order);
  const { userData } = useSelector(state => state.user);

  // Fetch invoices every time date range or query changes
  useEffect(() => {
    if (customers.length === 0) {
      // do not fetch orders if there are no customers and query check to avoid duplicate fetch
      return;
    }

    const customerIds = customers.map(x => x.customerId);
    // Fetch after customers are resolved and query check to avoid duplicate fetches
    dispatch(fetchInvoices(customerIds));
  }, [dispatch, startDate, endDate, customers]);

  useEffect(() => {
    // fetch favorite invoices
    const { userId } = userData;
    dispatch(fetchFavoriteInvoices(userId));
  }, [dispatch, userData]);

  useEffect(() => {
    if (typeof onItemCountUpdated === "function") {
      onItemCountUpdated((filteredInvoices || []).length);
    }
    // eslint-disable-next-line
  }, [filteredInvoices]);

  useEffect(() => {
    setFilteredInvoices(filterInvoices(invoices, searchValue));
  }, [invoices, searchValue]);

  const fetchInvoicesByFilterType = filterType => {
    const customerIds = customers.map(x => x.customerId);
    if (customerIds.length === 0) {
      return;
    }

    if (filterType === InvoiceFilters.All) {
      dispatch(fetchInvoices(customerIds));
    } else {
      dispatch(fetchInvoicesByIds(favoriteInvoiceIds));
    }
  };

  // eslint-disable-next-line
  function ProgressCircle() {
    return (
      <div className={classes.loadingIndicator}>
        <Progress show />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.invoiceFilter}>
        <InvoiceFilterSelect
          onFilterTypeSelect={filterType =>
            fetchInvoicesByFilterType(filterType)
          }
        />
      </div>
      {fetchingInvoices === true && <ProgressCircle />}
      {fetchingInvoices === false && (
        <InvoiceTable
          invoices={filteredInvoices}
          favoriteInvoiceIds={favoriteInvoiceIds}
          onMarkClicked={(invoice, marked) => {
            const { userId } = userData;
            dispatch(setFavoriteInvoice(userId, invoice, marked));
          }}
        />
      )}
    </div>
  );
}

Invoices.propTypes = {
  searchValue: PropTypes.string,
  onItemCountUpdated: PropTypes.func,
};

Invoices.defaultProps = {
  searchValue: "",
  onItemCountUpdated: null,
};

export default Invoices;

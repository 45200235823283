import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import styles from "./tableStyles";
import { formatCurrency } from "../../../../utils/currency/currency";
import { formatAddress } from "../../../../utils/customer/customer";
import { getOrderShipToCustomerName } from "../../../../utils/orderUtil/orderUtil";
// eslint-disable-next-line
import { TextLink } from "../../../generic";

export const HeaderCell = withStyles(styles)(props => {
  const { classes, children, ...others } = props;
  return (
    <TableCell {...others} className={classes.tableHeadCell}>
      {children}
    </TableCell>
  );
});

HeaderCell.propTypes = {
  classes: PropTypes.shape({}),
  children: PropTypes.node,
};

HeaderCell.defaultProps = {};

export const DataCell = withStyles(styles)(props => {
  const { classes, children, className } = props;
  return (
    <TableCell className={clsx(classes.tableDataCell, className)}>
      {children}
    </TableCell>
  );
});

DataCell.propTypes = {
  classes: PropTypes.shape({}),
  children: PropTypes.node,
};

DataCell.defaultProps = {};

export const PriceCell = withStyles(styles)(props => {
  const { classes, value, currency, className } = props;
  return (
    <DataCell className={className}>
      <div className={classes.price}>
        {formatCurrency(value, currency) || "-"}
      </div>
    </DataCell>
  );
});

export const OrderIdCell = withStyles(styles)(props => {
  const { classes, order } = props;
  return (
    <DataCell className={classes.orderIdCell}>
      <TextLink
        to={`/ordersInvoices/order/${order.orderId}`}
        text={order.orderId}
      />
    </DataCell>
  );
});

export const DeliveryAddressCell = withStyles(styles)(props => {
  const { order, classes } = props;
  const customerName = getOrderShipToCustomerName(order) || "-";
  return (
    <DataCell className={classes.deliveryAddressCell}>
      <Typography className={classes.cellText}>{order.shipTo}</Typography>
      <Typography className={classes.cellText}>{customerName}</Typography>
      <Typography className={classes.cellText}>
        {formatAddress(order.shipToAddress)}
      </Typography>
    </DataCell>
  );
});

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
// eslint-disable-next-line
import OrderHistoryTable from "./orderHistoryTable/orderHistoryTable";
import { OrderFilterType, fetchOrders } from "../../../../redux/reducers";
import Progress from "../../../generic/progress/progress";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
  header: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    ...theme.typography.button,
    textTransform: "none",
    marginLeft: theme.spacing(4),
  },
  loadingIndicator: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
}));

const ORDERS_PER_PAGE = 15;

function OrderHistory() {
  const classes = useStyles();
  const [rowIndex, setRowIndex] = useState(0);
  const { customers, selectedCustomerId } = useSelector(
    state => state.customer
  );
  const {
    orders,
    startDate,
    endDate,
    query,
    fetchingOrders,
    overallOrderCount,
  } = useSelector(state => state.order);
  const dispatch = useDispatch();

  const filterStr = `customerIds=${customers
    .map(c => `${c.customerId}`)
    .join(",")}&viewType=history`;
  const filter = {
    value: filterStr,
    type: OrderFilterType.OrderHistory,
  };

  useEffect(() => {
    // initial fetch
    if (customers.length > 0 && query !== null) {
      dispatch(
        fetchOrders(0, ORDERS_PER_PAGE * 2, startDate, endDate, query, filter)
      );
    }
  }, [dispatch, startDate, endDate, query, customers]); // eslint-disable-line

  const renderTable = () =>
    fetchingOrders && rowIndex === 0 ? (
      <div className={classes.loadingIndicator}>
        <Progress show />
      </div>
    ) : (
      <OrderHistoryTable
        orders={orders}
        customers={customers}
        selectedCustomerId={selectedCustomerId}
        overallOrderCount={overallOrderCount}
        fetchingOrders={fetchingOrders}
        onRequestOrders={(startIndex, stopIndex) => {
          setRowIndex(startIndex);
          dispatch(
            fetchOrders(
              startIndex,
              stopIndex,
              startDate,
              endDate,
              query,
              filter
            )
          );
        }}
      />
    );

  return <div className={classes.root}>{renderTable()}</div>;
}

export default OrderHistory;

import colors from "../../../../theme/colors";

const styles = theme => ({
  tableHeadCell: {
    fontSize: "0.875rem",
    color: theme.palette.text.disabled,
    borderBottom: `1px solid ${colors.backgroundDarkGray}`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  tableDataCell: {
    fontSize: "0.875rem",
    verticalAlign: "top",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  cellText: {
    fontSize: "0.875rem",
    lineHeight: 1.25,
    marginBottom: theme.spacing(1),
  },
  cellSecondaryText: {
    fontSize: "0.75rem",
    lineHeight: 1.25,
    color: theme.palette.text.disabled,
    marginBottom: theme.spacing(1),
  },
  waitingData: {
    ...theme.typography.caption,
    color: theme.palette.text.disabled,
  },
  fetchFailed: {
    ...theme.typography.caption,
    color: theme.palette.error.main,
  },
  price: {
    fontWeight: "bold",
  },
  productCell: {
    width: "20%",
  },
  orderDateCell: {
    width: "5%",
  },
  deliveryAddressCell: {
    width: "25%",
  },
  orderIdCell: {
    width: "8%",
  },
  poNumberCell: {
    width: "8%",
  },
  orderLineStatusCell: {
    width: "15%",
  },
  priceCellBackorder: {
    width: "10%",
  },
  priceCellOrder: {
    width: "10%",
  },
  quantityCell: {
    width: "10%",
  },
  orderLineCountCell: {
    width: "10%",
  },
  productCountCell: {
    width: "10%",
  },
  orderStatusCell: {
    width: "15%",
  },
  availabilityCell: {
    width: "10%",
  },
  deleteOrderline: {
    paddingTop: 4,
  },
  quantityContent: {
    display: "flex",
    justifyContent: "center",
  },
  deleteBackorder: {
    paddingTop: 0,
  },
});

export default styles;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Typography, IconButton } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line
import { ErrorPopup } from "../..";
import OrigoHeader from "../header/origoHeader";
import { clearAllErrors } from "../../../../redux/reducers";
import { resolveCartSendErrorMessage } from "../../../../utils/cartUtil/cartUtil";

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
  },
  content: {},
  networkError: {
    backgroundColor: theme.palette.error.main,
    display: "flex",
    flexDirection: "colum",
    position: "absolute",
  },
  errorText: {
    margin: theme.spacing(1),
  },
}));

function NetworkError() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [errors, setErrors] = useState([]);

  const cart = useSelector(state => state.cart);
  const template = useSelector(state => state.template);
  const product = useSelector(state => state.product);
  const stock = useSelector(state => state.stock);
  const order = useSelector(state => state.order);
  const invoice = useSelector(state => state.invoice);
  const notification = useSelector(state => state.notification);
  const dispatch = useDispatch();

  useEffect(() => {
    // eslint-disable-next-line
    const errors = [];
    const createErr = (title, identifier, errorObject, showInModal) => {
      if (errorObject && errorObject?.message !== "Request aborted") {
        errors.push({ title, errorObject, identifier, showInModal });
      }
    };

    createErr("Cart fetching failed", "cartFetch", cart.cartFetchError, true);
    createErr("Cart sending failed", "cartSend", cart.cartSendError, true);
    createErr("Order sending failed", "orderSend", order.orderSendError, false);
    createErr(
      "Backorder sending failed",
      "backorderSend",
      order.backorderSendError,
      true
    );
    createErr(
      "Template fetching failed",
      "templateFetch",
      template.templateFetchError,
      true
    );
    createErr(
      "Template sending failed",
      "templateSend",
      template.templateSendError,
      true
    );
    createErr(
      "Product fetching error",
      "productFetch",
      product.productFetchError || product.productFetchByIdError,
      true
    );
    createErr(
      "Favorite products fetching error",
      "favoriteFetch",
      product.favoritesFetchError,
      true
    );
    createErr(
      "Favorite product sending error",
      "favoriteSend",
      product.favoritesSendError,
      true
    );
    createErr(
      "Product price fetching error",
      "priceFetch",
      product.productPriceFetchError,
      true
    );
    createErr(
      "Orders fetching error",
      "orderFetch",
      order.ordersFetchError,
      true
    );
    createErr(
      "Notification sending failed",
      "notificationSend",
      notification.notificationSendError,
      true
    );

    createErr(
      "Invoice fetching error",
      "invoiceFetch",
      invoice.invoiceFetchError,
      true
    );
    createErr(
      "Invoice fetching error",
      "invoicesFetch",
      invoice.invoicesFetchError,
      true
    );
    setErrors(errors);
  }, [cart, template, product, setErrors, stock, order, notification, invoice]);

  const clearErrors = () => {
    setErrors([]);
    dispatch(clearAllErrors());
  };

  const renderDevelopmentErrors = () => {
    if (process.env.NODE_ENV === "development") {
      return (
        <div>
          {errors.length > 0 && (
            <div className={classes.networkError}>
              <div style={{ flexGrow: 1 }}>
                {errors.map(error => (
                  <Typography
                    key={error.title}
                    color="textSecondary"
                    className={classes.errorText}
                    variant="body1"
                  >
                    {error.title}: {JSON.stringify(error.errorObject)}
                  </Typography>
                ))}
              </div>
              <div>
                <IconButton onClick={clearErrors}>
                  <Clear />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const constuctErrorMessages = validErrors => {
    const errorDefinitions = status => ({
      cartFetch: t("cartFetchFailed"),
      cartSend: resolveCartSendErrorMessage(status, t),
      orderSend: t("orderSendFailed"),
      templateFetch: t("templateFetchFailed"),
      templateSend: t("templateSendFailed"),
      productFetch: t("productFetchFailed"),
      favoriteFetch: t("favoriteFetchFailed"),
      favoriteSend: t("favoriteSendFailed"),
      priceFetch: t("priceFetchFailed"),
      stockFetch: t("stockFetchFailed"),
      orderFetch: t("orderFetchFailed"),
      notificationSend: t("notificationSendFailed"),
      invoiceFetch: t("invoiceFetchFailed"),
      invoicesFetch: t("invoicesFetchFailed"),
      backorderSend:
        status === 409 ? t("backorderSendConflict") : t("backorderSendFailed"),
    });

    const errorMessages = validErrors.map(error => (
      <p style={{ display: "block" }} key={error.identifier}>
        {errorDefinitions(error.errorObject.status)[error.identifier]}
      </p>
    ));
    return errorMessages;
  };

  // certain errors are already built into their own components (like making an order).
  // We don't want to show this error modal for those actions
  // showInModal parameter defines if an error must be shown or not
  const getValidErrors = () => errors.filter(error => error.showInModal);

  const renderErrorPopup = () => {
    const validErrors = getValidErrors();
    const showPopup = !!(validErrors && validErrors.length);
    const title = t("dataLoadFailed");
    const description = constuctErrorMessages(validErrors);
    return (
      <ErrorPopup
        title={title}
        description={description}
        show={showPopup}
        onCancel={clearErrors}
        hideOkButton
      />
    );
  };

  // show
  return (
    <div>
      {renderErrorPopup()}
      {renderDevelopmentErrors()}
    </div>
  );
}

function Layout({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <OrigoHeader />
      <NetworkError />
      <div className={classes.content}>{children}</div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {};

export default Layout;

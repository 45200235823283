import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { More, Less } from "../../../../../images";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  iconButton: {
    padding: "0px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(0.5),
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  text: {
    color: theme.palette.text.disabled,
    fontSize: "1rem",
    fontWeight: "bold",
    marginLeft: theme.spacing(1),
  },
  clickableText: {
    color: theme.palette.text.disabled,
    fontSize: "1rem",
    fontWeight: "bold",
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },
}));

function MoreOrLess({
  enabled,
  value,
  text,
  count,
  moreLabel,
  lessLabel,
  onToggleExpand,
  children,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(value);

  useEffect(() => {
    onToggleExpand(expanded);
  }, [expanded, onToggleExpand]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  // eslint-disable-next-line
  function MoreOrLessButton() {
    return (
      <IconButton
        size="small"
        className={classes.iconButton}
        onClick={() => toggleExpanded()}
      >
        {expanded ? <Less /> : <More />}
      </IconButton>
    );
  }
  // eslint-disable-next-line
  function Footer() {
    return (
      <div className={classes.footer}>
        <MoreOrLessButton />
        <Typography
          variant="body1"
          className={classes.clickableText}
          onClick={() => toggleExpanded()}
        >
          {expanded ? lessLabel : moreLabel}
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {enabled && <MoreOrLessButton />}
        <Typography
          variant="body1"
          className={classes.text}
        >{`${text} (${count})`}</Typography>
      </div>
      {children}
      {enabled && <Footer />}
    </div>
  );
}

MoreOrLess.propTypes = {
  enabled: PropTypes.bool,
  value: PropTypes.bool,
  text: PropTypes.string,
  count: PropTypes.number,
  moreLabel: PropTypes.string,
  lessLabel: PropTypes.string,
  onToggleExpand: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

MoreOrLess.defaultProps = {
  enabled: true,
  value: false,
  text: "",
  count: "",
  moreLabel: "Show more",
  lessLabel: "Show less",
  onToggleExpand: () => {}, // eslint-disable-line
  children: null,
};

export default MoreOrLess;

import Delay from "../../../utils/delay/delay";

export const NotificationPopup = Object.freeze({
  NOTIFICATION_SHOW: "NOTIFICATION_SHOW",
  NOTIFICATION_HIDE: "NOTIFICATION_HIDE",
});

const delay = new Delay();

export const notificationHide = () => dispatch => {
  // clear any timers
  delay.clear();

  // hide
  dispatch({
    type: NotificationPopup.NOTIFICATION_HIDE,
  });
};

export const notificationShow =
  (title, primaryText, secondaryText, closeDelay) => dispatch => {
    const delayMs = closeDelay || 3000;

    // hide after delay
    delay.run(() => dispatch(notificationHide()), delayMs);

    // show
    dispatch({
      type: NotificationPopup.NOTIFICATION_SHOW,
      payload: {
        title,
        primaryText,
        secondaryText,
        closeDelay,
      },
    });
  };

const INIT_STATE = {
  show: false,
  title: "",
  primaryText: "",
  secondaryText: "",
};
// eslint-disable-next-line
export const notificationPopupReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case NotificationPopup.NOTIFICATION_SHOW:
      return { ...state, show: true, ...action.payload };
    case NotificationPopup.NOTIFICATION_HIDE:
      return {
        ...state,
        show: false,
        title: "",
        primaryText: "",
        secondaryText: "",
      };
    default:
      return state;
  }
};

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles(theme => ({
  titleContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  fieldTitleText: {
    color: theme.palette.text.disabled,
  },
  fieldInfoText: {
    color: theme.palette.text.disabled,
    paddingLeft: theme.spacing(4),
  },
  inputContainer: {
    marginTop: theme.spacing(1),
  },
  textField: {
    marginRight: theme.spacing(2),
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: theme.spacing(0.5),
    },
  },
}));

function MemoInput({
  value,
  onChange,
  onBlur,
  placeholder,
  title,
  info,
  maxLength,
}) {
  const classes = useStyles();
  const requestedMaxLength = maxLength != null ? maxLength : Infinity;

  return (
    <>
      <div className={classes.titleContainer}>
        <Typography
          className={classes.fieldTitleText}
          variant="subtitle2"
          noWrap
        >
          {title}
        </Typography>
        {info && (
          <Hidden smDown>
            <Typography
              className={classes.fieldInfoText}
              variant="body1"
              noWrap
            >
              {info}
            </Typography>
          </Hidden>
        )}
      </div>
      <div className={classes.inputContainer}>
        <TextField
          value={value}
          variant="outlined"
          placeholder={placeholder}
          className={classes.textField}
          margin="dense"
          onChange={onChange}
          onBlur={onBlur}
          inputProps={{ maxLength: requestedMaxLength }}
        />
      </div>
    </>
  );
}

MemoInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  info: PropTypes.string,
  maxLength: PropTypes.number,
};

MemoInput.defaultProps = {
  value: "",
  onChange: () => {}, // eslint-disable-line
  onBlur: () => {}, // eslint-disable-line
  placeholder: "",
  title: "",
  info: "",
  maxLength: null,
};

export default MemoInput;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import colors from "../../../theme/colors";
// eslint-disable-next-line
import ProductCategoryMenuList from "./productCategoryMenuList";
import { Categories, ArrowDown, ArrowUp } from "../../../images";
import {
  fetchSubCategories,
  clearCategories,
  selectCategories,
  updateSelectedCategoryCounts,
} from "../../../redux/reducers";
import getProductCategoryLocalizedName from "../../../utils/productCategoryUtil/productCategoryUtil";
import CategoryQuickButtons from "./categoryQuickButtons";
import Delay from "../../../utils/delay/delay";

const ROOT_CATEGORY_ID = "categories";
const delay = new Delay();

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  categoryButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    background: colors.backgroundGray,
    height: "2.5rem",
    width: "16rem",
    minWidth: "16rem",
  },
  quickFilters: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  categoryIcon: {
    marginRight: theme.spacing(1),
  },
  categoryText: {
    flexGrow: 1,
    ...theme.typography.body1,
    color: theme.palette.text.primary,
  },
}));

function ProductCategoryMenu({
  open,
  onClose,
  onOpen,
  onCategoryFiltersUpdated,
  searchText,
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [menuDataList, setMenuDataList] = useState([]);
  const [menuWidth, setMenuWidth] = useState(0);
  const {
    categoryMap,
    categoryFetchingId,
    categoryFetchError,
    selectedCategories,
  } = useSelector(state => state.productCategory);
  let buttonRef = null;

  useEffect(() => {
    // clear previous and re-fetch
    dispatch(clearCategories());
    dispatch(fetchSubCategories({ id: ROOT_CATEGORY_ID }, searchText));
    dispatch(updateSelectedCategoryCounts(selectedCategories, searchText));
    // ignore selectedCategories changes
    // eslint-disable-next-line
  }, [searchText, dispatch]);

  useEffect(() => {
    const rect = buttonRef.getBoundingClientRect();
    setMenuWidth(rect.width * 1.4);
  }, [buttonRef, setMenuWidth]);

  const createMenuData = (left, top, title, levelIndex, categoryId) => ({
    left,
    top,
    title,
    levelIndex,
    categoryId,
  });

  const onMenuClick = () => {
    if (open === true) {
      // request close
      onClose();
    } else {
      // init root menu
      const category = categoryMap.get(ROOT_CATEGORY_ID) || {};

      const rect = buttonRef.getBoundingClientRect();
      const leftPos = rect.left;
      const topPos = rect.top + rect.height;
      const mainMenu = createMenuData(
        leftPos,
        topPos,
        t("mainCategories"),
        0,
        category.id
      );
      setMenuDataList([mainMenu]);

      // request open
      onOpen();
    }
  };

  const initNewMenu = (levelIndex, category, fetchData) => {
    if (fetchData === true) {
      dispatch(fetchSubCategories(category, searchText));
    }

    const rect = buttonRef.getBoundingClientRect();
    const categoryName = getProductCategoryLocalizedName(
      i18n.language,
      category
    );
    const leftPos = rect.left + levelIndex * menuWidth;
    const topPos = rect.top + rect.height;
    return createMenuData(
      leftPos,
      topPos,
      categoryName || "-",
      levelIndex,
      category.id
    );
  };

  const onOpenClick = (levelIndex, category) => {
    let newMenuDataList = menuDataList.slice(0);
    const openSubMenuCount = menuDataList.length - 1;
    const topmostMenuClicked = openSubMenuCount === levelIndex;
    const categoryMapData = categoryMap.get(category.id);

    // topmost menu was clicked
    if (topmostMenuClicked === true) {
      // add new menu
      const newLevelIndex = levelIndex + 1;
      newMenuDataList.push(
        initNewMenu(newLevelIndex, category, categoryMapData == null)
      );
    } else {
      // some menu was clicked that was not topmost, was it on the current path
      const menuData = newMenuDataList.find(
        data => data.categoryId === category.id
      );
      if (menuData == null) {
        // was outside path, clear current
        newMenuDataList = newMenuDataList.filter(
          m => m.levelIndex <= levelIndex
        );

        // open new one
        const newLevelIndex = levelIndex + 1;
        newMenuDataList.push(
          initNewMenu(newLevelIndex, category, categoryMapData == null)
        );
      } else {
        // clear all but this
        const index = levelIndex + 1;
        newMenuDataList = newMenuDataList.slice(0, index);
      }
    }

    // update
    setMenuDataList(newMenuDataList);
  };

  const removeParents = (parentCategories, startParentId) => {
    // remove parent selects
    const resultCategoryList = parentCategories.slice(0);
    let category = categoryMap.get(startParentId);

    while (category != null) {
      const { id, parentId } = category;
      const index = resultCategoryList.findIndex(c => c.id === id);
      if (index !== -1) {
        resultCategoryList.splice(index, 1);
      }
      category = categoryMap.get(parentId);
    }

    return resultCategoryList;
  };

  const onSelectClick = (category, autoClose) => {
    let updated;
    const index = selectedCategories.findIndex(c => c.id === category.id);
    if (index === -1) {
      updated = removeParents(selectedCategories, category.parentId);
      updated = updated.concat(category);
    } else {
      // remove
      updated = selectedCategories.slice(0);
      updated.splice(index, 1);
    }

    // update
    dispatch(selectCategories(updated));

    // handle heavy clickers
    delay.run(() => onCategoryFiltersUpdated(updated), 500);

    // if close requested
    if (autoClose === true) {
      // request close
      onClose();
    }
  };

  const onClearClick = () => {
    dispatch(selectCategories([]));
    onCategoryFiltersUpdated([]);
  };

  const renderButton = () => {
    const text = open ? t("closeCategories") : t("showCategories");
    const arrow = open ? <ArrowUp /> : <ArrowDown />;
    return (
      <Button
        className={classes.categoryButton}
        onClick={() => onMenuClick()}
        // eslint-disable-next-line
        ref={el => (buttonRef = el)}
      >
        <div className={classes.categoryIcon}>
          <Categories />
        </div>
        <Typography className={classes.categoryText}>{text}</Typography>
        {arrow}
      </Button>
    );
  };

  const renderQuickFilters = () => {
    const onCategoryRemoved = category => {
      const index = selectedCategories.findIndex(c => c.id === category.id);
      if (index !== -1) {
        const updated = selectedCategories.slice(0);
        updated.splice(index, 1);
        dispatch(selectCategories(updated));
        onCategoryFiltersUpdated(updated);
      }
    };

    const onClearCategories = () => {
      dispatch(selectCategories([]));
      onCategoryFiltersUpdated([]);
    };

    return (
      <div className={classes.quickFilters}>
        <CategoryQuickButtons
          categories={selectedCategories}
          onCategoryRemoved={category => onCategoryRemoved(category)}
          onClearCategories={() => onClearCategories()}
        />
      </div>
    );
  };

  const renderMenus = () => (
    <div>
      {menuDataList.map(menuData => {
        const category = categoryMap.get(menuData.categoryId) || {};
        const subCategories = category.subCategories || [];
        return (
          <ProductCategoryMenuList
            key={menuData.categoryId}
            left={menuData.left}
            top={menuData.top}
            width={menuWidth}
            title={menuData.title}
            levelIndex={menuData.levelIndex}
            categories={subCategories}
            // eslint-disable-next-line
            onOpenClick={(levelIndex, category) =>
              onOpenClick(levelIndex, category)
            }
            selected={selectedCategories}
            // eslint-disable-next-line
            onSelectClick={(category, autoClose) =>
              onSelectClick(category, autoClose)
            }
            showClearButton={menuData.levelIndex === 0}
            onClearClick={() => onClearClick()}
            fetching={categoryFetchingId === menuData.categoryId}
            fetchError={categoryFetchError}
          />
        );
      })}
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {renderButton()}
        {renderQuickFilters()}
        <Modal
          open={open}
          onClose={onClose}
          BackdropProps={{ invisible: true }}
        >
          {renderMenus()}
        </Modal>
      </div>
    </div>
  );
}

ProductCategoryMenu.propTypes = {
  onCategoryFiltersUpdated: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  searchText: PropTypes.string,
};

ProductCategoryMenu.defaultProps = {
  searchText: null,
};

export default ProductCategoryMenu;

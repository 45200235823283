import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "moment/locale/fi";
import "moment/locale/sv";
import { AuthLoader, OrigoAuthenticator } from "@oriola-origo/origo-ui-core";
import { rest as RestService } from "@oriola-origo/origo-common-client-lib";
import { Layout } from "./components/generic";
import { Products } from "./components/productList";
import { OrdersInvoices, OrderCard } from "./components/orders";
import { ShoppingCartContainer } from "./components/shoppingCart";
import RequestDeliveryAddress from "./components/requestDeliveryAddress/requestDeliveryAddress";
import NotFound from "./components/404/404";
import UserInfo from "./components/userInfo/userInfo";
import { ProductCard } from "./components/productCard";
import {
  cartInit,
  cartCreate,
  templateFetchAll,
  fetchCustomers,
  userSignIn,
  fetchUserDataFromOrigo,
  fetchFavoriteProducts,
  setSelectedCustomer,
  userSignOut,
  setTokenData,
} from "./redux/reducers";
import {
  UserAndCustomerProtectedRoute,
  Permission,
  isAllowed,
  ShowOnlyInDevelopment,
  ANY_CUSTOMER,
} from "./components/auth";
import { generateDefaultName } from "./utils/cartUtil/cartUtil";
import {
  findCustomerById,
  getCustomerForDefaultCart,
} from "./utils/customer/customer";
import Paths from "./utils/navigation/navigation";
import Versions from "./components/versions/versions";

function App() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const cartInitialized = useSelector(state => state.cart.initialized);
  const { carts } = useSelector(state => state.cart);
  const user = useSelector(state => state.user);
  const { userData } = user;
  const { userId } = userData;
  const { search } = useLocation();
  const [isAppReadyToRender, setIsAppReadyToRender] = useState(false);
  useEffect(() => {
    if (user.tokenData && user.sessionId) {
      RestService.init(user, res => dispatch(setTokenData(res)));
    }
    // eslint-disable-next-line
  }, [user.tokenData, user.sessionId]);

  // Customer data is required for app to work correctly
  const {
    customers,
    customersFetchError,
    fetchingCustomers,
    selectedCustomerId,
  } = useSelector(state => state.customer);
  const customer = findCustomerById(selectedCustomerId, customers);

  useEffect(() => {
    if (
      cartInitialized &&
      carts.length === 0 &&
      userId &&
      customers.length !== 0 &&
      selectedCustomerId &&
      isAllowed(userData, Permission.ORDER_CREATE, ANY_CUSTOMER, { customer })
    ) {
      const defaultCartCustomer = getCustomerForDefaultCart(
        customers,
        userData,
        selectedCustomerId
      );
      if (!defaultCartCustomer) return;

      const newCart = {
        name: generateDefaultName([], t("shoppingCartDefaultName")),
        ...defaultCartCustomer,
      };
      dispatch(cartCreate(userId, newCart));
    }
    // eslint-disable-next-line
  }, [
    carts,
    t,
    userId,
    userData,
    cartInitialized,
    customers,
    customer,
    dispatch,
  ]); // eslint-disable-line

  useEffect(() => {
    const organizationId = new URLSearchParams(search).get("organizationId");
    // If query params have organizationId store it
    if (organizationId && organizationId !== selectedCustomerId) {
      dispatch(setSelectedCustomer(organizationId));
      // Use first customer as fallback
    } else if (!selectedCustomerId && customers.length > 0) {
      dispatch(setSelectedCustomer(customers[0].customerId));
    }
  }, [search, selectedCustomerId, customers, dispatch]);

  // cart init
  useEffect(() => {
    const userAndCustomerDataFetched =
      userData != null &&
      customers != null &&
      customers.length &&
      selectedCustomerId != null;
    if (userAndCustomerDataFetched) {
      // allowed by role
      const allowed = isAllowed(
        userData,
        Permission.ORDER_CREATE,
        ANY_CUSTOMER,
        { customer }
      );
      // init carts
      if (allowed === true) {
        // fetch carts and templates
        dispatch(cartInit(userId));
        dispatch(templateFetchAll(userId));
      }
    }
  }, [userData, customers, selectedCustomerId, userId, customer, dispatch]);

  const initData = signInData => {
    dispatch(userSignIn(signInData));
    // eslint-disable-next-line
    const { userId, organizationIds } = signInData.userData;
    dispatch(fetchUserDataFromOrigo(userId)).then(origoUserData => {
      if (origoUserData && origoUserData.language) {
        i18n.changeLanguage(origoUserData.language);
        dispatch(fetchFavoriteProducts(userId));
        dispatch(fetchCustomers(organizationIds));
        setIsAppReadyToRender(true);
      }
    });
  };

  const renderLoader = () => <AuthLoader />;

  const renderApp = () => (
    <div>
      <Layout>
        <Switch>
          <UserAndCustomerProtectedRoute
            exact
            user={userData}
            customers={customers}
            path="/"
            component={Products}
            failComponent={NotFound}
            serverError={customersFetchError}
            requiredPermission={Permission.PRODUCTS_VIEW}
            selectedCustomerId={selectedCustomerId}
          />
          <UserAndCustomerProtectedRoute
            exact
            user={userData}
            customers={customers}
            path={Paths.Products}
            component={Products}
            failComponent={NotFound}
            serverError={customersFetchError}
            requiredPermission={Permission.PRODUCTS_VIEW}
            selectedCustomerId={selectedCustomerId}
          />
          <UserAndCustomerProtectedRoute
            user={userData}
            customers={customers}
            path={`${Paths.Products}/:productId`}
            component={ProductCard}
            failComponent={NotFound}
            serverError={customersFetchError}
            requiredPermission={Permission.PRODUCTS_VIEW}
            selectedCustomerId={selectedCustomerId}
          />
          <UserAndCustomerProtectedRoute
            user={userData}
            customers={customers}
            path={`${Paths.OrdersInvoicesOrder}/:orderId`}
            component={OrderCard}
            failComponent={NotFound}
            requiredPermission={Permission.ORDER_VIEW}
            serverError={customersFetchError}
            selectedCustomerId={selectedCustomerId}
          />
          <UserAndCustomerProtectedRoute
            user={userData}
            customers={customers}
            path={Paths.OrdersInvoices}
            component={OrdersInvoices}
            failComponent={NotFound}
            requiredPermission={Permission.ORDER_VIEW}
            serverError={customersFetchError}
            selectedCustomerId={selectedCustomerId}
          />
          <UserAndCustomerProtectedRoute
            user={userData}
            customers={customers}
            path={Paths.ShoppingCart}
            component={ShoppingCartContainer}
            failComponent={NotFound}
            requiredPermission={Permission.ORDER_CREATE}
            serverError={customersFetchError}
            selectedCustomerId={selectedCustomerId}
          />
          <UserAndCustomerProtectedRoute
            user={userData}
            customers={customers}
            path={Paths.RequestDeliveryAddress}
            component={RequestDeliveryAddress}
            failComponent={NotFound}
            requiredPermission={Permission.ORDER_ADD_DELIVERY_ADDRESS}
            serverError={customersFetchError}
            selectedCustomerId={ANY_CUSTOMER}
          />
          <Route path={Paths.Versions} component={Versions} />
          <ShowOnlyInDevelopment>
            <Route path={Paths.UserInfo} component={UserInfo} />
          </ShowOnlyInDevelopment>
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </div>
  );

  const renderOrigoAuth = () => (
    <OrigoAuthenticator
      isSignedIn={user.signedIn}
      onSignedIn={signInData => initData(signInData)}
      onSignedOut={() => {
        // clear
        dispatch(userSignOut());
      }}
    >
      {!fetchingCustomers && isAppReadyToRender ? renderApp() : renderLoader()}
    </OrigoAuthenticator>
  );

  // TODO: use renderApp if you want to pass auth. Remember to call initData - function
  return <div>{renderOrigoAuth()}</div>;
}

App.propTypes = {
  location: PropTypes.shape({}),
};

App.defaultProps = {
  location: null,
};

export default App;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
// eslint-disable-next-line
import { ProductDetailRow } from "..";
import { IconTextButton } from "../../generic";
import {
  More,
  Less,
  Snowflake,
  Narcotics,
  Animal,
  Biohazard,
  Organic,
} from "../../../images";

import CELicensedImg from "../../../images/ce-mark.png";
import MDImg from "../../../images/md-mark.png";
import IVDImg from "../../../images/ivd-mark.png";
import checkIfHasValue from "../../../utils/valueChecker/valueChecker";

const {
  ProductAttribute,
  getSupplierItemNumber,
  getAttribute,
  getStrengthStr,
  getStrength2Str,
  getSupplier,
  isColdStorageProduct,
  isFreezerStorageProduct,
  getSupplierProductGroup,
  getSupplierProductFamily,
  getPackagingUnits,
  getBooleanAttribute,
  getManufacturer,
  getReplaces,
  getReplacedBy,
  getBrand,
  getMaterialGroup,
  isPharmaceuticalProduct,
  isDisplayNarcoticsWarning,
  getNarcoticsClass,
} = ProductUtil;

function ProductDetailsTable({ product }) {
  const { t, i18n } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const buttonText = showAll ? t("showLess") : t("showMore");
  const tableRow = (title, value, highlight, icons, key) => (
    <ProductDetailRow
      key={key || undefined}
      title={title}
      value={value}
      highlight={highlight}
      icons={icons}
    />
  );

  let rowCounter = 1;
  // eslint-disable-next-line
  const highlight = () => ++rowCounter % 2 === 0;
  const lang = i18n.language;
  const coldStorage = isColdStorageProduct(product);
  const freezerStorage = isFreezerStorageProduct(product);
  const packagingUnits = getPackagingUnits(product);
  const singlePackage = (packagingUnits && packagingUnits.shift()) || {};
  const isVeterinaryPharmaceutical = getBooleanAttribute(
    product,
    ProductAttribute.VETERINARY_PHARMACEUTICAL,
    lang
  );
  const isHumanPharmaceutical = getBooleanAttribute(
    product,
    ProductAttribute.HUMAN_PHARMACEUTICAL,
    lang
  );

  const isBiohazard = getBooleanAttribute(
    product,
    ProductAttribute.BIOHAZARD,
    lang
  );
  const isNarcotic = isDisplayNarcoticsWarning(product);
  const narcoticsClass = getNarcoticsClass(product);

  const validTableRow = (title, value, icons, key) =>
    checkIfHasValue(value) && tableRow(title, value, highlight(), icons, key);

  const renderPackagingUnit = u => {
    const unitName = t(`packagingUnit.${u.packagingUnit}`);
    const heading = `${unitName} ${t("packagingUnitSize")}`;
    return validTableRow(heading, u.size, undefined, u.packagingUnit);
  };

  // eslint-disable-next-line
  const getMarketingAuthorization = product => {
    const materialGroup = getMaterialGroup(product);
    if (materialGroup != null) {
      // localize
      switch (materialGroup) {
        case "P.1.1.4.1":
          return t("specialPermission");
        case "P.1.1.4.2":
          return t("temporarySpecialPermission");
        default:
          break;
      }
    }
    return null;
  };

  // eslint-disable-next-line
  const getMarketingAuthorizationNumber = product =>
    getAttribute(
      product,
      ProductAttribute.MARKETING_AUTHORIZATION_NUMBER,
      lang
    ).join(", ");

  const renderHidden = () => (
    <div>
      {validTableRow(t("supplierItemNumber"), getSupplierItemNumber(product))}
      {validTableRow(t("gtinCode"), singlePackage.gtin)}
      {validTableRow(t("additionalGtinCode"), singlePackage.additionalGtin)}
      {validTableRow(t("dimensionsInfo"), singlePackage.dimensionsStr)}
      {validTableRow(t("grossWeight"), singlePackage.weightStr)}
      {packagingUnits.map(u => renderPackagingUnit(u))}
      {validTableRow(
        t("atcCode"),
        getAttribute(product, ProductAttribute.ATC_CODE, lang).join(", ")
      )}
      {validTableRow(
        t("therapyGroup1"),
        getAttribute(product, ProductAttribute.THERAPY_GROUP_1, lang).join(", ")
      )}
      {validTableRow(
        t("therapyGroup2"),
        getAttribute(product, ProductAttribute.THERAPY_GROUP_2, lang).join(", ")
      )}
      {validTableRow(
        t("humanPharmaceutical"),
        isHumanPharmaceutical ? t("yes") : t("no")
      )}
      {isVeterinaryPharmaceutical &&
        validTableRow(t("veterinaryPharmaceutical"), t("yes"), <Animal />)}
      {validTableRow(t("manufacturer"), getManufacturer(product))}
      {validTableRow(t("countryOfOrigin"), product.countryOfOrigin)}
      {validTableRow(
        t("marketer"),
        getAttribute(product, ProductAttribute.MARKETER, lang).join(", ")
      )}
      {!isPharmaceuticalProduct(product) &&
        validTableRow(
          t("baseColor"),
          getAttribute(product, ProductAttribute.BASE_COLOR, lang).join(", ")
        )}
      {!isPharmaceuticalProduct(product) &&
        validTableRow(
          t("marketingColor"),
          getAttribute(product, ProductAttribute.MARKETING_COLOR, lang).join(
            ", "
          )
        )}
      {isBiohazard && validTableRow(t("biohazard"), t("yes"), <Biohazard />)}
      {getBooleanAttribute(product, ProductAttribute.ORGANIC_PRODUCT) &&
        validTableRow(t("organicProduct"), t("yes"), <Organic />)}
      {validTableRow(
        t("countryOfOriginForIngredients"),
        getAttribute(
          product,
          ProductAttribute.COUNTRY_OF_ORIGIN_FOR_INGREDIENTS,
          lang
        ).join(", ")
      )}
      {validTableRow(t("brand"), getBrand(product, lang).join(", "))}
    </div>
  );
  const productHasCELicensed = () => {
    const getProductCELicensedAttribute = product.attributes?.filter(
      a => a.attribute === "CE-Licensed"
    );

    return (
      getProductCELicensedAttribute?.length &&
      getProductCELicensedAttribute[0].value === "Yes"
    );
  };
  const materialGroupIcons = () => {
    const iconsList = [];
    if (productHasCELicensed()) {
      iconsList.push({
        img: CELicensedImg,
        key: "CE-marking",
      });
    }
    if (getMaterialGroup(product) === "P.3.1.1") {
      iconsList.push({
        img: IVDImg,
        key: "ivd-marking",
      });
    } else if (getMaterialGroup(product) === "P.3.1.2") {
      iconsList.push({
        img: MDImg,
        key: "md-marking",
      });
    }

    return iconsList;
  };
  return (
    <div>
      {validTableRow(t("vnr"), product.vnr)}
      {validTableRow(t("msi"), product.msiCode)}
      {validTableRow(t("oriolaProductNumber"), product.materialId)}
      {validTableRow(
        t("activeIngredients"),
        // TODO maybe change this key
        getAttribute(
          product,
          ProductAttribute.CONTENT_SUBSTANCE_NAME,
          lang
        ).join(", ")
      )}
      {validTableRow(
        t("storageTemperature"),
        getAttribute(product, ProductAttribute.STORAGE_TEMPERATURE, lang).join(
          ", "
        ),
        coldStorage === true || freezerStorage === true ? <Snowflake /> : null
      )}
      {validTableRow(
        t("form"),
        getAttribute(product, ProductAttribute.PHARMACEUTICAL_FORM, lang)
      )}
      {validTableRow(t("strength"), getStrengthStr(product, lang))}
      {validTableRow(t("strength"), getStrength2Str(product, lang))}
      {validTableRow(
        t("innerPackage"),
        getAttribute(product, ProductAttribute.INNER_PACKAGE, lang).join(", ")
      )}
      {validTableRow(
        t("materialGroup"),
        getMaterialGroup(product),
        materialGroupIcons()
      )}
      {validTableRow(t("marketingAuth"), getMarketingAuthorization(product))}
      {validTableRow(
        t("marketingAuthorizationHolder"),
        getAttribute(
          product,
          ProductAttribute.MARKETING_AUTHORIZATION_HOLDER,
          lang
        ).join(", ")
      )}
      {validTableRow(
        t("marketingAuthorizationNumber"),
        getMarketingAuthorizationNumber(product)
      )}
      {validTableRow(t("supplier"), getSupplier(product))}
      {validTableRow(t("importer"), product.importer)}
      {validTableRow(t("replaces"), getReplaces(product).join(", "))}
      {validTableRow(t("replacedBy"), getReplacedBy(product).join(", "))}
      {validTableRow(
        t("supplierProductGroup"),
        getSupplierProductGroup(product)
      )}
      {validTableRow(
        t("supplierProductFamily"),
        getSupplierProductFamily(product)
      )}
      {validTableRow(
        t("narcoticsClass"),
        isNarcotic ? narcoticsClass : null,
        isNarcotic && <Narcotics />
      )}
      {showAll && renderHidden()}
      <IconTextButton
        text={buttonText}
        icon={!showAll ? <More /> : <Less />}
        onClick={() => setShowAll(!showAll)}
      />
    </div>
  );
}

ProductDetailsTable.propTypes = {
  product: PropTypes.shape({
    vnr: PropTypes.string,
    msiCode: PropTypes.string,
    materialId: PropTypes.string,
    attributes: PropTypes.arrayOf(
      PropTypes.shape({
        attribute: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    countryOfOrigin: PropTypes.string,
    importer: PropTypes.string,
    marketingAuthorizationHolder: PropTypes.string,
    marketingAuthorizationNumber: PropTypes.string,
    supplier: PropTypes.string,
    replaces: PropTypes.arrayOf(PropTypes.string),
    replacedBy: PropTypes.arrayOf(PropTypes.string),
    supplierProductGroup: PropTypes.string,
    supplierProductFamily: PropTypes.string,
    narcoticsClass: PropTypes.string,
  }),
};

ProductDetailsTable.defaultProps = {
  product: {},
};

export default ProductDetailsTable;

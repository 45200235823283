import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// eslint-disable-next-line
import OpenOrdersTableRow from "./openOrdersTableRow";
import { HeaderCell } from "../openOrdersBackOrdersCells";
import styles from "../tableStyles";

const useStyles = makeStyles(styles);

function OpenOrdersTable({ orders }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const orderRow = order => (
    <OpenOrdersTableRow
      key={`open-orders-table-row-${order.orderId}`}
      order={order}
      classes={classes}
    />
  );

  const renderTable = () => (
    <Table>
      <TableHead className={classes.tableHead}>
        <TableRow>
          <HeaderCell>{t("openOrdersTable.orderRows")}</HeaderCell>
          <HeaderCell>{t("openOrdersTable.productsTotal")}</HeaderCell>
          <HeaderCell>{t("openOrdersTable.orderDate")}</HeaderCell>
          <HeaderCell>{t("openOrdersTable.deliveryAddress")}</HeaderCell>
          <HeaderCell>{t("openOrdersTable.orderNumber")}</HeaderCell>
          <HeaderCell>{t("openOrdersTable.poNumber")}</HeaderCell>
          <HeaderCell>{t("openOrdersTable.price")}</HeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>{orders.map(order => orderRow(order))}</TableBody>
    </Table>
  );

  if (!orders || orders.length === 0) {
    return null;
  }

  return <div>{renderTable()}</div>;
}

OpenOrdersTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({})),
};

OpenOrdersTable.defaultProps = {
  orders: [],
};

export default OpenOrdersTable;

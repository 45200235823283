import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";

const { ProductAttribute, getSingleValuedAttribute } = ProductUtil;

const useStyles = makeStyles(theme => ({
  titleMargin: {
    marginBottom: theme.spacing(0.5),
  },
}));

function NutritionInfo({ product }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const verifyNumberDecimal = value => {
    if (value && !Number.isNaN(value)) {
      const localized = Number(value).toLocaleString(i18n.language, {
        maximumFractionDigits: 1,
      });
      return localized;
    }
    return value;
  };

  const createEntry = (titleKey, attribute) => ({
    title: t(titleKey),
    value: verifyNumberDecimal(
      getSingleValuedAttribute(product, attribute, i18n.language)
    ),
  });

  let entries = [];
  entries[0] = createEntry("carbohydrate", ProductAttribute.CARBOHYDRATES);
  entries[1] = createEntry("portionSize", ProductAttribute.PORTION_SIZE);
  entries[2] = createEntry("energyKcal", ProductAttribute.ENERGY_KCAL);
  entries[3] = createEntry("energyKJ", ProductAttribute.ENERGY_KJ);
  entries[4] = createEntry("fat", ProductAttribute.FAT);
  entries[5] = createEntry("fiber", ProductAttribute.FIBER);
  entries[6] = createEntry("protein", ProductAttribute.PROTEIN);
  entries[7] = createEntry("salt", ProductAttribute.SALT);
  entries[8] = createEntry("saturatedFat", ProductAttribute.SATURATED_FAT);
  entries[9] = createEntry("sugar", ProductAttribute.SUGAR);
  entries[10] = createEntry("sodium", ProductAttribute.SODIUM);
  entries = entries
    .filter(entry => entry.value != null)
    .map(entry => `${entry.title} ${entry.value}`);

  if (entries.length > 0) {
    return (
      <div>
        <Typography
          variant="subtitle2"
          color="textPrimary"
          noWrap
          className={classes.titleMargin}
        >
          {t("nutritionInformation")}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {entries.join(", ")}
        </Typography>
      </div>
    );
  }
  return null;
}

NutritionInfo.propTypes = {
  product: PropTypes.shape({}),
};

NutritionInfo.defaultProps = {
  product: {},
};

export default NutritionInfo;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
// eslint-disable-next-line
import OrderHistoryTableRow from "./orderHistoryTableRow";
import { findCustomerById } from "../../../../../utils/customer/customer";
import { Cell, MobileHiddenCell } from "./cells";
import { InfinityList } from "../../../../generic";
import colors from "../../../../../theme/colors";

export const PRODUCTS_ON_PAGE = 15;
const SINGLE_ROW_HEIGHT = 110;
const DOUBLE_ROW_HEIGHT = 130;
const TRIPLE_ROW_HEIGHT = 155;

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: "2rem",
    marginRight: "2rem",
  },
  tableWrapper: {
    width: "100%",
    paddingBottom: theme.spacing(4),
  },
  fixedHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: `1px solid ${colors.backgroundDarkGray}`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // compensate selection bar and scrollbar
    borderLeft: "4px solid transparent",
    borderRight: "2px solid transparent",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  orderRowWrapper: {
    borderLeftWidth: theme.spacing(0.5),
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.text.primary,
    borderBottom: `solid 1px ${colors.backgroundDarkGray}`,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  orderRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  rowPadding: {
    paddingTop: theme.spacing(1),
  },
  orderDateColumn: {
    width: "11%",
    [theme.breakpoints.down("sm")]: {
      width: "25%",
    },
  },
  deliveryAddressColumn: {
    width: "31%",
  },
  orderNoColumn: {
    width: "14%",
    [theme.breakpoints.down("sm")]: {
      width: "25%",
    },
  },
  poNumberColumn: {
    width: "15%",
  },
  deliveryStatusColumn: {
    width: "15%",
  },
  totalPriceColumn: {
    width: "14%",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  cellTextHeader: {
    ...theme.typography.button,
    color: theme.palette.text.disabled,
    textTransform: "none",
  },
  cellTextValue: {
    ...theme.typography.button,
    color: theme.palette.text.primary,
    textTransform: "none",
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(0.5),
  },
  backOrderContainer: {
    marginTop: theme.spacing(3),
  },
  badgeContainer: {
    paddingRight: theme.spacing(2),
  },
  badge: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "0.75rem",
  },
  deliveryAddressCell: {
    border: "none",
    padding: 0,
  },
  rejectedRow: {
    paddingTop: theme.spacing(0.5),
  },
}));

function OrderHistoryTable({
  orders,
  customers,
  selectedCustomerId,
  overallOrderCount,
  fetchingOrders,
  onRequestOrders,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderHeaderText = text => (
    <Typography className={classes.cellTextHeader}>{text}</Typography>
  );

  const renderFixedHeader = () => (
    <div className={classes.fixedHeader}>
      <Cell className={classes.orderDateColumn}>
        {renderHeaderText(t("orderDate"))}
      </Cell>
      <MobileHiddenCell className={classes.deliveryAddressColumn}>
        {renderHeaderText(t("deliveryAddress"))}
      </MobileHiddenCell>
      <Cell className={classes.orderNoColumn}>
        {renderHeaderText(t("orderNo"))}
      </Cell>
      <MobileHiddenCell className={classes.poNumberColumn}>
        {renderHeaderText(t("PONumber"))}
      </MobileHiddenCell>
      <MobileHiddenCell className={classes.deliveryStatusColumn}>
        {renderHeaderText(t("deliveryStatus"))}
      </MobileHiddenCell>
      <Cell className={classes.totalPriceColumn}>
        {renderHeaderText(t("totalPrice"))}
      </Cell>
    </div>
  );

  const rowRenderer = ({ key, style, index }) => {
    const order = orders[index];
    const customer = findCustomerById(selectedCustomerId, customers);
    return (
      <div key={key} style={style}>
        <OrderHistoryTableRow
          key={key}
          order={order}
          customer={customer}
          classes={classes}
          height={style.height}
        />
      </div>
    );
  };

  const getRowHeight = ({ index }) => {
    const order = orders[index];
    let height = SINGLE_ROW_HEIGHT;

    if (order.hasBackorders && order.displayRejectedLines) {
      height = TRIPLE_ROW_HEIGHT;
    } else if (order.hasBackorders || order.displayRejectedLines) {
      height = DOUBLE_ROW_HEIGHT;
    }
    return height;
  };

  return (
    <div className={classes.root}>
      {renderFixedHeader()}
      <div className={classes.tableWrapper}>
        <InfinityList
          items={orders}
          visibleItemCount={PRODUCTS_ON_PAGE}
          itemOverallCount={overallOrderCount}
          getRowHeight={getRowHeight}
          rowSpacing={0}
          isLoading={fetchingOrders}
          onRenderRow={rowRenderer}
          onLoad={onRequestOrders}
        />
      </div>
    </div>
  );
}

OrderHistoryTable.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      orderDate: PropTypes.string,
      orderNo: PropTypes.string,
      poNumber: PropTypes.string,
      deliveryStatus: PropTypes.string,
      totalPrice: PropTypes.number,
      hasBackorders: PropTypes.bool,
      displayRejectedLines: PropTypes.bool,
    })
  ).isRequired,
  customers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedCustomerId: PropTypes.string.isRequired,
  overallOrderCount: PropTypes.number.isRequired,
  fetchingOrders: PropTypes.bool.isRequired,
  onRequestOrders: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
};

OrderHistoryTable.defaultProps = {
  style: null,
};

export default OrderHistoryTable;

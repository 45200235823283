import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import colors from "../../../theme/colors";

const useStyles = makeStyles(theme => ({
  linkContainer: {
    marginLeft: theme.spacing(2),
  },
  link: {
    lineHeight: 1.5,
    letterSpacing: "0.35px",
    borderBottom: "2px",
    borderBottomColor: colors.highlightBlue,
    borderBottomStyle: "solid",
    cursor: "pointer",
  },
}));

function OrigoLink(props) {
  const classes = useStyles();
  const { text, onClick } = props;

  return (
    <div className={classes.linkContainer}>
      <Typography
        className={classes.link}
        variant="body1"
        onClick={onClick}
        noWrap
      >
        {text}
      </Typography>
    </div>
  );
}

OrigoLink.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

OrigoLink.defaultProps = {
  text: "",
  onClick: () => {}, // eslint-disable-line
};

export default OrigoLink;

import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Checkbox } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import colors from "../../../theme/colors";
import { ArrowRight } from "../../../images";
import getProductCategoryLocalizedName from "../../../utils/productCategoryUtil/productCategoryUtil";
// eslint-disable-next-line
import { TooltipTypography } from "../../generic";

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: `solid 1px ${colors.backgroundDarkGray}`,
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  button: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    width: "100%",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    width: "100%",
    color: theme.palette.text.primary,
  },
  title: {
    ...theme.typography.body1,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginRight: theme.spacing(0.5),
  },
  count: {
    whiteSpace: "nowrap",
  },
  arrowContainer: {
    padding: theme.spacing(1.5),
  },
  arrowContainerOpened: {
    padding: theme.spacing(1.5),
    backgroundColor: colors.backgroundLightBlue,
  },
}));

function ProductCategoryRow({
  category,
  selected,
  onSelectClick,
  opened,
  onOpenClick,
  disableOpen,
}) {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const name = getProductCategoryLocalizedName(i18n.language, category);
  const nameText = `${name || "-"}`;
  const count = `(${category.count})`;
  const arrowClass = opened
    ? classes.arrowContainerOpened
    : classes.arrowContainer;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Button
          className={classes.button}
          onClick={() => {
            if (disableOpen === true) {
              onSelectClick(category, true);
            } else {
              onOpenClick(category);
            }
          }}
        >
          <Checkbox
            style={{ color: colors.secondaryNavyBlue }}
            className={classes.checkbox}
            checked={selected}
            onChange={() => onSelectClick(category, false)}
            onClick={e => e.stopPropagation()}
          />
          <div className={classes.titleContainer}>
            <TooltipTypography className={classes.title} tooltip={nameText}>
              {nameText}
            </TooltipTypography>
            <Typography>{count}</Typography>
          </div>

          {disableOpen === false && (
            <div className={arrowClass}>
              <ArrowRight />
            </div>
          )}
        </Button>
      </div>
    </div>
  );
}

ProductCategoryRow.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
  }).isRequired,
  opened: PropTypes.bool.isRequired,
  onOpenClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelectClick: PropTypes.func.isRequired,
  disableOpen: PropTypes.bool.isRequired,
};

ProductCategoryRow.defaultProps = {};

export default ProductCategoryRow;

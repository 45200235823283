import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { formatCurrency } from "../../../utils/currency/currency";
import Vat from "./vat";

const useStyles = makeStyles(theme => ({
  priceBlockBackgroundDisabled: {
    backgroundColor: theme.palette.text.disabled,
    borderWidth: "1px",
    borderColor: theme.palette.text.disabled,
    borderRadius: "5px",
    display: "inline-block",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(0.2),
      paddingRight: theme.spacing(0.2),
    },
  },
  priceBlockBackground: {
    display: "inline-block",
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(0.2),
      paddingRight: theme.spacing(0.2),
    },
  },
  priceAndText: {
    display: "flex",
    alignItems: "baseline",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  boldPrice: {
    ...theme.typography.button,
    fontWeight: "bold",
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    fontSize: "1.1rem",
    [theme.breakpoints.down("lg")]: {
      marginRight: theme.spacing(0.5),
    },
  },
  originalPrice: {
    color: theme.palette.text.disabled,
    textDecoration: "line-through",
    fontSize: "0.8rem",
  },
  description: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.text.secondary,
  },
  textColorCampaign: {
    color: theme.palette.text.disabled,
  },
}));

function BestPrice({
  bestPrice,
  originalPrice,
  currency,
  disabled,
  vatPrice,
  vatRate,
  showVatPercent,
  showVat,
}) {
  const classes = useStyles();
  let containerClass = classes.priceBlockBackground;
  if (disabled === true) {
    // is campaign but still disabled
    containerClass = classes.priceBlockBackgroundDisabled;
  }

  return (
    <div className={containerClass}>
      <div className={classes.priceAndText}>
        <Typography className={classes.boldPrice}>
          {formatCurrency(bestPrice, currency) || "-"}
        </Typography>
        <Typography className={classes.originalPrice}>
          {formatCurrency(originalPrice, currency) || "-"}
        </Typography>
      </div>
      {showVat === true && (
        <Vat
          vatRate={vatRate}
          vatPrice={vatPrice}
          currency={currency}
          className={classes.textColorCampaign}
          showPercent={showVatPercent}
        />
      )}
    </div>
  );
}

BestPrice.propTypes = {
  bestPrice: PropTypes.string.isRequired,
  currency: PropTypes.string,
  disabled: PropTypes.bool,
  originalPrice: PropTypes.string,
  showVat: PropTypes.bool,
  vatPrice: PropTypes.string,
  vatRate: PropTypes.string,
  showVatPercent: PropTypes.bool,
};

BestPrice.defaultProps = {
  vatRate: "24",
  currency: "EUR",
  originalPrice: "0",
  disabled: false,
  showVat: true,
  vatPrice: "0",
  showVatPercent: true,
};

export default BestPrice;

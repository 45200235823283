import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import {
  UncheckedCheckbox,
  CheckedCheckbox,
  DisabledCheckbox,
} from "../../../images";
import "./checkboxItem.css";

function CheckboxItem({
  onChange,
  checked,
  disabled,
  value,
  label,
  extraText,
  showExtraText,
}) {
  return (
    <div className="checkbox-item">
      <div className="checkbox-item__row">
        <FormControlLabel
          control={
            <Checkbox
              className="checkbox-element"
              onChange={onChange}
              checked={checked}
              icon={disabled ? <DisabledCheckbox /> : <UncheckedCheckbox />}
              checkedIcon={
                !disabled && checked ? (
                  <CheckedCheckbox />
                ) : (
                  <DisabledCheckbox />
                )
              }
              disabled={disabled}
            />
          }
          value={value}
          label={label}
        />
        <p>{value}</p>
      </div>
      {showExtraText && (
        <p className="checkbox-item__extra-text">{extraText}</p>
      )}
    </div>
  );
}

CheckboxItem.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  extraText: PropTypes.string,
  showExtraText: PropTypes.bool,
};

CheckboxItem.defaultProps = {
  onChange: () => {}, // eslint-disable-line
  checked: false,
  disabled: false,
  value: "",
  label: "",
  extraText: "",
  showExtraText: false,
};

export default CheckboxItem;

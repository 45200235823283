import getNumber from "../getNumber/getNumber";
import { sortByProductName } from "../orderUtil/orderUtil"; // eslint-disable-line import/no-cycle

const getDeliveredQuantity = orderLine => getNumber(orderLine.delQuantity);
const getInvoicedQuantity = orderLine => getNumber(orderLine.invQuantity);
const getOpenQuantity = orderLine => getNumber(orderLine.openQuantity);
const getConfirmedQuantity = orderLine =>
  getNumber(orderLine.confirmedQuantity);
const isOrderLineRejected = orderLine => orderLine.rejected === "Y";
export const isOrderLineBackorder = item =>
  item.backOrders === "Y" && item.rejected !== "Y";

const deliveryIsInTheFuture = orderLine => {
  if (!orderLine.deliveryDate) {
    return false;
  }

  return new Date(orderLine.deliveryDate) > new Date();
};

// Undeliverable:
// a) there is open quantity but no further deliveries
// or
// b) part has been delivered, there is confirmed quantity left but no further deliveries
const isOrderLineUndeliverable = orderLine => {
  if (orderLine.rejected === "Y") {
    return false;
  }
  const noFurtherDeliveries = orderLine.furtherDlv === "N";
  const openQty = getOpenQuantity(orderLine);
  const confirmedQty = getConfirmedQuantity(orderLine);
  const somethingDelivered =
    getDeliveredQuantity(orderLine) + getInvoicedQuantity(orderLine) > 0;

  return (
    noFurtherDeliveries &&
    (openQty > 0 || (confirmedQty > 0 && somethingDelivered))
  );
};

export const isOrderLineOpen = orderLine => {
  if (isOrderLineRejected(orderLine)) {
    return false;
  }
  if (orderLine.confirmedQuantity > 0) {
    const nothingDelivered =
      getDeliveredQuantity(orderLine) + getInvoicedQuantity(orderLine) === 0;
    return !isOrderLineUndeliverable(orderLine) || nothingDelivered;
  }
  if (deliveryIsInTheFuture(orderLine) && orderLine.openQuantity > 0) {
    return true;
  }

  return false;
};

export const hasAllOpenOrderLines = order => {
  const lines = (order && order.items) || [];
  return lines.every(isOrderLineOpen);
};

export const hasBackOrderLines = order => {
  const lines = (order && order.items) || [];
  return lines.some(isOrderLineBackorder);
};

const getOrderId = orderLine =>
  (orderLine && orderLine.order && orderLine.order.orderId) || "";
const comp = (a, b) => {
  if (a === b) {
    return 0;
  }
  return a < b ? -1 : +1;
};

export const sortBackOrderlinesByName = backOrderLines => {
  backOrderLines.sort((a, b) => {
    const res = sortByProductName(a, b);
    if (res !== 0) {
      return res;
    }
    return comp(getOrderId(a), getOrderId(b));
  });

  return backOrderLines;
};

/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import moment from "moment";
import {
  templateNameExists,
  findTemplateByName,
} from "../../../utils/templateUtil/templateUtil";
// eslint-disable-next-line
import { templateCreate } from "../../../redux/reducers";
import { Progress, PopupLayout } from "../../generic";
import { More } from "../../../images";
import {
  findCustomerById,
  getDefaultDeliveryAddress,
} from "../../../utils/customer/customer";

const useStyles = makeStyles(theme => ({
  selectTitle: {
    color: theme.palette.text.disabled,
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  select: {
    backgroundColor: theme.palette.background.default,
  },
  menuItemText: {
    color: theme.palette.text.disabled,
    textTransform: "none",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  createButton: {
    marginBottom: theme.spacing(3),
    textTransform: "none",
    paddingLeft: 0,
  },
  createButtonText: {
    marginLeft: theme.spacing(1),
  },
  createContainer: {
    marginBottom: theme.spacing(3),
  },
  createLabel: {
    color: theme.palette.text.disabled,
  },
  createInputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  createInput: {
    width: "80%",
    marginRight: theme.spacing(1),
  },
  inputError: {
    color: "red",
    backgroundColor: theme.palette.background.paper,
  },
}));

function TemplateAddPopup({
  show,
  top,
  left,
  bottom,
  right,
  onSave,
  onCancel,
  popupRef,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { orderTemplates, sendingTemplate } = useSelector(
    state => state.template
  );
  const { userId } = useSelector(state => state.user.userData);
  const { selectedCustomerId, customers } = useSelector(
    state => state.customer
  );
  const dispatch = useDispatch();
  const [selectedTemplate, setSelectedTemplate] = useState("empty");
  const [showInput, setShowInput] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [inputError, setInputError] = useState(null);

  useEffect(() => {
    if (orderTemplates.length !== 0) {
      orderTemplates.sort((a, b) =>
        moment(a.updatedDate).isSameOrAfter(moment(b.updatedDate)) ? -1 : 1
      );
      setSelectedTemplate(orderTemplates[0].name);
    }
  }, [orderTemplates]);

  const disableSave = () => {
    if (showInput && newTemplateName === "") {
      return true;
    }
    if (!showInput && selectedTemplate === "empty") {
      return true;
    }
    return false;
  };

  const handleSave = () => {
    // create new shopping template?
    if (newTemplateName && showInput) {
      if (!templateNameExists(newTemplateName, orderTemplates)) {
        // create
        const customer = findCustomerById(selectedCustomerId, customers);
        const deliveryAddress = getDefaultDeliveryAddress(customer);
        const newTemplate = {
          name: newTemplateName,
          products: [],
          customer: selectedCustomerId,
          shipToAddress: deliveryAddress ? deliveryAddress.ShipTo : undefined,
        };
        dispatch(templateCreate(userId, newTemplate)).then(createdTemplate => {
          if (createdTemplate) {
            onSave(createdTemplate);
            setShowInput(false);
            setNewTemplateName("");
          } else {
            setInputError(t("networkError"));
          }
        });
      } else {
        setInputError(t("nameAlreadyUsed"));
      }
    } else {
      const template = findTemplateByName(selectedTemplate, orderTemplates);
      onSave(template);
    }
  };

  const renderCreateTemplateButton = () => (
    <Button className={classes.createButton} onClick={() => setShowInput(true)}>
      <More />
      <div className={classes.createButtonText}>
        {t("createNewOrderTemplate")}
      </div>
    </Button>
  );

  const renderCreateInput = () => {
    const onChange = event => {
      // if user selects template and then
      // starts to create new one. Clear.
      setSelectedTemplate("empty");
      setInputError(null);
      setNewTemplateName(event.target.value);
    };

    return (
      <div className={classes.createContainer}>
        <Typography className={classes.createLabel} variant="body1">
          {t("createNewOrderTemplate")}
        </Typography>
        <div className={classes.createInputContainer}>
          <TextField
            className={classes.createInput}
            margin="dense"
            placeholder={t("writeOrderTemplateName")}
            size="small"
            variant="outlined"
            value={newTemplateName}
            onChange={event => onChange(event)}
            inputProps={{ maxLength: 30 }}
          />
          <Progress show={sendingTemplate} />
        </div>
      </div>
    );
  };

  const onTemplateSelected = event => {
    // if the user chooses template from the list
    // and has input open, close it and clear
    setShowInput(false);
    setNewTemplateName("");
    setSelectedTemplate(event.target.value);
  };

  const sortedOrderTemplates = orderTemplates.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <PopupLayout
      show={show}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      onOk={() => handleSave()}
      onCancel={() => onCancel()}
      disableSave={disableSave()}
      popupRef={popupRef}
    >
      <Typography variant="h6" color="textPrimary">
        {t("addProductToOrderTemplate")}
      </Typography>
      <Typography className={classes.selectTitle} variant="body1">
        {t("selectOrderTemplate")}
      </Typography>
      <FormControl className={classes.formControl}>
        <Select
          disableUnderline
          value={selectedTemplate}
          displayEmpty
          onChange={event => onTemplateSelected(event)}
          className={classes.select}
          renderValue={value => {
            let text = t("select");
            if (value !== "empty") {
              text = value;
            }
            return (
              <Typography
                className={classes.menuItemText}
                color="textPrimary"
                variant="button"
              >
                {text}
              </Typography>
            );
          }}
        >
          {sortedOrderTemplates.map(template => (
            <MenuItem key={template.templateId} value={template.name}>
              <Typography
                className={classes.menuItemText}
                color="textPrimary"
                variant="button"
              >
                {template.name}
              </Typography>
            </MenuItem>
          ))}
        </Select>
        {inputError && (
          <FormHelperText className={classes.inputError}>
            {inputError}
          </FormHelperText>
        )}
      </FormControl>

      {!showInput && renderCreateTemplateButton()}
      {showInput && renderCreateInput()}
    </PopupLayout>
  );
}

TemplateAddPopup.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  bottom: PropTypes.number,
  right: PropTypes.number,
  show: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  popupRef: PropTypes.func,
};

TemplateAddPopup.defaultProps = {
  top: undefined,
  left: undefined,
  bottom: undefined,
  right: undefined,
  show: false,
  onSave: () => {},
  onCancel: () => {},
  popupRef: () => {},
};

export default TemplateAddPopup;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PopupLayout from "../popupLayout/popupLayout";
import StockNotificationSelection from "../stockNotificationSelection";

const useStyles = makeStyles(theme => ({
  content: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
    gridRowGap: "16px",
    marginTop: theme.spacing(4),
    marginBottom: "32px",
  },
  description: {
    color: theme.palette.text.disabled,
    textAlign: "left",
    marginLeft: "20px",
    width: "100%",
  },
}));

function ConfirmationPopup({
  title,
  description,
  show,
  top,
  left,
  bottom,
  right,
  onOk,
  onCancel,
  popupRef,
  saveKey,
  cancelKey,
  showLoader,
  iconType,
  content,
  disableSave,
}) {
  const classes = useStyles();

  const ICONS = {
    shoppingCart: require("../../../images/shopping_cart.svg").default,
    subscription: require("../../../images/emailUnread.svg").default,
  };

  return (
    <PopupLayout
      show={show}
      left={left}
      top={top}
      right={right}
      bottom={bottom}
      onOk={onOk}
      onCancel={onCancel}
      popupRef={popupRef}
      saveKey={saveKey}
      cancelKey={cancelKey}
      showLoader={showLoader}
      title={title}
      disableSave={disableSave}
    >
      <div className={classes.content}>
        {iconType && <img src={ICONS[iconType]} alt="" />}
        <Typography className={`${classes.description}`} variant="body1">
          {description}
        </Typography>
        {content &&
          content.contentType &&
          content.contentType === "stockNotification" && (
            <StockNotificationSelection {...content} />
          )}
      </div>
    </PopupLayout>
  );
}

ConfirmationPopup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  top: PropTypes.number,
  left: PropTypes.number,
  bottom: PropTypes.number,
  right: PropTypes.number,
  show: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  popupRef: PropTypes.func,
  saveKey: PropTypes.string,
  cancelKey: PropTypes.string,
  showLoader: PropTypes.bool,
  iconType: PropTypes.string,
  content: PropTypes.shape({
    contentType: PropTypes.string,
  }),
  disableSave: PropTypes.bool,
};

ConfirmationPopup.defaultProps = {
  title: "",
  description: "",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  show: false,
  onOk: () => {}, // eslint-disable-line
  onCancel: () => {}, // eslint-disable-line
  popupRef: () => {}, // eslint-disable-line
  saveKey: "",
  cancelKey: "cancel",
  showLoader: false,
  iconType: "",
  content: {},
  disableSave: false,
};

export default ConfirmationPopup;

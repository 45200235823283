import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { userReducer } from "./user/user";
import { productReducer } from "./product/product";
import { cartReducer } from "./cart/cart";
import { templateReducer } from "./template/template";
import { stockService } from "./stock/stock";
import { availabilityService } from "./availability/availability";
import { customerReducer } from "./customer/customer";
import { orderReducer } from "./order/order";
import { notificationReducer } from "./notification/notification";
import { notificationPopupReducer } from "./notificationPopup/notificationPopup";
import { makingOrderDialogReducer } from "./makingOrderDialog/makingOrderDialog";
import { versionsReducer } from "./versions/versions";
import { announcementsReducer } from "./announcements/announcements";
import { invoiceReducer } from "./invoices/invoices";
import { productCategoryReducer } from "./productCategories/productCategories";
import { backorderPopupReducer } from "./backorderPopup/backorderPopup";

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    product: productReducer,
    cart: cartReducer,
    template: templateReducer,
    stock: stockService,
    availability: availabilityService,
    customer: customerReducer,
    order: orderReducer,
    notification: notificationReducer,
    notificationPopup: notificationPopupReducer,
    makingOrderDialog: makingOrderDialogReducer,
    versions: versionsReducer,
    announcements: announcementsReducer,
    invoice: invoiceReducer,
    productCategory: productCategoryReducer,
    backorderPopup: backorderPopupReducer,
  });
export default createRootReducer;

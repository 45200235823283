/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  CustomerAndShippingAddressSelect,
  MemoInput,
  OrigoTextButton,
  Progress,
} from "../../generic";
import { templateCreate } from "../../../redux/reducers";
import colors from "../../../theme/colors";
import {
  findCustomerById,
  removeInvalidCustomersAndAddresses,
} from "../../../utils/customer/customer";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  header: {
    display: "flex",
    alignItems: "flex-end",
    paddingTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  headerText: {
    marginRight: theme.spacing(2),
  },
  memoContainer: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  cartText: {
    color: theme.palette.text.disabled,
  },
  bottomControlsContainer: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  customerAndAddressContainer: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
  additionalInfoContainer: {
    marginBottom: theme.spacing(4),
  },
  additionalInfoInputContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  additionalInfoTitleText: {
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(2),
  },
  customerSelect: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: theme.spacing(0.5),
    },
  },
  inputField: {
    marginRight: theme.spacing(2),
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: theme.spacing(0),
    },
  },
  buttonsContainer: {
    paddingBottom: theme.spacing(4),
  },
  buttonsRow: {
    display: "flex",
    flexDirection: "row",
  },
  button: {
    textTransform: "none",
    backgroundColor: colors.backgroundGray,
  },
  error: {
    marginTop: theme.spacing(1),
  },
  progress: {
    marginRight: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    backgroundColor: "yellow",
  },
}));

function NewOrderTemplate({ onCreated, onCancel }) {
  const classes = useStyles();
  const { userId, organizationIds, defaultOrganizationId } = useSelector(
    state => state.user.userData
  );
  const customers = useSelector(state => state.customer.customers);
  const { sendingTemplate, templateSendError } = useSelector(
    state => state.template
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [validCustomers, setValidCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(undefined);
  const [selectedShippingAddress, setSelectedShippingAddress] =
    useState(undefined);
  const [noteValue, setNoteValue] = useState("");
  const [templateName, setTemplateName] = useState("");

  useEffect(() => {
    // scroll always to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
    const validCustomers = removeInvalidCustomersAndAddresses(customers);
    setValidCustomers(validCustomers);

    // set default customer and shipping address
    const defaultCustomer =
      findCustomerById(defaultOrganizationId, validCustomers) ||
      validCustomers.find(c => organizationIds.includes(c.customerId));
    setSelectedCustomer(defaultCustomer);
    if (
      defaultCustomer &&
      defaultCustomer.deliveryAddresses &&
      defaultCustomer.deliveryAddresses.length === 1
    ) {
      setSelectedShippingAddress(defaultCustomer.deliveryAddresses[0]);
    }
  }, [customers, organizationIds, defaultOrganizationId, setValidCustomers]);

  const renderHeader = () => (
    <div className={classes.header}>
      <Typography
        variant="h4"
        color="textPrimary"
        className={classes.headerText}
        noWrap
      >
        {t("createNewOrderTemplate")}
      </Typography>
    </div>
  );

  const renderNameControl = () => {
    const title = t("orderTemplateName");
    const placeholder = t("writeOrderTemplateName");
    const info = null;
    return (
      <div className={classes.memoContainer}>
        <MemoInput
          title={title}
          placeholder={placeholder}
          info={info}
          value={templateName || ""}
          onChange={event => setTemplateName(event.target.value)}
          maxLength={30}
        />
      </div>
    );
  };

  const renderMemoControl = () => {
    const title = `${t("yourMemo")} (${t("optional").toLowerCase()})`;
    const placeholder = t("writeMemo");
    const info = t("thisVisibleOnlyYou");
    return (
      <div className={classes.memoContainer}>
        <MemoInput
          title={title}
          placeholder={placeholder}
          info={info}
          value={noteValue || ""}
          onChange={event => setNoteValue(event.target.value)}
          maxLength={250}
        />
      </div>
    );
  };

  const renderCustomerAndShippingAddressSelection = () => (
    <div className={classes.customerAndAddressContainer}>
      <CustomerAndShippingAddressSelect
        customers={validCustomers}
        className={classes.customerSelect}
        value={{
          customer: selectedCustomer,
          shippingAddress: selectedShippingAddress,
        }}
        onSelect={(customer, shippingAddress) => {
          setSelectedCustomer(customer);
          setSelectedShippingAddress(shippingAddress);
        }}
      />
    </div>
  );

  const onSaveTemplate = () => {
    const template = {
      name: templateName,
      note: noteValue,
      customer: selectedCustomer ? selectedCustomer.customerId : undefined,
      shipToAddress: selectedShippingAddress
        ? selectedShippingAddress.ShipTo
        : undefined,
      products: [],
    };
    dispatch(templateCreate(userId, template)).then(createdTemplate => {
      if (createdTemplate) {
        onCreated(createdTemplate);
      }
      // TODO: handle errors
    });
  };

  const isSaveDisabled = () => !templateName;

  const renderBottomButtons = () => (
    <div className={classes.buttonsContainer}>
      <div className={classes.buttonsRow}>
        <OrigoTextButton disabled={sendingTemplate} onClick={onCancel}>
          {t("cancel")}
        </OrigoTextButton>
        <OrigoTextButton
          color="secondary"
          disabled={isSaveDisabled() || sendingTemplate}
          onClick={onSaveTemplate}
        >
          {t("save")}
        </OrigoTextButton>
        <Progress show={sendingTemplate} />
      </div>
      {templateSendError && (
        <Typography className={classes.error} color="error">
          {`${t("networkError")} ${templateSendError.status}`}
        </Typography>
      )}
    </div>
  );

  const renderBottomControls = () => (
    <div className={classes.bottomControlsContainer}>
      {renderCustomerAndShippingAddressSelection()}
      {renderBottomButtons()}
    </div>
  );

  return (
    <Paper className={classes.root}>
      {renderHeader()}
      {renderNameControl()}
      {renderMemoControl()}
      {renderBottomControls()}
    </Paper>
  );
}

NewOrderTemplate.propTypes = {
  onCreated: PropTypes.func,
  onCancel: PropTypes.func,
};

NewOrderTemplate.defaultProps = {
  onCreated: () => {}, // eslint-disable-line
  onCancel: () => {}, // eslint-disable-line
};

export default NewOrderTemplate;

const filterOrder = (order, items, lcSearchValue) => {
  const { customerId, poNumber, orderId } = order;

  const searchableValues = [];
  searchableValues.push(customerId, poNumber, orderId);

  // eslint-disable-next-line
  for (const item of items) {
    searchableValues.push(item.productId, item.productName, item.vnr);
  }

  // eslint-disable-next-line
  for (const searchableValue of searchableValues) {
    if ((searchableValue || "").toLocaleLowerCase().startsWith(lcSearchValue)) {
      return true;
    }
  }

  return false;
};

const filterInvoice = (invoice, lcSearchValue) => {
  const { invoiceNumber, customerId } = invoice;

  const searchableValues = [];
  searchableValues.push(invoiceNumber, customerId);

  // eslint-disable-next-line
  for (const searchableValue of searchableValues) {
    if ((searchableValue || "").toLocaleLowerCase().startsWith(lcSearchValue)) {
      return true;
    }
  }

  return false;
};

export const filterOpenOrders = (openOrders, searchValue) => {
  if (!searchValue) {
    return openOrders;
  }
  const lcSearchValue = searchValue.toLocaleLowerCase();
  return openOrders.filter(order =>
    filterOrder(order, order.items || [], lcSearchValue)
  );
};

export const filterBackOrders = (backOrderLines, searchValue) => {
  if (!searchValue) {
    return backOrderLines;
  }
  const lcSearchValue = searchValue.toLocaleLowerCase();
  return backOrderLines.filter(itemLine =>
    filterOrder(itemLine.order, [itemLine], lcSearchValue)
  );
};

export const filterInvoices = (invoices, searchValue) => {
  if (!searchValue) {
    return invoices;
  }

  const lcSearchValue = searchValue.toLocaleLowerCase();
  return invoices.filter(invoice => filterInvoice(invoice, lcSearchValue));
};

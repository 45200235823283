/* eslint-disable consistent-return */
import moment from "moment";
// eslint-disable-next-line
import { RestService } from "../../../components/generic";
import ensureTrailingSlash from "../../../utils/url/url";
import {
  preProcessOrderResults,
  combineOrderGroups,
} from "../../../utils/orderUtil/orderUtil";
import { sortBackOrderlinesByName } from "../../../utils/orderLineUtil/orderLineUtil";
import { decompress } from "../../../utils/dataCompression/dataCompression";

const ISO_8601_FORMAT = "YYYY-MM-DD";
const baseUrl = ensureTrailingSlash(process.env.REACT_APP_ECOM_SERVICE);
const orderSearchApi = "orders/search/compressed/v1"; // process.env.REACT_APP_ECOM_ORDER_SEARCH_API; // NOSONAR
const orderApi = ensureTrailingSlash(process.env.REACT_APP_ORDER_API);

export const Order = Object.freeze({
  FETCH_ORDERS_STARTED: "FETCH_ORDERS_STARTED",
  FETCH_ORDERS_FINISHED: "FETCH_ORDERS_FINISHED",
  FETCH_ORDERS_ERROR: "FETCH_ORDERS_ERROR",
  SEND_ORDER_STARTED: "SEND_ORDER_STARTED",
  SEND_ORDER_FINISHED: "SEND_ORDER_FINISHED",
  SEND_ORDER_ERROR: "SEND_ORDER_ERROR",
  SEND_BACKORDER_STARTED: "SEND_BACKORDER_STARTED",
  SEND_BACKORDER_FINISHED: "SEND_BACKORDER_FINISHED",
  SEND_BACKORDER_ERROR: "SEND_BACKORDER_ERROR",
  FETCH_ORDER_STARTED: "FETCH_ORDER_STARTED",
  FETCH_ORDER_FINISHED: "FETCH_ORDER_FINISHED",
  FETCH_ORDER_ERROR: "FETCH_ORDER_ERROR",
  SET_DATE_RANGE: "SET_DATE_RANGE",
  SET_QUERY_TEXT: "SET_QUERY_TEXT",
  CLEAR_ALL_ERRORS: "CLEAR_ALL_ERRORS",
  FETCH_EXCEL_STARTED: "FETCH_EXCEL_STARTED",
  FETCH_EXCEL_FINISHED: "FETCH_EXCEL_FINISHED",
  FETCH_EXCEL_ERROR: "FETCH_EXCEL_ERROR",
});

// actions

export const setDateRange = (startDate, endDate) => ({
  type: Order.SET_DATE_RANGE,
  payload: {
    startDate,
    endDate,
  },
});

export const setQueryText = text => ({
  type: Order.SET_QUERY_TEXT,
  payload: text,
});

export const OrderFilterType = {
  NoFilter: "NoFilter",
  OpenOrdersBackOrders: "OpenOrdersBackOrders",
  OrderHistory: "OrderHistory",
};

export const fetchOrders =
  (
    startIndex,
    stopIndex,
    startDate,
    endDate,
    queryText,
    filter = { type: OrderFilterType.NoFilter }
  ) =>
  async (dispatch, getState) => {
    const pageSize = stopIndex - startIndex + 1;
    const startDateStr = moment(startDate).format(ISO_8601_FORMAT);
    const endDateStr = moment(endDate).format(ISO_8601_FORMAT);
    const query = queryText || "";
    const filters = filter.value || "";
    const path = `${baseUrl}${orderSearchApi}?startIndex=${startIndex}&pageSize=${pageSize}&query=${query}&startDate=${startDateStr}&endDate=${endDateStr}&${filters}`;

    try {
      // start
      dispatch({ type: Order.FETCH_ORDERS_STARTED });

      const compressedResult = await RestService.get(path);
      const result = JSON.parse(decompress(compressedResult));

      const overallResultCount = result.overallCount;
      const foundOrders = result.orders;

      let orderGroups = preProcessOrderResults(foundOrders, overallResultCount);

      // append if not starting from zero index
      if (startIndex > 0) {
        // get existing and append results
        const orderState = getState().order;
        const orders = orderState.orders.slice(0);
        const openOrders = orderState.openOrders.slice(0);
        const backOrderLines = orderState.backOrderLines.slice(0);
        orderGroups = combineOrderGroups(
          { orders, openOrders, backOrderLines },
          orderGroups
        );
      }

      const sortedBackOrderLines = sortBackOrderlinesByName(
        orderGroups.backOrderLines
      );

      const overallOrderCount =
        filter.type === OrderFilterType.OpenOrdersBackOrders
          ? orderGroups.filteredOrderCount
          : overallResultCount;

      // update products
      dispatch({
        type: Order.FETCH_ORDERS_FINISHED,
        payload: {
          orders: orderGroups.orders,
          openOrders: orderGroups.openOrders,
          backOrderLines: sortedBackOrderLines,
          startIndex,
          overallOrderCount,
          analytics: {
            startDateStr,
            endDateStr,
            startIndex,
            filter,
            queryText,
            resultCount: overallOrderCount,
          },
        },
      });

      return foundOrders;
    } catch (error) {
      // TODO: error handling
      dispatch({ type: Order.FETCH_ORDERS_ERROR, payload: error });
    }
  };

export const fetchOrder = orderId => async dispatch => {
  const path = `${baseUrl}${orderApi}order/${orderId}`;

  try {
    // start
    dispatch({ type: Order.FETCH_ORDER_STARTED });

    const result = await RestService.get(path);

    // update products
    dispatch({
      type: Order.FETCH_ORDER_FINISHED,
      payload: result.order,
    });

    return result;
  } catch (error) {
    // TODO: error handling
    dispatch({ type: Order.FETCH_ORDER_ERROR, payload: error });
  }
};

export const orderMakeOrder = orderData => async dispatch => {
  const path = `${baseUrl + orderApi}create`;

  try {
    // start
    dispatch({ type: Order.SEND_ORDER_STARTED });

    const orderResult = await RestService.post(path, orderData);

    // finished
    dispatch({ type: Order.SEND_ORDER_FINISHED });

    return orderResult;
  } catch (error) {
    dispatch({ type: Order.SEND_ORDER_ERROR, payload: error });
    return Promise.reject(error);
  }
};

const updateOrderLinesToBeWaitingSapUpdate = (orderlines, orderLineId) =>
  (orderlines || []).map(orderLine => {
    if (orderLine.orderLineId === orderLineId) {
      return { ...orderLine, isWaitingSapUpdate: true };
    }
    return orderLine;
  });
const updateOrdersToBeWaitingSapUpdate = (orders, orderId, orderLineId) =>
  orders.map(order => {
    if (order.orderId === orderId) {
      const orderlines = updateOrderLinesToBeWaitingSapUpdate(
        order.items,
        orderLineId
      );
      return { ...order, items: orderlines };
    }
    return order;
  });

export const modifyOrderline =
  (orderId, orderLineId, quantity) => async (dispatch, getState) => {
    const path = `${baseUrl + orderApi}modifyOrderLine`;
    const data = {
      orderNumber: orderId,
      orderLineNumber: orderLineId,
      newQuantity: quantity,
    };
    try {
      // start
      dispatch({ type: Order.SEND_BACKORDER_STARTED });

      const orderResult = await RestService.post(path, data);

      const orderState = getState().order;
      const { orders, order, backOrderLines } = orderState;

      // Update orders to be waiting SAP update
      const updatedOrders = updateOrdersToBeWaitingSapUpdate(
        orders,
        orderId,
        orderLineId
      );
      const updatedSingleOrder = updateOrdersToBeWaitingSapUpdate(
        [order],
        orderId,
        orderLineId
      );
      const updatedBackOrderLines = updateOrderLinesToBeWaitingSapUpdate(
        backOrderLines,
        orderLineId
      );

      // finished
      dispatch({
        type: Order.SEND_BACKORDER_FINISHED,
        payload: {
          orders: updatedOrders,
          order: updatedSingleOrder[0],
          backOrderLines: updatedBackOrderLines,
        },
      });

      return orderResult;
    } catch (error) {
      dispatch({ type: Order.SEND_BACKORDER_ERROR, payload: error });
    }
  };

export const fetchExportToExcelUrl =
  (customerIds, searchQuery, locale) => async dispatch => {
    try {
      dispatch({ type: Order.FETCH_EXCEL_STARTED });
      const path = `${baseUrl + orderApi}report`;

      const queryObj = {
        locale,
        customerIds: customerIds.map(id => id.toString()).join(","),
        searchQuery,
      };
      const queryParams = Object.keys(queryObj)
        .filter(key => !!queryObj[key])
        .map(key => `${key}=${queryObj[key]}`)
        .join("&");

      const res = await RestService.post(`${path}?${queryParams}`);

      dispatch({ type: Order.FETCH_EXCEL_FINISHED });

      return res;
    } catch (error) {
      dispatch({ type: Order.FETCH_EXCEL_ERROR, payload: error });
      return Promise.reject(error);
    }
  };

// state
const INIT_STATE = {
  // search for last full 24 months
  startDate: moment().subtract(24, "months").startOf("month"),
  // today
  endDate: moment().endOf("day"),
  // query text
  query: null,
  orders: [],
  openOrders: [],
  backOrderLines: [],
  startIndex: 0,
  overallOrderCount: 0,
  order: {},
  fetchingOrders: false,
  ordersFetchError: null,
  sendingOrder: false,
  orderSendError: null,
  sendingBackorder: false,
  backorderSendError: null,
  fetchingExcelReport: false,
};

export const orderReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case Order.CLEAR_ALL_ERRORS: {
      return {
        ...state,
        ordersFetchError: null,
        orderSendError: null,
        backorderSendError: null,
      };
    }
    case Order.FETCH_ORDERS_STARTED:
      return { ...state, fetchingOrders: true, ordersFetchError: null };
    case Order.FETCH_ORDERS_FINISHED: {
      const { orders, openOrders, backOrderLines, overallOrderCount } =
        action.payload;
      return {
        ...state,
        fetchingOrders: false,
        orders,
        overallOrderCount,
        openOrders,
        backOrderLines,
      };
    }
    case Order.FETCH_ORDERS_ERROR:
      return {
        ...state,
        fetchingOrders: false,
        ordersFetchError: action.payload,
      };
    case Order.SEND_ORDER_STARTED:
      return { ...state, sendingOrder: true, orderSendError: null };
    case Order.SEND_ORDER_FINISHED:
      return { ...state, sendingOrder: false };
    case Order.SEND_ORDER_ERROR:
      return { ...state, sendingOrder: false, orderSendError: action.payload };
    case Order.FETCH_ORDER_STARTED:
      return { ...state, fetchingOrders: true, ordersFetchError: null };
    case Order.FETCH_ORDER_FINISHED: {
      return { ...state, fetchingOrders: false, order: action.payload };
    }
    case Order.FETCH_ORDER_ERROR:
      return {
        ...state,
        fetchingOrders: false,
        ordersFetchError: action.payload,
      };
    case Order.SET_DATE_RANGE: {
      const { startDate, endDate } = action.payload;
      return { ...state, startDate, endDate };
    }
    case Order.SET_QUERY_TEXT: {
      return { ...state, query: action.payload };
    }
    case Order.SEND_BACKORDER_STARTED:
      return { ...state, sendingBackorder: true, backorderSendError: null };
    case Order.SEND_BACKORDER_FINISHED: {
      const { orders, order, backOrderLines } = action.payload;
      return {
        ...state,
        sendingBackorder: false,
        orders,
        order,
        backOrderLines,
      };
    }
    case Order.SEND_BACKORDER_ERROR:
      return {
        ...state,
        sendingBackorder: false,
        backorderSendError: action.payload,
      };
    case Order.FETCH_EXCEL_STARTED:
      return { ...state, fetchingExcelReport: true };
    case Order.FETCH_EXCEL_FINISHED:
    case Order.FETCH_EXCEL_ERROR:
      return { ...state, fetchingExcelReport: false };
    default:
      return state;
  }
};

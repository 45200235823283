/* eslint-disable consistent-return */
// eslint-disable-next-line
import { RestService } from "../../../components/generic";
import ensureTrailingSlash from "../../../utils/url/url";

export const Announcements = Object.freeze({
  FETCH_STARTED: "ANNOUCEMENTS_FETCH_STARTED",
  FETCH_FINISHED: "ANNOUCEMENTS_FETCH_FINISHED",
  FETCH_ERROR: "ANNOUCEMENTS_FETCH_ERROR",
});

const INIT_STATE = {
  announcements: [],
  showAllUrl: null,
  announcementsFetching: false,
  announcementsFetchError: null,
};

const baseUrl = ensureTrailingSlash(process.env.REACT_APP_ECOM_SERVICE);
const annoucementApi = process.env.REACT_APP_ANNOUCEMENT_API;

export const fetchAnnouncements =
  (filters = "") =>
  async dispatch => {
    try {
      dispatch({ type: Announcements.FETCH_STARTED });

      const path = `${baseUrl}${annoucementApi}${filters}`;
      const result = await RestService.get(path);

      dispatch({ type: Announcements.FETCH_FINISHED, payload: result });

      return result;
    } catch (error) {
      dispatch({ type: Announcements.FETCH_ERROR, payload: error });
    }
  };

// eslint-disable-next-line
export const announcementsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Announcements.FETCH_STARTED: {
      return {
        ...state,
        announcementsFetching: true,
        announcementsFetchError: null,
      };
    }
    case Announcements.FETCH_FINISHED: {
      const { announcements, showAllUrl } = action.payload;
      return {
        ...state,
        announcementsFetching: false,
        announcements,
        showAllUrl,
      };
    }
    case Announcements.FETCH_ERROR:
      return {
        ...state,
        announcementsFetching: false,
        announcementsFetchError: action.payload,
      };

    default:
      return state;
  }
};
